import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const NoRequests = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 80px 0;
		img {
			width: 115px;
			margin-bottom: 24px;
		}
		p {
			color: ${COLORS.darkGray1};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 4px;
		}
	`;
}
