import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		flex-direction: row-reverse;
		margin-bottom: 24px;
	`;
}
