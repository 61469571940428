import React from 'react';

import BODashboard from 'modules/BackOffice/containers/BODashboard';

export const BackOfficeDashboardPage = () => {
	return (
		<>
			<BODashboard />
		</>
	);
};

export default BackOfficeDashboardPage;
