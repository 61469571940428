import React, { Key } from 'react';

import { ColumnsDataType } from 'components/Table/Table.types';
import { IQuestionnaires, TableColumnsType } from 'modules/Common/types';
import { TStyled } from 'theme';
import { Routes } from 'types';
import { getDateFormat } from 'utils/helpers';

import { Styled } from '../Contracts/Contracts.styled';

type DataTableType = IQuestionnaires & {
	key?: Key;
};

export const columns = (clientId?: string): ColumnsDataType & TableColumnsType => [
	{
		title: 'Client',
		dataIndex: 'client',
		key: 'title',
		sorter: !clientId,
		render: (_: string, { client }: Partial<DataTableType>) => (
			// TODO Will be redirected with a separate view on the client details
			<Styled.PageLink to={`${Routes.BackOffice}${Routes.Questionnaires}`}>
				{client}
			</Styled.PageLink>
		),
	},
	{
		title: 'Questionnaire',
		dataIndex: 'title',
		key: 'title',
		render: (_: string, { title }: Partial<DataTableType>) => {
			return (
				<TStyled.PageLink to={`${Routes.BackOffice}${Routes.Questionnaires}`} state={{ title }}>
					<span>{title}</span>
				</TStyled.PageLink>
			);
		},
	},
	{
		title: 'Number of questions',
		dataIndex: 'numberOfQuestions',
		key: 'numberOfQuestions',
		align: 'center',
		render: (_: string, { numberOfQuestions }: Partial<DataTableType>) => (
			<span>{numberOfQuestions ? numberOfQuestions : 0}</span>
		),
	},
	{
		title: 'Created date',
		dataIndex: 'created',
		key: 'created',
		align: 'center',
		sorter: true,
		render: (_: string, { created }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(created) ?? '-';

			return <>{formattedData}</>;
		},
	},
];
