import React from 'react';

import { ProtectedRoute } from 'modules/Common/components/ProtectedRoute';
import ErrorPage from 'pages/ErrorPages/ErrorPage';
import { RouteTypesEnum } from 'types';

import HRCalendarPage from '../pages/HRCalendarPage';
import HRCreateEditEmployeePage from '../pages/HRCreateEditEmployeePage';
import DashboardPage from '../pages/HrDashboard';
import HREmployeeListPage from '../pages/HREmployeeListPage';
import HRModuleMainPage from '../pages/HRModuleMainPage';
import HRModuleSettingsPage from '../pages/HRModuleSettingsPage';
import HROwnProfilePage from '../pages/HROwnProfile';
import HRViewEmployeePage from '../pages/HRViewEmployeePage';

import { Routes, SettingsRoutes } from './types';

const HRModuleRoutes = [
	{
		path: Routes.HRModule,
		element: (
			<ProtectedRoute type={RouteTypesEnum.REGISTERED}>
				<HRModuleMainPage />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
		children: [
			{
				path: `${Routes.HRModule}${Routes.Dashboard}`,
				element: <DashboardPage />,
			},
			{
				path: `${Routes.HRModule}${Routes.Calendar}`,
				element: <HRCalendarPage />,
			},
			{
				path: `${Routes.HRModule}${Routes.PeopleManagement}`,
				element: <HREmployeeListPage />,
			},
			{
				path: `${Routes.HRModule}${Routes.ModuleSettings}`,
				element: <HRModuleSettingsPage />,
			},
			{
				path: `${Routes.HRModule}${Routes.EmployeeCreate}`,
				element: <HRCreateEditEmployeePage />,
			},
			{
				path: `${Routes.HRModule}${Routes.EmployeeEdit}/:employeeId`,
				element: <HRCreateEditEmployeePage />,
			},
			{
				path: `${Routes.HRModule}${Routes.Employee}/:employeeId`,
				element: <HRViewEmployeePage />,
			},
			{
				path: `${Routes.HRModule}${Routes.Settings}`,
				element: <div>hr settings1</div>,
				children: [],
			},
			{
				path: SettingsRoutes.UserDetails,
				element: <HROwnProfilePage />,
			},
		],
	},
];

export default HRModuleRoutes;
