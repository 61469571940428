import React, { useCallback, type FC } from 'react';
import { Field } from 'react-final-form';

import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import FileItemRendererAttachments from 'modules/Common/components/FileItemRendererAttachments';
import { acceptedEmployeeFilesUploadFormFileFormat } from 'modules/Common/constants';
import { FileUploadTypesEnum, IUser } from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { UserRolesEnum } from 'types';
import { handleFileDownload } from 'utils/helpers';

import { Styled } from './Attachments.styled';

const fileUploadURL = `${process.env.REACT_APP_API_URL}/hr/employee/file`;

type AttachmentsProps = {
	attachmentsList: UploadFile[];
	newAttachmentsList: Set<string>;
	user: IUser;
	handleUpdateAttachmentsList: (fileList: UploadFile[]) => void;
	handleUpdateForNewFiles: (newFiles: Set<string>) => void;
};

export const Attachments: FC<AttachmentsProps> = ({
	attachmentsList,
	newAttachmentsList,
	user,
	handleUpdateAttachmentsList,
	handleUpdateForNewFiles,
}) => {
	const isSuperUser = [UserRolesEnum.HR_MANAGER, UserRolesEnum.JAT_SUPER_USER].includes(
		user?.roleId,
	);

	const handleDownload = useCallback((file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	}, []);

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData) => {
				onChange([...value, responseData]);
				handleUpdateForNewFiles((prev) => new Set(prev).add(responseData));
			},
			{
				fileList: attachmentsList,
				accept: acceptedEmployeeFilesUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					const updatedFileList = attachmentsList.filter((f) => f.uid !== file.uid);
					handleUpdateAttachmentsList(updatedFileList);
					onChange(updatedFileList.map((f) => f?.response?.data));
					handleUpdateForNewFiles((prev) => {
						const updated = new Set(prev);
						updated.delete(file?.response?.data);

						return updated;
					});
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.FILE,
				maxCount: undefined,
				url: fileUploadURL,
			},
			handleUpdateAttachmentsList,
		);

	const isFileRemovable = useCallback(
		(file: UploadFile) => isSuperUser || newAttachmentsList.has(file?.response?.data),
		[isSuperUser, newAttachmentsList],
	);

	const customItemRender = useCallback(
		(originNode: React.ReactElement, file: UploadFile) => {
			const isRemovable = isFileRemovable(file);

			return (
				<FileItemRendererAttachments
					originNode={originNode}
					file={file}
					isRemovable={isRemovable}
					onDownload={handleDownload}
					onRemove={(f) => draggerProps({ onChange: () => undefined, value: [] }).onRemove(f)}
				/>
			);
		},
		[isFileRemovable, newAttachmentsList, handleDownload, draggerProps, isSuperUser],
	);

	return (
		<Styled.FormBlockWrap>
			<Field name='attachments'>
				{({ input, meta }) => (
					<FieldWrapper
						isFixed
						name='attachments'
						label='Attachments'
						errorMessage={meta.submitFailed && meta.touched && meta.error}
					>
						<Styled.Dragger
							{...draggerProps(input)}
							listType='text'
							showUploadList={{
								showDownloadIcon: false,
								showRemoveIcon: false,
							}}
							itemRender={customItemRender}
						>
							<p className='ant-upload-drag-icon'>
								<InboxOutlined />
							</p>
							<p className='ant-upload-text'>Upload a files or drag and drop it</p>
							<p className='ant-upload-hint'> PDF, DOC, DOCX, XLS, XLSX, JPEG, PNG up to 15MB </p>
						</Styled.Dragger>
					</FieldWrapper>
				)}
			</Field>
		</Styled.FormBlockWrap>
	);
};
