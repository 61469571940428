import { memo } from 'react';

import { COLORS } from 'theme';

import { IconType } from '../SVG.types';

function Icon({ fill = COLORS.blue, width = '24', height = '24' }: IconType) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.66667 6C6.29848 6 6 6.29848 6 6.66667V12C6 12.3682 6.29848 12.6667 6.66667 12.6667H12C12.3682 12.6667 12.6667 12.3682 12.6667 12V6.66667C12.6667 6.29848 12.3682 6 12 6H6.66667ZM4.66667 6.66667C4.66667 5.5621 5.5621 4.66667 6.66667 4.66667H12C13.1046 4.66667 14 5.5621 14 6.66667V12C14 13.1046 13.1046 14 12 14H6.66667C5.5621 14 4.66667 13.1046 4.66667 12V6.66667Z'
				fill='#314EFF'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H9.33333C9.86377 2 10.3725 2.21071 10.7475 2.58579C11.1226 2.96086 11.3333 3.46957 11.3333 4V5.33333C11.3333 5.70152 11.0349 6 10.6667 6C10.2985 6 10 5.70152 10 5.33333V4C10 3.82319 9.92976 3.65362 9.80474 3.5286C9.67971 3.40357 9.51014 3.33333 9.33333 3.33333H4C3.82319 3.33333 3.65362 3.40357 3.5286 3.5286C3.40357 3.65362 3.33333 3.82319 3.33333 4V9.33333C3.33333 9.51014 3.40357 9.67971 3.5286 9.80474C3.65362 9.92976 3.82319 10 4 10H5.33333C5.70152 10 6 10.2985 6 10.6667C6 11.0349 5.70152 11.3333 5.33333 11.3333H4C3.46957 11.3333 2.96086 11.1226 2.58579 10.7475C2.21071 10.3725 2 9.86377 2 9.33333V4C2 3.46957 2.21071 2.96086 2.58579 2.58579Z'
				fill={fill}
			/>
		</svg>
	);
}

export default memo(Icon);
