import React, { type FC } from 'react';

import { ButtonTypes } from 'components/Button/Button.types';
import HRThemedButton from 'modules/HR/components/HRThemedButton';

import { Styled } from './EnableDisableWorkingDaysModal.styled';

type EnableDisableWorkingDaysModalProps = {
	isOpen: string | null;
	onCancel: () => void;
	onApprove: () => void;
};

export const EnableDisableWorkingDaysModal: FC<EnableDisableWorkingDaysModalProps> = ({
	isOpen,
	onCancel,
	onApprove,
}) => {
	return (
		<Styled.Modal open={!!isOpen} footer={null} centered onCancel={onCancel} width='auto'>
			<Styled.ModalContent>
				<Styled.ModalQuestion>{isOpen}</Styled.ModalQuestion>
				<Styled.ButtonBox>
					<HRThemedButton type='button' buttonType={ButtonTypes.primary} onClick={onApprove}>
						Yes
					</HRThemedButton>
					<HRThemedButton type='button' buttonType={ButtonTypes.tertiary} onClick={onCancel}>
						No
					</HRThemedButton>
				</Styled.ButtonBox>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
