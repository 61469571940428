import styled, { keyframes } from 'styled-components/macro';

import { COLORS } from 'theme';

type PieProps = {
	color: string;
	percentage: number;
	offsetPercentage: number;
	marginRight?: string;
	width?: string;
	borderWidth?: string;
	fontSize?: string;
};

export const statisticAnimation = keyframes`
  from {
	transform: scale(20%) rotate(1turn);
  }
  to {
	transform: scale(100%) rotate(1turn);
  }
`;

export namespace Styled {
	export const Pie = styled.div<PieProps>`
		width: ${(props) => props.width};
		aspect-ratio: 1;
		display: inline-grid;
		place-content: center;
		font-size: 25px;
		font-weight: bold;
		font-family: sans-serif;
		position: relative;
		font-size: ${(props) => props.fontSize};

		--percent: ${(props) => props.percentage};
		--offsetPercentage: ${(props) => props.offsetPercentage};

		&:before {
			content: '';
			position: absolute;
			border-radius: 50%;
			inset: 0;
			background-image: conic-gradient(
				${COLORS.lightGray2} 0%,
				${COLORS.lightGray2} calc(var(--offsetPercentage) * 1%),
				${(props) => props.color} calc(var(--offsetPercentage) * 1%),
				${(props) => props.color} calc((var(--offsetPercentage) + var(--percent)) * 1%),
				${COLORS.lightGray2} calc((var(--offsetPercentage) + var(--percent)) * 1%)
			);
			mask: radial-gradient(
				farthest-side,
				#0000 calc(99% - ${(props) => props.borderWidth}),
				#000 calc(100% - ${(props) => props.borderWidth})
			);
			animation: ${statisticAnimation} 1s linear;
		}
	`;
}
