import React, { FC, useState } from 'react';
import { connect } from 'react-redux';

import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

import UserIcon from 'modules/Common/components/UserIcon';
import { IUser } from 'modules/Common/types';
import { CalendarEventType } from 'modules/Common/types/hrModuleTypes';
import HRCalendarPopupEvent from 'modules/HR/components/HRCalendarPopupEvent/HRCalendarPopupEvent';
import { CALENDAR_EVENT_TYPES } from 'modules/HR/constants/HRModuleConstants.constants';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import {
	capitalizeFirstLetterOfEachWord,
	fetchEmployeePhotoWithCredentials,
	getBackgroundColor,
} from 'utils/helpers';

import { Styled } from './HRCalendarCustomEvent.styled';

type InterviewCalendarPopupEventTypeProps = {
	event: CalendarEventType;
	user: IUser;
	popoverPlacement?: TooltipPlacement;
	popoverTrigger?: 'click' | 'hover';
	popoverIsOff?: boolean;
	handleEventDecline: (id: number) => void;
	handleEventApprove: (id: number) => void;
	handleEventEdit: (id: number) => void;
	handleEventDelete: (id: number) => void;
};

const HRCalendarCustomEvent: FC<InterviewCalendarPopupEventTypeProps> = ({
	event,
	user,
	popoverPlacement,
	popoverTrigger,
	popoverIsOff,
	handleEventDecline,
	handleEventApprove,
	handleEventEdit,
	handleEventDelete,
}) => {
	const [isPopoverVisible, setIsPopoverVisible] = useState(false);

	const handleApproveWrapper = (id: number) => {
		handleEventApprove(id);
		setIsPopoverVisible(false);
	};

	const handleDeclineWrapper = (id: number) => {
		handleEventDecline(id);
		setIsPopoverVisible(false);
	};

	const handleEditWrapper = (id: number) => {
		handleEventEdit(id);
		setIsPopoverVisible(false);
	};

	const {
		id,
		type,
		name,
		employeeFullName,
		employeeId,
		start,
		end,
		description,
		icon,
		iconBackgroundColor,
		employeePhotoUuid,
		isApproved,
	} = event || {};

	const eventTitle =
		type === CALENDAR_EVENT_TYPES.bankHoliday || type === CALENDAR_EVENT_TYPES.companyHoliday
			? `${icon} ${name}`
			: `${icon} ${capitalizeFirstLetterOfEachWord(type)} - ${employeeFullName}`;

	if (type === CALENDAR_EVENT_TYPES.bankHoliday || type === CALENDAR_EVENT_TYPES.companyHoliday) {
		return <span>{eventTitle}</span>;
	}

	const popoverTitle = (
		<Styled.PopoverHead>
			<UserIcon
				imageId={employeePhotoUuid}
				backgroundColor={(employeeId && getBackgroundColor(employeeId)) || iconBackgroundColor}
				fullName={employeeFullName}
				width='24px'
				fetchAndSetImageHelper={fetchEmployeePhotoWithCredentials}
			/>
			<h4>{employeeFullName}</h4>
		</Styled.PopoverHead>
	);

	return (
		<Styled.EventWrapper>
			{popoverIsOff ? (
				<span>{eventTitle}</span>
			) : (
				<Popover
					open={isPopoverVisible}
					onOpenChange={setIsPopoverVisible}
					placement={popoverPlacement || 'rightTop'}
					title={popoverTitle}
					trigger={popoverTrigger || 'click'}
					content={
						<HRCalendarPopupEvent
							id={id}
							employeeId={employeeId || 0}
							isApproved={isApproved}
							user={user}
							type={type}
							start={start}
							end={end}
							comment={description}
							handleApprove={handleApproveWrapper}
							handleDecline={handleDeclineWrapper}
							handleEdit={handleEditWrapper}
							handleDelete={handleEventDelete}
						/>
					}
				>
					<span>{eventTitle}</span>
				</Popover>
			)}
		</Styled.EventWrapper>
	);
};

export default connect((state) => ({
	user: unregisteredDucks.unregisteredSelectors.getUser(state),
}))(HRCalendarCustomEvent);
