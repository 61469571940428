import React, { type FC } from 'react';
import { Field, useFormState } from 'react-final-form';

import { DatePicker, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FormApi } from 'final-form';

import FieldWrapper from 'components/FieldWrapper';
import { IEmployeeValues } from 'modules/Common/types/employee';
import {
	composeValidators,
	endDateValidator,
	requiredFieldValidator,
	textValidator,
} from 'utils/validators';

import FormBlockLine from '../FormBlockLine';

import { Styled } from './JobDetails.styled';

type JobDetailsProps = {
	dateFormat: string;
	formObj: FormApi<IEmployeeValues, Partial<IEmployeeValues>>;
	disabledFields?: string[];
	getIsATSEmployee: (roleId: number) => boolean;
};

export const JobDetails: FC<JobDetailsProps> = ({
	dateFormat,
	formObj,
	disabledFields,
	getIsATSEmployee,
}) => {
	const startDate = formObj.getState().values.startDate;
	const minEndDate = startDate ? dayjs(startDate).startOf('day').add(1, 'day') : null;
	const endDate = formObj.getState().values.leaveDate;
	const minStartDate = endDate ? dayjs(endDate).startOf('day') : null;
	const { values } = useFormState();
	const isATSEmployee = getIsATSEmployee(values?.role);

	return (
		<Styled.FormBlockWrap title='Job Details'>
			<FormBlockLine>
				<Field name='startDate' validate={isATSEmployee ? () => null : requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							isFixed
							name='startDate'
							label='Start Date'
							required={!isATSEmployee}
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<DatePicker
								{...input}
								format={dateFormat}
								inputReadOnly={false}
								picker='date'
								disabledDate={(current) =>
									minStartDate ? current && current > minStartDate : false
								}
								disabled={disabledFields?.includes('startDate')}
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field
					name='leaveDate'
					validate={(value: Dayjs) => endDateValidator(formObj.getState().values.startDate, value)}
				>
					{({ input, meta }) => (
						<FieldWrapper
							isFixed
							name='leaveDate'
							label='Leaving Date'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<DatePicker
								{...input}
								format={dateFormat}
								inputReadOnly={false}
								picker='date'
								disabledDate={(current) => {
									return minEndDate ? current && current < minEndDate : false;
								}}
								disabled={disabledFields?.includes('leaveDate')}
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field
					name='jobTitle'
					validate={
						isATSEmployee ? () => null : composeValidators(requiredFieldValidator, textValidator)
					}
				>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								isFixed
								name='jobTitle'
								label='Job Title'
								required={!isATSEmployee}
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input
									{...input}
									placeholder='Enter Job Title'
									autoComplete='off'
									disabled={disabledFields?.includes('jobTitle')}
								/>
							</FieldWrapper>
						</>
					)}
				</Field>
				<Field name='departmentName'>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='departmentName'
								label='Department'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input
									{...input}
									placeholder='Enter Department'
									autoComplete='off'
									disabled={disabledFields?.includes('departmentName')}
								/>
							</FieldWrapper>
						</>
					)}
				</Field>
			</FormBlockLine>
		</Styled.FormBlockWrap>
	);
};
