import { handlersDefaultCase } from 'utils/reduxHelpers';

import { initialState } from './reducer.initial_state';
import { ActionTypes, GenericAction } from './types';

export const HRModuleReducer = (TYPES: ActionTypes) => {
	return (state = initialState, action: GenericAction<unknown> = { type: '' }) => {
		const handlers = {
			[TYPES.GET_EMPLOYEE_ATTACHMENTS.SUCCESS]: () => ({
				...state,
				employeeDetails: {
					...state.employeeDetails,
					attachments: action.payload,
				},
			}),
			[TYPES.GET_EMPLOYEE_ATTACHMENTS.FAILED]: () => ({
				...state,
				employeeDetails: {
					...state.employeeDetails,
					attachments: [],
				},
			}),
			[TYPES.GET_EMPLOYEE_STATUSES.SUCCESS]: () => ({
				...state,
				employeeStatuses: action.payload,
			}),
			[TYPES.VIEW_EMPLOYEES_LIST.SUCCESS]: () => ({
				...state,
				employeesList: action.payload,
			}),
			[TYPES.VIEW_EMPLOYEES_LIST.FAILED]: () => ({
				...state,
				employeesList: initialState.employeesList,
			}),
			[TYPES.GET_EMPLOYEE_BY_ID.SUCCESS]: () => ({
				...state,
				employeeDetails: action.payload,
			}),
			[TYPES.GET_EMPLOYEE_BY_ID.FAILED]: () => ({
				...state,
				employeeDetails: initialState.employeeDetails,
			}),
			[TYPES.GET_ONOFF_BOARDING_SETTINGS.SUCCESS]: () => ({
				...state,
				onOffBoardingSettings: action.payload,
			}),
			[TYPES.GET_ONOFF_BOARDING_SETTINGS.FAILED]: () => ({
				...state,
				onOffBoardingSettings: initialState.onOffBoardingSettings,
			}),
			[TYPES.GET_EMPLOYEE_SHORT_LIST.SUCCESS]: () => ({
				...state,
				employeeShortList: action.payload,
			}),
			[TYPES.GET_EMPLOYEE_SHORT_LIST.FAILED]: () => ({
				...state,
				employeeShortList: initialState.employeeShortList,
			}),
			[TYPES.GET_OWN_PROFILE.SUCCESS]: () => ({
				...state,
				employeeDetails: action.payload,
			}),
			[TYPES.GET_OWN_PROFILE.FAILED]: () => ({
				...state,
				employeeDetails: initialState.employeeDetails,
			}),
			[TYPES.RESET_EMPLOYEE_DETAILS as unknown as string]: () => ({
				...state,
				employeeDetails: initialState.employeeDetails,
			}),
			[TYPES.GET_HR_VACATION_SETTINGS.SUCCESS]: () => ({
				...state,
				vacationSettings: action.payload,
			}),
			[TYPES.CREATE_UPDATE_VACATION_SETTINGS.SUCCESS]: () => {
				const { id } = action.payload;
				const existingIndex = state.vacationSettings.findIndex((vacation) => vacation?.id === id);

				return {
					...state,
					vacationSettings:
						existingIndex === -1
							? [...state.vacationSettings, action.payload]
							: state.vacationSettings.map((vacation, index) =>
								index === existingIndex ? action.payload : vacation,
							  ),
				};
			},
			[TYPES.DELETE_VACATION_SETTINGS.SUCCESS]: () => ({
				...state,
				vacationSettings: state.vacationSettings.filter(
					(vacation) => vacation?.employee?.id !== action.payload,
				),
			}),
			[TYPES.GET_EMPLOYEE_OPTIONS.SUCCESS]: () => ({
				...state,
				employeeOptions: action.payload,
			}),
			[TYPES.GET_WORK_WEEK_SETTINGS.SUCCESS]: () => ({
				...state,
				workWeekSettings: action.payload,
			}),
			[TYPES.UPDATE_WORK_WEEK_SETTINGS.SUCCESS]: () => ({
				...state,
				workWeekSettings: action.payload,
			}),
			[TYPES.GET_TIME_OFF_REQUEST_DURATION.SUCCESS]: () => ({
				...state,
				timeOffRequestDuration: action.payload,
			}),
			[TYPES.RESET_TIME_OFF_REQUEST_DURATION as unknown as string]: () => ({
				...state,
				timeOffRequestDuration: initialState.timeOffRequestDuration,
			}),
			[TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE.SUCCESS]: () => ({
				...state,
				timeOffStaticsCurrentEmployee: action.payload,
			}),
			[TYPES.GET_ALL_TIME_OFF_REQUESTS.SUCCESS]: () => ({
				...state,
				requestTimeOffCalendarData: action.payload,
			}),
			[TYPES.GET_BANK_HOLIDAYS.SUCCESS]: () => ({
				...state,
				bankHolidays: action.payload,
			}),
		};

		return handlersDefaultCase(handlers, action, state);
	};
};
