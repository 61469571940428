import React from 'react';

import HRModuleSettings from 'modules/HR/containers/HRModuleSettings';

import { Styled } from './HRModuleSettingsPage.styled';

const HRModuleSettingsPage = () => {
	return (
		<>
			<Styled.Head>
				<Styled.Title>Module Settings</Styled.Title>
			</Styled.Head>
			<Styled.Content>
				<HRModuleSettings />
			</Styled.Content>
		</>
	);
};

export default HRModuleSettingsPage;
