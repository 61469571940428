export const enum Routes {
	HRModule = '/hr-module',
	Dashboard = '/dashboard',
	Calendar = '/calendar',
	PeopleManagement = '/people-management',
	ModuleSettings = '/module-settings',
	Settings = '/settings',
	Employee = '/employee',
	EmployeeCreate = '/employee/create',
	EmployeeEdit = '/employee/edit',
}

export const enum SettingsRoutes {
	UserDetails = '/hr-module/settings/own-profile',
}
