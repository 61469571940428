export const createActions = (TYPES) => {
	const archiveAndUnarchiveJobRequested = (id, active = true) => ({
		type: TYPES.ARCHIVE_AND_UNARCHIVE_JOB.REQUESTED,
		payload: { id, active },
	});

	const assignCandidateToVacancyRequested = (candidateApplicationId, jobs, cb) => ({
		type: TYPES.ASSIGN_CANDIDATE_TO_VACANCY.REQUESTED,
		payload: { candidateApplicationId, jobs },
		cb,
	});
	const getUnappliedCandidateJobsRequested = (data) => ({
		type: TYPES.GET_UNAPPLIED_CANDIDATE_JOBS.REQUESTED,
		payload: data,
	});

	const getAtsUsersRequested = (params) => ({
		type: TYPES.GET_ATS_USERS.REQUESTED,
		payload: params,
	});
	const getAtsUserRolesRequested = (params) => ({
		type: TYPES.GET_ATS_USER_ROLES.REQUESTED,
		payload: params,
	});
	const getAtsUserByIdRequested = (id, callback) => ({
		type: TYPES.GET_ATS_USER_BY_ID.REQUESTED,
		payload: id,
		callback,
	});
	const createAtsUserRequested = (values, callback) => ({
		type: TYPES.CREATE_ATS_USER.REQUESTED,
		payload: values,
		callback,
	});
	const deleteAtsUserRequested = (userId, callback) => ({
		type: TYPES.DELETE_ATS_USER.REQUESTED,
		payload: userId,
		callback,
	});
	const updateAtsUserRequested = (values, callback) => ({
		type: TYPES.UPDATE_ATS_USER.REQUESTED,
		payload: values,
		callback,
	});

	const copyPostJobChangesFormProcess = (id) => ({
		type: TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.REQUESTED,
		payload: id,
	});
	const getAndSavePostJobChangesFormProcess = (values) => ({
		type: TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.REQUESTED,
		payload: values,
	});
	const resetPostJobChangesFormProcess = () => ({
		type: TYPES.RESET_POST_JOB_CHANGES_FORM,
	});
	const updateRejectionMessagePerJobRequested = (id, messageRejection) => ({
		type: TYPES.UPDATE_REJECTION_MESSAGES.REQUESTED,
		payload: { id, messageRejection },
	});

	const getATSBrandingByClientId = (id) => ({
		type: TYPES.GET_ATS_BRANDING_BY_ID.REQUESTED,
		payload: id,
	});
	const getATSCareerBrandingByClientId = (id) => ({
		type: TYPES.GET_ATS_CAREEER_BRANDING_BY_ID.REQUESTED,
		payload: id,
	});

	const createOrderInvoiceRequested = (orderId) => ({
		type: TYPES.CREATE_ORDER_INVOICE.REQUESTED,
		payload: orderId,
	});
	const getAtsInvoicesRequested = (params) => ({
		type: TYPES.GET_ATS_INVOICES.REQUESTED,
		payload: params,
	});
	const getAtsInvoiceByIdRequested = (invoiceId) => ({
		type: TYPES.GET_ATS_INVOICE_BY_ID.REQUESTED,
		payload: invoiceId,
	});

	const getClientATSApplicationMessagesByClientId = (id) => ({
		type: TYPES.GET_CLIENT_APPLICATION_MESSAGES_BY_ID.REQUESTED,
		payload: id,
	});
	const getClientApplicationNotification = (id) => ({
		type: TYPES.GET_CLIENT_APPLICATION_NOTIFICATION.REQUESTED,
		payload: id,
	});

	const getCurrentClientCredits = (clientId) => ({
		type: TYPES.GET_CLIENT_CREDITS.REQUESTED,
		payload: clientId,
	});

	const getAllAtsJobsRequested = (params = { active: true }) => ({
		type: TYPES.GET_ALL_ATS_JOBS.REQUESTED,
		payload: params,
	});

	const getAtsActiveJobsRequested = () => ({
		type: TYPES.GET_ATS_ACTIVE_JOBS.REQUESTED,
	});

	const getAtsInterviewJobsListRequested = (params = { showAll: false }) => ({
		type: TYPES.GET_ATS_INTERVIEW_JOBS_LIST.REQUESTED,
		payload: params,
	});
	const getAtsEditInterviewJobsListRequested = (id) => ({
		type: TYPES.GET_ATS_EDIT_INTERVIEW_JOBS_LIST.REQUESTED,
		payload: id,
	});
	const resendAtsInterviewLinkRequested = (id, candidate) => ({
		type: TYPES.RESEND_ATS_INTERVIEW_LINK.REQUESTED,
		payload: { id, candidate },
	});

	const saveSettingsCompanyDetailsAction = (values) => ({
		type: TYPES.SETTINGS_SAVE_COMPANY_DETAILS,
		payload: values,
	});

	const getJobUpgradesRequested = () => ({
		type: TYPES.GET_JOB_UPGRAGED.REQUESTED,
	});

	const saveClientAtsBranding = (values) => ({
		type: TYPES.SAVE_ATS_BRANDING,
		payload: values,
		sendState: true,
	});

	const saveClientCareerBranding = (values) => ({
		type: TYPES.SAVE_CAREER_BRANDING,
		payload: values,
		sendState: true,
	});
	const updateClientATSApplicationMessages = (values) => ({
		type: TYPES.UPDATE_CLIENT_APPLICATION_MESSAGES.REQUESTED,
		payload: values,
	});

	const updateATSBranding = (values) => ({
		type: TYPES.UPDATE_ATS_BRANDING.REQUESTED,
		payload: values,
	});

	const orderCreditsRequested = (values, callback) => ({
		type: TYPES.ORDER_CREDITS.REQUESTED,
		payload: { values, callback },
	});

	const resetCurrentOrder = () => ({
		type: TYPES.RESET_ATS_CURRENT_ORDER,
	});

	const orderSMSRequested = (values, callback) => ({
		type: TYPES.ORDER_SMS.REQUESTED,
		payload: { values, callback },
	});

	const orderInterviewsRequested = (values, callback) => ({
		type: TYPES.ORDER_INTERVIEWS.REQUESTED,
		payload: { values, callback },
	});

	const updateAtsCareerBranding = (values) => ({
		type: TYPES.UPDATE_ATS_CAREER_BRANDING.REQUESTED,
		payload: values,
	});

	const geAtsTicketsRequested = (params) => ({
		type: TYPES.GET_ATS_TICKETS.REQUESTED,
		payload: params,
	});

	const getAtsTicketDetailsRequested = (ticketId) => ({
		type: TYPES.GET_ATS_TICKET_DETAILS.REQUESTED,
		payload: ticketId,
	});

	const getAtsCandidatesApplicationByJobIdRequested = (id, cb) => ({
		type: TYPES.GET_CANDIDATE_APPLICATION_BY_JOB_ID.REQUESTED,
		payload: { id, cb },
	});

	const getCandidateAssessmentApplicationRequested = (id) => ({
		type: TYPES.GET_CANDIDATE_ASSESSMENT_APPLICATION.REQUESTED,
		payload: id,
	});

	const getOrderByIdRequested = (id) => ({
		type: TYPES.GET_ORDER_BY_ID.REQUESTED,
		payload: id,
	});

	const getAtsInterviewTypeRequested = () => ({
		type: TYPES.GET_ATS_INTERVIEW_TYPE.REQUESTED,
	});
	const getAtsUserInterviewRequested = () => ({
		type: TYPES.GET_ATS_USER_INTERVIEW.REQUESTED,
	});
	const cancelAtsInterviewByIdRequested = (id, callback) => ({
		type: TYPES.CANCEL_ATS_INTERVIEW_BY_ID.REQUESTED,
		payload: id,
		callback,
	});
	const getAtsInterviewByIdRequested = (id) => ({
		type: TYPES.GET_ATS_INTERVIEW_BY_ID.REQUESTED,
		payload: id,
	});
	const saveAtsInterviewRequested = (values, callback) => ({
		type: TYPES.SAVE_ATS_INTERVIEW.REQUESTED,
		payload: values,
		callback,
	});
	const updateAtsInterviewRequested = (values, callback) => ({
		type: TYPES.UPDATE_ATS_INTERVIEW.REQUESTED,
		payload: values,
		callback,
	});
	const getAtsInterviewCalendarListRequested = (values) => ({
		type: TYPES.GET_ATS_INTERVIEW_CALENDAR_LIST.REQUESTED,
		payload: values,
	});
	const getClientSubscriptionRequested = (callback) => ({
		type: TYPES.GET_CLIENT_SUBSCRIPTION.REQUESTED,
		callback,
	});
	const getClientSocialNetworksRequested = () => ({
		type: TYPES.GET_CLIENT_SOCIAL_NETWORKS.REQUESTED,
	});
	const updateClientSocialNetworksRequested = (values, callback) => ({
		type: TYPES.UPDATE_CLIENT_SOCIAL_NETWORKS.REQUESTED,
		payload: values,
		callback,
	});
	const getClientEmailSignatureRequested = () => ({
		type: TYPES.GET_CLIENT_EMAIL_SIGNATURE.REQUESTED,
	});
	const updateClientEmailSignatureRequested = (values) => ({
		type: TYPES.UPDATE_CLIENT_EMAIL_SIGNATURE.REQUESTED,
		payload: values,
	});
	const getClientAutoPopRequested = () => ({
		type: TYPES.GET_CLIENT_AUTO_POP.REQUESTED,
	});
	const updateClientAutoPopRequested = (values) => ({
		type: TYPES.UPDATE_CLIENT_AUTO_POP.REQUESTED,
		payload: values,
	});
	const getClient2FARequested = () => ({
		type: TYPES.GET_CLIENT_2FA.REQUESTED,
	});
	const updateClient2FARequested = (values) => ({
		type: TYPES.UPDATE_CLIENT_2FA.REQUESTED,
		payload: values,
	});
	const getSMSNotificationsRequested = (clientId) => ({
		type: TYPES.GET_CLIENT_SMS_NOTIFICATIONS.REQUESTED,
		payload: clientId,
	});
	const updateSMSNotificationsRequested = (values) => ({
		type: TYPES.UPDATE_CLIENT_SMS_NOTIFICATIONS.REQUESTED,
		payload: values,
	});
	const getUserEmailNotificationsRequested = () => ({
		type: TYPES.GET_USER_EMAIL_NOTIFICATIONS.REQUESTED,
	});
	const updateUserEmailNotificationsRequested = (values) => ({
		type: TYPES.UPDATE_USER_EMAIL_NOTIFICATIONS.REQUESTED,
		payload: values,
	});
	const changeClientUserPasswordRequested = (values, callback) => ({
		type: TYPES.CHANGE_CLIENT_USER_PASSWORD.REQUESTED,
		payload: values,
		callback,
	});
	const getATSUserPersonalDataRequested = () => ({
		type: TYPES.GET_ATS_USER_PERSONAL_DATA.REQUESTED,
	});
	const updateATSUserPersonalDataRequested = (values) => ({
		type: TYPES.UPDATE_ATS_USER_PERSONAL_DATA.REQUESTED,
		payload: values,
	});

	const getSubscriptionTypesRequested = () => ({
		type: TYPES.GET_SUBSCRIPTION_TYPES.REQUESTED,
	});
	const createATSTicketRequested = (values, callback) => ({
		type: TYPES.CREATE_ATS_TICKET.REQUESTED,
		payload: values,
		callback,
	});

	const getCandidateNotesByIdRequested = (id) => ({
		type: TYPES.GET_CANDIDATE_NOTES_BY_ID.REQUESTED,
		payload: id,
	});
	const addNoteToCandidateRequested = (candidateAppId, note, cb) => ({
		type: TYPES.ADD_NOTE_TO_CANDIDATE.REQUESTED,
		payload: note,
		candidateAppId,
		cb,
	});
	const getCandidatesDatabaseRequested = (params) => ({
		type: TYPES.GET_CANDIDATES_DATABASE.REQUESTED,
		payload: params,
	});
	const getCandidateDatabaseByIdRequested = (id) => ({
		type: TYPES.GET_CANDIDATE_DATABASE_BY_ID.REQUESTED,
		payload: id,
	});
	const getCandidateSMSInformationRequested = (ids) => ({
		type: TYPES.GET_CANDIDATE_SMS_INFORMATION.REQUESTED,
		payload: ids,
	});
	const resetCandidatesSMSInformation = () => ({
		type: TYPES.RESET_CANDIDATES_SMS_INFORMATION,
	});
	const updateCandidatePhoneNumberRequested = (values, callback) => ({
		type: TYPES.UPDATE_CANDIDATE_PHONE_NUMBER.REQUESTED,
		payload: values,
		callback,
	});
	const sendSMSToCandidateRequested = (values, callback) => ({
		type: TYPES.SEND_SMS_TO_CANDIDATE.REQUESTED,
		payload: values,
		callback,
	});
	const getAtsVideoInterviewCountRequested = (dates) => ({
		type: TYPES.GET_ATS_VIDEO_INTERVIEW_COUNT.REQUESTED,
		payload: dates,
	});
	const resetInterviewCount = () => ({
		type: TYPES.RESET_INTERVIEW_COUNT,
	});
	const showHideCandidateInDatabaseRequested = (candidateApplicationId, data, callback) => ({
		type: TYPES.SHOW_HIDE_CANDIDATE_DATABASE.REQUESTED,
		payload: { candidateApplicationId, data },
		callback,
	});
	const assignCandidateToVacancyDatabaseRequested = (candidateApplicationId, jobs, callback) => ({
		type: TYPES.ASSIGN_CANDIDATE_TO_VACANCY_DATABASE.REQUESTED,
		payload: { candidateApplicationId, jobs },
		callback,
	});
	const forwardInvoiceRequested = (data) => ({
		type: TYPES.FORWARD_INVOICE.REQUESTED,
		payload: data,
	});
	const getCandidateStatisticsRequested = (params) => ({
		type: TYPES.GET_CANDIDATE_STATISTICS.REQUESTED,
		payload: params,
	});
	const getApplicationsStatisticsForLastMonthRequested = () => ({
		type: TYPES.GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH.REQUESTED,
	});
	const getCandidateApplicationPerStateStatisticsRequested = (yearMonth) => ({
		type: TYPES.GET_CANDIDATE_APPLICATIONS_PER_STATE_STATISTICS.REQUESTED,
		payload: yearMonth,
	});
	const getJobsMonthStatisticRequested = () => ({
		type: TYPES.GET_JOBS_MONTH_STATISTIC.REQUESTED,
	});
	const getAcceptedOffersStatisticsRequested = (yearMonth) => ({
		type: TYPES.GET_ACCEPTED_OFFERS_STATISTICS.REQUESTED,
		payload: yearMonth,
	});
	const getDashboardUnreadEmailsRequested = () => ({
		type: TYPES.GET_DASHBOARD_UNREAD_EMAILS.REQUESTED,
	});
	const getApplicationSourcesStatisticsRequested = (yearMonth) => ({
		type: TYPES.GET_APPLICATION_SOURCES_STATISTICS.REQUESTED,
		payload: yearMonth,
	});
	const resetAtsInterviewCalenderList = () => ({
		type: TYPES.RESET_ATS_INTERVIEW_CALENDAR_LIST,
	});
	const getMonthApplicationStatisticRequested = (yearMonth) => ({
		type: TYPES.GET_APPLICATION_MONTH_STATISTICS.REQUESTED,
		payload: yearMonth,
	});
	const getYearApplicationStatisticRequested = () => ({
		type: TYPES.GET_APPLICATION_YEAR_STATISTICS.REQUESTED,
	});
	const getTimeToHireYearRequested = () => ({
		type: TYPES.GET_TIME_TO_HIRE_YEAR.REQUESTED,
	});
	const getTimeToHireMonthRequested = (yearMonth) => ({
		type: TYPES.GET_TIME_TO_HIRE_MONTH.REQUESTED,
		payload: yearMonth,
	});
	const getAtsAllJobsListRequested = () => ({
		type: TYPES.GET_ATS_ALL_JOBS_LIST.REQUESTED,
	});
	const getAtsAllCandidateApplicationByJobIdRequested = (jobId) => ({
		type: TYPES.GET_ATS_ALL_CANDIDATE_APPLICATION_BY_JOB_ID.REQUESTED,
		payload: jobId,
	});
	const createAtsEmailThreadRequested = (data, callback) => ({
		type: TYPES.CREATE_ATS_EMAIL_THREAD.REQUESTED,
		payload: data,
		callback,
	});
	const getAtsEmailTemplatesRequested = () => ({
		type: TYPES.GET_ATS_EMAIL_TEMPLATES.REQUESTED,
	});
	const getAtsEmailInboxRequested = (params) => ({
		type: TYPES.GET_ATS_EMAIL_INBOX.REQUESTED,
		payload: params,
	});
	const getAtsEmailThreadByIdRequested = (threadId) => ({
		type: TYPES.GET_ATS_EMAIL_THREAD_BY_ID.REQUESTED,
		payload: threadId,
	});
	const resetAtsCurrentEmailThread = () => ({
		type: TYPES.RESET_ATS_CURRENT_EMAIL_THREAD,
	});
	const postAtsToExistingEmailThreadRequested = (threadId, data, callback) => ({
		type: TYPES.POST_ATS_TO_EXISTING_EMAIL_THREAD.REQUESTED,
		payload: { threadId, data },
		callback,
	});
	const updateAtsEmailUnreadThreadCount = (candidateApplicationId, threadId) => ({
		type: TYPES.UPDATE_ATS_EMAIL_UNREAD_THREAD_COUNT,
		payload: { candidateApplicationId, threadId },
	});
	const getGravityJobsRequested = (params) => ({
		type: TYPES.GET_GRAVITY_JOBS.REQUESTED,
		payload: params,
	});
	const getListOfGravityCandidatesByJobIdRequested = (params) => ({
		type: TYPES.GET_LIST_OF_GRAVITY_CANDIDATES_BY_JOB_ID.REQUESTED,
		payload: params,
	});
	const assignGravityCandidateToVacancyRequested = (gravityCandidateId, jobs, callback) => ({
		type: TYPES.ASSIGN_GRAVITY_CANDIDATE_TO_VACANCY.REQUESTED,
		payload: { gravityCandidateId, jobs },
		callback,
	});
	const resetActiveJobsList = () => ({
		type: TYPES.RESET_ACTIVE_JOBS_LIST,
	});
	const resetCandidateDatabase = () => ({
		type: TYPES.RESET_CANDIDATES_DATABASE,
	});
	const resetInterviewJobsList = () => ({
		type: TYPES.RESET_INTERVIEW_JOBS_LIST,
	});

	return {
		archiveAndUnarchiveJobRequested,
		assignCandidateToVacancyRequested,
		getUnappliedCandidateJobsRequested,
		getAtsUsersRequested,
		getAtsUserRolesRequested,
		getAtsUserByIdRequested,
		createAtsUserRequested,
		deleteAtsUserRequested,
		getOrderByIdRequested,
		updateAtsUserRequested,
		copyPostJobChangesFormProcess,
		getATSBrandingByClientId,
		getATSCareerBrandingByClientId,
		getClientATSApplicationMessagesByClientId,
		getClientApplicationNotification,
		getCurrentClientCredits,
		getAtsInvoicesRequested,
		getAllAtsJobsRequested,
		getAtsActiveJobsRequested,
		getCandidateAssessmentApplicationRequested,
		getAtsInterviewJobsListRequested,
		getAtsEditInterviewJobsListRequested,
		getAtsInvoiceByIdRequested,
		getAndSavePostJobChangesFormProcess,
		resetPostJobChangesFormProcess,
		getJobUpgradesRequested,
		updateRejectionMessagePerJobRequested,
		saveClientAtsBranding,
		saveClientCareerBranding,
		saveSettingsCompanyDetailsAction,
		updateATSBranding,
		updateAtsCareerBranding,
		updateClientATSApplicationMessages,
		geAtsTicketsRequested,
		getAtsTicketDetailsRequested,
		getAtsCandidatesApplicationByJobIdRequested,
		getAtsInterviewTypeRequested,
		getAtsUserInterviewRequested,
		getAtsInterviewByIdRequested,
		cancelAtsInterviewByIdRequested,
		saveAtsInterviewRequested,
		updateAtsInterviewRequested,
		orderCreditsRequested,
		resetCurrentOrder,
		getAtsInterviewCalendarListRequested,
		resendAtsInterviewLinkRequested,
		createOrderInvoiceRequested,
		getClientSubscriptionRequested,
		getClientSocialNetworksRequested,
		updateClientSocialNetworksRequested,
		getClientAutoPopRequested,
		updateClientAutoPopRequested,
		getSMSNotificationsRequested,
		updateSMSNotificationsRequested,
		getUserEmailNotificationsRequested,
		updateUserEmailNotificationsRequested,
		changeClientUserPasswordRequested,
		getATSUserPersonalDataRequested,
		updateATSUserPersonalDataRequested,
		getSubscriptionTypesRequested,
		createATSTicketRequested,
		getCandidateNotesByIdRequested,
		addNoteToCandidateRequested,
		getCandidatesDatabaseRequested,
		getCandidateDatabaseByIdRequested,
		getCandidateSMSInformationRequested,
		updateCandidatePhoneNumberRequested,
		sendSMSToCandidateRequested,
		getClientEmailSignatureRequested,
		updateClientEmailSignatureRequested,
		resetCandidatesSMSInformation,
		getAtsVideoInterviewCountRequested,
		resetInterviewCount,
		orderSMSRequested,
		orderInterviewsRequested,
		showHideCandidateInDatabaseRequested,
		assignCandidateToVacancyDatabaseRequested,
		forwardInvoiceRequested,
		getCandidateStatisticsRequested,
		getApplicationsStatisticsForLastMonthRequested,
		getCandidateApplicationPerStateStatisticsRequested,
		getJobsMonthStatisticRequested,
		getAcceptedOffersStatisticsRequested,
		getDashboardUnreadEmailsRequested,
		getApplicationSourcesStatisticsRequested,
		resetAtsInterviewCalenderList,
		getMonthApplicationStatisticRequested,
		getYearApplicationStatisticRequested,
		getTimeToHireYearRequested,
		getTimeToHireMonthRequested,
		getAtsAllJobsListRequested,
		getAtsAllCandidateApplicationByJobIdRequested,
		createAtsEmailThreadRequested,
		getAtsEmailTemplatesRequested,
		getAtsEmailInboxRequested,
		getAtsEmailThreadByIdRequested,
		resetAtsCurrentEmailThread,
		postAtsToExistingEmailThreadRequested,
		updateAtsEmailUnreadThreadCount,
		getClient2FARequested,
		updateClient2FARequested,
		getGravityJobsRequested,
		getListOfGravityCandidatesByJobIdRequested,
		assignGravityCandidateToVacancyRequested,
		resetActiveJobsList,
		resetCandidateDatabase,
		resetInterviewJobsList,
	};
};
