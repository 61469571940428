import { Key } from 'react';

import {
	IContractState,
	IInvoicesState,
	IInvoiceType,
	InvoicesStatusEnum,
	InvoicesStatusTypesFormEnum,
	IPaymentMethod,
} from 'modules/Common/types';
import { GenericType, UserRolesType, UserTypesValueEnum } from 'types';

type ReportData = {
	pageIndex: number;
	pageSize: number;
	totalCount: number;
};

export interface ICredits {
	numberOfPremiumMultisiteCredits: number;
	numberOfCvFilteringCredits: number;
	numberOfGuardianCredits: number;
	numberOfIndeedCredits: number;
}

export type ContractReportValues = {
	contractId: number;
	currency: string;
	startDate: string;
	endDate: string;
	btoReference: string;
	net: number;
	gross: number;
	vat: number;
	status: IContractState;
	employeeFullName: string;
	credits: ICredits;
	client: string;
};

export type InvoicesReportValues = {
	invoiceId: number;
	currency: string;
	btoReference: string;
	net: number;
	gross: number;
	vat: number;
	status: IInvoicesState;
	employeeFullName: string;
	paidDate: string;
	paymentMethod: IPaymentMethod;
	invoiceType: IInvoiceType;
	credits: ICredits;
	client: string;
};

type BaseFinancialReportItem = {
	id: number;
	currency: string;
	btoReference: string;
	net: number;
	gross: number;
	vat: number;
	employeeFullName: string;
	credits: ICredits | null;
	client: string;
};

type ContractItem = BaseFinancialReportItem & {
	type: 'CONTRACT';
	startDate: string;
	contractEndDate: string;
	invoicePaidDate: null;
	invoiceType: null;
	invoicePaymentMethod: null;
	status: IContractState;
};

type InvoiceItem = BaseFinancialReportItem & {
	type: 'INVOICE';
	startDate: null;
	contractEndDate: null;
	invoicePaidDate: string | null;
	invoiceType: IInvoiceType;
	invoicePaymentMethod: IPaymentMethod;
	status: IInvoicesState;
};

export type FinancialReportItemValues = ContractItem | InvoiceItem;

type ContractReportData = ReportData & {
	data: ContractReportValues[];
};

type InvoiceReportData = ReportData & {
	data: InvoicesReportValues[];
};

type FinancialReportsData = ReportData & {
	data: FinancialReportItemValues[];
};

export type FinancialReportsProps = {
	financialReports: FinancialReportsData;
	invoicesReports: InvoiceReportData;
	contractsReports: ContractReportData;
	roles: UserRolesType;
	userList: { value: number; label: string }[];
	loading?: GenericType;
	getFinancialReportRequested: (params: { page?: number; size?: number }) => void;
	getInvoicesReportRequested: (params: { page?: number; size?: number }) => void;
	getContractsReportRequested: (params: { page?: number; size?: number }) => void;
	updateContractStatus: (
		contractId: number,
		data: { contractState: number },
		params?: { page?: number; size?: number; client?: string },
		platform?: UserTypesValueEnum,
		callback?: () => void,
	) => void;
	updateInvoiceStatusRequested: (
		invoiceId: number,
		values: { status: InvoicesStatusTypesFormEnum; datePaid?: string; paymentMethod?: number },
		callback?: () => void,
	) => void;
	getBOUserListRequested: () => void;
};

interface IBaseFinancialTableValues {
	key?: Key;
	id: number;
	client: string;
	btoReference: string;
	net: number;
	gross: number;
	vat: number;
	employee: string;
	invoiceType?: null | IInvoiceType;
	invoiceStatus: null | InvoicesStatusEnum;
	credits: object;
	contractState: IContractState | null;
}

interface IPaymentDetails {
	datePaid: string | null;
	paymentType: string | null;
}

export interface IInvoicesTableValues extends IBaseFinancialTableValues, IPaymentDetails {}

export const enum TabsTitleEnum {
	All = '1',
	Invoices = '2',
	Contracts = '3',
}
