import React, { useState } from 'react';
import { connect } from 'react-redux';

import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { IUser } from 'modules/Common/types';
import HREmployeeViewProfile from 'modules/HR/containers/HREmployeeViewProfile';
import { Routes } from 'modules/HR/routes/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';

import { Styled } from './HRViewEmployeePage.styled';

const HRViewEmployeePage = ({ userInfo }: { userInfo: IUser }) => {
	const isSuperUser = !!userInfo?.hrAdmin;
	const [pageTitle, setPageTitle] = useState(isSuperUser ? 'People Management' : 'People');

	const navPath = `${Routes.HRModule}${Routes.PeopleManagement}`;

	const handleUpdatePageTitle = (title: string) => {
		setPageTitle(title);
	};

	return (
		<>
			<Styled.Head>
				<PageNavTitle title={pageTitle} navigationLink={navPath} />
			</Styled.Head>
			<Styled.Content>
				<HREmployeeViewProfile
					isSuperUser={isSuperUser}
					handleUpdatePageTitle={handleUpdatePageTitle}
				/>
			</Styled.Content>
		</>
	);
};

export default connect((state) => ({
	userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
}))(HRViewEmployeePage);
