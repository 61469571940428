import { Select } from 'antd';
import DraggerAntd from 'antd/es/upload/Dragger';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Block = styled.section`
		border-radius: 10px;
		border: 1px solid ${COLORS.lightGray3};
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 24px 32px;
		margin-bottom: 24px;
	`;

	export const BlockTitle = styled.h2`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: -0.264px;
		margin-bottom: 24px;
		text-transform: capitalize;
	`;

	export const Dragger = styled(DraggerAntd)`
		&.ant-upload-wrapper {
			.ant-upload-list {
				.ant-upload-list-item {
					border: 1px solid ${COLORS.gray};
					padding: 10px;
					height: auto;
					transition: all 0.2s ease;
					&:hover {
						background-color: transparent;
						box-shadow: 0px 3px 5px 0px ${COLORS.gray};
						cursor: pointer;
					}
					.ant-upload-list-item-actions {
						display: flex;
					}
					.ant-upload-icon {
						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-actions {
						.ant-btn {
							margin: 0 5px;
						}
					}
					.ant-upload-list-item-action {
						opacity: 1;
						color: ${COLORS.black};

						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-name {
						color: ${COLORS.black};
						font-size: 16px;
					}
				}
			}
		}
	`;
}
