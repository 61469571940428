import React, { Key } from 'react';

import { Tooltip } from 'antd';

import { ColumnsDataType } from 'components/Table/Table.types';
import { IContractState, IInvoicesState, InvoicesTypesValuesEnum } from 'modules/Common/types';
import { UserRolesType } from 'types';
import { truncateString } from 'utils/helpers';

import { renderCredits, renderCombinedStatus } from './FinancialReports.helpers';
import { FinancialReportItemValues } from './FinancialReports.types';

type DataTableType = FinancialReportItemValues & {
	key?: Key;
};

interface ColumnsParams {
	roles: UserRolesType;
	invoiceStatuses: IInvoicesState[];
	contractStatuses: IContractState[];
	handleChangeInvoiceStatus: (invoiceId: number, statusId: number) => void;
	handleChangeContractStatus: (contractId: number, statusId: number) => void;
}

export const columns = ({
	roles,
	invoiceStatuses,
	contractStatuses,
	handleChangeInvoiceStatus,
	handleChangeContractStatus,
}: ColumnsParams): ColumnsDataType => [
	{
		title: 'Client',
		dataIndex: 'client',
		key: 'client',
		sorter: true,
		render: (_: string, { client }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(client || '-', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span style={{ fontWeight: 'bold' }}>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Start Date',
		dataIndex: 'startDate',
		key: 'startDate',
		sorter: true,
		align: 'center',
		render: (_: string, { startDate }: Partial<DataTableType>) => <span>{startDate || '-'}</span>,
	},
	{
		title: 'End Date',
		dataIndex: 'endDate',
		key: 'endDate',
		align: 'center',
		render: (_: string, { contractEndDate }: Partial<DataTableType>) => (
			<span>{contractEndDate || '-'}</span>
		),
	},
	{
		title: 'Reference',
		dataIndex: 'btoReference',
		key: 'btoReference',
		sorter: true,
		align: 'center',
		render: (_: string, { btoReference }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(btoReference || '-', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Net',
		dataIndex: 'net',
		key: 'net',
		align: 'center',
		render: (_: string, { net }: Partial<DataTableType>) => <span>{net ?? '-'}</span>,
	},
	{
		title: 'Gross',
		dataIndex: 'gross',
		key: 'gross',
		align: 'center',
		render: (_: string, { gross }: Partial<DataTableType>) => <span>{gross ?? '-'}</span>,
	},
	{
		title: 'Vat',
		dataIndex: 'vat',
		key: 'vat',
		align: 'center',
		render: (_: string, { vat }: Partial<DataTableType>) => <span>{vat ?? '-'}</span>,
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		align: 'center',
		width: '100px',
		render: (_: string, { invoiceType, id, type, status }: Partial<DataTableType>) =>
			renderCombinedStatus(
				{ invoiceType, id, type, status },
				invoiceStatuses,
				contractStatuses,
				handleChangeInvoiceStatus,
				handleChangeContractStatus,
				roles,
			),
	},
	{
		title: 'Employee',
		dataIndex: 'employee',
		key: 'employee',
		sorter: true,
		render: (_: string, { employeeFullName }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(employeeFullName || '-', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Date Paid',
		dataIndex: 'datePaid',
		key: 'datePaid',
		align: 'center',
		render: (_: string, { invoicePaidDate }: Partial<DataTableType>) => (
			<span>{invoicePaidDate || '-'}</span>
		),
	},
	{
		title: 'Payment Type',
		dataIndex: 'paymentType',
		key: 'paymentType',
		align: 'center',
		render: (_: string, { invoicePaymentMethod }: Partial<DataTableType>) => (
			<span>{invoicePaymentMethod?.name || '-'}</span>
		),
	},
	{
		title: 'Credits',
		dataIndex: 'credits',
		key: 'credits',
		width: '240px',
		align: 'center',
		render: (_: string, { invoiceType, credits }: Partial<DataTableType>) =>
			renderCredits({
				invoiceType: invoiceType?.value as unknown as InvoicesTypesValuesEnum,
				credits: credits ?? null,
			}),
	},
];
