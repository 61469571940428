import { Input as InputAnt } from 'antd';
import SelectComponent, { type DefaultOptionType } from 'antd/es/select';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS, device } from 'theme';

interface ButtonProps {
	minWidth?: number;
	fontSize?: number;
}

interface ExtraControlsProps {
	marginBottom?: number;
}

interface ISelectProps extends DefaultOptionType {
	styles: {
		border: string;
		background: string;
		borderColor: string;
	};
	fontWeight?: string;
}

const { Option: OptionComponent } = SelectComponent;

export namespace Styled {
	export const Root = styled.div`
		padding: 32px 24px 24px;
		background-color: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		border-radius: 10px;
		.ant-tabs-nav {
			&:before {
				border-bottom: none;
			}
		}
	`;

	export const EmployeeDetails = styled.div`
		display: flex;
		align-items: center;
		gap: 8px;
	`;

	export const Button = styled(ButtonComponent)<ButtonProps>`
		line-height: 28px;
		height: 28px;
		min-width: ${({ minWidth }) => `${minWidth ?? 28}px`};
		font-size: ${({ fontSize }) => `${fontSize ?? 12}px`}!important;
		padding: 4px 8px;
		gap: 8px;
	`;

	export const Select = styled(SelectComponent)<ISelectProps>`
		&.ant-select {
			.ant-select-selector {
				min-width: 160px;
				height: 36px;
				line-height: 36px;
				${({ styles }) => styles}
				border-radius: 4px;
				font-weight: bold;

				.ant-select-selection-item {
					line-height: 36px;
					text-transform: none;
				}
			}
			.ant-select-selection-item {
				font-weight: ${({ fontWeight }) => `${fontWeight || 'normal'}`};
			}
		}
	`;

	export const Option = styled(OptionComponent)``;

	export const CopyWrapper = styled.div`
		display: flex;
		align-items: center;
		gap: 8px;
	`;

	export const CopyIcon = styled.div`
		display: flex;
		svg {
			cursor: pointer;
		}
	`;

	export const ExtraControls = styled.div<ExtraControlsProps>`
		display: flex;
		margin-bottom: ${({ marginBottom }) => `${marginBottom ?? 0}px`};
		input {
			margin-right: 16px;
		}
	`;

	export const SelectWrap = styled.div`
		display: flex;
		align-items: center;
		gap: 8px;
		margin-left: 24px;
	`;

	export const SelectLabel = styled.span`
		color: ${COLORS.black};
		text-align: center;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		text-transform: none;
	`;

	export const Input = styled(InputAnt)`
		margin-right: 16px;
		width: 376px;
		@media ${device.desktop} {
			width: auto;
		}
	`;
}
