import React, { type FC, useCallback, useEffect, useState } from 'react';

import { TablePaginationConfig, Tabs } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import Table from 'components/Table';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { TabsTitleEnum } from 'modules/Common/types';
import { propsFilter } from 'utils/helpers';

import { JobsListFields } from './JobsListContainer.constants';
import { Styled } from './JobsListContainer.styled';
import { JobsListContainerProps } from './JobsListContainer.types';
import { columns } from './JobsListTable.entities';

export const JobsListContainer: FC<JobsListContainerProps> = ({
	loading,
	jobsData,
	getAllBackOfficeJobs,
	boostJobBO,
	isClientContext = false,
	pageLink,
	clientId,
}) => {
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);
	const { pageIndex, pageSize, totalCount } = jobsData;
	const [tableParams, setTableParams] = useState({});

	const activeData = propsFilter(jobsData?.data, JobsListFields);
	const isActiveTab = tab === TabsTitleEnum.ActiveTab;

	const handleChangeTabs = (id: string) => {
		setTab(id);
		setTableParams({
			active: isActiveTab,
			...(tableParams.search && { search: tableParams.search }),
		});
	};

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			setTableParams({
				...tableParams,
				active: isActiveTab,
				page,
				size,
			});
		},
		[clientId, tableParams],
	);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			sorter,
		});
	};

	const handleBoostJob = (jobId: number) => {
		boostJobBO(jobId, () =>
			getAllBackOfficeJobs(
				{
					active: isActiveTab,
					page: 1,
					size: pageSize,
					...(tableParams.search && { search: tableParams.search }),
				},
				isClientContext,
			),
		);
	};

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		getAllBackOfficeJobs(
			{
				active: isActiveTab,
				page: tableParams?.page || 0,
				size: tableParams?.size || pageSize,
				...(tableParams.search && { search: tableParams.search }),
				...(tableParams?.sorter?.order &&
					tableParams?.sorter?.field && {
					sort: `${tableParams?.sorter?.field},${
						ordersMap[tableParams?.sorter?.order || 'ascend']
					}`,
				}),
			},
			isClientContext,
		);
	}, [clientId, tableParams]);

	const columnsData =
		pageLink && isClientContext
			? columns(pageLink, handleBoostJob)?.slice(1)
			: pageLink && columns(pageLink, handleBoostJob);

	const handleSearch = (value: string) => {
		setTableParams({ search: value });
	};

	return (
		<Styled.JLCRoot>
			<Styled.JLCSearch>
				<SearchFilterSortWrapper
					search={{
						onSearch: handleSearch,
						isDebounce: false,
						placeholder: isClientContext ? 'Search' : 'Search a job',
						width: '276px',
					}}
				/>
			</Styled.JLCSearch>
			<Tabs
				defaultActiveKey={TabsTitleEnum.ActiveTab}
				tabBarStyle={{ textTransform: 'uppercase' }}
				onChange={handleChangeTabs}
				items={[
					{
						label: 'Active',
						key: TabsTitleEnum.ActiveTab,
						children: (
							<Table
								data={activeData}
								columns={columnsData}
								loading={loading?.getAllBackofficeJobsLoad}
								pageSize={pageSize}
								current={pageIndex}
								total={totalCount}
								onChange={handleTablePaginationChange}
								onPageSizeChange={handleTablePaginationChange}
								onTableChange={handleTableChange}
							/>
						),
					},
					{
						label: 'Archive',
						key: TabsTitleEnum.ArchiveTab,
						active: true,
						children: activeData && (
							<Table
								data={activeData}
								columns={columnsData}
								loading={loading?.getAllBackofficeJobsLoad}
								pageSize={pageSize}
								current={pageIndex}
								total={totalCount}
								onChange={handleTablePaginationChange}
								onPageSizeChange={handleTablePaginationChange}
								onTableChange={handleTableChange}
							/>
						),
					},
				]}
			/>
		</Styled.JLCRoot>
	);
};
