import React from 'react';

import { Dropdown, Space, Tooltip } from 'antd';

import ButtonComponent from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import DeleteIcon from 'components/SVG/DeleteIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import { COLORS } from 'theme';
import { TStyled } from 'theme/styled';
import { truncateString } from 'utils/helpers';

import {
	DataTableType,
	DeleteCandidateModalType,
	TableColumnsType,
} from './CandidatesApplicationContainer.types';

export const columns = (
	pageLink: string,
	handleDeleteAction: (candidateData: DeleteCandidateModalType) => void,
): ColumnsDataType & TableColumnsType => [
	{
		title: 'Candidate',
		dataIndex: 'candidate',
		key: 'candidate',
		render: (_: string, { candidate, candidateApplicationId }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(candidate || '', 60);

			return (
				<TStyled.PageLink
				// to={`${pageLink}${Routes.Candidates}/${candidateApplicationId}`}
				>
					<Tooltip title={fullText} overlayStyle={{ maxWidth: '400px' }}>
						<span>{truncated}</span>
					</Tooltip>
				</TStyled.PageLink>
			);
		},
	},
	{
		title: 'Email',
		dataIndex: 'candidateEmail',
		key: 'candidateEmail',
		align: 'center',
		render: (_: string, { candidateEmail }: Partial<DataTableType>) => {
			return <>{candidateEmail}</>;
		},
	},
	{
		title: 'Applied jobs',
		dataIndex: 'jobTitle',
		key: 'jobTitle',
		align: 'center',
		render: (_: string, { jobTitle }: Partial<DataTableType>) => {
			return <>{jobTitle}</>;
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		render: (_: string, { candidateApplicationId, hidden }: Partial<DataTableType>) => {
			const menuItems = [
				!hidden && {
					label: (
						<div
							onClick={() =>
								candidateApplicationId &&
								handleDeleteAction({ candidateApplicationId, deleteType: 'database' })
							}
						>
							Delete from Candidate Database
						</div>
					),
					key: 0,
				},
				{
					label: (
						<div
							onClick={() =>
								candidateApplicationId &&
								handleDeleteAction({ candidateApplicationId, deleteType: 'client' })
							}
						>
							Delete from Client
						</div>
					),
					key: 1,
				},
			];

			return (
				<Space size='middle'>
					<Dropdown
						menu={{
							items: menuItems,
						}}
						placement='bottom'
					>
						<ButtonComponent
							buttonType={ButtonTypes.default}
							icon={<DeleteIcon fill={COLORS.black} width='16' height='16' />}
						/>
					</Dropdown>
				</Space>
			);
		},
	},
];
