import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Switch } from 'antd';

import Box from 'components/Box';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import CollapseList from 'modules/ATS/components/CollapseList';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { commonDucks } from 'modules/Common/ducks';
import { IUpdateCandidateRankProps, IUser } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType, Routes } from 'types';

import { atsDucks } from '../../ducks';

import { Styled } from './InterviewCandidatesList.styled';
import { InterviewsList } from './InterviewCandidatesList.types';
import InterviewCandidatesListTable from './InterviewCandidatesListTable';

const SortByOptions = [
	{ value: 'POSITION', label: 'Position' },
	{ value: 'DATETIME', label: 'Date & Time' },
];

type InterviewCandidateListTypeProps = {
	interviewJobsList: {
		id: number;
		title: string;
		interviews: InterviewsList[];
	}[];
	getAtsInterviewJobsList: ({ showAll }: { showAll: boolean }) => void;
	resendAtsInterviewLink: (id: number, candidate: string) => void;
	updateCandidateRankRequested: (props: IUpdateCandidateRankProps) => void;
	user: IUser;
	loading: GenericType;
};

const InterviewCandidatesList: FC<InterviewCandidateListTypeProps> = ({
	interviewJobsList,
	getAtsInterviewJobsList,
	resendAtsInterviewLink,
	updateCandidateRankRequested,
	user,
	loading,
}) => {
	const [showInterviews, setShowInterviews] = useState<boolean>(false);
	const handleInterviewResendLink = useCallback((id: number, candidate: string) => {
		resendAtsInterviewLink(id, candidate);
	}, []);
	const [tableParams, setTableParams] = useState({});

	useEffect(() => {
		getAtsInterviewJobsList({ showAll: showInterviews, ...tableParams });
	}, [showInterviews, tableParams]);

	const handleCandidateRank = useCallback(
		(id: string | number, value: number, jobIdFromTable?: string | number, cb?: () => void) => {
			updateCandidateRankRequested({
				candidateAppId: id,
				rank: value,
				jobId: jobIdFromTable,
				atsReducerChanges: true,
				cb,
			});
		},
		[],
	);

	const items = interviewJobsList?.map((job) => {
		const { interviews } = job;

		return {
			key: job.id,
			label: (
				<Link to={`${Routes.ATS}${Routes.Jobs}/${job.id}`}>
					{job.title} ({interviews?.length})
				</Link>
			),
			showArrow: !!interviews?.length,
			collapsible: !interviews?.length ? 'disabled' : 'header',
			children: (
				<InterviewCandidatesListTable
					data={interviews}
					jobId={job.id}
					user={user}
					resendInterviewLink={handleInterviewResendLink}
					handleCandidateRank={handleCandidateRank}
				/>
			),
		};
	});

	const handleChangeInterviewList = () => {
		setShowInterviews((prevState) => !prevState);
	};

	const handleSearch = (value: string) => {
		setTableParams({ search: value });
	};

	const handleSortByFilter = (value: number) => {
		setTableParams({ sortOption: value });
	};

	useMount(() => {
		!interviewJobsList?.length && getAtsInterviewJobsList({ showAll: false });
	});

	const defaultActiveKey = interviewJobsList?.map((job) => job.id);

	return (
		<Box>
			<Styled.Header>
				<SearchFilterSortWrapper
					search={{
						onSearch: handleSearch,
						isDebounce: false,
						placeholder: 'Search a candidate',
					}}
					filter={{
						mode: 'single',
						options: SortByOptions,
						onFilter: handleSortByFilter,
						label: 'Sort by',
						width: '160px',
						placeholder: 'All',
					}}
				/>
				<Styled.AllInterviewSwitcher>
					<span>All interviews</span>
					<Switch
						checked={showInterviews}
						defaultChecked={false}
						onChange={handleChangeInterviewList}
					/>
				</Styled.AllInterviewSwitcher>
			</Styled.Header>
			{loading?.getAtsInterviewJobsListLoad ? (
				<Spinner fixed />
			) : (
				<Styled.Main>
					{interviewJobsList?.length && items?.length ? (
						<CollapseList items={items} defaultActiveKey={defaultActiveKey} />
					) : (
						<Styled.Info>There are no interviews</Styled.Info>
					)}
				</Styled.Main>
			)}
		</Box>
	);
};

export default connect(
	(state) => ({
		interviewJobsList: atsDucks.atsSelectors.getInterviewJobsList(state),
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAtsInterviewJobsList: atsDucks.atsActions.getAtsInterviewJobsListRequested,
		resendAtsInterviewLink: atsDucks.atsActions.resendAtsInterviewLinkRequested,
		updateCandidateRankRequested: commonDucks.commonActions.updateCandidateRankRequested,
	},
)(InterviewCandidatesList);
