import React from 'react';

import { Popconfirm, UploadFile } from 'antd';

import DeleteIcon from 'components/SVG/DeleteIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import { COLORS } from 'theme';

import { Styled } from './FileItemRendererAttachments.styled';

interface FileItemRendererAttachmentsProps {
	originNode: React.ReactElement;
	file: UploadFile;
	isRemovable: boolean;
	onDownload: (file: UploadFile) => void;
	onRemove: (file: UploadFile) => void;
}

const FileItemRendererAttachments: React.FC<FileItemRendererAttachmentsProps> = ({
	originNode,
	file,
	isRemovable,
	onDownload,
	onRemove,
}) => {
	return (
		<Styled.FileItem>
			<Styled.FileName>{originNode}</Styled.FileName>
			<Styled.FileControls>
				<Styled.DownloadIcon onClick={() => onDownload(file)}>
					<DownloadIcon fill={COLORS.darkGray2} />
				</Styled.DownloadIcon>
				{isRemovable && (
					<Popconfirm
						title='Are you sure?'
						onConfirm={() => onRemove(file)}
						okText='Yes'
						cancelText='Cancel'
					>
						<Styled.DeleteIcon>
							<DeleteIcon fill={COLORS.darkGray2} />
						</Styled.DeleteIcon>
					</Popconfirm>
				)}
			</Styled.FileControls>
		</Styled.FileItem>
	);
};

export default FileItemRendererAttachments;
