import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RowSelectMethod } from 'antd/es/table/interface';

import { getJobCandidatesCsvAts, getJobCandidatesAtsZipFile } from 'api/endpoints/jobs';
import { ButtonTypes } from 'components/Button';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import SubscriptionModal from 'modules/ATS/components/SubscriptionModal';
import ConfirmationModal from 'modules/Common/components/ConfirmationModal';
import { ADD_EMPLOYEE_TO_HR_MODULE } from 'modules/Common/constants/modalTitles.constants';
import {
	DataTableType,
	JobDataType,
	CandidateStatusType,
	CandidateResponseType,
} from 'modules/Common/types';
import { Routes as HRRoutes } from 'modules/HR/routes/types';
import { SubscriptionPlansType, Routes } from 'types';
import { propsFilter, downloadCsv, downloadZip } from 'utils/helpers';

import ActionMenu from '../ActionMenu';
import {
	CANDIDATE_FIELDS,
	DEFAULT_TABLE_ITEMS,
	menuItems,
} from '../CandidatesApplication.constants';
import { Styled } from '../CandidatesApplication.styled';
import { CandidatesStatusValuesEnum } from '../CandidatesApplication.types';
import CandidatesApplicationTable from '../CandidatesApplicationTable';

type CandidatesApplicationTableControllerProps = {
	item: JobDataType;
	updateCandidateStatus: CandidateStatusType;
	handleOpenModal: (ids: number[], job: number) => void;
	handleSelectSpecificCandidate: (id: number) => void;
	handleResendCandidateQuestionnaire: (id: number) => void;
	handleRecallCandidateQuestionnaire: (id: number) => void;
	handleCandidateRank: (
		id: string | number,
		rank: number,
		jobId?: string | number,
		cb?: () => void,
	) => void;
	setSelectedCandidateIds: (ids: number[]) => void;
	selectedCandidateIds: number[];
	setSelectedJobId: (value: number) => void;
	selectedJobId?: number;
	isAtsSuperUser: boolean;
	subscriptionPlans: SubscriptionPlansType;
	setLoading: (value: boolean) => void;
};

const CandidatesApplicationTableController: FC<CandidatesApplicationTableControllerProps> = ({
	item,
	updateCandidateStatus,
	handleOpenModal,
	handleSelectSpecificCandidate,
	handleResendCandidateQuestionnaire,
	handleRecallCandidateQuestionnaire,
	handleCandidateRank,
	subscriptionPlans,
	setSelectedCandidateIds,
	selectedCandidateIds,
	setSelectedJobId,
	selectedJobId,
	isAtsSuperUser,
	setLoading,
}) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [displaySize, setDisplaySize] = useState(DEFAULT_TABLE_ITEMS);
	const [employeeToHRModal, setEmployeeToHRModal] = useState<CandidateResponseType | null>(null);
	const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
	const [zipArchiveLoading, setZipArchiveLoading] = useState<boolean>(false);

	const navigate = useNavigate();
	const location = useLocation();

	const isJobsPage = location?.pathname?.includes(Routes.Jobs);

	const data = item?.candidates?.length
		? propsFilter(
			item?.candidates.map((ic) => ({
				...ic,
				candidateState: {
					...ic.candidateState,
					updateCandidateStatus,
				},
				job: item.id,
			})),
			CANDIDATE_FIELDS,
		  )
		: null;

	const handleShowButton = useCallback(() => {
		setDisplaySize((prevState) => prevState + DEFAULT_TABLE_ITEMS);
	}, []);

	const handleChangeTable = useCallback(
		(
			newSelectedRowKeys: React.Key[],
			selectedRows: DataTableType[],
			info: { type: RowSelectMethod },
		) => {
			if (info?.type === 'all' && !selectedRows.length) {
				setSelectedRowKeys([]);
				setSelectedCandidateIds([]);
			} else if (info?.type === 'all') {
				setSelectedRowKeys(data?.map((row: JobDataType) => row.key) as string[]);
				setSelectedCandidateIds(data?.map((row: JobDataType) => row.id) as number[]);
				setDisplaySize(() => data?.length);
				setSelectedJobId(item?.id);
			} else {
				setSelectedRowKeys(newSelectedRowKeys);
				setSelectedCandidateIds(selectedRows?.map((i) => i?.id));
				setSelectedJobId(item.id);
			}
		},
		[displaySize, setSelectedRowKeys, selectedCandidateIds, setSelectedCandidateIds],
	);

	const handleDownloadCandidates = downloadCsv(
		() => item?.id && getJobCandidatesCsvAts(item.id),
		`job-${item?.id}-candidates`,
	);

	const handleDownloadCandidatesBulkAction = zipArchiveLoading
		? () => null
		: downloadZip(async () => {
			setZipArchiveLoading(true);

			return await getJobCandidatesAtsZipFile(JSON.stringify(selectedCandidateIds), () =>
				setZipArchiveLoading(false),
			);
		  }, item?.title);

	const handleSendSMSBulkAction = useCallback(() => {
		navigate(`${Routes.ATS}${Routes.CandidatesSendSMS}`, {
			state: { selectedCandidateIds, prevPage: location.pathname },
		});
	}, [selectedCandidateIds, item?.id]);

	const handleSendEmailBulkAction = useCallback(() => {
		const candidateList = item?.candidates
			?.filter((c) => selectedCandidateIds.includes(c?.id))
			.map((c) => ({ id: c?.id, fullName: c?.name }));

		navigate(`${Routes.ATS}${Routes.CreateMessage}`, {
			state: { candidateList },
		});
	}, [selectedCandidateIds, item]);

	const handleSendSMSAction = useCallback((id: number) => {
		navigate(`${Routes.ATS}${Routes.CandidatesSendSMS}`, {
			state: { selectedCandidateIds: [id], prevPage: location.pathname },
		});
	}, []);

	const handleOpenModalBulkAction = useCallback(() => {
		handleOpenModal && handleOpenModal(selectedCandidateIds, item?.id);
	}, [selectedCandidateIds, item?.id]);

	const handleOpenSubscriptionModal = useCallback(() => {
		setSubscriptionModal(true);
	}, []);

	const handleCloseSubscriptionModal = useCallback(() => {
		setSubscriptionModal(false);
	}, []);

	const handleOpenEmployeeToHRModal = useCallback(
		(status: CandidatesStatusValuesEnum, employeeData: CandidateResponseType[]) => {
			if (status === CandidatesStatusValuesEnum.Hired && employeeData?.length && isAtsSuperUser) {
				setEmployeeToHRModal(employeeData[0]);
			}
		},
		[item],
	);

	const handleCloseEmployeeToHRModal = useCallback(() => {
		setEmployeeToHRModal(null);
	}, []);

	const handleMoveCandidateToHRModule = useCallback(() => {
		handleCloseEmployeeToHRModal();
		if (subscriptionPlans.isPremiumPlan) {
			navigate(`${HRRoutes.HRModule}${HRRoutes.EmployeeCreate}`, {
				state: { ...employeeToHRModal, jobTitle: item.title },
			});
		} else {
			handleOpenSubscriptionModal();
		}
	}, [employeeToHRModal, item]);

	const handleUpdateCandidateStatusBulkAction = useCallback(
		(status: CandidatesStatusValuesEnum) => {
			setLoading(true);

			updateCandidateStatus(
				selectedCandidateIds,
				status,
				item?.id,
				(failedIds, successfulCandidates) => {
					setSelectedCandidateIds(failedIds);

					setSelectedRowKeys(
						data?.reduce((acc, row: JobDataType) => {
							if (selectedCandidateIds.includes(row.id)) {
								acc.push(row.key);
							}

							return acc;
						}, []) as string[],
					);
					if (failedIds?.length) {
						failedIds?.length && setSelectedCandidateIds(failedIds);
					} else {
						setSelectedRowKeys([]);
					}

					setLoading(false);

					handleOpenEmployeeToHRModal(status, successfulCandidates);
				},
			);
		},
		[selectedCandidateIds, item?.id],
	);

	useEffect(() => {
		if (selectedCandidateIds?.length !== data?.length) {
			setSelectedRowKeys(
				data?.reduce((acc, row: JobDataType) => {
					if (selectedCandidateIds.includes(row.id)) {
						acc.push(row.key);
					}

					return acc;
				}, []) as string[],
			);
		}
	}, [selectedCandidateIds]);

	const items = menuItems(
		handleOpenModalBulkAction,
		handleUpdateCandidateStatusBulkAction,
		handleDownloadCandidatesBulkAction,
		handleSendSMSBulkAction,
		handleSendEmailBulkAction,
	);

	const isActiveMenuShown = +selectedJobId === item?.id && !!selectedCandidateIds?.length;

	return (
		<Styled.Root>
			<Styled.ActionPanel compactView={isJobsPage}>
				<ATSThemedButton buttonType={ButtonTypes.primary} onClick={handleDownloadCandidates}>
					Download CSV
				</ATSThemedButton>
				{isActiveMenuShown && <ActionMenu items={items} />}
			</Styled.ActionPanel>
			<Styled.CollapsePanel key={item?.id}>
				{data && (
					<CandidatesApplicationTable
						data={data}
						onSelectChange={handleChangeTable}
						selectedRowKeys={selectedRowKeys}
						displaySize={displaySize}
						zipArchiveLoading={zipArchiveLoading}
						handleShowButton={handleShowButton}
						handleOpenModal={handleOpenModal}
						handleOpenEmployeeToHRModal={handleOpenEmployeeToHRModal}
						handleSelectSpecificCandidate={handleSelectSpecificCandidate}
						handleResendCandidateQuestionnaire={handleResendCandidateQuestionnaire}
						handleRecallCandidateQuestionnaire={handleRecallCandidateQuestionnaire}
						handleCandidateRank={handleCandidateRank}
						handleSendSMSAction={handleSendSMSAction}
						subscriptionPlans={subscriptionPlans}
					/>
				)}
			</Styled.CollapsePanel>
			<ConfirmationModal
				modalOpen={!!employeeToHRModal}
				title={ADD_EMPLOYEE_TO_HR_MODULE}
				handleApprove={handleMoveCandidateToHRModule}
				handleCloseModal={handleCloseEmployeeToHRModal}
			/>
			<SubscriptionModal
				modalOpen={subscriptionModal}
				handleCloseModal={handleCloseSubscriptionModal}
			/>
		</Styled.Root>
	);
};

export default CandidatesApplicationTableController;
