import React, { FC } from 'react';

import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import Spinner from 'components/Spinner';
import CalendarIcon from 'components/SVG/CalendarIcon';
import CandidatesIcon from 'components/SVG/CandidatesIcon';
import EmailIcon from 'components/SVG/EmailIcon';
import MapMarkerIcon from 'components/SVG/MapMarkerIcon';
import PhoneIcon from 'components/SVG/PhoneIcon';
import ProfileIcon from 'components/SVG/ProfileIcon';
import { COLORS } from 'theme';
import { fetchEmployeePhotoWithCredentials, truncateString } from 'utils/helpers';

import UserIcon from '../UserIcon';

import { Styled } from './ViewProfileSidebar.styled';
import { ViewProfileSidebarProps } from './ViewProfileSidebar.types';

const InfoBlock: FC<{ icon?: React.ReactNode; label: string; noTruncate?: boolean }> = ({
	icon,
	label,
	noTruncate = false,
}) => {
	const { truncated, fullText } = truncateString(label, noTruncate ? Infinity : 26);

	return (
		<Tooltip title={fullText}>
			<Styled.InfoBlock>
				{icon && <Styled.InfoIcon>{icon}</Styled.InfoIcon>}
				<Styled.InfoText>{truncated}</Styled.InfoText>
			</Styled.InfoBlock>
		</Tooltip>
	);
};

export const ViewProfileSidebar: FC<ViewProfileSidebarProps> = ({
	photo,
	firstName,
	lastName,
	jobTitle,
	birthdayDate,
	departmentName,
	startDate,
	workPhone,
	personalPhone,
	email,
	emergencyContactFullName,
	emergencyContactPhone,
	address,
	city,
	country,
	postCode,
	isSuperUser,
	loading,
}) => {
	const birthdayDateFormat = isSuperUser ? 'MMM DD, YYYY' : 'MMM DD';
	const dateFormat = 'MMM DD, YYYY';
	const fullName = `${firstName} ${lastName}`;

	const blocks = [
		{
			condition: birthdayDate,
			content: (
				<InfoBlock
					icon={<CalendarIcon width='16' height='16' fill={COLORS.black} />}
					label={`Birthday: ${dayjs(birthdayDate).format(birthdayDateFormat)}`}
				/>
			),
		},
		{
			condition: true,
			content: (
				<>
					{departmentName && (
						<InfoBlock
							icon={<CandidatesIcon width='16' height='16' fill={COLORS.black} />}
							label={`Department: ${departmentName}`}
						/>
					)}
					{city && (
						<InfoBlock
							icon={<MapMarkerIcon width='16' height='16' fill={COLORS.black} />}
							label={city}
						/>
					)}
					{
						<InfoBlock
							icon={<CalendarIcon width='16' height='16' fill={COLORS.black} />}
							label={`Hire Date: ${startDate ? dayjs(startDate).format(dateFormat) : 'N/A'}`}
						/>
					}
				</>
			),
		},
		{
			condition: workPhone || personalPhone || email,
			title: 'Contacts',
			content: (
				<>
					{workPhone && (
						<InfoBlock
							icon={<PhoneIcon width='16' height='16' fill={COLORS.black} />}
							label={workPhone}
						/>
					)}
					{personalPhone && (
						<InfoBlock
							icon={<PhoneIcon width='16' height='16' fill={COLORS.black} />}
							label={personalPhone}
						/>
					)}
					{email && (
						<InfoBlock
							icon={<EmailIcon width='16' height='16' fill={COLORS.black} />}
							label={email}
						/>
					)}
				</>
			),
		},
		{
			condition: emergencyContactFullName || emergencyContactPhone,
			title: 'Emergency contact',
			content: (
				<>
					{emergencyContactFullName && (
						<InfoBlock
							icon={<ProfileIcon width='16' height='16' fill={COLORS.black} />}
							label={emergencyContactFullName}
						/>
					)}
					{emergencyContactPhone && (
						<InfoBlock
							icon={<PhoneIcon width='16' height='16' fill={COLORS.black} />}
							label={emergencyContactPhone}
						/>
					)}
				</>
			),
		},
		{
			condition: address || city || country || postCode,
			title: 'Address',
			content: (
				<>
					{address && <InfoBlock label={address} noTruncate={true} />}
					{(city || country) && (
						<InfoBlock
							label={`${city || ''}${city && country ? ', ' : ''}${country || ''}`}
							noTruncate={true}
						/>
					)}
					{postCode && <InfoBlock label={postCode} noTruncate={true} />}
				</>
			),
		},
	];

	if (loading) {
		return (
			<Styled.Root>
				<Spinner fixed />
			</Styled.Root>
		);
	}

	return (
		<Styled.Root>
			<Styled.TopSection>
				<UserIcon
					width='184px'
					imageId={photo}
					fullName={fullName}
					backgroundColor={COLORS.darkGray1}
					fetchAndSetImageHelper={fetchEmployeePhotoWithCredentials}
				/>
				<Styled.ProfileFullName>{fullName}</Styled.ProfileFullName>
				<Styled.ProfileJobTitle>{jobTitle}</Styled.ProfileJobTitle>
			</Styled.TopSection>
			{blocks.map(
				({ condition, title, content }, index) =>
					condition && (
						<Styled.Block key={index}>
							{title && <Styled.BlockTitle>{title}</Styled.BlockTitle>}
							<Styled.BlockContent>{content}</Styled.BlockContent>
						</Styled.Block>
					),
			)}
		</Styled.Root>
	);
};
