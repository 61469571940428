import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const DataWrap = styled.div``;

	export const Day = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: -0.132px;
		text-transform: uppercase;
		padding-left: 8px;
		&:after {
			content: '';
			display: block;
			height: 1px;
			background-color: ${COLORS.lightGray2};
			width: 2000%;
			margin-top: 8px;
			position: relative;
			left: -30px;
		}
	`;

	export const Date = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 21px */
		letter-spacing: -0.154px;
		text-transform: capitalize;
		margin-top: 16px;
	`;
}
