import React, { type FC } from 'react';
import { Field } from 'react-final-form';

import FormSwitch from 'components/FormSwitch';

import { Styled } from './OtherDetails.styled';

type OtherDetailsProps = {
	disabledFields?: string[];
};

export const OtherDetails: FC<OtherDetailsProps> = ({ disabledFields }) => {
	return (
		<Styled.FormBlockWrap>
			<Styled.ToggleWrap>
				<Field name='usesDefaultOnboardingTemplate'>
					{({ input }) => (
						<>
							<FormSwitch
								{...input}
								disabled={disabledFields?.includes('usesDefaultOnboardingTemplate')}
								title='Use Standard Onboarding Template'
							/>
						</>
					)}
				</Field>
			</Styled.ToggleWrap>
		</Styled.FormBlockWrap>
	);
};
