import { RequestsEnum } from 'types';

import { get, put } from '../axiosInstance';

export const getClientsAtsApplicationMessageByClientId = async (clientId) =>
	get(`${RequestsEnum.ClientApplicationMessage}/${clientId}`);

export const updateClientsAtsApplicationMessageByClientId = async (data) =>
	put(RequestsEnum.ClientApplicationMessage, data);

export const getClientEmailSignature = async () => get(RequestsEnum.UserEmailSignature);

export const updateClientEmailSignature = async (data) =>
	put(RequestsEnum.UserEmailSignature, data);

export const getClientsAutoPop = async () => get(RequestsEnum.ATSUserAutoPop);

export const updateClientsAutoPop = async (data) => put(RequestsEnum.ATSUserAutoPop, data);

export const getClientsTwoFA = async () => get(RequestsEnum.ATSClient2FA);

export const updateClientsTwoFA = async (data) => put(RequestsEnum.ATSClient2FA, data);

export const getUserEmailNotifications = async () => get(RequestsEnum.ATSUserEmailNotifications);

export const updateUserEmailNotifications = async (data) =>
	put(RequestsEnum.ATSUserEmailNotifications, data);

export const getClientApplicationNotifications = async (clientId) =>
	get(`${RequestsEnum.ClientApplicationNotification}/${clientId}`);

export const updateClientApplicationNotifications = async (data) =>
	put(RequestsEnum.ClientApplicationNotifications, data);

export const getATSUserPersonalData = async () => get(RequestsEnum.ATSUserPersonalData);

export const updateATSUserPersonalData = async (data) =>
	put(RequestsEnum.ATSUserPersonalData, data);

export const getHRWorkWeekSettings = async () => get(RequestsEnum.HRWorkWeekSettings);

export const updateHRWorkWeekSettings = async (data) =>
	put(RequestsEnum.HRWorkWeekSettings, data);

const settingsService = {
	getClientsAtsApplicationMessageByClientId,
	updateClientsAtsApplicationMessageByClientId,
	getClientEmailSignature,
	updateClientEmailSignature,
	getUserEmailNotifications,
	updateUserEmailNotifications,
	getClientApplicationNotifications,
	updateClientApplicationNotifications,
	getClientsAutoPop,
	updateClientsAutoPop,
	getATSUserPersonalData,
	updateATSUserPersonalData,
	getClientsTwoFA,
	updateClientsTwoFA,
	getHRWorkWeekSettings,
	updateHRWorkWeekSettings,
};

export default settingsService;
