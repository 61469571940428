import React, { type FC } from 'react';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';

import { Input } from 'antd';

import FieldWrapper from 'components/FieldWrapper';
import { phoneValidator } from 'utils/validators';

import FormBlockLine from '../FormBlockLine';

import { Styled } from './EmergencyContacts.styled';

export const EmergencyContacts: FC = () => {
	return (
		<Styled.FormBlockWrap title='Emergency Contacts'>
			<FormBlockLine>
				<Field name='emergencyContactFullName'>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='emergencyContactFullName'
								label='Full Name'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input {...input} placeholder='Full Name' autoComplete='off' />
							</FieldWrapper>
						</>
					)}
				</Field>
				<Field name='emergencyContactPhone' validate={phoneValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							isFixed
							name='emergencyContactPhone'
							label='Phone Number'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<Styled.PhoneFieldWrapper>
								<PhoneInput international {...input} defaultCountry={'GB'} />
							</Styled.PhoneFieldWrapper>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>
		</Styled.FormBlockWrap>
	);
};
