import React from 'react';

import dayjs from 'dayjs';

import { IActivityNotes, TagColoursType } from 'modules/Common/types';
import { getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';

import Tag from '../Tag';

import { MergedActivityWithNotesItem } from './TicketNotes.helper';
import { Styled } from './TicketNotes.styled';

interface ActivityNoteProps {
	note: MergedActivityWithNotesItem;
	senderColors: Record<number, string>;
}

const ActivityNote: React.FC<ActivityNoteProps> = ({ note, senderColors }) => {
	const tagColor = (statusName: string) => {
		return statusName === 'New'
			? TagColoursType.Blue
			: statusName === 'Resolved'
				? TagColoursType.Green
				: TagColoursType.Orange;
	};
	const noteId = note.id;
	const userId = note.userId;
	const fullName = (note.data as IActivityNotes).user.fullName;
	const fromStatusName = (note.data as IActivityNotes).fromStatus.name;
	const toStatusName = (note.data as IActivityNotes).toStatus.name;
	const noteDate = note.date;

	return (
		<Styled.NoteItem key={noteId}>
			<Styled.NoteInfo>
				<Styled.UserIcon bgColor={senderColors[userId]}>
					{getFirstLettersUppercase(fullName)}
				</Styled.UserIcon>
				<Styled.NoteName>{fullName}</Styled.NoteName>
				<Styled.NoteStatus>changed status</Styled.NoteStatus>
				<Styled.NoteDate>{dayjs(noteDate).format('MMM DD, YYYY')}</Styled.NoteDate>
			</Styled.NoteInfo>
			<Styled.NoteActivity>
				<Tag color={tagColor(fromStatusName)}>{fromStatusName}</Tag>
				<Styled.ArrowRight />
				<Tag color={tagColor(toStatusName)}>{toStatusName}</Tag>
			</Styled.NoteActivity>
		</Styled.NoteItem>
	);
};

export default ActivityNote;
