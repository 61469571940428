import React, { Key, useState } from 'react';

import { Space, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { ButtonTypes } from 'components/Button/Button.types';
import ArchiveIcon from 'components/SVG/ArchiveIcon';
import CopyIcon from 'components/SVG/CopyIcon';
import EditIcon from 'components/SVG/EditIcon';
import ProfileIcon from 'components/SVG/ProfileIcon';
import UarchiveIcon from 'components/SVG/UarchiveIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import UserIcon from 'modules/Common/components/UserIcon';
import {
	EmployeeStatusTypesFormEnum,
	IEmployeeState,
	IEmployeeTableValues,
} from 'modules/Common/types/employee';
import { COLORS } from 'theme';
import { IOption } from 'types';
import {
	fetchEmployeePhotoWithCredentials,
	getBackgroundColor,
	truncateString,
} from 'utils/helpers';

import { StatusStylesData } from './HREmployeesList.constants';
import { getAvailableStatusesOptionsForEmployee } from './HREmployeesList.helpers';
import { Styled } from './HREmployeesList.styled';
import { ChangeActionEnum } from './HREmployeesList.types';

type DataTableType = IEmployeeTableValues & {
	key?: Key;
};

const CopyColumn = ({ field, fieldName }: { field: string; fieldName: string }) => {
	const [copied, setCopied] = useState(false);
	const { truncated, fullText } = truncateString(field, 30);

	const handleCopy = () => {
		navigator.clipboard.writeText(field || '');
		setCopied(true);
		setTimeout(() => setCopied(false), 750);
	};

	if (!field) {
		return <span>-</span>;
	}

	return (
		<Tooltip title={copied ? `${fieldName} copied` : fullText}>
			<Styled.CopyWrapper>
				{truncated}
				<Styled.CopyIcon onClick={handleCopy}>
					<CopyIcon fill={COLORS.black} width='16px' />
				</Styled.CopyIcon>
			</Styled.CopyWrapper>
		</Tooltip>
	);
};

export const columns = (
	isActiveTab: boolean,
	employeeStatuses: IEmployeeState[],
	isEmployeeUser: boolean,
	handleChangeStatus: (
		employeeId: number,
		changeAction: ChangeActionEnum,
		startStatus?: EmployeeStatusTypesFormEnum,
		endStatus?: EmployeeStatusTypesFormEnum,
	) => void,
	handleViewItem: (id: number) => void,
	handleEditItem: (id: number) => void,
): ColumnsDataType => [
	{
		title: 'Employee Details',
		dataIndex: 'firstName',
		key: 'employeeDetails',
		sorter: true,
		render: (_: string, { userId, photo, firstName, lastName }: Partial<DataTableType>) => {
			return (
				<Styled.EmployeeDetails>
					<UserIcon
						imageId={photo}
						backgroundColor={(userId && getBackgroundColor(userId)) || COLORS.gray}
						fullName={`${firstName} ${lastName}`}
						fetchAndSetImageHelper={fetchEmployeePhotoWithCredentials}
					/>
					<span>{firstName + ' ' + lastName}</span>
				</Styled.EmployeeDetails>
			);
		},
	},
	{
		title: 'Job',
		dataIndex: 'jobTitle',
		key: 'jobTitle',
		render: (_: string, { jobTitle }: Partial<DataTableType>) => <span>{jobTitle || '-'}</span>,
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		render: (_: string, { email }: Partial<DataTableType>) => (
			<CopyColumn field={email || ''} fieldName='Email' />
		),
	},
	...[
		isEmployeeUser
			? {
				title: 'Work phone number',
				dataIndex: 'workPhone',
				key: 'workPhone',
				align: 'center',
				render: (_: string, { workPhone }: Partial<DataTableType>) => (
					<CopyColumn field={workPhone || ''} fieldName='Work phone number' />
				),
			  }
			: {},
	],
	{
		title: isActiveTab ? 'Hire date' : 'Left date',
		dataIndex: isActiveTab ? 'startDate' : 'leaveDate',
		key: isActiveTab ? 'startDate' : 'leaveDate',
		align: 'center',
		width: '140px',
		sorter: true,
		render: (_: string, { startDate, leaveDate }: Partial<DataTableType>) => {
			const data = isActiveTab ? startDate : leaveDate;

			return <span>{data ? dayjs(data).format('MMM DD, YYYY') : '-'}</span>;
		},
	},
	...[
		!isEmployeeUser
			? {
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				align: 'center' as 'center' | 'left' | 'right',
				sorter: isActiveTab,
				render: (_, { status, employeeId }: Partial<DataTableType>) => {
					const styles = status?.name && StatusStylesData[status?.name];

					const availableStatusesOptions =
							status && getAvailableStatusesOptionsForEmployee(status, employeeStatuses);

					return (
						<Styled.Select
							value={status?.id || null}
							styles={styles}
							fontWeight='bold'
							onChange={(statusId: EmployeeStatusTypesFormEnum) =>
								employeeId &&
									handleChangeStatus(
										employeeId,
										ChangeActionEnum.ChangeStatus,
										status?.id as EmployeeStatusTypesFormEnum,
										statusId,
									)
							}
							placeholder='Select a status'
							label=''
						>
							{availableStatusesOptions?.map((option: IOption) => (
								<Styled.Option key={option.id} value={option.id}>
									{option.name}
								</Styled.Option>
							))}
						</Styled.Select>
					);
				},
			  }
			: {},
	],
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '54px',
		render: (_, { employeeId }: Partial<DataTableType>) => {
			return isEmployeeUser ? (
				<Styled.Button
					buttonType={ButtonTypes.default}
					minWidth={100}
					fontSize={14}
					onClick={() => employeeId && handleViewItem(employeeId)}
				>
					View Profile
				</Styled.Button>
			) : (
				<Space size='middle'>
					{isActiveTab && (
						<Tooltip placement='top' title={'Edit'}>
							<Styled.Button
								buttonType={ButtonTypes.default}
								icon={<EditIcon fill={COLORS.black} />}
								onClick={() => employeeId && handleEditItem(employeeId)}
							/>
						</Tooltip>
					)}
					<Tooltip placement='top' title={isActiveTab ? 'Archive' : 'Un-archive'}>
						<Styled.Button
							buttonType={ButtonTypes.default}
							icon={
								isActiveTab ? (
									<ArchiveIcon fill={COLORS.black} width='16px' />
								) : (
									<UarchiveIcon fill={COLORS.black} width='16px' />
								)
							}
							onClick={() =>
								employeeId &&
								handleChangeStatus(
									employeeId,
									isActiveTab ? ChangeActionEnum.Archive : ChangeActionEnum.UnArchive,
								)
							}
						/>
					</Tooltip>
					<Tooltip placement='top' title={'View Profile'}>
						<Styled.Button
							buttonType={ButtonTypes.default}
							icon={<ProfileIcon width='16' fill={COLORS.black} />}
							onClick={() => employeeId && handleViewItem(employeeId)}
						/>
					</Tooltip>
				</Space>
			);
		},
	},
];
