import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
		display: flex;
		justify-content: space-between;
		gap: 24px;
		padding: 24px 0 40px;
		@media ${device.tablet} {
			flex-direction: column;
		}
	`;

	export const LeftColumn = styled.div`
		width: 28.6%;
		min-width: 380px;
		@media ${device.tablet} {
			width: 100%;
		}
	`;

	export const RightColumn = styled.div`
		width: 69.6%;
		display: flex;
		flex-direction: column;
		gap: 24px;
		@media ${device.tablet} {
			width: 100%;
		}
	`;

	export const FirstDayInvitation = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 10px;
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 16px 24px;
	`;

	export const FirstDayInvitationText = styled.div`
		margin-right: 24px;
	`;

	export const FirstDayInvitationHeader = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 8px;
	`;

	export const FirstDayInvitationDescription = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	`;

	export const NewTimeOffRequestWrap = styled.div`
		border-radius: 10px;
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 16px 24px 24px 24px;
	`;

	export const NewTimeOffRequestTopLine = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const NewTimeOffRequestTitle = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-right: 24px;
	`;

	export const NewTimeOffRequestList = styled.div``;

	export const TimeOffBlock = styled.div`
		padding: 32px 32px 24px 32px;
		border-radius: 10px;
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
	`;

	export const AttachmentsBlock = styled.div`
		border-radius: 10px;
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 24px 32px 0;
	`;

	export const StatisticWrap = styled.div`
		margin-bottom: 32px;
	`;

	export const AllRequests = styled.div`
		max-height: 640px;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		gap: 32px;
	`;

	export const RequestsWrapper = styled.div``;

	export const RequestsList = styled.div`
		display: flex;
		flex-direction: column;
		gap: 16px;
	`;

	export const RequestTitle = styled.h6`
		color: ${COLORS.darkGray2};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.176px;
		text-transform: uppercase;
		margin-bottom: 8px;
	`;

	export const NoRequests = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 80px 0;
		img {
			width: 115px;
			margin-bottom: 24px;
		}
		p {
			color: ${COLORS.darkGray1};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 4px;
		}
	`;
}
