import { Key } from 'react';

import { ColumnsType } from 'antd/es/table';
import { FieldValidator } from 'final-form';

import { GenericType } from 'types';

export type ItemType = {
	id: number;
	name: string;
};

export enum TagColoursType {
	DarkGray2 = '#5C5C60',
	GeekBlue = 'geekblue',
	Volcano = 'volcano',
	Orange = 'orange',
	Blue = 'blue',
	Green = 'green',
}

export enum StatusColorsType {
	DarkOrange = '#FAAD14',
	LightOrange = '#FFF8e5',
	DarkBlue = '#1690FF',
	LightBlue = '#E6F8FF',
	DarkGreen = '#51C31B',
	LightGreen = '#DDFFCA',
}

export enum SubscriptionTypes {
	Free = 'Free',
	Trial = 'Trial',
	Premium = 'Premium',
}

export enum SubscriptionTypesEnum {
	Standard = 'Standard',
	Pro = 'Pro',
	Trial = 'Trial',
}

export interface IChargeVat {
	valueInPercents: number;
	country: string;
}

export type FieldInputType = {
	id: string;
	name: string;
	label?: string;
	placeholder: string;
	type: FieldTypesEnum;
	hint?: string;
	validate?: FieldValidator<string>;
	onBlur?: (event: MouseEvent) => void;
	disabled?: boolean;
};

export const enum FieldTypesEnum {
	Email = 'email',
	File = 'file',
	Number = 'number',
}

export const enum FileUploadTypesEnum {
	Logo = 'LOGO',
	CV = 'CV',
	CL = 'CL',
	JobDescriptionAttachments = 'JOB',
	JobApplicationFormAttachment = 'JOB_APP_FORM',
	Ticket = 'TICKET',
	Email = 'EMAIL',
	FILE = 'FILE',
}

export type DataTableType = GenericType & {
	key?: Key;
};

export type TableColumnsType = ColumnsType<DataTableType>;

export const enum TabsTitleEnum {
	ActiveTab = '1',
	ArchiveTab = '2',
	GravityHistoryTab = '3',
}

export interface IColorProps {
	color: StatusColorsType;
	background: StatusColorsType;
}

export const colorPropsMap: { [key: string]: IColorProps } = {
	New: { color: StatusColorsType.DarkBlue, background: StatusColorsType.LightBlue },
	'In progress': { color: StatusColorsType.DarkOrange, background: StatusColorsType.LightOrange },
	Resolved: { color: StatusColorsType.DarkGreen, background: StatusColorsType.LightGreen },
};

export type QueryParamsSearchType = { searchKey: string; searchResult: number };

export type QueryParamsType = {
	search?: QueryParamsSearchType | null;
	filter?: [];
	sort?: [];
};

export type regType = string | RegExp;
