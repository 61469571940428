export const TimeOptions = [
	{
		label: 'Day(s)',
		value: 'D',
	},
	{
		label: 'Week(s)',
		value: 'W',
	},
	{
		label: 'Month(s)',
		value: 'M',
	},
];

export const RadioOptions = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
];

export const MAX_FILE_SIZE = 15;

export const MAX_FILE_SIZE_FOR_APPLICATIONS = 5;

export const acceptedImageFileFormat = 'image/png, image/jpg, image/jpeg, image/svg+xml';

export const acceptedCalendarFileFormat = '.ics';

export const acceptedApplicationUploadFormFileFormat = '.pdf, .doc, .docx';

export const acceptedTicketUploadFormFileFormat =
	'.pdf, .doc, .docx, .xls, .xlsx, .csv, image/png, image/jpg, image/jpeg';

export const acceptedEmployeeFilesUploadFormFileFormat =
	'.pdf, .doc, .docx, .xls, .xlsx, image/png, image/jpg, image/jpeg';

export const acceptedOnboardingFilesUploadFormFileFormat =
	'.pdf, .doc, .docx, .xls, .xlsx, .csv, image/png, image/jpg, image/jpeg';

export const DEFAULT_LOGO = '/images/bluetown-small-logo.png';

export const DEFAULT_LOGO_CONNECT = '/images/bluetown-small-logo-connect.png';

export const LOGO_CONNECT_BIG = '/images/connect-logo-big.png';

export const DEFAULT_ATS_BG = '/images/background.png';

export const DEFAULT_CAREER_BG = '/images/backgroundCareer.png';

export const FOOTER_HEIGHT = 52;
