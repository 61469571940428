import React from 'react';

import { Modal } from 'antd';

import Button, { ButtonTypes } from 'components/Button';

import { Styled } from './ConfirmationModal.styled';

type ConfirmationModalProps = {
	modalOpen: boolean;
	title: string;
	handleApprove: () => void;
	handleCloseModal: () => void;
};

const ConfirmationModal = ({
	modalOpen,
	title,
	handleApprove,
	handleCloseModal,
}: ConfirmationModalProps) => {
	return (
		<Modal open={!!modalOpen} footer={null} centered onCancel={handleCloseModal} width={500}>
			<Styled.ConfirmModalTitle>{title}</Styled.ConfirmModalTitle>
			<Styled.ConfirmModalButtons>
				<Button buttonType={ButtonTypes.primary} onClick={handleApprove}>
					Yes
				</Button>
				<Button buttonType={ButtonTypes.secondary} onClick={handleCloseModal}>
					No
				</Button>
			</Styled.ConfirmModalButtons>
		</Modal>
	);
};

export default ConfirmationModal;
