import React, { type FC } from 'react';

import { IButton } from 'components/Button/Button.types';
import ThemedButton from 'modules/Common/containers/ThemedButton';

import { useHRLayoutContext } from '../../containers/HRLayoutContext/HRLayoutContext';

const HRThemedButton: FC<IButton> = ({ children, ...props }) => {
	return (
		<ThemedButton context={useHRLayoutContext} {...props}>
			{children}
		</ThemedButton>
	);
};

export default HRThemedButton;
