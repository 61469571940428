import React, { type FC } from 'react';

import { TablePaginationConfig, Tabs } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import Table from 'components/Table';
import { ColumnsDataType } from 'components/Table/Table.types';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { TableColumnsType, TabsTitleEnum } from 'modules/Common/types';

import { Styled } from './QuestionnaireListContainer.styled';

type QuestionnaireListProps = {
	handleChangeTabs: (id: TabsTitleEnum | string) => void;
	handleTablePaginationChange?: (page: number, size: number) => void;
	handleTableChange?: (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => void;
	handleSearch: (value: string) => void;
	showDefaultPagination?: boolean;
	tableData: [];
	columns: (clientId?: string) => ColumnsDataType & TableColumnsType;
	pageIndex?: number;
	pageSize?: number;
	totalCount?: number;
	currentTab: TabsTitleEnum | string;
	loading: boolean;
	clientId?: string;
};

export const QuestionnaireListContainer: FC<QuestionnaireListProps> = ({
	handleChangeTabs,
	handleTablePaginationChange,
	handleTableChange,
	handleSearch,
	showDefaultPagination,
	tableData,
	columns,
	pageIndex,
	pageSize,
	totalCount,
	currentTab,
	loading,
	clientId,
}) => {
	const columnData = columns(clientId);

	const noDataAvailable = !tableData && !loading;

	return (
		<Styled.Root>
			<Tabs
				defaultActiveKey={currentTab}
				activeKey={currentTab}
				onChange={handleChangeTabs}
				tabBarStyle={{ fontFamily: 'Inter' }}
				items={[
					{
						label: 'Active',
						key: TabsTitleEnum.ActiveTab,
						active: true,
						children: (
							<Styled.BoxWrap isBoxComponent={!clientId}>
								<Styled.TableWrap noDataAvailable={noDataAvailable}>
									<Table
										data={tableData}
										columns={columnData}
										pageSize={pageSize}
										current={pageIndex}
										total={totalCount}
										onChange={handleTablePaginationChange}
										onPageSizeChange={handleTablePaginationChange}
										onTableChange={handleTableChange}
										showDefaultPagination={showDefaultPagination}
										loading={loading}
									/>
									<span>There are no active questionnaires</span>
								</Styled.TableWrap>
							</Styled.BoxWrap>
						),
					},
					{
						label: 'Archive',
						key: TabsTitleEnum.ArchiveTab,
						children: (
							<Styled.BoxWrap isBoxComponent={!clientId}>
								<Styled.TableWrap noDataAvailable={noDataAvailable}>
									<Table
										data={tableData}
										columns={columnData}
										pageSize={pageSize}
										current={pageIndex}
										total={totalCount}
										onChange={handleTablePaginationChange}
										onPageSizeChange={handleTablePaginationChange}
										onTableChange={handleTableChange}
										showDefaultPagination={showDefaultPagination}
										loading={loading}
									/>
									<span>There are no archive questionnaires</span>
								</Styled.TableWrap>
							</Styled.BoxWrap>
						),
					},
				]}
				tabBarExtraContent={
					<div style={{ textTransform: 'capitalize', marginBottom: '10px' }}>
						<SearchFilterSortWrapper
							search={{
								onSearch: handleSearch,
								isDebounce: true,
								placeholder: 'Search a client',
								styles: { width: '300px', flexBasis: 'auto' },
							}}
						/>
					</div>
				}
			/>
		</Styled.Root>
	);
};

export default QuestionnaireListContainer;
