import React, { Key } from 'react';

import { Tooltip } from 'antd';

import { ColumnsDataType } from 'components/Table/Table.types';
import { IContractState } from 'modules/Common/types';
import { UserRolesType } from 'types';
import { truncateString } from 'utils/helpers';

import { renderContractStatus, renderCredits } from './FinancialReports.helpers';
import { ContractReportValues } from './FinancialReports.types';

type DataTableType = ContractReportValues & {
	key?: Key;
};

interface ColumnsParams {
	roles: UserRolesType;
	contractStatuses: IContractState[];
	handleChangeContractStatus: (contractId: number, statusId: number) => void;
}

export const columns = ({
	roles,
	contractStatuses,
	handleChangeContractStatus,
}: ColumnsParams): ColumnsDataType => [
	{
		title: 'Client',
		dataIndex: 'client',
		key: 'client',
		sorter: true,
		render: (_: string, { client }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(client || '-', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span style={{ fontWeight: 'bold' }}>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Start Date',
		dataIndex: 'startDate',
		key: 'startDate',
		sorter: true,
		align: 'center',
		render: (_: string, { startDate }: Partial<DataTableType>) => <span>{startDate ?? '-'}</span>,
	},
	{
		title: 'End Date',
		dataIndex: 'endDate',
		key: 'endDate',
		align: 'center',
		render: (_: string, { endDate }: Partial<DataTableType>) => <span>{endDate ?? '-'}</span>,
	},
	{
		title: 'Reference',
		dataIndex: 'btoReference',
		key: 'btoReference',
		render: (_: string, { btoReference }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(btoReference || '-', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Net',
		dataIndex: 'net',
		key: 'net',
		align: 'center',
		render: (_: string, { net }: Partial<DataTableType>) => <span>{net ?? '-'}</span>,
	},
	{
		title: 'Gross',
		dataIndex: 'gross',
		key: 'gross',
		align: 'center',
		render: (_: string, { gross }: Partial<DataTableType>) => <span>{gross ?? '-'}</span>,
	},
	{
		title: 'Vat',
		dataIndex: 'vat',
		key: 'vat',
		align: 'center',
		render: (_: string, { vat }: Partial<DataTableType>) => <span>{vat ?? '-'}</span>,
	},
	{
		title: 'Status',
		dataIndex: 'state',
		key: 'status',
		sorter: true,
		align: 'center',
		render: (_: string, { status, contractId }: Partial<DataTableType>) =>
			renderContractStatus(
				{ status, id: contractId },
				contractStatuses,
				roles,
				handleChangeContractStatus,
			),
	},
	{
		title: 'Employee',
		dataIndex: 'employee',
		key: 'employee',
		render: (_: string, { employeeFullName }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(employeeFullName || '-', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Credits',
		dataIndex: 'credits',
		key: 'credits',
		width: '240px',
		align: 'center',
		render: (_: string, { credits }: Partial<DataTableType>) => renderCredits({ credits }),
	},
];
