export enum ListActionEnums {
	ADD = 'ADD',
	DELETE = 'DELETE',
	UPDATE = 'UPDATE',
}

export type CustomHolidayEventType = {
	id: number;
	holidayName: string;
	holidayDate: string;
	isEveryYear: boolean;
};

export type BankHolidayEventType = {
	id: number;
	holidayName: string;
	holidayDate: string;
};

export type VacationLeaveDayType = {
	id: number;
	employeeId: number;
	days: number;
};

export type EmployeeOptions = {
	value: number;
	label: string;
};

export type WorkWeekSettingsValuesType = {
	mondayEnabled: boolean;
	tuesdayEnabled: boolean;
	wednesdayEnabled: boolean;
	thursdayEnabled: boolean;
	fridayEnabled: boolean;
	saturdayEnabled: boolean;
	sundayEnabled: boolean;
};

export type WorkWeekSettingsValuesFromBackendType = WorkWeekSettingsValuesType & {
	id: number;
	client: string;
};
