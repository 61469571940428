import ArrowVectorUp from 'components/SVG/ArrowVectorUp';
import BluetownProLogo from 'components/SVG/BluetownProLogo';
import FilterIcon from 'components/SVG/FilterIcon';
import GuardianIcon from 'components/SVG/GuardianIcon';
import IndeedIcon from 'components/SVG/IndeedIcon';
import MessageIcon from 'components/SVG/MessageIcon';
import VideoPlayIcon from 'components/SVG/VideoPlayIcon';
import { COLORS } from 'theme';

export const CreditIcons = {
	1: <ArrowVectorUp fill={COLORS.iconColor} width='18' height='18' />,
	2: <FilterIcon fill={COLORS.iconColor} width='16' height='18' />,
	3: <GuardianIcon fill={COLORS.iconColor} />,
	4: <IndeedIcon fill={COLORS.iconColor} />,
	5: <VideoPlayIcon fill={COLORS.iconColor} width='16' height='18' />,
	6: <MessageIcon fill={COLORS.iconColor} width='16' height='18' />,
	7: <BluetownProLogo fill={COLORS.iconColor} width='16' height='18' />,
};
