import React from 'react';

import { isEmpty } from 'lodash';

import { Styled } from './BODashboard.styled';
import { IBODashboardInsights } from './BODashboard.types';

const BODashboardInsights = ({ insights }: { insights: IBODashboardInsights }) => {
	if (isEmpty(insights)) return null;

	return (
		<Styled.InsightsWrapper>
			<Styled.InsightBox>
				<Styled.InsightBoxNumber>{insights.clientsTotal.toLocaleString()}</Styled.InsightBoxNumber>
				<Styled.InsightBoxName>Clients</Styled.InsightBoxName>
			</Styled.InsightBox>
			<Styled.InsightBox>
				<Styled.InsightBoxNumber>
					{insights.activeJobsTotal.toLocaleString()}
				</Styled.InsightBoxNumber>
				<Styled.InsightBoxName>Jobs</Styled.InsightBoxName>
			</Styled.InsightBox>
			<Styled.InsightBox>
				<Styled.InsightBoxNumber>
					{insights.totalCandidates.toLocaleString()}
				</Styled.InsightBoxNumber>
				<Styled.InsightBoxName>Candidates</Styled.InsightBoxName>
			</Styled.InsightBox>
			<Styled.InsightBox>
				<Styled.InsightBoxNumber>
					{insights.totalInterviewCandidates.toLocaleString()}
				</Styled.InsightBoxNumber>
				<Styled.InsightBoxName>Interview</Styled.InsightBoxName>
			</Styled.InsightBox>
			<Styled.InsightBox>
				<Styled.InsightBoxNumber>
					{insights.totalHiredCandidates.toLocaleString()}
				</Styled.InsightBoxNumber>
				<Styled.InsightBoxName>Hired</Styled.InsightBoxName>
			</Styled.InsightBox>
		</Styled.InsightsWrapper>
	);
};

export default BODashboardInsights;
