import React, { type FC, memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { TablePaginationConfig, Tabs, message } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import Box from 'components/Box';
import Table from 'components/Table';
import { atsDucks } from 'modules/ATS/ducks';
import Badge from 'modules/Common/components/Badge';
import ReceiptModal from 'modules/Common/components/ReceiptModal';
import { InvoiceFields, MESSAGES } from 'modules/Common/constants';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants/table';
import { commonDucks } from 'modules/Common/ducks';
import {
	IContractValues,
	IInvoicesData,
	ReceiptDataType,
	SubscriptionPayPremiumStatusesEnum,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, GenericType, UserTypesValueEnum } from 'types';
import { propsFilter } from 'utils/helpers';

import { ContractFields } from './FinanceTables.constants';
import { columns } from './FinanceTables.entities';
import { Styled } from './FinanceTables.styled';
import { invoiceColumns } from './Invoices.entities';

const enum TabsTitleEnum {
	InvoicesTab = '1',
	ContractTab = '2',
}

type FinanceTableProps = {
	contractsData: {
		data: IContractValues;
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	outstandingCount: number;
	invoices: IInvoicesData;
	countries: CountriesType;
	contractReceipt: ReceiptDataType;
	getAtsInvoicesRequested: (params: { page: number; size: number }) => void;
	getAtsContracts: (
		{ page, size }: { page?: number; size?: number },
		platform: UserTypesValueEnum,
	) => void;
	getCreditsRequested: () => void;
	getContractReceipt: (
		id: number,
		isInvoice?: boolean,
		platform?: UserTypesValueEnum,
		callback?: () => void,
	) => void;
	createOrderInvoiceRequested: (orderId: string) => void;
	creditsFields: GenericType[];
	loading: GenericType;
	loadingAts: GenericType;
};

const FinanceTables: FC<FinanceTableProps> = ({
	contractsData,
	outstandingCount,
	invoices,
	countries,
	contractReceipt,
	getAtsInvoicesRequested,
	getCreditsRequested,
	getContractReceipt,
	createOrderInvoiceRequested,
	getAtsContracts,
	creditsFields,
	loading,
	loadingAts,
}) => {
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.InvoicesTab);
	const [sortParamsInvoice, setSortParamsInvoice] = useState({});
	const [sortParamsContract, setSortParamsContract] = useState({});

	const [openModal, setOpenModal] = useState(false);

	const { isUK } = countries || {};

	const { pageIndex, pageSize, totalCount } = contractsData || {};

	const handleViewItem = useCallback(
		async (id: number) => {
			id &&
				getContractReceipt(id, tab === TabsTitleEnum.InvoicesTab, UserTypesValueEnum.ATS, () =>
					setOpenModal(true),
				);
		},
		[tab, contractReceipt],
	);

	const filteredContractsData = propsFilter(contractsData?.data, ContractFields);
	const filteredInvoicesData = propsFilter(invoices.data, InvoiceFields);
	const contractColumns = columns();
	const invoicesColumns = invoiceColumns(handleViewItem, countries);
	const [searchParams, setSearchParams] = useSearchParams();
	const orderId = searchParams.get('order_id');
	const paymentState = searchParams.get('state');

	const handleTablePageSizeChangeInvoices = useCallback(
		(page: number, size: number) => {
			setSortParamsInvoice({
				...sortParamsInvoice,
				page,
				size,
			});
		},
		[sortParamsInvoice],
	);

	const handleTableChangeInvoices = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setSortParamsInvoice({
			...sortParamsInvoice,
			sorter,
		});
	};

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		getAtsInvoicesRequested({
			page: sortParamsInvoice?.page || 0,
			size: sortParamsInvoice?.size || pageSize,
			...(sortParamsInvoice?.sorter?.order &&
				sortParamsInvoice?.sorter?.field && {
				sort: `${sortParamsInvoice?.sorter?.field},${
					ordersMap[sortParamsInvoice?.sorter?.order || 'ascend']
				}`,
			}),
		});
	}, [sortParamsInvoice]);

	const handlePageChangeContracts = useCallback(
		(page: number, size: number) => {
			setSortParamsContract({
				...sortParamsContract,
				page,
				size,
			});
		},
		[sortParamsContract],
	);

	const handleTableChangeContracts = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setSortParamsContract({
			...sortParamsContract,
			sorter,
		});
	};

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		getAtsContracts(
			{
				page: sortParamsContract?.page || 0,
				size: sortParamsContract?.size || pageSize,
				...(sortParamsContract?.sorter?.order &&
					sortParamsContract?.sorter?.field && {
					sort: `${sortParamsContract?.sorter?.field},${
						ordersMap[sortParamsContract?.sorter?.order || 'ascend']
					}`,
				}),
			},
			UserTypesValueEnum.ATS,
		);
	}, [sortParamsContract]);

	const handleChangeTabs = (id: TabsTitleEnum | string) => {
		setTab(id);
	};

	const handleCancel = () => setOpenModal(false);

	const items = [
		{
			label: 'Invoices',
			key: TabsTitleEnum.InvoicesTab,
			children: (
				<Box>
					{filteredInvoicesData && (
						<Table
							data={filteredInvoicesData}
							columns={invoicesColumns}
							pageSize={invoices.pageSize}
							current={invoices.pageIndex}
							total={invoices.totalCount}
							onChange={handleTablePageSizeChangeInvoices}
							onPageSizeChange={handleTablePageSizeChangeInvoices}
							onTableChange={handleTableChangeInvoices}
							loading={!!loadingAts?.getAtsInvoicesLoad}
						/>
					)}
				</Box>
			),
		},
		isUK && {
			label: (
				<>
					Contracts
					<Styled.BadgeWrapper>{!!outstandingCount && <Badge dot />}</Styled.BadgeWrapper>
				</>
			),
			key: TabsTitleEnum.ContractTab,
			active: true,
			children: (
				<Box>
					{filteredContractsData && (
						<Table
							data={filteredContractsData}
							columns={contractColumns}
							pageSize={pageSize}
							current={pageIndex}
							total={totalCount}
							onChange={handlePageChangeContracts}
							onPageSizeChange={handlePageChangeContracts}
							onTableChange={handleTableChangeContracts}
							loading={!!loading?.getContractsLoad}
						/>
					)}
				</Box>
			),
		},
	];

	useEffect(() => {
		if (orderId) {
			message.success('Your payment has been sent.');

			createOrderInvoiceRequested(orderId);
			setSearchParams({});
		}
	}, [orderId]);

	useEffect(() => {
		if (paymentState === SubscriptionPayPremiumStatusesEnum.Success) {
			message.success(MESSAGES.successfullyPaySubscription);

			setSearchParams({});
		}
	}, [paymentState]);

	useEffect(() => {
		if (isUK) {
			getAtsContracts(
				{ page: pageIndex as number, size: pageSize as number },
				UserTypesValueEnum.ATS,
			);
		}
	}, [isUK]);

	useEffect(() => {
		getAtsInvoicesRequested(DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE);
		isUK && getCreditsRequested();
	}, [isUK]);

	return (
		<Styled.Root>
			<Tabs
				defaultActiveKey={tab}
				activeKey={tab}
				onChange={handleChangeTabs}
				items={items}
				tabBarStyle={{ fontFamily: 'Inter', textTransform: 'uppercase' }}
			/>
			<ReceiptModal
				open={openModal}
				onCancel={handleCancel}
				content={contractReceipt}
				creditsFields={creditsFields}
				countries={countries}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		contractsData: commonDucks.commonSelectors.getContracts(state),
		invoices: atsDucks.atsSelectors.getInvoicesState(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		outstandingCount: commonDucks.commonSelectors.getContractsOutstandingCount(state),
		contractReceipt: commonDucks.commonSelectors.getContractReceipt(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
		loadingAts: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAtsInvoicesRequested: atsDucks.atsActions.getAtsInvoicesRequested,
		createOrderInvoiceRequested: atsDucks.atsActions.createOrderInvoiceRequested,
		getAtsContracts: commonDucks.commonActions.getContractsRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		getContractReceipt: commonDucks.commonActions.getContractReceiptRequested,
	},
)(memo(FinanceTables));
