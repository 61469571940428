import styled from 'styled-components/macro';

import { FormBlock, FormBlockProps } from 'modules/Common/components/FormBlock/FormBlock';
import { COLORS } from 'theme';

export namespace Styled {
	export const FormBlockWrap = styled(FormBlock).attrs<FormBlockProps>(() => ({
		style: {
			border: 'none',
			boxShadow: 'none',
			padding: '0',
		},
	}))<FormBlockProps>``;

	export const PhoneFieldWrapper = styled.div`
		width: 100%;

		input {
			color: rgba(0, 0, 0, 0.88);
			border: 1px solid ${COLORS.neutral5};
			border-radius: 6px;
			transition: all 0.2s;
			font-size: 14px;
			padding: 4px 11px;
			line-height: 1.57;

			&:hover {
				border-color: ${COLORS.inputColor};
			}

			&:focus {
				border-color: ${COLORS.inputColor};
				box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
				border-inline-end-width: 1px;
				outline: 0;
			}
		}
	`;
}
