import styled from 'styled-components/macro';

import { COLORS } from 'theme';

type RootProps = {
	isInBlockView: boolean;
};

type RequestDatesProps = {
	isShortView: boolean;
};

type RequestApproveStatusBadgeProps = {
	color: string;
	bgColor: string;
	borderColor: string;
};

type RequestMainContentProps = {
	isEmployeeView: boolean;
	isInBlockView: boolean;
};

export namespace Styled {
	export const Root = styled.div<RootProps>`
		${({ isInBlockView }) =>
		!isInBlockView &&
			`
            padding: 24px;
            border-radius: 4px;
            border: 1px solid ${COLORS.lightGray2};
            background: ${COLORS.white};
            box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
        `}
	`;

	export const Header = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 16px;
		border-bottom: 1px solid ${COLORS.lightGray2};
	`;

	export const EmployeeData = styled.div`
		display: flex;
		align-items: center;
		gap: 8px;
	`;

	export const EmployeeName = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	`;

	export const RequestDate = styled.div`
		color: ${COLORS.black};
		text-align: right;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	`;

	export const RequestMainContent = styled.div<RequestMainContentProps>`
		padding: ${({ isEmployeeView }) => (isEmployeeView ? '0' : '16px 0')};
		${({ isInBlockView }) =>
		isInBlockView &&
			`
			margin-top: 16px;
            padding: 16px;
            border-radius: 10px;
            background: ${COLORS.lightBlue7};
        `}
	`;

	export const RequestInfoTopRow = styled.div`
		display: flex;
		justify-content: space-between;
	`;

	export const RequestInfoTypeDatesWrap = styled.div``;

	export const RequestApproveStatusBadge = styled.div<RequestApproveStatusBadgeProps>`
		text-align: center;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		border-radius: 2px;
		padding: 4px 8px;
		min-width: 110px;
		align-self: self-start;
		border-width: 1px;
		border-style: solid;
		color: ${({ color }) => color};
		background: ${({ bgColor }) => bgColor};
		border: 1px solid ${({ borderColor }) => borderColor};
	`;

	export const RequestType = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		text-transform: capitalize;
	`;

	export const RequestDates = styled.div<RequestDatesProps>`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: ${({ isShortView }) => (isShortView ? '0' : '16px')};
	`;

	export const RequestComment = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		span {
			display: block;
			color: ${COLORS.darkGray2};
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-top: 16px;
		gap: 24px;
	`;
}
