import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import { useMount } from 'hooks';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import QuestionnaireListContainer from 'modules/Common/containers/QuestionnaireListContainer';
import { commonDucks } from 'modules/Common/ducks';
import { IQuestionnairesPaginated, TabsTitleEnum } from 'modules/Common/types';
import { GenericType, IRequestParamsExtended } from 'types';
import { propsFilter } from 'utils/helpers';

import { TableFields } from './QuestionnaireList.constants';
import { columns } from './QuestionnaireList.entities';

type QuestionnaireListProps = {
	getQuestionnaires: (params?: IRequestParamsExtended) => void;
	questionnaires: IQuestionnairesPaginated;
	loading: GenericType;
};

export const QuestionnaireList: FC<QuestionnaireListProps> = ({
	getQuestionnaires,
	questionnaires,
	loading,
}) => {
	const { clientId } = useParams();
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);
	const [tableParams, setTableParams] = useState({});

	const { data, pageIndex, pageSize, totalCount } = questionnaires;

	const filteredTableData = data?.length && propsFilter(data, TableFields);

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			const isActiveTab = tab === TabsTitleEnum.ActiveTab;
			setTableParams({
				...tableParams,
				active: isActiveTab,
				page,
				size,
				client: clientId,
			});
		},
		[tab, questionnaires, tableParams],
	);

	const handleSearch = (value: string) => {
		setTableParams({ ...tableParams, page: 1, search: value });
	};

	const handleChangeTabs = useCallback(
		(id: TabsTitleEnum | string) => {
			setTableParams({
				...tableParams,
				active: id === TabsTitleEnum.ActiveTab,
				page: 0,
				size: pageSize,
				client: clientId,
			});

			setTab(id);
		},
		[tab, pageIndex],
	);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			sorter,
		});
	};

	useMount(() => {
		!data?.length &&
			getQuestionnaires({
				active: true,
				page: 0,
				size: DEFAULT_PAGE_SIZE,
				client: clientId,
			});
	});

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		getQuestionnaires({
			active: tableParams?.active || tab === TabsTitleEnum.ActiveTab,
			page: tableParams?.page || 0,
			size: tableParams?.size || pageSize,
			client: clientId,
			...(tableParams.search && { search: tableParams.search }),
			...(tableParams?.sorter?.order &&
				tableParams?.sorter?.field && {
				sort: `${tableParams?.sorter?.field},${
					ordersMap[tableParams?.sorter?.order || 'ascend']
				}`,
			}),
		});
	}, [clientId, tableParams, tab]);

	return (
		<QuestionnaireListContainer
			handleTablePaginationChange={handleTablePaginationChange}
			onPageSizeChange={handleTablePaginationChange}
			handleTableChange={handleTableChange}
			handleChangeTabs={handleChangeTabs}
			handleSearch={handleSearch}
			tableData={filteredTableData}
			columns={columns}
			pageIndex={pageIndex}
			pageSize={pageSize}
			totalCount={totalCount}
			currentTab={tab}
			loading={!!loading?.getAllBoQuestionnairesLoad}
			clientId={clientId}
		/>
	);
};

export default connect(
	(state) => ({
		questionnaires: commonDucks.commonSelectors.getBOQuestionnaires(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getQuestionnaires: commonDucks.commonActions.getAllBOQuestionnairesRequested,
	},
)(QuestionnaireList);
