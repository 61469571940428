import styled from 'styled-components/macro';

interface IHeaderProps {
	backgroundColor?: string;
	isHrModule?: boolean;
};

export namespace S {
	export const Logo = styled.img`
		max-width: 100px;
		max-height: 56px;
	`;
	export const Header = styled.header<IHeaderProps>`
		padding: 16px 55px;
		background: ${({ theme, isHrModule, backgroundColor }) =>
		isHrModule ? backgroundColor : theme.colors.darkBlue};
		z-index: ${({ isHrModule }) => isHrModule ? 999 : 9999};
	`;

	export const Container = styled.section`
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		padding: 0;
		max-width: ${({ theme }) => theme.size.large};
	`;
	export const Main = styled.main`
		display: flex;
		align-items: center;
	`;
	export const MainMenuWrapper = styled.div<IHeaderProps>`
		padding-left: ${({ isHrModule }) => (isHrModule ? '56px' : '0')};
	`;
}
