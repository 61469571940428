import React, { useMemo, type FC } from 'react';
import { Field, Form } from 'react-final-form';

import { Input, Spin } from 'antd';
import { FormApi } from 'final-form';
import { omit } from 'lodash';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldPassword from 'components/FieldPassword';
import FieldWrapper from 'components/FieldWrapper';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { IChangePersonalDataValues, IUserPersonalData } from 'modules/Common/types';
import {
	composeValidators,
	passwordValidator,
	repeatPasswordValidator,
	requiredFieldValidator,
	requiredValidator,
} from 'utils/validators';

import { Styled } from './ChangePersonalDataContainer.styled';

type ChangePersonalDataContainerProps = {
	userPersonalData: IUserPersonalData;
	handleSaveUserPersonalData: (values: IChangePersonalDataValues) => void;
	loading: boolean;
};

export const ChangePersonalDataContainer: FC<ChangePersonalDataContainerProps> = ({
	userPersonalData,
	handleSaveUserPersonalData,
	loading,
}) => {
	const initialValues = useMemo(
		() => ({
			firstName: userPersonalData?.firstName || '',
			lastName: userPersonalData?.lastName || '',
		}),
		[userPersonalData],
	);

	const onSubmit = (values: IChangePersonalDataValues) => {
		handleSaveUserPersonalData(omit(values, ['confirmPassword']));
	};

	const oldPasswordValidator = (
		form: FormApi<IChangePersonalDataValues, Partial<IChangePersonalDataValues>>,
	) => {
		const { oldPassword, newPassword, confirmPassword } = form.getState().values;
		if (newPassword || confirmPassword) {
			return requiredValidator(oldPassword);
		}

		return undefined;
	};

	const newPasswordValidator = (
		form: FormApi<IChangePersonalDataValues, Partial<IChangePersonalDataValues>>,
	) => {
		const { oldPassword, newPassword } = form.getState().values;
		if (oldPassword) {
			return composeValidators(requiredValidator, passwordValidator)(newPassword);
		}

		return undefined;
	};

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, dirty, form }) => (
					<form onSubmit={handleSubmit}>
						<Box noSpaces fullWidth style={{ minHeight: '100%' }}>
							<Styled.Title>Change Personal Data</Styled.Title>
							<Styled.Fields>
								<FormBlockLine>
									<Field name='firstName' validate={requiredFieldValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='firstName'
												label='First Name'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<Input {...input} placeholder='Enter first name...' />
											</FieldWrapper>
										)}
									</Field>
									<Field name='lastName' validate={requiredFieldValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='lastName'
												label='Last Name'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<Input {...input} placeholder='Enter last name...' />
											</FieldWrapper>
										)}
									</Field>
								</FormBlockLine>
								<FormBlockLine>
									<Field name='oldPassword' validate={() => oldPasswordValidator(form)}>
										{({ input, meta }) => (
											<FieldWrapper
												name='oldPassword'
												label='Old password'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												isFixed
											>
												<FieldPassword {...input} placeholder='' />
											</FieldWrapper>
										)}
									</Field>
									<Field name='newPassword' validate={() => newPasswordValidator(form)}>
										{({ input, meta }) => (
											<FieldWrapper
												name='newPassword'
												label='New password'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												isFixed
											>
												<FieldPassword {...input} placeholder='' />
											</FieldWrapper>
										)}
									</Field>
								</FormBlockLine>
								<FormBlockLine>
									<Field
										name='confirmPassword'
										validate={(value: string) =>
											repeatPasswordValidator(form.getState().values.newPassword, value)
										}
									>
										{({ input, meta }) => (
											<FieldWrapper
												name='confirmPassword'
												label='Confirm new password'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												isFixed
											>
												<FieldPassword {...input} placeholder='' />
											</FieldWrapper>
										)}
									</Field>
									<div></div>
								</FormBlockLine>
							</Styled.Fields>
						</Box>

						<Styled.ButtonWrapper>
							<ATSThemedButton
								buttonType={ButtonTypes.primary}
								type='submit'
								disabled={!dirty || loading}
							>
								Save
								{loading ? <Spin style={{ marginLeft: '12px' }} /> : ''}
							</ATSThemedButton>
						</Styled.ButtonWrapper>
					</form>
				)}
			/>
		</Styled.Root>
	);
};
