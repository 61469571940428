import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { useMount } from 'hooks';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Routes } from 'types';

import { Styled } from './BODashboard.styled';
import { BODashboardProps } from './BODashboard.types';
import { columns as clientsColumns } from './BODashboardClientsTable.entities';
import BODashboardInsights from './BODashboardInsights';
import { columns as ticketsColumns } from './BODashboardTicketsTable.entities';

const BODashboard: FC<BODashboardProps> = ({
	userInfo,
	insightsData,
	clientsData,
	ticketsData,
	loading,
	getBODashboardInsightsRequested,
	getBODashboardClientsRequested,
	getBODashboardTicketsRequested,
}) => {
	const { clients, numberOfNewClients } = clientsData || {};
	const { tickets, numberOfNewTickets } = ticketsData || {};
	const navigate = useNavigate();

	useMount(() => {
		getBODashboardInsightsRequested();
		getBODashboardClientsRequested();
		getBODashboardTicketsRequested();
	});

	const insightsLoading = !!loading?.getBoDashboardInsightsLoad;
	const clientsLoading = !!loading?.getBoDashboardClientsLoad;
	const ticketsLoading = !!loading?.getBoDashboardTicketsLoad;
	const clientsColumnsData = clientsColumns();
	const ticketsColumnsData = ticketsColumns();

	const newTicketsLabel = numberOfNewTickets ? `(${numberOfNewTickets} new)` : '';
	const newClientsLabel = numberOfNewClients ? `(${numberOfNewClients} new)` : '';

	if (insightsLoading) {
		return <Spinner />;
	}

	return (
		<Styled.Root>
			<Styled.HeaderBackground />
			<Styled.Header>
				<Styled.HeaderTitle>
					Welcome back <br />
					{`${userInfo?.firstName}`}
				</Styled.HeaderTitle>
				<BODashboardInsights insights={insightsData} />
			</Styled.Header>
			<Styled.Content>
				<Styled.ContentBlock>
					<Styled.ContentBlockHeader>
						<Styled.ContentBlockTitle>Clients {newClientsLabel}</Styled.ContentBlockTitle>
						<Button
							buttonType={ButtonTypes.primary}
							onClick={() => navigate(`${Routes.BackOffice}${Routes.ClientCreate}`)}
						>
							Create Client
						</Button>
					</Styled.ContentBlockHeader>
					<Styled.ContentBlockBody>
						<Table data={clients} columns={clientsColumnsData} loading={clientsLoading} />
					</Styled.ContentBlockBody>
					<Styled.ContentBlockFooter>
						<Button
							buttonType={ButtonTypes.secondary}
							onClick={() => navigate(`${Routes.BackOffice}${Routes.Clients}`)}
						>
							View All
						</Button>
					</Styled.ContentBlockFooter>
				</Styled.ContentBlock>
				<Styled.ContentBlock>
					<Styled.ContentBlockHeader>
						<Styled.ContentBlockTitle>Tickets {newTicketsLabel}</Styled.ContentBlockTitle>
						<Button
							buttonType={ButtonTypes.primary}
							onClick={() => navigate(`${Routes.BackOffice}${Routes.CreateSupportTicket}`)}
						>
							Create Ticket
						</Button>
					</Styled.ContentBlockHeader>
					<Styled.ContentBlockBody>
						<Table data={tickets} columns={ticketsColumnsData} loading={ticketsLoading} />
					</Styled.ContentBlockBody>
					<Styled.ContentBlockFooter>
						<Button
							buttonType={ButtonTypes.secondary}
							onClick={() => navigate(`${Routes.BackOffice}${Routes.SupportTickets}`)}
						>
							View All
						</Button>
					</Styled.ContentBlockFooter>
				</Styled.ContentBlock>
			</Styled.Content>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		insightsData: backOfficeDucks.backOfficeSelectors.getBODashboardsInsights(state),
		userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
		clientsData: backOfficeDucks.backOfficeSelectors.getBODashboardsClients(state),
		ticketsData: backOfficeDucks.backOfficeSelectors.getBODashboardsTickets(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getBODashboardInsightsRequested:
			backOfficeDucks.backOfficeActions.getBODashboardInsightsRequested,
		getBODashboardClientsRequested:
			backOfficeDucks.backOfficeActions.getBODashboardClientsRequested,
		getBODashboardTicketsRequested:
			backOfficeDucks.backOfficeActions.getBODashboardTicketsRequested,
	},
)(BODashboard);
