import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const EventWrapper = styled.div`
		position: relative;
		height: 100%;

		.rbc-month-view & {
			max-height: 14px;
		}

		span {
			display: flex;
			align-items: center;
			height: 100%;
		}
	`;

	export const PopoverHead = styled.div`
		display: flex;
		max-width: 326px;
		margin: 0 -12px;
		padding: 4px 16px 8px 24px;
		border-bottom: 1px solid ${COLORS.lightGray2};
		gap: 8px;
		font-family: 'Inter';

		h4 {
			word-break: break-all;
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}

		a {
			font-size: 14px;
			line-height: 20px;
		}
	`;
}
