import { get, post, patch, put, del } from 'api/axiosInstance';
import { RequestsEnum } from 'types';
import { getTableParams } from 'utils/helpers';

export const getEmployeeRoles = async () => {
	return get(RequestsEnum.HREmployeeRoles);
};

export const getEmployeeStatuses = async () => {
	return get(RequestsEnum.HREmployeeStatus);
};

export const getEmployeesListSuperUser = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.HREmployeeSuperUser}${queryParams}`);
};

export const getEmployeesListNonSuperUser = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.HREmployeeNonSuperUser}${queryParams}`);
};

export const archiveEmployee = async ({ employeeId, leaveDate }) =>
	patch(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.Archive}`, { leaveDate });

export const unArchiveEmployee = async (employeeId) =>
	patch(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.Unarchive}`);

export const changeEmployeeStatus = async ({ employeeId, ...props }) =>
	patch(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.Status}`, props);

export const addEmployee = async (data) => post(RequestsEnum.HREmployee, data);

export const getEmployeeByIdSuperUser = async (employeeId) =>
	get(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.SuperUser}`);

export const getEmployeeByIdNonSuperUser = async (employeeId) =>
	get(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.NonSuperUser}`);

export const editEmployeeSuperUser = async (employeeId, values) =>
	put(`${RequestsEnum.HREmployee}/${employeeId}`, values);

export const editEmployeeNonSuperUser = async (employeeId, values) =>
	put(`${RequestsEnum.HREmployee}/${employeeId}/non-super`, values);

export const getOwnProfileSuperUser = async () => get(`${RequestsEnum.HREmployee}/personal-data`);

export const editOwnProfileSuperUser = async (values) =>
	put(`${RequestsEnum.HREmployee}/personal-data/super`, values);

export const editOwnProfileNonSuperUser = async (values) =>
	put(`${RequestsEnum.HREmployee}/personal-data/non-super`, values);

export const getEmployeePhotoByUUID = async (uuid) => {
	const blob = await get(`${RequestsEnum.HREmployeePhoto}/${uuid}`, { responseType: 'blob' });
	const imageUrl = URL.createObjectURL(blob);

	return imageUrl;
};

export const getOnOffBoardingFile = async (uuid) => {
	const blob = await get(`${RequestsEnum.HROnOffBoardingFile}/${uuid}`, { responseType: 'blob' });
	const fileUrl = URL.createObjectURL(blob);

	return fileUrl;
};

export const getOnOffBoardingSettings = async () => get(RequestsEnum.HROnOffBoardingSettings);

export const setOnOffBoardingSettings = async (values) =>
	put(RequestsEnum.HROnOffBoardingSettings, values);

export const sendFirstDayEmployeeInvitation = async (employeeId) =>
	post(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.SendInvitation}`);

export const getEmployeeShortList = async () => get(RequestsEnum.HREmployeeSuperDashboard);

export const getEmployeeOptions = async () => get(RequestsEnum.HREmployeeShortList);

export const getEmployeeAttachmentsById = async (employeeId) =>
	get(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.Attachment}`);

export const getOwnEmployeeAttachments = async () =>
	get(`${RequestsEnum.HREmployee}${RequestsEnum.Attachment}`);

export const updateOtherEmployeeAttachments = async ({ employeeId, attachments }) =>
	patch(`${RequestsEnum.HREmployee}/${employeeId}${RequestsEnum.Attachment}`, { attachments });

export const updateOwnEmployeeAttachments = async ({ attachments }) =>
	patch(`${RequestsEnum.HREmployee}${RequestsEnum.Attachment}`, { attachments });

export const getHRVacationSettings = async () => get(RequestsEnum.HRSettingsVacation);

export const createUpdateHRVacationSettings = async (values) =>
	put(RequestsEnum.HRSettingsVacation, values);

export const deleteHRVacationSettings = async (employeeId) =>
	del(`${RequestsEnum.HRSettingsVacation}/${employeeId}`);

const employeeService = {
	getEmployeeRoles,
	getEmployeeStatuses,
	addEmployee,
	getEmployeesListSuperUser,
	getEmployeesListNonSuperUser,
	getEmployeeShortList,
	archiveEmployee,
	unArchiveEmployee,
	changeEmployeeStatus,
	getEmployeeByIdSuperUser,
	getEmployeeByIdNonSuperUser,
	sendFirstDayEmployeeInvitation,
	getEmployeeAttachmentsById,
	getOwnEmployeeAttachments,
	updateOtherEmployeeAttachments,
	updateOwnEmployeeAttachments,
	editEmployeeSuperUser,
	editEmployeeNonSuperUser,
	getOwnProfileSuperUser,
	editOwnProfileSuperUser,
	editOwnProfileNonSuperUser,
	getOnOffBoardingSettings,
	setOnOffBoardingSettings,
	getHRVacationSettings,
	createUpdateHRVacationSettings,
	deleteHRVacationSettings,
	getEmployeeOptions,
};

export default employeeService;
