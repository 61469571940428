import { COLORS } from 'theme';

export type StatisticDataType = {
	vacationsUsed: number;
	vacationsByDefault: number;
};

export enum TimeOffTypes {
	VACATION = 'VACATION',
}

interface TimeOff {
	color: string;
	emoji: string;
}

export const TimeOffs: Record<TimeOffTypes, TimeOff> = {
	[TimeOffTypes.VACATION]: {
		color: COLORS.green3,
		emoji: '🌴',
	},
};
