import { Routes, UserRolesEnum, UserTypesEnum } from 'types';

export const getLoginRoute = ({ user, isTempPassword, is2FA, jwt2fa }) => {
	let route = '';
	const isHrUser = [UserRolesEnum.HR_EMPLOYEE, UserRolesEnum.HR_MANAGER].includes(
		user?.roles[0].id,
	);

	if (is2FA) {
		route = `${Routes.TwoFA}?jwt2fa=${jwt2fa}`;
	} else if (isTempPassword) {
		route = Routes.FinishRegistration;
	} else if (user?.userType === UserTypesEnum.BACKOFFICE) {
		route = `${Routes.BackOffice}${Routes.Dashboard}`;
	} else if (isHrUser) {
		route = `${Routes.HRModule}${Routes.Dashboard}`;
	} else if (
		user?.userType === UserTypesEnum.ATS &&
		user?.roles[0].id === UserRolesEnum.JAT_FINANCE_USER
	) {
		route = `${Routes.ATS}${Routes.Credits}`;
	} else if (user?.userType === UserTypesEnum.ATS) {
		route = `${Routes.ATS}${Routes.Dashboard}`;
	}

	return route;
};
