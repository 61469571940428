import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import { ButtonTypes } from 'components/Button';
import HRRequestComponent from 'modules/HR/components/HRRequestComponent';
import { EmployeeRequestTimeOffType } from 'modules/HR/components/HRRequestComponent/HRRequestComponent.types';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import { Routes } from 'modules/HR/routes/types';

import NoRequests from '../NoRequests/NoRequests';

import { Styled } from './RequestsWidget.styled';

interface HRViewProps {
	title: string;
	emptyRequestsTitle: string;
	isEmployeeView: boolean;
	shortView?: boolean;
	employeeRequests: EmployeeRequestTimeOffType[];
	userId?: number;
	handleApproveAllRequests?: () => void;
	handleDeclineEmployeeTimeOffRequest?: (
		requestId: number,
		values: { declineComment: string },
		callback?: () => void,
	) => void;
}

const RequestsWidget: FC<HRViewProps> = ({
	title,
	emptyRequestsTitle,
	isEmployeeView,
	shortView = false,
	employeeRequests,
	userId,
	handleApproveAllRequests,
	handleDeclineEmployeeTimeOffRequest,
}) => {
	const navigate = useNavigate();
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	const handleSeeAllRequests = (id?: number) => {
		id && navigate(`${Routes.HRModule}${Routes.Employee}/${id}`);
	};

	const HeaderButton = () => {
		return isEmployeeView ? (
			<Styled.SeeMoreButton onClick={() => handleSeeAllRequests(userId)}>
				View All
			</Styled.SeeMoreButton>
		) : (
			<HRThemedButton
				type='button'
				buttonType={ButtonTypes.secondary}
				onClick={handleApproveAllRequests}
			>
				Approve all
			</HRThemedButton>
		);
	};

	return (
		<Styled.AllRequests isMobile={isMobile}>
			{employeeRequests.length === 0 ? (
				<NoRequests title={emptyRequestsTitle} />
			) : (
				<Styled.RequestsWrapper>
					<Styled.RequestsHeader>
						<Styled.RequestTitle>{title}</Styled.RequestTitle>
						{!shortView && HeaderButton()}
					</Styled.RequestsHeader>
					<Styled.RequestsList>
						{employeeRequests.map((request) => (
							<HRRequestComponent
								key={request.id}
								employeeRequest={request}
								isEmployeeView={isEmployeeView}
								isShortView={shortView}
								declineEmployeeTimeOffRequest={
									handleDeclineEmployeeTimeOffRequest
										? handleDeclineEmployeeTimeOffRequest
										: () => undefined
								}
							/>
						))}
					</Styled.RequestsList>
				</Styled.RequestsWrapper>
			)}
		</Styled.AllRequests>
	);
};

export default RequestsWidget;
