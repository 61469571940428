import React, { Key } from 'react';

import { Popconfirm, Space } from 'antd';

import { getJobCandidatesCsvBO } from 'api/endpoints/jobs';
import { ButtonTypes } from 'components/Button/Button.types';
import BoostIcon from 'components/SVG/BoostIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import { JobClassesEnum, JobsListType, PostJobTypeValuesSimpleEnum } from 'modules/Common/types';
import { COLORS } from 'theme';
import { CountriesEnumIds, DataFormatEnum, Routes } from 'types';
import { getDateFormat, downloadCsv } from 'utils/helpers';

import { Styled } from './JobsListContainer.styled';

type DataTableType = JobsListType & {
	key?: Key;
};

export const columns = (
	pageLink: string,
	handleBoostJob: (jobId: number) => void,
): ColumnsDataType => [
	{
		title: 'Client',
		dataIndex: 'clientName',
		key: 'client',
		sorter: true,
		render: (_: string, { client }: Partial<DataTableType>) => (
			// TODO Will be redirected with a separate view on the client details
			<Styled.PageLink to={`${pageLink}${Routes.Jobs}`}>{client}</Styled.PageLink>
		),
	},
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
		render: (_: string, { title, id }: Partial<DataTableType>) => (
			<Styled.PageLink to={`${pageLink}${Routes.Jobs}`} state={{ contractName: title }}>
				{title}
			</Styled.PageLink>
		),
	},
	{
		title: 'Method',
		dataIndex: 'method',
		key: 'method',
		render: (_: string, { method }: Partial<DataTableType>) => {
			return method;
		},
	},
	{
		title: 'Type',
		dataIndex: 'type',
		key: 'type',
		render: (_: string, { jobClass }: Partial<DataTableType>) => {
			const type =
				jobClass === JobClassesEnum.PaidJob
					? PostJobTypeValuesSimpleEnum.PaidJob
					: PostJobTypeValuesSimpleEnum.FreeJob;

			return <>{type}</>;
		},
	},
	{
		title: 'Questionnaires',
		dataIndex: 'questionnaires',
		key: 'questionnaires',
		align: 'center',
		render: (_: string, { questionnairesNumber }: Partial<DataTableType>) =>
			questionnairesNumber ?? 0,
	},
	{
		title: 'Interview',
		dataIndex: 'interview',
		key: 'interview',
		align: 'center',
		render: (_: string, { interviewsNumber }: Partial<DataTableType>) => interviewsNumber ?? 0,
	},
	{
		title: 'Applications',
		dataIndex: 'numberOfApplications',
		key: 'numberOfApplications',
		align: 'center',
		render: (_: string, { applicationsNumber }: Partial<DataTableType>) => (
			<>
				{applicationsNumber ? (
					<Styled.ApplicationPageLink
						to={`${pageLink}${Routes.Jobs}`}
						style={{ justifyContent: 'center' }}
					>
						<span>{applicationsNumber}</span>
					</Styled.ApplicationPageLink>
				) : (
					0
				)}
			</>
		),
	},
	{
		title: 'Date Listed',
		dataIndex: 'startDate',
		key: 'startDate',
		align: 'center',
		sorter: true,
		render: (_: string, { startDate }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(startDate, DataFormatEnum.Full) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '176px',
		render: (_: string, { id, jobClass, region }: Partial<DataTableType>) => {
			const boostButtonIsVisible =
				region === CountriesEnumIds.UK && jobClass === JobClassesEnum.FreeJob;

			return (
				<Space size='middle'>
					<Styled.ButtonLink to={`${pageLink}${Routes.EditJob}/${id}`}>
						<Styled.Button
							buttonType={ButtonTypes.default}
							icon={<EditIcon fill={COLORS.black} />}
						/>
					</Styled.ButtonLink>
					{boostButtonIsVisible && (
						<Popconfirm
							placement='bottomRight'
							title='Are you sure you would like to boost this vacancy?'
							onConfirm={() => id && handleBoostJob(id)}
							okText='Yes'
							cancelText='Cancel'
						>
							<Styled.Button
								buttonType={ButtonTypes.default}
								icon={<BoostIcon fill={COLORS.black} width='16' height='16' />}
							/>
						</Popconfirm>
					)}
					<Styled.Button
						buttonType={ButtonTypes.default}
						onClick={downloadCsv(() => getJobCandidatesCsvBO(id), `job-${id}-candidates`)}
						icon={<DownloadIcon fill={COLORS.black} width='16' height='16' />}
					>
						CSV
					</Styled.Button>
				</Space>
			);
		},
	},
];
