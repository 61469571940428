import React, { type FC } from 'react';

import { Styled } from './HRCalendarCustomDateHeader.styled';

type HRCalendarCustomDateHeaderTypeProps = {
	label: string;
};

const HRCalendarCustomDateHeader: FC<HRCalendarCustomDateHeaderTypeProps> = ({ label }) => {
	const labelData = label.split(' ');

	return (
		<Styled.DataWrap>
			<Styled.Day>{labelData[0]}</Styled.Day>
			<Styled.Date>{labelData[1]}</Styled.Date>
		</Styled.DataWrap>
	);
};

export default HRCalendarCustomDateHeader;
