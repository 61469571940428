import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface IconProps {
	width: string;
	backgroundImageUrl?: string;
	backgroundColor?: string;
}

export namespace Styled {
	export const Icon = styled.div<IconProps>`
		width: ${({ width }) => width};
		min-width: ${({ width }) => width};
		height: ${({ width }) => width};
		${({ backgroundImageUrl }) =>
		backgroundImageUrl &&
			`background: url(${backgroundImageUrl}) no-repeat; background-size: cover; background-position: center;`};
		background-color: ${({ backgroundColor }) => backgroundColor};
		border-radius: 50%;
		color: ${COLORS.white};
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: calc(${({ width }) => width} / 2.3);
	`;
}
