import { Link as LinkComponent } from 'react-router-dom';

import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
		background-color: ${COLORS.white};
		padding: 0 30px;
	`;

	export const HeaderBackground = styled.header`
		height: 240px;
		width: 100%;
		position: absolute;
		left: -50%;
		transform: translateX(50%);
		background-color: #212121;
	`;

	export const Header = styled.header`
		height: 240px;
		position: relative;
	`;

	export const HeaderTitle = styled.h1`
		color: ${COLORS.white};
		text-align: center;
		font-family: Inter;
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: -0.33px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	`;

	export const InsightsWrapper = styled.div`
		display: flex;
		justify-content: space-between;
		gap: 24px;
		width: 100%;
		position: absolute;
		bottom: 0;
		transform: translateY(50%);
	`;

	export const InsightBox = styled.div`
		padding: 8px 16px;
		border-radius: 4px;
		background: ${COLORS.white};
		box-shadow: 0px 4px 12px 0px rgba(99, 111, 122, 0.16);
		flex-grow: 1;
	`;

	export const InsightBoxNumber = styled.h4`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%;
		letter-spacing: -0.352px;
		text-transform: uppercase;
	`;

	export const InsightBoxName = styled.p`
		color: ${COLORS.darkGray2};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: -0.154px;
		text-transform: uppercase;
	`;

	export const ClientPageLink = styled(LinkComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
	`;

	export const ButtonLink = styled(LinkComponent)``;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		gap: 8px;
	`;

	export const Content = styled.div`
		margin-top: 72px;
	`;

	export const ContentBlock = styled.div`
		margin-bottom: 32px;
	`;

	export const ContentBlockHeader = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
	`;

	export const ContentBlockTitle = styled.h3`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: -0.264px;
	`;

	export const ContentBlockBody = styled.div`
		margin-top: 16px;
		margin-bottom: 16px;
	`;

	export const ContentBlockFooter = styled.div`
		display: flex;
		justify-content: center;
		position: relative;
		overflow: hidden;
		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: #e3e8ec;
			position: absolute;
			top: 50%;
			right: calc(50% + 65px);
		}

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: #e3e8ec;
			position: absolute;
			top: 50%;
			left: calc(50% + 65px);
		}
	`;
}
