import React, { type FC } from 'react';

import { ReactParent } from 'types';

import TimeOffPieChart from '../../TimeOffPieChart';

import { Styled } from './TimeOffStatisticPieChart.styled';

type TimeOffStatisticPieChartProps = ReactParent & {
	name: string;
	offsetPercentage?: number;
	color: string;
	daysUsed: number;
	totalDays: number;
	totalTimeOffDays: number;
	emoji: string;
};

const TimeOffStatisticPieChart: FC<TimeOffStatisticPieChartProps> = ({
	name,
	offsetPercentage = 0,
	color,
	daysUsed,
	totalDays,
	totalTimeOffDays,
	emoji,
}) => {
	const percentUsed = Math.round((daysUsed / (totalTimeOffDays || totalDays)) * 100);
	const statisticsPercentText = totalTimeOffDays ? `${percentUsed}%` : `${daysUsed} d`;
	const statisticPercentDaysAspectText = totalTimeOffDays
		? `${daysUsed}/${totalTimeOffDays} Days`
		: `from ${totalDays}`;

	return (
		<Styled.Root>
			<Styled.PieWrap marginRight={totalTimeOffDays ? '24px' : '16px'}>
				<TimeOffPieChart
					offsetPercentage={offsetPercentage}
					color={color}
					percentUsed={percentUsed}
					emoji={emoji}
				/>
			</Styled.PieWrap>
			<Styled.StatisticName>{name}:</Styled.StatisticName>
			<Styled.StatisticDataWrap>
				<Styled.StatisticPercent>{statisticsPercentText}</Styled.StatisticPercent>
				<Styled.StatisticPercentDaysAspect>
					{statisticPercentDaysAspectText}
				</Styled.StatisticPercentDaysAspect>
			</Styled.StatisticDataWrap>
		</Styled.Root>
	);
};

export default TimeOffStatisticPieChart;
