import React, { FC, useCallback, useState } from 'react';

import dayjs from 'dayjs';

import { ButtonTypes } from 'components/Button';
import { CalendarEmojisByTypes } from 'modules/HR/constants/HRModuleConstants.constants';
import { formatDateRange } from 'utils/helpers';

import UserIcon from '../../../Common/components/UserIcon';
import HRThemedButton from '../HRThemedButton';

import { DeclineRequestModal } from './DeclineRequestModal/DeclineRequestModal';
import { RequestColorsByTypes } from './HRRequest.constants';
import { Styled } from './HRRequestComponent.styled';
import { EmployeeRequestTimeOffType } from './HRRequestComponent.types';

type HRRequestComponentProps = {
	employeeRequest: EmployeeRequestTimeOffType;
	isEmployeeView?: boolean;
	isInBlockView?: boolean;
	isShortView?: boolean;
	declineEmployeeTimeOffRequest: (
		requestId: number,
		values: { declineComment: string },
		callback?: () => void,
	) => void;
	approveEmployeeTimeOffRRequest?: (requestId: number) => void;
};

export const HRRequestComponent: FC<HRRequestComponentProps> = ({
	employeeRequest,
	isEmployeeView = false,
	isInBlockView = false,
	isShortView = false,
	declineEmployeeTimeOffRequest,
	approveEmployeeTimeOffRRequest,
}) => {
	const [requestId, setRequestId] = useState<number | null>(null);
	const dateFormat = 'MMM DD, YYYY';
	const requestDates = formatDateRange(employeeRequest.startDate, employeeRequest.endDate);

	const handleApproveRequest = () => {
		// approveEmployeeTimeOffRRequest(employeeRequest.id);
	};

	const handleDeclineRequest = useCallback((id: number) => {
		setRequestId(id);
	}, []);

	const handleCloseDeclineModal = useCallback(() => {
		setRequestId(null);
	}, []);

	const employeeFullName = `${employeeRequest.employeeFirstName} ${employeeRequest.employeeLastName}`;
	let requestStatus = 'Requested';
	if (employeeRequest.approved) requestStatus = 'Approved';
	if (employeeRequest.declined) requestStatus = 'Declined';

	return (
		<Styled.Root isInBlockView={isInBlockView}>
			{!isEmployeeView && !isInBlockView && (
				<Styled.Header>
					<Styled.EmployeeData>
						<UserIcon
							imageId={employeeRequest.employeePhoto}
							backgroundColor='grey'
							fullName={`${employeeFullName}`}
						/>
						<Styled.EmployeeName>{`${employeeFullName}`}</Styled.EmployeeName>
					</Styled.EmployeeData>
					<Styled.RequestDate>
						{dayjs(employeeRequest.created).format(dateFormat)}
					</Styled.RequestDate>
				</Styled.Header>
			)}
			<Styled.RequestMainContent isEmployeeView={isEmployeeView} isInBlockView={isInBlockView}>
				<Styled.RequestInfoTopRow>
					<Styled.RequestInfoTypeDatesWrap>
						<Styled.RequestType>{`${
							CalendarEmojisByTypes[employeeRequest.type]
						} ${employeeRequest.type.toLocaleLowerCase()}`}</Styled.RequestType>
						<Styled.RequestDates isShortView={isShortView}>{requestDates}</Styled.RequestDates>
					</Styled.RequestInfoTypeDatesWrap>
					{requestStatus && (
						<Styled.RequestApproveStatusBadge
							color={RequestColorsByTypes[requestStatus].color}
							bgColor={RequestColorsByTypes[requestStatus].bgColor}
							borderColor={RequestColorsByTypes[requestStatus].borderColor}
						>
							{requestStatus}
						</Styled.RequestApproveStatusBadge>
					)}
				</Styled.RequestInfoTopRow>
				{!isShortView && (
					<Styled.RequestComment>
						{!isInBlockView && <span>Comments</span>}
						{employeeRequest.description}
					</Styled.RequestComment>
				)}
			</Styled.RequestMainContent>
			{!isEmployeeView && !employeeRequest.approved && !employeeRequest.declined && (
				<Styled.ButtonBox>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.secondary}
						onClick={() => handleDeclineRequest(employeeRequest.id)}
					>
						Decline
					</HRThemedButton>
					<HRThemedButton
						type='submit'
						buttonType={ButtonTypes.primary}
						onClick={handleApproveRequest}
					>
						Approve
					</HRThemedButton>
				</Styled.ButtonBox>
			)}
			<DeclineRequestModal
				requestId={requestId}
				onCancel={handleCloseDeclineModal}
				declineTimeOffRequestRequested={declineEmployeeTimeOffRequest}
			/>
		</Styled.Root>
	);
};
