import { del, get, put, post, patch } from 'api/axiosInstance';
import { RequestsEnum } from 'types';
import { getTableParams } from 'utils/helpers';

export const createAtsUser = (data) => post(RequestsEnum.ATSUser, data);

export const deleteAtsUser = (id) => del(`${RequestsEnum.ATSUser}/${id}`);

export const getAtsUsers = ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.ATSUser}${queryParams}`);
};

export const getUserById = (id) => get(`${RequestsEnum.User}/${id}`);

export const getBOUserById = (id) => get(`${RequestsEnum.BOUser}/dto/${id}`);

export const getAtsUserRole = () => get(RequestsEnum.ATSUserRole);

export const getAtsUserById = (id) => get(`${RequestsEnum.UserDTO}/${id}`);

export const getClientContextUserById = (id) => get(`${RequestsEnum.ClientContextUser}/${id}`);

export const updateAtsUser = (data) => put(RequestsEnum.ATSUser, data);

export const getBOUsers = ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BOUser}${queryParams}`);
};

export const getClientContextUsers = ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.ClientContextUser}${queryParams}`);
};

export const createUser = (data) => post(RequestsEnum.BOUser, data);

export const createClientContextUser = (data) => post(RequestsEnum.ClientContextUser, data);

export const updateBOUser = (data) => put(RequestsEnum.BOUser, data);

export const updateClientContextUser = (data) => put(RequestsEnum.ClientContextUser, data);

export const getBOUserRoles = () => get(RequestsEnum.BOUserRole);

export const getBOUserManagers = () => get(RequestsEnum.BOUserManager);

export const activationBOUserById = (id, data) =>
	patch(`${RequestsEnum.BOUser}/${id}/active`, data);

export const activationAtsUserById = (id, data) =>
	patch(`${RequestsEnum.ATSUser}/${id}/active`, data);

export const updateAtsUserPassword = (data) => patch(RequestsEnum.ATSUserSetPassword, data);

export const updateATSUserPassword = (data) => patch(RequestsEnum.ATSUserSetPasswordNew, data);

export const getEmailPlaceholder = () => get(RequestsEnum.BOEmailPlaceholder);

export const getEmailDefaultTemplates = () => get(RequestsEnum.BOEmailDefaultTemplate);

export const getAtsEmailDefaultTemplates = () => get(RequestsEnum.ATSEmailDefaultTemplate);

export const updateEmailDefaultTemplates = (data) => put(RequestsEnum.BOEmailDefaultTemplate, data);

export const updateAtsEmailDefaultTemplates = (data) =>
	put(RequestsEnum.ATSEmailDefaultTemplate, data);

const usersService = {
	createAtsUser,
	deleteAtsUser,
	getAtsUsers,
	getUserById,
	getClientContextUserById,
	createUser,
	createClientContextUser,
	updateBOUser,
	updateClientContextUser,
	getBOUserById,
	getBOUserRoles,
	getBOUserManagers,
	getBOUsers,
	getClientContextUsers,
	activationBOUserById,
	activationAtsUserById,
	getAtsUserRole,
	getAtsUserById,
	updateAtsUser,
	updateAtsUserPassword,
	updateATSUserPassword,
	getEmailPlaceholder,
	getEmailDefaultTemplates,
	getAtsEmailDefaultTemplates,
	updateEmailDefaultTemplates,
	updateAtsEmailDefaultTemplates,
};

export default usersService;
