import { COLORS } from 'theme';

export const RequestStatus = {
	approved: 'Approved',
	rejected: 'Rejected',
	requested: 'Requested',
};

export const RequestColorsByTypes = {
	[RequestStatus.approved]: { color: COLORS.green, bgColor: '#f6ffed', borderColor: '#b7eb8f' },
	[RequestStatus.rejected]: { color: COLORS.red, bgColor: '#fff2e8', borderColor: '#ffbb96' },
	[RequestStatus.requested]: { color: COLORS.blue6, bgColor: '#e6f7ff', borderColor: '#91d5ff' },
};
