import React, { Key } from 'react';

import { Popconfirm, Space, Tooltip } from 'antd';

import { ButtonLink } from 'components/Button/Button.styled';
import { ButtonTypes } from 'components/Button/Button.types';
import DeleteIcon from 'components/SVG/DeleteIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import Tag from 'modules/Common/components/Tag';
import {
	TicketsStatusValuesEnum,
	TicketsStatusColors,
	ITicketsTableValues,
	TicketsStatusEnum,
} from 'modules/Common/types';
import { COLORS } from 'theme';
import { Routes, DataFormatEnum } from 'types';
import { getDateFormat, removeSpacesUppercaseFirstLetter, truncateString } from 'utils/helpers';

import { Styled } from './SupportTickets.styled';

type DataTableType = ITicketsTableValues & {
	key?: Key;
};

export const columns = (
	isActiveTab: boolean,
	isSuperUser: boolean,
	handleDeleteItem: (ticketId: number) => void,
	clientContextId?: string,
): ColumnsDataType => [
	{
		title: 'Client',
		dataIndex: 'clientName',
		key: 'client',
		sorter: true,
		render: (_: string, { client }: Partial<DataTableType>) => {
			return <span>{client}</span>;
		},
	},
	{
		title: 'Ticket type',
		dataIndex: 'ticketType',
		key: 'ticketType',
		sorter: true,
		render: (_: string, { ticketType }: Partial<DataTableType>) => <span>{ticketType}</span>,
	},
	{
		title: 'Subject',
		dataIndex: 'subject',
		key: 'subject',
		render: (_: string, { subject }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(subject, 60);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '400px' }}>
					<span>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Status',
		dataIndex: 'ticketStatus',
		key: 'ticketStatus',
		align: 'center',
		sorter: isActiveTab,
		render: (_, { ticketStatus }: Partial<DataTableType>) => {
			const transformedTicketStatus = removeSpacesUppercaseFirstLetter(
				ticketStatus,
			) as TicketsStatusEnum;

			return (
				ticketStatus && (
					<Tag color={TicketsStatusColors[transformedTicketStatus]}>
						{TicketsStatusValuesEnum[transformedTicketStatus]}
					</Tag>
				)
			);
		},
	},
	{
		title: isActiveTab ? 'Creation Date' : 'Resolved Date',
		dataIndex: isActiveTab ? 'creationDate' : 'resolutionDate',
		key: isActiveTab ? 'creationDate' : 'resolutionDate',
		align: 'center',
		sorter: isActiveTab,
		render: (_: string, { creationDate, resolutionDate }: Partial<DataTableType>) => {
			const date = isActiveTab ? creationDate : resolutionDate;
			const formattedData = getDateFormat(date, DataFormatEnum.FullHoursMinutes) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '54px',
		render: (_, { id }: Partial<DataTableType>) => {
			const route = clientContextId
				? `${Routes.BOClientContext}/${clientContextId}${Routes.SupportTickets}/${id}`
				: `${Routes.BackOffice}${Routes.SupportTickets}/${id}`;

			return (
				<Space size='middle'>
					<Styled.ButtonWrap>
						<ButtonLink to={route}>View</ButtonLink>
					</Styled.ButtonWrap>
					{isSuperUser && (
						<Popconfirm
							placement='bottomRight'
							title='Are you sure?'
							onConfirm={() => id && handleDeleteItem(id)}
							okText='Yes'
							cancelText='Cancel'
						>
							<Styled.Button
								buttonType={ButtonTypes.default}
								icon={<DeleteIcon fill={COLORS.black} width='14' height='14' />}
							/>
						</Popconfirm>
					)}
				</Space>
			);
		},
	},
];
