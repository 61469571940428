import { Modal as ModalAntd } from 'antd';
import styled from 'styled-components/macro';

import { device } from 'theme';

export namespace Styled {
	export const Modal = styled(ModalAntd)`
		.ant-modal-content {
			padding: 32px 40px;
			@media ${device.tablet} {
				padding: 16px 24px;
			}
		}
	`;
	export const ModalHead = styled.div`
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
		font-size: 16px;
		text-align: center;
		h3 {
			@media ${device.tablet} {
				font-size: 19px;
			}
		}
	`;

	export const ModalContent = styled.div`
		max-width: 100%;
	`;

	export const FieldsBlock = styled.div`
		margin-bottom: 25px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-top: 24px;
		gap: 16px;
	`;
}
