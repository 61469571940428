import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface INavigationProps {
	isImpersonating?: boolean;
	isMobile?: boolean;
}

export namespace S {
	export const Root = styled.div<INavigationProps>`
		display: flex;
		flex-direction: column;
		height: ${({ isImpersonating }) => (isImpersonating ? 'calc(100% - 72px)' : '100%')};
		min-height: ${({ isImpersonating }) => (isImpersonating ? 'calc(100% - 72px)' : '100%')};
		overflow-y: auto;
		background-color: ${({ isMobile }) => (isMobile ? COLORS.white : 'transparent')};
	`;

	export const Main = styled.main<{ isMobile: boolean }>`
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		overflow-y: ${({ isMobile }) => (isMobile ? 'initial' : 'auto')};
		padding: ${({ isMobile }) => (isMobile ? '0' : '0 16px')};
	`;
}
