import React, { type FC } from 'react';
import { useMedia } from 'react-use';

import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';

import TimeOffStatisticLine from './TimeOffStatisticLine';
import TimeOffStatisticPieChart from './TimeOffStatisticPieChart';
import { Styled } from './TimeOffUsedStatistics.styled';
import { StatisticDataType, TimeOffs } from './TimeOffUsedStatistics.types';

type TimeOffUsedStatisticsProps = {
	statisticData: StatisticDataType;
	dashboardChart: boolean;
};

const calculateOffsetPercentage = (
	usedDays: number,
	totalDays: number,
	previousOffset = 0,
): number => previousOffset + Math.round((usedDays / totalDays) * 100);

const TimeOffUsedStatistics: FC<TimeOffUsedStatisticsProps> = ({
	statisticData,
	dashboardChart,
}) => {
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);
	const {
		vacationsUsed = 0,
		vacationsByDefault = 0,
		sickLeaveUsed = 0,
		sickLeaveByDefault = 0,
		parentalUsed = 0,
		parentalByDefault = 0,
		otherUsed = 0,
		otherByDefault = 0,
	} = statisticData;
	const totalTimeOffDays = dashboardChart
		? vacationsByDefault + sickLeaveByDefault + parentalByDefault + otherByDefault
		: 0;
	const title = dashboardChart ? 'Time Offs Used this year' : 'Time Off';
	const secondaryTitle = dashboardChart ? '% from total Time offs' : 'Taken days';

	const sickLeaveOffsetPercentage = dashboardChart
		? calculateOffsetPercentage(vacationsUsed, totalTimeOffDays)
		: 0;
	const parentalOffsetPercentage = dashboardChart
		? calculateOffsetPercentage(sickLeaveUsed, totalTimeOffDays, sickLeaveOffsetPercentage)
		: 0;
	const otherOffsetPercentage = dashboardChart
		? calculateOffsetPercentage(parentalUsed, totalTimeOffDays, parentalOffsetPercentage)
		: 0;

	return (
		<Styled.Root isDashboardChart={dashboardChart} isMobile={isMobile}>
			<Styled.Title>{title}</Styled.Title>
			{dashboardChart && (
				<TimeOffStatisticLine
					name={'Vacation'}
					color={TimeOffs.VACATION.color}
					daysUsed={vacationsUsed}
					totalDays={totalTimeOffDays}
					emoji={TimeOffs.VACATION.emoji}
				/>
			)}
			<Styled.SecondaryTitle>{secondaryTitle}</Styled.SecondaryTitle>
			<Styled.StatisticsContainer direction={dashboardChart ? 'column' : 'row'}>
				{vacationsByDefault > 0 && (
					<TimeOffStatisticPieChart
						name={'Vacation'}
						color={TimeOffs.VACATION.color}
						daysUsed={vacationsUsed}
						totalDays={vacationsByDefault}
						totalTimeOffDays={totalTimeOffDays}
						emoji={TimeOffs.VACATION.emoji}
					/>
				)}
				{sickLeaveByDefault > 0 && (
					<TimeOffStatisticPieChart
						name={'Sick Day'}
						offsetPercentage={sickLeaveOffsetPercentage}
						color={TimeOffs.SICK_DAY.color}
						daysUsed={sickLeaveUsed}
						totalDays={sickLeaveByDefault}
						totalTimeOffDays={totalTimeOffDays}
						emoji={TimeOffs.SICK_DAY.emoji}
					/>
				)}
				{parentalByDefault > 0 && (
					<TimeOffStatisticPieChart
						name={'Parental leave'}
						offsetPercentage={parentalOffsetPercentage}
						color={TimeOffs.MATERNITY_PATERNITY.color}
						daysUsed={parentalUsed}
						totalDays={parentalByDefault}
						totalTimeOffDays={totalTimeOffDays}
						emoji={TimeOffs.MATERNITY_PATERNITY.emoji}
					/>
				)}
				{otherByDefault > 0 && (
					<TimeOffStatisticPieChart
						name={'Other'}
						offsetPercentage={otherOffsetPercentage}
						color={TimeOffs.OTHER.color}
						daysUsed={otherUsed}
						totalDays={otherByDefault}
						totalTimeOffDays={totalTimeOffDays}
						emoji={TimeOffs.OTHER.emoji}
					/>
				)}
			</Styled.StatisticsContainer>
		</Styled.Root>
	);
};

export default TimeOffUsedStatistics;
