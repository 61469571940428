import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const UploadContainer = styled.div<{ isDragging: boolean }>`
		border-radius: 2px;
		border: 1px dashed ${COLORS.lightBlue1};
		background-color: ${({ isDragging }) =>
		isDragging ? 'rgba(49, 78, 255, 0.20)' : 'rgba(49, 78, 255, 0.10)'};
		padding: 20px 12px;
		text-align: center;
		width: 100%;
		height: 240px;
		width: 240px;
		margin: 0 auto 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		cursor: pointer;

		&:hover {
			background-color: #eef1ff;
		}
	`;

	export const UploadIcon = styled.div`
		width: 78px;
		height: 78px;
		margin: 0 auto 20px;
		background-color: ${COLORS.white};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: ${COLORS.darkGray1};
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
	`;

	export const UploadText = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		margin-bottom: 6px;
	`;

	export const UploadHint = styled.div`
		color: ${COLORS.darkGray2};
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
	`;

	export const HiddenFileInput = styled.input`
		display: none;
	`;

	export const Button = styled.button``;
}
