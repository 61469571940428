import { Key } from 'react';

import { ColumnsType } from 'antd/es/table';

import { GenericType } from 'types';

export type CandidatesDataType = {
	candidateApplicationId: number;
	candidateEmail: string;
	jobTitle: string;
	candidate: string;
	client: string;
	hidden: boolean;
};

export type DataTableType = CandidatesDataType & {
	key?: Key;
};

export type TableColumnsType = ColumnsType<DataTableType>;

export type CandidateApplicationContainerType = {
	candidatesData: {
		data: CandidatesDataType[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	getBackOfficeCandidates: (params: { page: number; size: number; client?: number }) => void;
	deleteBackofficeCandidate: (
		id: number,
		deleteFromDatabase: boolean,
		callback: () => void,
	) => void;
	loading: GenericType;
};

export type CandidateApplicationContainerProps = CandidateApplicationContainerType & {
	isClientContext?: boolean;
	clientId?: string;
	pageLink: string;
};

export enum DeleteCandidateEnum {
	DATABASE = 'database',
	CLIENT = 'client',
}

export type DeleteCandidateModalType = {
	candidateApplicationId: number;
	deleteType: DeleteCandidateEnum;
};
