import React, { ChangeEvent, FC, useCallback, useRef } from 'react';

import { Select, DatePicker } from 'antd';
import { debounce } from 'lodash';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SearchField from 'modules/BackOffice/components/SearchField';
import { OptionsType } from 'types';
import { filterSelectOptions } from 'utils/helpers';

import { Styled } from './SearchFilterSortWrapper.styled';

const { RangePicker } = DatePicker;

type FilterProps = {
	mode: 'multiple' | 'single';
	placeholder: string;
	options: OptionsType[];
	onFilter: (id: number) => void;
	label: string;
	width?: string;
	showSearch?: boolean;
};

type SearchFilterSortWrapperProps = {
	search?: {
		onSearch: (value: string) => void;
		defaultValue?: string;
		isDebounce?: boolean;
		debounceTime?: number;
		placeholder?: string;
		loading?: boolean;
		width?: string;
		styles?: React.CSSProperties;
	};
	filter?: FilterProps;
	secondFilter?: FilterProps;
	thirdFilter?: FilterProps;
	dateFilter?: {
		onDateFilter: () => void;
		placeholder: string;
		label: string;
	};
};

const SearchFilterSortWrapper: FC<SearchFilterSortWrapperProps> = ({
	search,
	filter,
	secondFilter,
	thirdFilter,
	dateFilter,
}) => {
	const searchValueRef = useRef<string | null>(null);

	const debouncedSearch = useCallback(
		debounce((value: string) => {
			search?.onSearch(value);
		}, search?.debounceTime || 500),
		[search],
	);

	const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
		event?.preventDefault();

		const { value } = event?.target || {};

		search?.isDebounce ? debouncedSearch(value) : search?.onSearch(value);
	};

	return (
		<Styled.Root>
			{search && (
				<Styled.Search width={search.width} style={search.styles}>
					<SearchField
						ref={searchValueRef}
						onSearch={handleSearch}
						placeholder={search?.placeholder}
						defaulValue={search?.defaultValue}
						isLoading={search?.loading}
					/>
				</Styled.Search>
			)}
			{filter && (
				<Styled.Filter>
					<Styled.FilterLabel>{filter?.label} :</Styled.FilterLabel>
					<Select
						allowClear
						placeholder='Filter'
						style={{ width: filter?.width ?? '300px' }}
						mode={filter?.mode}
						options={filter?.options}
						onChange={filter?.onFilter}
						showSearch={filter?.showSearch}
						{...(filter?.showSearch && {
							filterOption: (inputValue, option) => filterSelectOptions(inputValue, option),
						})}
					/>
				</Styled.Filter>
			)}
			{secondFilter && (
				<Styled.Filter>
					<Styled.FilterLabel>{secondFilter?.label} :</Styled.FilterLabel>
					<Select
						allowClear
						placeholder='Filter'
						style={{ width: secondFilter?.width ?? '300px' }}
						mode={secondFilter?.mode}
						options={secondFilter?.options}
						onChange={secondFilter?.onFilter}
						showSearch={secondFilter?.showSearch}
						{...(secondFilter?.showSearch && {
							filterOption: (inputValue, option) => filterSelectOptions(inputValue, option),
						})}
					/>
				</Styled.Filter>
			)}
			{thirdFilter && (
				<Styled.Filter>
					<Styled.FilterLabel>{thirdFilter?.label} :</Styled.FilterLabel>
					<Select
						allowClear
						placeholder='Filter'
						style={{ width: thirdFilter?.width ?? '300px' }}
						mode={thirdFilter?.mode}
						options={thirdFilter?.options}
						onChange={thirdFilter?.onFilter}
						showSearch={thirdFilter?.showSearch}
						{...(thirdFilter?.showSearch && {
							filterOption: (inputValue, option) => filterSelectOptions(inputValue, option),
						})}
					/>
				</Styled.Filter>
			)}
			{dateFilter && (
				<Styled.Filter>
					<Styled.FilterLabel>{dateFilter?.label} :</Styled.FilterLabel>
					<RangePicker allowClear onChange={dateFilter?.onDateFilter} />
				</Styled.Filter>
			)}
		</Styled.Root>
	);
};

export default SearchFilterSortWrapper;
