import styled from 'styled-components/macro';

import { FormBlock, FormBlockProps } from 'modules/Common/components/FormBlock/FormBlock';
import { COLORS } from 'theme';

interface PersonProfilePhotoProps {
	imageUrl: string;
}

export namespace Styled {
	export const Wrap = styled.div`
		display: flex;
		gap: 32px;
		flex-wrap: wrap;
		justify-content: center;
	`;

	export const PersonProfilePhoto = styled.div<PersonProfilePhotoProps>`
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 240px;
		width: 240px;
		border-radius: 50%;
		${({ imageUrl }) =>
		imageUrl &&
			`background: url(${imageUrl}) no-repeat; background-size: cover; background-position: center;`};
	`;

	export const PersonProfileCropper = styled.div`
		max-width: 240px;
		z-index: 100;
		position: relative;
	`;

	export const FormBlockWrap = styled(FormBlock).attrs<FormBlockProps>(() => ({
		style: {
			border: 'none',
			boxShadow: 'none',
			padding: '0',
			flex: '1 1 300px',
		},
	}))<FormBlockProps>``;

	export const FormBlockLine = styled.div`
		display: flex;
		gap: 32px;
		flex-wrap: wrap;
		margin-bottom: 32px;

		& > div {
			display: flex;
			flex-direction: column;
			min-width: 220px;
			max-width: 306px;
			flex: 1 1 calc(33.333% - 32px);
			@media screen and (max-width: 550px) {
				max-width: 100%;
			}
		}
		&:last-of-type {
			margin-bottom: 16px;
		}
	`;

	export const PhoneFieldWrapper = styled.div`
		width: 100%;

		input {
			color: rgba(0, 0, 0, 0.88);
			border: 1px solid ${COLORS.neutral5};
			border-radius: 6px;
			transition: all 0.2s;
			font-size: 14px;
			padding: 4px 11px;
			line-height: 1.57;

			&:hover {
				border-color: ${COLORS.inputColor};
			}

			&:focus {
				border-color: ${COLORS.inputColor};
				box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
				border-inline-end-width: 1px;
				outline: 0;
			}
		}
	`;
}
