import styled, { css, keyframes } from 'styled-components/macro';

import { COLORS } from 'theme';

type ProgressBarProps = {
	color: string;
	percentage: number;
};

type PercentTextProps = {
	percentage: number;
};

export namespace Styled {
	export const Root = styled.div`
		width: 100%;
		border-radius: 10px;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		display: flex;
		gap: 8px;
		padding: 30px 12px;
		align-items: center;
	`;

	export const Icon = styled.div`
		margin-right: 12px;
	`;

	export const StatisticName = styled.div`
		color: ${COLORS.darkGray3};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
	`;

	export const StatisticLine = styled.div`
		display: block;
		border-radius: 4px;
		height: 8px;
		width: 100%;
		background-color: ${COLORS.lightGray2};
	`;

	export const ProgressBarContainer = styled.div`
		width: 100%;
		background-color: ${COLORS.lightGray2};
		border-radius: 8px;
		position: relative;
	`;

	const progressAnimation = (percentage: number) => keyframes`
		from {
		width: 0;
		}
		to {
		width: ${percentage}%;
		}
		`;

	export const ProgressBar = styled.div<ProgressBarProps>`
		height: 8px;
		background-color: ${({ color }) => color};
		width: ${({ percentage }) => percentage}%;
		border-radius: 4px;
		animation: ${({ percentage }) =>
		css`
				${progressAnimation(percentage)} 0.3s ease-out
			`};
	`;

	export const PercentText = styled.div<PercentTextProps>`
		position: absolute;
		top: -30px;
		left: ${({ percentage }) => `calc(${percentage}% - 16px)`};
		background-color: #fff;
		padding: 4px 6px;
		border-radius: 4px;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 16px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
		color: ${({ color }) => color};
		${({ percentage }) =>
		percentage > 90 &&
			`
				left: auto;
				right: 0;
			`}
		&::before {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			border-width: 6px;
			border-image: initial;
			transform: translateX(-50%);
			border-right: 1px solid #e7e7e7;
			border-bottom: 1px solid #e7e7e7;
			width: 7px;
			height: 7px;
			transform: rotate(45deg) translate(-4px);
			background: white;
		}
	`;

	export const ProgressDaysUsedText = styled.div<PercentTextProps>`
		position: absolute;
		top: 14px;
		width: max-content;
		left: ${({ percentage }) => `calc(${percentage}% - 15px)`};
		right: 0;
		color: ${COLORS.darkGray3};
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
	`;

	export const ProgressDaysTotalText = styled.div`
		position: absolute;
		top: 14px;
		right: 0;
		color: ${COLORS.darkGray3};
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
	`;

	export const DaysText = styled.div`
		margin-top: 8px;
		font-size: 14px;
		font-weight: bold;
		text-align: center;
	`;
}
