import { Button } from 'antd';
import { darken } from 'polished';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const PopoverContent = styled.div`
		min-width: 302px;
		padding: 0 4px 4px 12px;
		font-family: 'Inter';
	`;

	export const PopoverEventType = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		margin-bottom: 5px;
	`;

	export const PopoverEventDate = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	`;

	export const PopoverCommentsSection = styled.div`
		margin-top: 16px;
		h6 {
			color: ${COLORS.darkGray1};
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			margin-bottom: 5px;
		}
		p {
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			letter-spacing: -0.154px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		gap: 8px;
		font-family: 'Inter';

		a {
			display: block;
		}

		button {
			width: 100%;
			height: 40px;
			font-size: 16px;
			font-weight: 500;
		}
	`;

	export const ButtonApprove = styled(Button)`
		background-color: ${COLORS.green};
		&:hover {
			background-color: ${darken(0.1, COLORS.green)}!important;
		}
	`;

	export const ButtonIcon = styled(Button)`
		line-height: 40px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		font-size: 14px;
		gap: 8px;
		min-width: 40px;
	`;
}
