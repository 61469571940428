import React, { useCallback, useEffect, type FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { pick } from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import CalendarEmpty from 'components/SVG/CalendarEmpty';
import { useMount, useUnmount } from 'hooks';
import ViewProfileSidebar from 'modules/Common/components/ViewProfileSidebar';
import { IUser } from 'modules/Common/types';
import { IEmployeeProfileBase, IEmployeeProfileSuperUser } from 'modules/Common/types/employee';
import HRRequestComponent from 'modules/HR/components/HRRequestComponent';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import TimeOffUsedStatistics from 'modules/HR/components/TimeOffUsedStatistics';
import { hrDucks } from 'modules/HR/ducks';
import { Routes } from 'modules/HR/routes/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType } from 'types';

import HRAttachmentListForm from '../HRAttachmentListForm';

import { employeeAllowedFields } from './HREmployeeViewProfile.constants';
import { Styled } from './HREmployeeViewProfile.styled';
import { employeeRequestOffDummyData, statisticDummyData } from './HREmployeeViewProfileDummyData';

type HREmployeeViewProfileProps = {
	isSuperUser: boolean;
	employeeDetails: null | IEmployeeProfileBase | IEmployeeProfileSuperUser;
	user: IUser;
	loading: GenericType;
	getEmployeeByIdRequested: (employeeId: string, isSuperUser: boolean) => void;
	getEmployeeAttachmentsRequested: (employeeId: string, isSuperUser: boolean) => void;
	sendFirstDayInvitationRequested: (employeeId: string) => void;
	resetEmployeeDetails: () => void;
	handleUpdatePageTitle: (title: string) => void;
};

const HREmployeeViewProfile: FC<HREmployeeViewProfileProps> = ({
	isSuperUser,
	employeeDetails,
	user,
	loading,
	getEmployeeByIdRequested,
	getEmployeeAttachmentsRequested,
	sendFirstDayInvitationRequested,
	resetEmployeeDetails,
	handleUpdatePageTitle,
}) => {
	const { employeeId } = useParams();
	const statisticData = statisticDummyData;
	const employeeRequests = employeeRequestOffDummyData;
	const navigate = useNavigate();
	const showAttachments = isSuperUser || user?.id === employeeDetails?.userId;

	const currentDate = dayjs();
	const upcomingRequests = employeeRequests.filter((request) =>
		dayjs(request.endDate).isAfter(currentDate),
	);
	const historicalRequests = employeeRequests.filter((request) =>
		dayjs(request.endDate).isBefore(currentDate),
	);

	const handleFirstDayInvitation = useCallback(() => {
		employeeId && sendFirstDayInvitationRequested(employeeId);
	}, [employeeId]);

	const handleCheckCalendar = useCallback(() => {
		navigate(`${Routes.HRModule}${Routes.Calendar}`);
	}, []);

	const updateAttachmentsCallback = useCallback(() => {
		getEmployeeAttachmentsRequested(employeeId || '', isSuperUser);
	}, [showAttachments]);

	useEffect(() => {
		employeeDetails &&
			handleUpdatePageTitle(`${employeeDetails.firstName} ${employeeDetails.lastName}`);
	}, [employeeDetails]);

	useEffect(() => {
		showAttachments && getEmployeeAttachmentsRequested(employeeId || '', isSuperUser);
	}, [showAttachments]);

	useMount(() => {
		employeeId && getEmployeeByIdRequested(employeeId, isSuperUser);
	});

	useUnmount(() => {
		resetEmployeeDetails();
	});

	const employeeDetailsSanitized = isSuperUser
		? employeeDetails
		: pick(employeeDetails, employeeAllowedFields);

	return (
		<Styled.Root>
			<Styled.LeftColumn>
				<ViewProfileSidebar
					{...employeeDetailsSanitized}
					isSuperUser={isSuperUser}
					loading={!!loading.getEmployeeByIdLoad}
				/>
			</Styled.LeftColumn>
			<Styled.RightColumn>
				{employeeDetails?.showFirstDayInvitation && (
					<Styled.FirstDayInvitation>
						<Styled.FirstDayInvitationText>
							<Styled.FirstDayInvitationHeader>
								First Day Invitation
							</Styled.FirstDayInvitationHeader>
							<Styled.FirstDayInvitationDescription>
								Please use this option to re-send the first day&apos;s invitation. The date of hire
								is{' '}
								{employeeDetails?.startDate
									? dayjs(employeeDetails?.startDate).format('MMMM DD')
									: 'N/A'}
							</Styled.FirstDayInvitationDescription>
						</Styled.FirstDayInvitationText>
						<HRThemedButton
							type='submit'
							buttonType={ButtonTypes.primary}
							loading={false}
							onClick={handleFirstDayInvitation}
							disabled={!!loading?.sendFirstDayInvitationLoad}
							style={{ minWidth: '120px' }}
						>
							Re-Send
						</HRThemedButton>
					</Styled.FirstDayInvitation>
				)}
				{isSuperUser && (
					<Styled.NewTimeOffRequestWrap>
						<Styled.NewTimeOffRequestTopLine>
							<Styled.NewTimeOffRequestTitle>New Time Off Request</Styled.NewTimeOffRequestTitle>
							<HRThemedButton
								type='submit'
								buttonType={ButtonTypes.secondary}
								loading={false}
								onClick={handleCheckCalendar}
							>
								{'Check Calendar'}
							</HRThemedButton>
						</Styled.NewTimeOffRequestTopLine>
						<Styled.NewTimeOffRequestList>
							{employeeRequests.map((request) => (
								<HRRequestComponent
									key={request.id}
									employeeRequest={request}
									isEmployeeView={!isSuperUser}
									isInBlockView={true}
								/>
							))}
						</Styled.NewTimeOffRequestList>
					</Styled.NewTimeOffRequestWrap>
				)}
				<Styled.TimeOffBlock>
					{isSuperUser && (
						<Styled.StatisticWrap>
							<TimeOffUsedStatistics statisticData={statisticData} dashboardChart={false} />
						</Styled.StatisticWrap>
					)}
					<Styled.AllRequests>
						{employeeRequests.length === 0 && (
							<Styled.NoRequests>
								<CalendarEmpty width='120px' />
								<p>There are no time-off requested.</p>
							</Styled.NoRequests>
						)}
						{upcomingRequests.length && (
							<Styled.RequestsWrapper>
								<Styled.RequestTitle>Upcoming vacations</Styled.RequestTitle>
								<Styled.RequestsList>
									{employeeRequests.map((request) => (
										<HRRequestComponent
											key={request.id}
											employeeRequest={request}
											isEmployeeView={!isSuperUser}
										/>
									))}
								</Styled.RequestsList>
							</Styled.RequestsWrapper>
						)}
						{historicalRequests.length && (
							<Styled.RequestsWrapper>
								<Styled.RequestTitle>History</Styled.RequestTitle>
								<Styled.RequestsList>
									{historicalRequests.map((request) => (
										<HRRequestComponent
											key={request.id}
											employeeRequest={request}
											isEmployeeView={true}
										/>
									))}
								</Styled.RequestsList>
							</Styled.RequestsWrapper>
						)}
					</Styled.AllRequests>
				</Styled.TimeOffBlock>
				{showAttachments && (
					<Styled.AttachmentsBlock>
						<HRAttachmentListForm
							employeeId={employeeId || ''}
							isSuperUser={isSuperUser}
							attachmentsList={employeeDetails?.attachments || []}
							updateAttachmentsCallback={updateAttachmentsCallback}
						/>
					</Styled.AttachmentsBlock>
				)}
			</Styled.RightColumn>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		employeeDetails: hrDucks.hrSelectors.getEmployeeDetails(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
	}),
	{
		getEmployeeByIdRequested: hrDucks.hrActions.getEmployeeByIdRequested,
		getEmployeeAttachmentsRequested: hrDucks.hrActions.getEmployeeAttachmentsRequested,
		sendFirstDayInvitationRequested: hrDucks.hrActions.sendFirstDayInvitationRequested,
		resetEmployeeDetails: hrDucks.hrActions.resetEmployeeDetails,
	},
)(HREmployeeViewProfile);
