import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

export namespace Styled {
	export const Root = styled.nav`
		display: flex;
		align-items: start;
		justify-content: space-between;
		flex-direction: column;
		max-width: 246px;
		width: 100%;
		margin-right: 24px;
		padding: 32px 0;
		background-color: ${COLORS.white};
		box-shadow: ${COLORS.elevationShadow1};
		border-radius: 10px;
	`;

	export const PanelMenu = styled.ul`
		display: flex;
		align-items: start;
		justify-content: space-between;
		flex-direction: column;
		width: 100%;
		gap: 16px;

		@media ${device.mobile} {
			gap: 10px;
		}
	`;

	export const PanelItem = styled.li`
		width: 100%;

		a {
			display: block;
			padding: 8px 20px;
			line-height: 24px;
			cursor: pointer;
			transition: background-color 0.2s ease;

			&.active {
				background-color: ${COLORS.settingsIconColor};
				font-weight: 500;

				span:first-of-type {
					background-color: ${COLORS.white};
				}

				span {
					font-weight: 500;
				}
			}

			&:hover {
				background-color: ${COLORS.settingsIconColor};

				span:first-of-type {
					background-color: ${COLORS.white};
				}
			}
		}
	`;

	export const PanelItemBox = styled.div`
		display: flex;
		align-items: center;
	`;

	export const ItemIcon = styled.span`
		display: flex;
		background-color: ${COLORS.settingsIconColor};
		border-radius: 4px;
		transition: background-color 0.2s ease;

		svg {
			padding: 4px;
		}
	`;

	export const ItemName = styled.span`
		margin-left: 12px;
		font-size: 16px;
		font-weight: 400;
		color: ${COLORS.black};
		transition: color ease 0.2s;
		white-space: nowrap;
	`;
}
