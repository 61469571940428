import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMount } from 'hooks';
import SubscriptionModal from 'modules/ATS/components/SubscriptionModal';
import ConfirmationModal from 'modules/Common/components/ConfirmationModal';
import { UNASSIGNED_JOB_TITLE } from 'modules/Common/constants';
import { ADD_EMPLOYEE_TO_HR_MODULE } from 'modules/Common/constants/modalTitles.constants';
import { commonDucks } from 'modules/Common/ducks';
import {
	ICandidatesData,
	IJobsApplied,
	JobDataType,
	CandidateStatusType,
	AssignCandidateToVacancyResponseType,
	IUpdateCandidateRankProps,
	CandidateResponseType,
} from 'modules/Common/types';
import { Routes as HRRoutes } from 'modules/HR/routes/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Routes, SubscriptionPlansType, UserRolesType } from 'types';

import { atsDucks } from '../../ducks';
import CandidateApplicationDetails from '../CandidateApplicationDetails';
import { CandidatesStatusValuesEnum } from '../CandidatesApplication/CandidatesApplication.types';

import AppliedJobsPanelMenu from './AppliedJobsPanelMenu';

type ATSJobsListPageProps = {
	clientId: number;
	autoPop: boolean;
	getJobById: (jobId: string) => void;
	unappliedCandidateJobsData: Partial<JobDataType>[];
	jobData: JobDataType;
	subscriptionPlans: SubscriptionPlansType;
	getClientAutoPop: () => void;
	updateCandidateRankRequested: (props: IUpdateCandidateRankProps) => void;
	updateCandidateStatus: CandidateStatusType;
	getCandidateByIdRequested: (id: number) => void;
	assignCandidateToVacancy: (
		candidateApplicationId: number,
		jobs: number[],
		cb?: (res: AssignCandidateToVacancyResponseType) => void,
	) => void;
	getUnappliedCandidateJobs: (candidateAppId: number) => void;
	roles: UserRolesType;
};

const AppliedJobs: FC<ATSJobsListPageProps> = ({
	getJobById,
	jobData,
	autoPop,
	subscriptionPlans,
	getClientAutoPop,
	unappliedCandidateJobsData,
	updateCandidateRankRequested,
	updateCandidateStatus,
	getCandidateByIdRequested,
	assignCandidateToVacancy,
	getUnappliedCandidateJobs,
	roles,
}) => {
	const navigate = useNavigate();
	const { candidateId } = useParams();
	const { state, search } = useLocation();
	const { isAtsSuperUser } = roles;

	const queryParams = new URLSearchParams(search);
	const jobIdParam = queryParams.get('jobId');

	const [currentCandidate, setCurrentCandidate] = useState<Partial<ICandidatesData> | null>(null);
	const [currentJob, setCurrentJob] = useState<IJobsApplied | null>(
		currentCandidate?.jobsApplied?.length ? currentCandidate?.jobsApplied[0] : null,
	);
	const [employeeToHRModal, setEmployeeToHRModal] = useState<CandidateResponseType | null>(null);
	const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
	const [jobVacancies, setJobVacanciesIds] = useState<number[]>([]);

	const [jobId, setJobId] = useState(jobIdParam ? +jobIdParam : state?.jobId);

	const handleChangeJob = (job: IJobsApplied) => {
		navigate(
			`${Routes.ATS}${Routes.AppliedJobsCandidate}/${job?.candidateApplicationId}?jobId=${job?.jobId}`,
		);

		setJobId(job?.jobId);
		setCurrentJob(job);
	};

	const handleSelectVacancy = useCallback((value: number[]) => {
		setJobVacanciesIds(value);
	}, []);

	const navigateUnassignedCandidateToAppliedJobs = (res: AssignCandidateToVacancyResponseType) => {
		if (res?.createdCandidateApplications?.length) {
			const job = res.createdCandidateApplications[0];
			navigate(`${Routes.ATS}${Routes.AppliedJobsCandidate}/${job?.id}?jobId=${job?.job}`);
			setJobId(job?.job);
		}
	};

	const unassignedCandidate = currentCandidate?.jobsApplied[0]?.title === UNASSIGNED_JOB_TITLE;

	const handleAssignToCandidate = useCallback(() => {
		const callback = (res: AssignCandidateToVacancyResponseType) => {
			if (unassignedCandidate) {
				navigateUnassignedCandidateToAppliedJobs(res);
			} else {
				jobId && getJobById(jobId);

				currentCandidate?.id && getUnappliedCandidateJobs(currentCandidate?.id);
			}
		};

		jobVacancies?.length &&
			currentCandidate?.id &&
			assignCandidateToVacancy(currentCandidate?.id, jobVacancies, callback);
		setJobVacanciesIds([]);

		if (unassignedCandidate) return;

		if (jobData && candidateId) {
			const newCandidate = jobData?.candidates?.find((candidate) => candidate?.id === +candidateId);

			newCandidate && setCurrentCandidate(newCandidate);
		}
	}, [jobVacancies, jobId]);

	const handleCancelAssignToCandidate = useCallback(() => {
		setJobVacanciesIds([]);
	}, []);

	const handleCandidateRank = useCallback(
		(value: number, callback: () => void) => {
			currentCandidate?.id !== undefined &&
				updateCandidateRankRequested({
					candidateAppId: currentCandidate.id,
					rank: value,
					jobId,
					cb: callback,
				});
		},
		[jobData, currentCandidate?.id],
	);

	const handleOpenSubscriptionModal = useCallback(() => {
		setSubscriptionModal(true);
	}, []);

	const handleCloseSubscriptionModal = useCallback(() => {
		setSubscriptionModal(false);
	}, []);

	const handleOpenEmployeeToHRModal = useCallback(
		(status: CandidatesStatusValuesEnum, employeeData: CandidateResponseType[]) => {
			if (status === CandidatesStatusValuesEnum.Hired && employeeData.length && isAtsSuperUser) {
				setEmployeeToHRModal(employeeData[0]);
			}
		},
		[],
	);

	const handleCloseEmployeeToHRModal = useCallback(() => {
		setEmployeeToHRModal(null);
	}, []);

	const handleMoveCandidateToHRModule = useCallback(() => {
		handleCloseEmployeeToHRModal();
		if (subscriptionPlans.isPremiumPlan) {
			navigate(`${HRRoutes.HRModule}${HRRoutes.EmployeeCreate}`, {
				state: { ...employeeToHRModal, jobTitle: currentJob?.title || '' },
			});
		} else {
			handleOpenSubscriptionModal();
		}
	}, [employeeToHRModal]);

	const handleUpdateCandidateStatus = useCallback(
		(
			candidateIds: number[],
			status: string,
			job: number | undefined,
			callback: (ids: number[]) => void,
		) => {
			updateCandidateStatus(candidateIds, status, job, (__, successfulCandidates) => {
				callback && callback(candidateIds);
				handleOpenEmployeeToHRModal(status as CandidatesStatusValuesEnum, successfulCandidates);

				if (autoPop && status === CandidatesStatusValuesEnum.Rejected) {
					const currentList = currentCandidate?.jobsApplied?.filter(
						(item) => item?.status !== CandidatesStatusValuesEnum.Rejected,
					);
					const currentJobIndex = currentList?.findIndex(
						(item) => item?.jobId === currentJob?.jobId,
					);
					const nextJobIndex =
						currentJobIndex !== -1 &&
						currentList?.length &&
						currentJobIndex !== currentList?.length - 1
							? currentJobIndex + 1
							: 0;

					const nextJob = currentList?.[nextJobIndex];
					nextJob && handleChangeJob(nextJob);
				}

				jobId && getJobById(jobId);
			});
		},
		[jobData, currentJob, jobId],
	);

	useEffect(() => {
		jobId && getJobById(jobId);
	}, [jobId]);

	useEffect(() => {
		currentCandidate?.id && getUnappliedCandidateJobs(currentCandidate?.id);
	}, [currentCandidate]);

	useEffect(() => {
		if (jobData?.candidates?.length && candidateId) {
			const newCandidate = jobData?.candidates?.find((candidate) => candidate?.id === +candidateId);

			newCandidate && setCurrentCandidate(newCandidate);
		}
	}, [jobId, jobData, candidateId]);

	useEffect(() => {
		currentCandidate &&
			setCurrentJob(
				currentCandidate?.jobsApplied?.length
					? currentCandidate?.jobsApplied?.find((j) => j.jobId === jobId)
					: null,
			);
	}, [candidateId, currentCandidate, jobId]);

	useEffect(() => {
		if (currentCandidate?.id) {
			// Update status New for Current Candidate
			currentCandidate.isNew && getCandidateByIdRequested(currentCandidate.id);
		}
	}, [currentCandidate?.id]);

	useMount(() => {
		getClientAutoPop();
	});

	return (
		!!currentCandidate && (
			<>
				<CandidateApplicationDetails
					updateCandidateStatus={handleUpdateCandidateStatus}
					updateCandidateRank={handleCandidateRank}
					currentJobId={jobId}
					currentCandidate={currentCandidate}
					defaultTab={state?.tab}
					panelMenuComponent={() => (
						<AppliedJobsPanelMenu
							currentJob={currentJob}
							jobVacancies={jobVacancies}
							handleChangeJob={handleChangeJob}
							handleSelectVacancy={handleSelectVacancy}
							handleSave={handleAssignToCandidate}
							handleCancel={handleCancelAssignToCandidate}
							currentCandidate={currentCandidate}
							jobsData={unappliedCandidateJobsData}
						/>
					)}
				/>
				<ConfirmationModal
					modalOpen={!!employeeToHRModal}
					title={ADD_EMPLOYEE_TO_HR_MODULE}
					handleApprove={handleMoveCandidateToHRModule}
					handleCloseModal={handleCloseEmployeeToHRModal}
				/>
				<SubscriptionModal
					modalOpen={subscriptionModal}
					handleCloseModal={handleCloseSubscriptionModal}
				/>
			</>
		)
	);
};

export default connect(
	(state) => ({
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		autoPop: atsDucks.atsSelectors.getClientAutoPop(state)?.autoPop,
		jobData: commonDucks.commonSelectors.getJobData(state),
		unappliedCandidateJobsData: atsDucks.atsSelectors.getUnappliedCandidateJobsData(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
	}),
	{
		getJobById: commonDucks.commonActions.getJobByIdRequested,
		getClientAutoPop: atsDucks.atsActions.getClientAutoPopRequested,
		getUnappliedCandidateJobs: atsDucks.atsActions.getUnappliedCandidateJobsRequested,
		updateCandidateRankRequested: commonDucks.commonActions.updateCandidateRankRequested,
		assignCandidateToVacancy: atsDucks.atsActions.assignCandidateToVacancyRequested,
		getCandidateByIdRequested: commonDucks.commonActions.getCandidateByIdRequested,
		updateCandidateStatus: commonDucks.commonActions.updateApplicationStatusRequested,
	},
)(AppliedJobs);
