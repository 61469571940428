import { EmployeeStatusEnum } from 'modules/Common/types/employee';

export const StatusStylesData = {
	[EmployeeStatusEnum.PENDING]: {
		color: '#A158DD',
		background: '#F6EBFF',
		borderColor: '#CF94FF',
	},
	[EmployeeStatusEnum.CURRENT]: {
		color: '#52C41A',
		background: '#F6FFED',
		borderColor: '#B7EB8F',
	},
	[EmployeeStatusEnum.LEAVING]: {
		color: '#FAAD14',
		background: '#FFF7E6',
		borderColor: '#FFD591',
	},
	[EmployeeStatusEnum.LEFT]: {
		color: '#5C5C60',
		background: '#F8F8F8',
		borderColor: '#EBEBED',
	},
};

export const enum TabsTitleEnum {
	ActiveTab = '1',
	ArchiveTab = '2',
}
