import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface RootProps {
	isWeekView: boolean;
}

export namespace Styled {
	export const Root = styled.div<RootProps>`
		position: relative;

		.rbc-time-view,
		.rbc-time-header-content,
		.rbc-time-header,
		.rbc-time-header-cell,
		.rbc-header,
		.rbc-day-bg + .rbc-day-bg {
			border: none;
		}

		.rbc-time-view {
			border: ${COLORS.lightGray4};

			.rbc-row {
				min-height: 0;
			}
		}

		.rbc-header span {
			font-size: 12px;
			font-weight: 600;
		}

		.rbc-time-content {
			border: 0.25px solid ${COLORS.lightGray4};

			& > * + * > * {
				border-left: 0.25px solid ${COLORS.lightGray4};
			}
		}

		.rbc-allday-cell {
			.rbc-row {
				min-height: 0;
			}
		}

		.rbc-time-header {
			.rbc-header {
				display: flex;
				align-items: center;
				min-height: 24px;
			}
		}

		.rbc-time-header-content {
			.rbc-today {
				color: ${COLORS.blue2};
				background-color: transparent;
			}
		}

		.rbc-btn-group {
			button {
				transition: color 0.2s ease;
				cursor: pointer;

				&:hover {
					color: ${COLORS.blue};
				}
			}
		}

		.rbc-events-container {
			margin: 0 10px;
			border: none;

			.rbc-event-label {
				display: none;
			}
		}

		.rbc-ellipsis,
		.rbc-show-more,
		.rbc-row-segment .rbc-event-content,
		.rbc-event-label {
			white-space: initial;
		}

		.rbc-timeslot-group {
			min-height: 48px;
			border-bottom: 0.25px solid ${COLORS.lightGray4};

			.rbc-time-slot {
				display: flex;
				justify-content: center;
				align-items: end;
			}

			.rbc-label {
				font-size: 12px;
			}
		}

		.rbc-day-slot {
			.rbc-time-slot {
				border: none;
			}

			.rbc-event-content {
				line-height: normal;
			}
		}

		.rbc-current-time-indicator {
			height: 2px;
			background-color: ${COLORS.blue};
		}

		.rbc-event {
			font-size: 12px;
			padding: 4px 6px;
			border-radius: 2px;
		}

		.rbc-time-column.rbc-now.rbc-today {
			width: 100%;
			background-color: ${COLORS.lightBlue7};
		}

		${({ isWeekView }) =>
		isWeekView &&
			`
			.rbc-time-content,
			.rbc-label.rbc-time-header-gutter {
				display: none;
			}

			.rbc-header {
				text-align: left;
				border-bottom: none;
			}

			.rbc-header button {
				padding: 10px 16px;
				pointer-events: none;

				span {
					color: ${COLORS.black};
					font-family: Inter;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
					letter-spacing: -0.132px;
					text-transform: uppercase;
				}
			}

			.rbc-time-view .rbc-row {
				min-height: auto;
			}

			.rbc-time-header-content {
				border: none;
			}

			.rbc-row-content {
				padding-top: 10px;
			}

			.rbc-time-header {
				flex: 1 0 auto;
			}

			.rbc-time-view {
				overflow-y: auto;
				border: 1px solid ${COLORS.lightGray2};
			}
			.rbc-row-segment {
				margin-bottom: 2px;
			}
			.rbc-day-bg + .rbc-day-bg,
			.rbc-header + .rbc-header {
				border-left: 1px solid #ddd;
			}
		`}
	`;
}
