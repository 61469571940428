import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;

		.rbc-time-content,
		.rbc-label.rbc-time-header-gutter {
			display: none;
		}

		.rbc-header {
			text-align: left;
			border-bottom: none;
		}

		.rbc-header button {
			padding: 10px 16px;
			pointer-events: none;

			span {
				color: ${COLORS.black};
				font-family: Inter;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				letter-spacing: -0.132px;
				text-transform: uppercase;
			}
		}

		.rbc-time-view .rbc-row {
			min-height: auto;
		}

		.rbc-time-header-content {
			border: none;
		}

		.rbc-row-content {
			padding-top: 10px;
		}

		.rbc-time-header {
			flex: 1 0 auto;
		}

		.rbc-time-view {
			overflow-y: auto;
			border: 1px solid ${COLORS.lightGray2};
		}
		.rbc-row-segment {
			margin-bottom: 2px;
		}
	`;
}
