import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import FileItemRendererAttachments from 'modules/Common/components/FileItemRendererAttachments';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedEmployeeFilesUploadFormFileFormat } from 'modules/Common/constants';
import { FileUploadTypesEnum, IPostTicketValues } from 'modules/Common/types';
import { IEmployeeAttachment } from 'modules/Common/types/employee';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { hrDucks } from 'modules/HR/ducks';
import { GenericType } from 'types';
import { handleFileDownload } from 'utils/helpers';

import { Styled } from './HRAttachmentListForm.styled';

type HRAttachmentListFormProps = {
	employeeId: string;
	isSuperUser: boolean;
	hrLoading: GenericType;
	attachmentsList: IEmployeeAttachment[];
	updateAttachmentsCallback: () => void;
	saveEmployeeAttachmentsRequested: (
		attachments: string[],
		employeeId: string,
		isSuperUser: boolean,
		callback?: () => void,
	) => void;
};

const fileUploadURL = `${process.env.REACT_APP_API_URL}/hr/employee/file`;

const HRAttachmentListForm: FC<HRAttachmentListFormProps> = ({
	employeeId,
	isSuperUser,
	hrLoading,
	attachmentsList,
	updateAttachmentsCallback,
	saveEmployeeAttachmentsRequested,
}) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [newFiles, setNewFiles] = useState<Set<string>>(new Set());

	const onSubmit = (values: IPostTicketValues) => {
		const attachments = fileList?.map((file) => file.response.data);
		saveEmployeeAttachmentsRequested(attachments, employeeId, isSuperUser, () => {
			updateAttachmentsCallback();
			setNewFiles(new Set());
		});
	};

	useEffect(() => {
		if (!attachmentsList?.length) return;
		const initialFileList = attachmentsList.map((attachment, index) => ({
			uid: `${index}`,
			name: attachment.originalFileName,
			id: attachment.id,
			status: 'done',
			response: {
				data: attachment.ref,
			},
		})) as UploadFile[];
		setFileList(initialFileList);
	}, [attachmentsList]);

	const initialValues = useMemo(
		() => ({
			attachments: attachmentsList,
		}),
		[attachmentsList],
	);

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData) => {
				onChange([...value, responseData]);
				setNewFiles((prev) => new Set(prev).add(responseData));
			},
			{
				fileList,
				accept: acceptedEmployeeFilesUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					const updatedFileList = fileList.filter((f) => f.uid !== file.uid);
					setFileList(updatedFileList);
					onChange(updatedFileList.map((f) => f.response.data));
					setNewFiles((prev) => {
						const updated = new Set(prev);
						updated.delete(file?.response?.data);

						return updated;
					});
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.FILE,
				maxCount: undefined,
				url: fileUploadURL,
			},
			setFileList,
		);

	const handleDownload = useCallback((file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	}, []);

	const isFileRemovable = useCallback(
		(file: UploadFile) => isSuperUser || newFiles.has(file?.response?.data),
		[isSuperUser, newFiles],
	);

	const customItemRender = useCallback(
		(originNode: React.ReactElement, file: UploadFile) => {
			const isRemovable = isFileRemovable(file);

			return (
				<FileItemRendererAttachments
					originNode={originNode}
					file={file}
					isRemovable={isRemovable}
					onDownload={handleDownload}
					onRemove={(f) => draggerProps({ onChange: () => undefined, value: [] }).onRemove(f)}
				/>
			);
		},
		[isFileRemovable, newFiles, handleDownload, draggerProps],
	);

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				render={({ handleSubmit, dirty }) => (
					<form onSubmit={handleSubmit}>
						<FormBlockLine columns={1}>
							<Field name='attachments'>
								{({ input, meta }) => (
									<FieldWrapper
										isFixed
										name='attachments'
										label='Attachments List'
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<Styled.Dragger
											{...draggerProps(input)}
											listType='text'
											showUploadList={{
												showDownloadIcon: false,
												showRemoveIcon: false,
											}}
											itemRender={customItemRender}
										>
											<p className='ant-upload-drag-icon'>
												<InboxOutlined />
											</p>
											<p className='ant-upload-text'>Upload a files or drag and drop it</p>
											<p className='ant-upload-hint'>
												{' '}
												PDF, DOC, DOCX, XLS, XLSX, JPEG, PNG up to 15MB{' '}
											</p>
										</Styled.Dragger>
									</FieldWrapper>
								)}
							</Field>
						</FormBlockLine>
						<Styled.ButtonBox>
							<HRThemedButton
								type='submit'
								buttonType={ButtonTypes.primary}
								loading={false}
								disabled={
									!!hrLoading?.saveEmployeeAttachmentsLoad ||
									!(dirty || fileList.length !== attachmentsList?.length)
								}
							>
								{'Save attachments'}
							</HRThemedButton>
						</Styled.ButtonBox>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		hrLoading: hrDucks.hrSelectors.getHrModuleLoading(state),
	}),
	{
		saveEmployeeAttachmentsRequested: hrDucks.hrActions.saveEmployeeAttachmentsRequested,
	},
)(HRAttachmentListForm);
