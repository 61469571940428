import { WorkWeekSettingsValuesType } from './HRModuleSettings.types';

export const AllowedWorkWeekFormFields = [
	'mondayEnabled',
	'tuesdayEnabled',
	'wednesdayEnabled',
	'thursdayEnabled',
	'fridayEnabled',
	'saturdayEnabled',
	'sundayEnabled',
];

export const defaultWorkWeekSettingsValues: WorkWeekSettingsValuesType = {
	mondayEnabled: true,
	tuesdayEnabled: true,
	wednesdayEnabled: true,
	thursdayEnabled: true,
	fridayEnabled: false,
	saturdayEnabled: false,
	sundayEnabled: false,
};
