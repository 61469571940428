import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import CreditTag from 'modules/BackOffice/components/CreditTag';
import Tag from 'modules/Common/components/Tag';
import { CreditIcons } from 'modules/Common/constants';
import {
	ContractStatusEnum,
	ContractStatusValuesEnum,
	IContractState,
	IInvoicesState,
	InvoicesStatusColors,
	InvoicesStatusEnum,
	InvoicesStatusStylesData,
	InvoicesTypesValuesEnum,
	InvoiceTypeValuesEnum,
} from 'modules/Common/types';
import { IOption, UserRolesType } from 'types';

import { ContractStatusColours, StatusStylesData } from './FinancialReports.constants';
import { Styled } from './FinancialReports.styled';
import { FinancialReportItemValues, ICredits } from './FinancialReports.types';

export const renderCredits = ({
	invoiceType,
	credits,
}: {
	invoiceType?: InvoicesTypesValuesEnum;
	credits: ICredits | null | undefined;
}) => {
	if (
		(!invoiceType ||
			invoiceType === InvoicesTypesValuesEnum.ContractAccept ||
			invoiceType === InvoicesTypesValuesEnum.CreditsPurchase) &&
		credits
	) {
		return (
			<Styled.CreditsWrap>
				<CreditTag amount={credits?.numberOfPremiumMultisiteCredits ?? 0} icon={CreditIcons[1]} />
				<CreditTag amount={credits?.numberOfCvFilteringCredits ?? 0} icon={CreditIcons[2]} />
				<CreditTag amount={credits?.numberOfGuardianCredits ?? 0} icon={CreditIcons[3]} />
				<CreditTag amount={credits?.numberOfIndeedCredits ?? 0} icon={CreditIcons[4]} />
			</Styled.CreditsWrap>
		);
	}

	return <span>-</span>;

	// left maybe for future implementation
	// if (
	// 	invoiceType === InvoicesTypesValuesEnum.PremiumSubscrition ||
	// 	invoiceType === InvoicesTypesValuesEnum.PremiumSubscritionManual
	// ) {
	// 	return <CreditTag amount={1} icon={CreditIcons[7]} />;
	// }

	// if (invoiceType === InvoicesTypesValuesEnum.InterviewPurchase) {
	// 	return <CreditTag amount={credits?.numberOfInterviewCredits || 0} icon={CreditIcons[5]} />;
	// }

	// if (invoiceType === InvoicesTypesValuesEnum.SmsPurchase) {
	// 	return <CreditTag amount={credits?.numberOfSmsCredits || 0} icon={CreditIcons[6]} />;
	// }
};

export const renderInvoiceStatus = (
	{ status, invoiceType, id }: Partial<FinancialReportItemValues>,
	invoiceStatuses: IInvoicesState[],
	handleChangeInvoiceStatus: (id: number, statusId: number) => void,
) => {
	const invoiceStatusName = status?.name;
	const isPaid = invoiceStatusName === InvoicesStatusEnum.Paid;
	const isSubscriptionBased =
		invoiceType?.value === InvoiceTypeValuesEnum.PremiumSubscritionManual ||
		invoiceType?.value === InvoiceTypeValuesEnum.PremiumSubscrition;
	const showTag = isPaid || isSubscriptionBased;
	const selectedStatusId = invoiceStatuses?.find((item) => {
		return invoiceStatusName === InvoicesStatusEnum.PaidManually
			? item.name === InvoicesStatusEnum.Paid
			: item.name === invoiceStatusName;
	})?.id;
	const styles = invoiceStatusName && InvoicesStatusStylesData[invoiceStatusName];

	return (
		<>
			{showTag ? (
				<Tag
					color={String(InvoicesStatusColors[invoiceStatusName as InvoicesStatusEnum])}
					style={{
						boxSizing: 'content-box',
						minWidth: '90px',
						width: '90px',
						fontSize: '12px',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{InvoicesStatusEnum[invoiceStatusName as InvoicesStatusEnum] === 'PaidManually'
						? 'Paid'
						: InvoicesStatusEnum[invoiceStatusName as InvoicesStatusEnum]}
				</Tag>
			) : (
				<Styled.Select
					value={selectedStatusId || null}
					styles={styles}
					onChange={(statusId: number) => id && handleChangeInvoiceStatus(id, statusId)}
					placeholder='Select a status'
					label=''
				>
					{invoiceStatuses?.map((option: IOption) => (
						<Styled.Option key={option.id} value={option.id}>
							{option.name}
						</Styled.Option>
					))}
				</Styled.Select>
			)}
		</>
	);
};

export const renderContractStatus = (
	{ status, id }: Partial<FinancialReportItemValues>,
	contractStatuses: IContractState[],
	roles: UserRolesType,
	handleChangeContractStatus: (id: number, statusId: number) => void,
) => {
	const isFulfilled = status?.value === ContractStatusValuesEnum.Fulfilled;
	const isAccepted = status?.value === ContractStatusValuesEnum.Accepted;
	const styles = status?.name && StatusStylesData[status?.name];
	const idOfContract = id;
	const { isSuperUser } = roles || {};

	return (
		<>
			{(isFulfilled || isAccepted) && isSuperUser ? (
				<Styled.Select
					value={status?.id || null}
					styles={styles}
					onChange={(statusId: number) =>
						idOfContract && handleChangeContractStatus(idOfContract, statusId)
					}
					placeholder='Select a status'
					label=''
				>
					{contractStatuses?.map((option: IOption) => (
						<Styled.Option key={option.id} value={option.id}>
							{option.name}
						</Styled.Option>
					))}
				</Styled.Select>
			) : (
				<Tag
					color={ContractStatusColours[status?.value]}
					style={{
						boxSizing: 'content-box',
						minWidth: '90px',
						width: '90px',
						fontSize: '12px',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{ContractStatusEnum[status?.value]}
				</Tag>
			)}
		</>
	);
};

export const renderCombinedStatus = (
	{ invoiceType, id, type, status }: Partial<FinancialReportItemValues>,
	invoiceStatuses: IInvoicesState[],
	contractStatuses: IContractState[],
	handleChangeInvoiceStatus: (id: number, statusId: number) => void,
	handleChangeContractStatus: (id: number, statusId: number) => void,
	roles: UserRolesType,
) => {
	if (type === 'INVOICE') {
		return renderInvoiceStatus(
			{ status, invoiceType, id },
			invoiceStatuses,
			handleChangeInvoiceStatus,
		);
	}

	return renderContractStatus({ status, id }, contractStatuses, roles, handleChangeContractStatus);
};

export const handleTableChange =
	(setTableParams: React.Dispatch<React.SetStateAction<object>>, tableParams: unknown) =>
		(
			pagination: TablePaginationConfig,
			filters: unknown,
			sorter: SorterResult<unknown> | SorterResult<unknown>[],
		) => {
			setTableParams({
				...tableParams,
				sorter,
			});
		};

export const handleTablePaginationChange =
	(setTableParams: React.Dispatch<React.SetStateAction<object>>, tableParams: unknown) =>
		(page: number, size: number) => {
			setTableParams({ ...tableParams, page, size });
		};
