import React, { type FC } from 'react';

import type { TableRowSelection, RowSelectMethod } from 'antd/es/table/interface';

import TableComponent from 'components/Table';
import { CandidateResponseType, DataTableType, JobDataType } from 'modules/Common/types';
import { SubscriptionPlansType } from 'types';

import { columns } from '../CandidatesApplication.entities';
import { Styled } from '../CandidatesApplication.styled';
import { CandidatesStatusValuesEnum } from '../CandidatesApplication.types';

type CandidatesApplicationTableProps = {
	data: JobDataType[];
	onSelectChange: (
		newSelectedRowKeys: React.Key[],
		selectedRows: DataTableType[],
		info: { type: RowSelectMethod },
	) => void;
	selectedRowKeys: React.Key[];
	displaySize: number;
	handleShowButton: () => void;
	handleOpenModal: (id: number, job: number) => void;
	handleOpenEmployeeToHRModal: (
		status: CandidatesStatusValuesEnum,
		employeeData: CandidateResponseType[],
	) => void;
	handleSelectSpecificCandidate: (id: number) => void;
	handleResendCandidateQuestionnaire: (id: number) => void;
	handleRecallCandidateQuestionnaire: (id: number) => void;
	zipArchiveLoading: boolean;
	handleCandidateRank: (
		id: string | number,
		rank: number,
		jobId?: string | number,
		cb?: () => void,
	) => void;
	handleSendSMSAction: (id: number) => void;
	subscriptionPlans: SubscriptionPlansType;
};

const CandidatesApplicationTable: FC<CandidatesApplicationTableProps> = ({
	data,
	onSelectChange,
	selectedRowKeys,
	displaySize,
	handleShowButton,
	handleOpenModal,
	handleSelectSpecificCandidate,
	handleResendCandidateQuestionnaire,
	handleRecallCandidateQuestionnaire,
	handleCandidateRank,
	handleSendSMSAction,
	handleOpenEmployeeToHRModal,
	subscriptionPlans,
	zipArchiveLoading,
}) => {
	const rowSelection: TableRowSelection<DataTableType> = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const columnsData = columns(
		handleOpenModal,
		handleResendCandidateQuestionnaire,
		handleRecallCandidateQuestionnaire,
		handleCandidateRank,
		handleSendSMSAction,
		subscriptionPlans,
		handleSelectSpecificCandidate,
		handleOpenEmployeeToHRModal,
	);

	return (
		<Styled.JobBox>
			<TableComponent
				pagination={false}
				loading={zipArchiveLoading}
				rowSelection={rowSelection}
				data={data.slice(0, displaySize)}
				columns={columnsData}
			/>
			{data?.length > displaySize && (
				<Styled.ShowButton onClick={handleShowButton} style={{ marginTop: 16 }}>
					{`View ${data.length - displaySize} more`}
				</Styled.ShowButton>
			)}
		</Styled.JobBox>
	);
};

export default CandidatesApplicationTable;
