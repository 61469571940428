import React, { useCallback, useMemo, useRef, type FC } from 'react';
import { Field, Form } from 'react-final-form';

import TextArea from 'antd/es/input/TextArea';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { lengthValidator } from 'utils/validators';

import { Styled } from './DeclineRequestTimeOffModal.styled';

type DeclineRequestTimeOffModalProps = {
	isOpen: number | null;
	loading?: boolean;
	onCancel: () => void;
	onApprove: (
		{ requestId, comment }: { requestId: number; comment?: string },
		callback?: () => void,
	) => void;
};

const DeclineRequestTimeOffModal: FC<DeclineRequestTimeOffModalProps> = ({
	isOpen,
	loading,
	onApprove,
	onCancel,
}) => {
	const formRef = useRef<unknown>(null);

	const initialValues = useMemo(
		() => ({
			comment: '',
		}),
		[isOpen],
	);

	const handleCloseModal = () => {
		formRef.current?.reset();
		onCancel();
	};

	const onSubmit = useCallback(
		(values: { comment?: string }) => {
			const valuesToSubmit = {
				...values,
				requestId: isOpen as number,
			};
			onApprove(valuesToSubmit, handleCloseModal);
		},
		[isOpen],
	);

	return (
		<Styled.Modal open={!!isOpen} footer={null} centered onCancel={handleCloseModal} width='auto'>
			<Styled.ModalHead>
				<h3>Decline Time Off Request</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					autoComplete='off'
					render={({ handleSubmit, submitting, form }) => {
						formRef.current = form;

						return (
							<form onSubmit={handleSubmit}>
								<Styled.FieldsBlock>
									<Field name='comment' validate={lengthValidator(0, 3000)}>
										{({ input, meta }) => (
											<FieldWrapper
												name='comment'
												label='Comment'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<TextArea
													{...input}
													placeholder='Please provide a reason (optional)'
													autoSize={{ minRows: 4, maxRows: 4 }}
												/>
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldsBlock>
								<Styled.ButtonBox>
									<HRThemedButton type='submit' buttonType={ButtonTypes.primary} disabled={loading}>
										Decline
									</HRThemedButton>
									<HRThemedButton
										type='button'
										buttonType={ButtonTypes.tertiary}
										onClick={onCancel}
									>
										Cancel
									</HRThemedButton>
								</Styled.ButtonBox>
							</form>
						);
					}}
				/>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};

export default DeclineRequestTimeOffModal;
