import styled from 'styled-components/macro';

export namespace Styled {
	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		margin-top: 40px;

		h2 {
			margin: 0;
		}
	`;

	export const Content = styled.div`
		margin-top: 24px;
	`;

	export const Title = styled.h2``;

	export const ButtonsBox = styled.div`
		display: flex;
		gap: 16px;
	`;
}
