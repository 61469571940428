import { UploadFile } from 'antd';

import {
	acceptedCalendarFileFormat,
	acceptedOnboardingFilesUploadFormFileFormat,
} from 'modules/Common/constants';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { BankHolidayType } from 'modules/Common/types/hrModuleTypes';
import {
	getMultipleFileUploaderProps,
	getSingleFileUploaderProps,
} from 'modules/Common/utils/brandingUploader';
import { handleFileDownload } from 'utils/helpers';

import {
	EmployeeOptions,
	ListActionEnums,
	WorkWeekSettingsValuesType,
} from './HRModuleSettings.types';

export const updateListHelper = <T extends { id: number }>(
	list: T[],
	item: T,
	action: ListActionEnums,
): T[] => {
	switch (action) {
	case ListActionEnums.ADD:
		return [...list, item];
	case ListActionEnums.DELETE:
		return list.filter((listItem) => listItem.id !== item.id);
	case ListActionEnums.UPDATE:
		return list.map((listItem) => (listItem.id === item.id ? item : listItem));
	default:
		return list;
	}
};

export const transformEmployeeList = (
	employeeList: EmployeeOptions[],
): { [key: number]: string } => {
	return employeeList.reduce((acc, curr) => {
		acc[curr.value] = curr.label;

		return acc;
	}, {} as { [key: number]: string });
};

export const handleDownload = (file: UploadFile) => {
	const { name } = file;
	const uid = file?.response?.data;
	handleFileDownload(uid, name);
};

export const getDraggerProps =
	(
		fileList: UploadFile[],
		setFileList: React.Dispatch<React.SetStateAction<UploadFile<unknown>[]>>,
	) =>
		({ onChange, value = [] }: { onChange: (fileIds: string[]) => void; value: string[] }) =>
			getMultipleFileUploaderProps(
				(url, responseData) => {
					onChange([...value, responseData]);
				},
				{
					fileList,
					accept: acceptedOnboardingFilesUploadFormFileFormat,
					onRemove: (file) => {
						onChange([...value.filter((item) => item !== file?.response?.data)]);
					},
					onDownload: handleDownload,
					fileType: FileUploadTypesEnum.Ticket,
					maxCount: undefined,
				},
				setFileList,
			);

export const transformWorkWeekToArray = (
	workWeekValues: WorkWeekSettingsValuesType,
): DayOfWeek[] => {
	return [
		{ name: 'mondayEnabled', label: 'Monday', value: workWeekValues.mondayEnabled },
		{ name: 'tuesdayEnabled', label: 'Tuesday', value: workWeekValues.tuesdayEnabled },
		{ name: 'wednesdayEnabled', label: 'Wednesday', value: workWeekValues.wednesdayEnabled },
		{ name: 'thursdayEnabled', label: 'Thursday', value: workWeekValues.thursdayEnabled },
		{ name: 'fridayEnabled', label: 'Friday', value: workWeekValues.fridayEnabled },
		{ name: 'saturdayEnabled', label: 'Saturday', value: workWeekValues.saturdayEnabled },
		{ name: 'sundayEnabled', label: 'Sunday', value: workWeekValues.sundayEnabled },
	];
};

export const filterPublicBankHolidaysForYear = (
	bankHolidays: BankHolidayType[],
	year: string = new Date().getFullYear().toString(),
): BankHolidayType[] => {
	return bankHolidays.filter(
		(bankHoliday) => bankHoliday.client === null && bankHoliday.date.includes(year),
	);
};

export const filterCustomBankHolidays = (bankHolidays: BankHolidayType[]): BankHolidayType[] =>
	bankHolidays.filter((bankHoliday) => bankHoliday.client !== null);

export const getImportHolidaysDraggerProps = (
	overwritePreviousHolidays: boolean,
	onSuccess: () => void,
) => {
	return getSingleFileUploaderProps(
		(url, responseData) => {
			onSuccess();
		},
		{
			showUploadList: false,
			accept: acceptedCalendarFileFormat,
			url: `${process.env.REACT_APP_API_URL}/hr/settings/holiday/import?overwrite=${overwritePreviousHolidays}`,
		},
	);
};
