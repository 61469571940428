import { CALENDAR_EVENT_TYPES } from 'modules/HR/constants/HRModuleConstants.constants';

export const filterOptions = [
	{ label: 'Vacation', value: CALENDAR_EVENT_TYPES.vacation },
	{ label: 'Sick Leave', value: CALENDAR_EVENT_TYPES.sickLeave },
	{ label: 'Parental leave', value: CALENDAR_EVENT_TYPES.maternityLeave },
	{ label: 'Other', value: CALENDAR_EVENT_TYPES.other },
];

export const dayTypeOptions = [
	{ label: 'Full Day (8 hrs)', value: 'FULL' },
	{ label: 'Half Day (4 hrs) - AM', value: 'HALF_AM' },
	{ label: 'Half Day (4 hrs) - PM', value: 'HALF_PM' },
];
