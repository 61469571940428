import React, { type FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import Spinner from 'components/Spinner';
import { atsDucks } from 'modules/ATS/ducks';
import UnImpersonateContainer from 'modules/Common/containers/UnImpersonateContainer';
import { ICurrentSubscription } from 'modules/Common/types';
import { ClientBrandingDataType } from 'modules/Common/types/branding';
import HrModuleLayout from 'modules/HR/containers/HrModuleLayout/HrModuleLayout';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CookiesType, UserRolesType, ReactParent, Routes as CommonRoutes } from 'types';

type HRModuleMainPageProps = ReactParent & {
	clientId: number;
	clientAtsBrandingData: ClientBrandingDataType;
	roles: UserRolesType;
	currentSubscription: ICurrentSubscription;
	getATSBrandingByClientId: (clientId: number) => void;
	getCurrentSubscriptionInfo: () => void;
	logout: (p: () => void) => void;
};

export const HRModuleMainPage: FC<HRModuleMainPageProps> = ({
	children,
	clientId,
	roles,
	logout,
	getCurrentSubscriptionInfo,
	currentSubscription,
	clientAtsBrandingData,
	getATSBrandingByClientId,
}) => {
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);
	const brandingDataEmpty = isEmpty(clientAtsBrandingData) || !clientAtsBrandingData.id;
	const isOnlyHrmoduleUser = roles.isHrEmployee || roles.isHrManager;
	const isHrModuleAvailable = currentSubscription?.trial || currentSubscription?.premium;
	const navigate = useNavigate();

	useEffect(() => {
		if (clientId) {
			getATSBrandingByClientId(clientId);
		}
	}, [clientId]);

	useEffect(() => {
		getCurrentSubscriptionInfo();
	}, []);

	if (!currentSubscription || brandingDataEmpty) {
		return <Spinner fullWidth />;
	}

	if (!isHrModuleAvailable) {
		if (isOnlyHrmoduleUser) {
			logout(() => navigate(CommonRoutes.Login));
		} else {
			const redirectUrl = roles.isAtsFinanceUser
				? `${CommonRoutes.ATS}${CommonRoutes.Finance}`
				: `${CommonRoutes.ATS}${CommonRoutes.Dashboard}`;
			navigate(redirectUrl);
		}
	}

	return (
		<>
			{isImpersonate && <UnImpersonateContainer />}
			<HrModuleLayout brandingData={clientAtsBrandingData} isImpersonate={isImpersonate}>
				<Outlet />
				<>{children}</>
			</HrModuleLayout>
		</>
	);
};

export default connect(
	(state) => ({
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		clientAtsBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		currentSubscription: atsDucks.atsSelectors.getCurrentSubscriptionState(state),
	}),
	{
		getCurrentSubscriptionInfo: atsDucks.atsActions.getClientSubscriptionRequested,
		getATSBrandingByClientId: atsDucks.atsActions.getATSBrandingByClientId,
		logout: unregisteredDucks.unregisteredActions.logOut,
	},
)(HRModuleMainPage);
