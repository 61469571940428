import { ToolbarProps, Navigate } from 'react-big-calendar';

import { Button } from 'antd';

import ArrowNext from 'components/SVG/ArrowNext';
import ArrowPrevious from 'components/SVG/ArrowPrevious';
import { Routes } from 'modules/HR/routes/types';
import { COLORS } from 'theme';

import { Styled } from './HRCalendarCustomToolbar.styled';

export const HRCalendarCustomToolbar: React.FC<ToolbarProps> = (props) => {
	const { label, view, onNavigate } = props;

	return (
		<Styled.Toolbar>
			<Styled.ViewsWrapper>Upcoming week</Styled.ViewsWrapper>
			<Styled.DateRangeWrapper>
				<Button
					className='rbc-btn-group__nav-btn'
					icon={<ArrowPrevious width='7' height='9' fill={COLORS.blue2} />}
					onClick={() => onNavigate(Navigate.PREVIOUS)}
					size='small'
				></Button>
				<Styled.DateRangeLabel className={view}>{label}</Styled.DateRangeLabel>
				<Button
					className='rbc-btn-group__nav-btn'
					icon={<ArrowNext width='9' height='9' fill={COLORS.blue2} />}
					onClick={() => onNavigate(Navigate.NEXT)}
					size='small'
				></Button>
			</Styled.DateRangeWrapper>
			<Styled.Link to={`${Routes.HRModule}${Routes.Calendar}`}>Full Calendar</Styled.Link>
		</Styled.Toolbar>
	);
};
