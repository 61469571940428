import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';

interface ButtonProps {
	minWidth?: number;
	fontSize?: number;
}

export namespace Styled {
	export const ButtonWrap = styled.div`
		width: 60px;
	`;

	export const Button = styled(ButtonComponent)<ButtonProps>`
		line-height: 28px;
		height: 28px;
		min-width: ${({ minWidth }) => `${minWidth ?? 28}px`};
		font-size: ${({ fontSize }) => `${fontSize ?? 12}px`}!important;
		padding: 4px 8px;
		gap: 8px;
	`;
}
