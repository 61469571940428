import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		display: flex;
		flex-direction: column;
		gap: 16px;
	`;

	export const MobileRequestTimeOffButton = styled.button`
		display: flex;
		align-items: center;
		padding: 16px;
		border-radius: 16px;
		background: ${COLORS.white};
		box-shadow: 0px 4px 16px 0px rgba(86, 112, 134, 0.15);
		backdrop-filter: blur(27.182817459106445px);
		border: none;
		transition: box-shadow 0.3s;
		&:hover {
			cursor: pointer;
			box-shadow: 0px 4px 16px 0px rgba(86, 112, 134, 0.25);
		}

		&:active {
			box-shadow: 0px 4px 12px 0px rgba(86, 112, 134, 0.3);
		}
		p {
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
			margin: 0 0 0 8px;
		}

		svg {
			margin-left: auto;
		}
	`;

	export const MobileTabs = styled.div`
		display: flex;
		background: ${COLORS.lightGray3};
		border-radius: 8px;
		margin-bottom: 16px;
	`;

	export const Tab = styled.div<{ active: boolean }>`
		color: ${(props) => (props.active ? COLORS.white : COLORS.gray1)};
		background: ${(props) => (props.active ? COLORS.blue : 'transparent')};
		padding: 4px 12px;
		border-radius: 8px;
		text-align: center;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		width: 50%;
		cursor: pointer;
	`;
}
