import { View, Views } from 'react-big-calendar';

import moment from 'moment';

import {
	BankHolidayType,
	CalendarEventType,
	DateType,
	RequestTimeOffType,
} from 'modules/Common/types/hrModuleTypes';
import {
	CalendarColorsByTypes,
	CalendarEmojisByTypes,
} from 'modules/HR/constants/HRModuleConstants.constants';
import { paletteColors } from 'theme/colors';

export const mapBankHolidaysToEvents = (bankHolidays: BankHolidayType[]): CalendarEventType[] => {
	return bankHolidays.map((holiday) => ({
		id: holiday.id,
		type: holiday.client === null ? 'BANK_HOLIDAY' : 'COMPANY_HOLIDAY',
		name: holiday.name,
		description: holiday.description,
		start: moment(holiday.date).toDate(),
		end: moment(holiday.date).toDate(),
		icon: CalendarEmojisByTypes['PUBLIC_HOLIDAY'],
		isApproved: true,
	}));
};

export const mapTimeOffToEvents = (
	timeOffEvents: RequestTimeOffType[],
	colorMapping: Record<string, string>,
): CalendarEventType[] => {
	return timeOffEvents.map((event) => ({
		id: event.id,
		employeeId: event.employee.id,
		employeeFullName: event.employee.fullName,
		employeePhotoUuid: event.employee.photoRef,
		type: event.type,
		description: event.description,
		start: moment(event.startDate).toDate(),
		end:
			event.lastDayType === 'NONE' || event.lastDayType === 'FULL'
				? moment(event.endDate).add(23, 'hours').toDate()
				: moment(event.endDate).add(12, 'hours').toDate(),
		firstDayType: event.firstDayType,
		lastDayType: event.lastDayType,
		isApproved: event.approved,
		reviewTime: event.reviewTime,
		reviewer: event.reviewer,
		colors: CalendarColorsByTypes[event.type],
		icon: CalendarEmojisByTypes[event.type],
		iconBackgroundColor: colorMapping[event.employee.id] || paletteColors.lightBlue,
	}));
};

export const getStartEndDate = (date: Date, view: View) => {
	const rangeDate = {
		[Views.MONTH]: {
			start: moment(date).startOf('month').toDate(),
			end: moment(date).endOf('month').toDate(),
		},
		[Views.WEEK]: [moment(date).startOf('week').toDate(), moment(date).endOf('week').toDate()],
		[Views.DAY]: [moment(date).toDate()],
	} as never;

	return rangeDate && rangeDate[view];
};

export const validateTypeView = (date: Date[] | DateType, view: View) => {
	let fromDate;
	let toDate;

	if (Array.isArray(date) && view === Views.DAY) {
		toDate = moment(date[0]?.toString()).add(1, 'days');
		fromDate = moment(date[0]?.toString()).subtract(1, 'days');
	} else if (Array.isArray(date) && view === Views.WEEK) {
		toDate = date?.[date?.length - 1];
		fromDate = date?.[0];
	} else if ('start' in date && view === Views.MONTH) {
		fromDate = date?.start;
		toDate = date?.end;
	} else {
		toDate = new Date();
		fromDate = new Date();
	}

	return {
		fromDate: moment(fromDate.toString())?.format('YYYY-MM-DD'),
		toDate: moment(toDate.toString())?.format('YYYY-MM-DD'),
	};
};
