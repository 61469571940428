export const employeeAllowedFields = [
	'birthdayDate',
	'departmentName',
	'email',
	'employeeId',
	'firstName',
	'jobTitle',
	'lastName',
	'photo',
	'startDate',
	'userId',
	'workPhone',
];
