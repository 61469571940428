import { ToolbarProps, Navigate, type View } from 'react-big-calendar';

import { Button, Select } from 'antd';

import ArrowNext from 'components/SVG/ArrowNext';
import ArrowPrevious from 'components/SVG/ArrowPrevious';
import { FilterOptions } from 'modules/HR/constants/HRModuleConstants.constants';
import { COLORS } from 'theme';

import { Styled } from './HRCalendarCustomToolbar.styled';

interface HRCalendarCustomToolbarProps {
	filterValue: string;
	handleFilterChange: (value: string) => void;
}

export const HRCalendarCustomToolbar: React.FC<ToolbarProps & HRCalendarCustomToolbarProps> = (
	props,
) => {
	const { label, view, views, filterValue, handleFilterChange, onNavigate, onView } = props;
	const viewsList = views as View[];

	return (
		<Styled.Toolbar>
			<Styled.ViewsWrapper>
				{viewsList && viewsList.length
					? viewsList.map((name) => (
						<Styled.ViewButton
							key={name}
							className={`${view === name ? 'rbc-active' : ''}`}
							onClick={() => onView(name)}
						>
							{name}
						</Styled.ViewButton>
					  ))
					: null}
			</Styled.ViewsWrapper>
			<Styled.DateRangeWrapper>
				<Button
					className='rbc-btn-group__nav-btn'
					icon={<ArrowPrevious width='7' height='9' fill={COLORS.blue2} />}
					onClick={() => onNavigate(Navigate.PREVIOUS)}
					size='small'
				></Button>
				<Styled.DateRangeLabel className={view}>{label}</Styled.DateRangeLabel>
				<Button
					className='rbc-btn-group__nav-btn'
					icon={<ArrowNext width='9' height='9' fill={COLORS.blue2} />}
					onClick={() => onNavigate(Navigate.NEXT)}
					size='small'
				></Button>
			</Styled.DateRangeWrapper>

			<Styled.FilterWrap>
				<label>
					Time Off Type:{' '}
					<Select
						value={filterValue}
						options={FilterOptions}
						onChange={handleFilterChange}
						placeholder='Time Off Type'
					/>
				</label>
			</Styled.FilterWrap>
		</Styled.Toolbar>
	);
};
