import { MenuList } from 'modules/Common/types';
import { Routes } from 'types';

export const SuperUserMenuList = (isSuperUser: boolean) => [
	{
		id: 101,
		name: MenuList.ClientContext,
		path: `${Routes.BackOffice}${Routes.ClientContext}`,
	},
	{
		id: 1,
		name: MenuList.Dashboard,
		path: `${Routes.BackOffice}${Routes.Dashboard}`,
	},
	{
		id: 2,
		name: MenuList.Clients,
		path: `${Routes.BackOffice}${Routes.Clients}`,
	},
	{
		id: 3,
		name: MenuList.SupportTickets,
		path: `${Routes.BackOffice}${Routes.SupportTickets}`,
	},
	{
		id: 4,
		name: MenuList.Vacancies,
		path: `${Routes.BackOffice}${Routes.Jobs}`,
	},
	{
		id: 5,
		name: MenuList.Questionnaires,
		path: `${Routes.BackOffice}${Routes.Questionnaires}`,
	},
	{
		id: 6,
		name: MenuList.Contracts,
		path: `${Routes.BackOffice}${Routes.Contracts}`,
	},
	{
		id: 7,
		name: MenuList.Invoices,
		path: `${Routes.BackOffice}${Routes.Invoices}`,
	},
	...(isSuperUser
		? [
			{
				id: 8,
				name: MenuList.Financial,
				path: `${Routes.BackOffice}${Routes.FinancialReports}`,
			},
		  ]
		: []),
];

export const SupportMenuList = [
	{
		id: 1,
		name: MenuList.SupportTickets,
		path: `${Routes.BackOffice}${Routes.SupportTickets}`,
	},
];
