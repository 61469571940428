import styled from 'styled-components/macro';

import Button from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section<{ isMobile: boolean }>`
		background: ${COLORS.white};
		border: ${({ isMobile }) => (isMobile ? `1px solid ${COLORS.lightGray2}` : 'none')};
		padding: ${({ isMobile }) => (isMobile ? '16px' : '24px')};
		box-shadow: ${({ isMobile }) =>
		isMobile ? '0px 2px 4px 0px rgba(99, 111, 122, 0.12)' : 'none'};
		border-radius: 10px;
	`;

	export const Header = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
	`;

	export const Title = styled.h4`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		letter-spacing: -0.22px;
		text-transform: capitalize;
	`;

	export const SeeMoreButton = styled(Button)`
		color: ${COLORS.blue};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		padding-right: 0;
		height: auto;
	`;

	export const ButtonBox = styled.div`
		display: flex;
	`;

	export const NoEmployees = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 12px;
		img {
			width: 115px;
			margin-bottom: 24px;
		}
		p {
			color: ${COLORS.darkGray1};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-top: 24px;
			margin-bottom: 24px;
		}
	`;

	export const EmployeeList = styled.div`
		display: flex;
		flex-direction: column;
		gap: 24px;
		max-height: 300px;
		overflow-y: scroll;
	`;

	export const EmployeeWrapper = styled.div`
		display: flex;
		align-items: center;
		gap: 8px;
	`;

	export const EmployeeFullName = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: -0.176px;
		text-transform: capitalize;
	`;

	export const NewEmployeeBadge = styled.div`
		color: ${COLORS.lightBlue1};
		padding: 4px 8px;
		text-align: center;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: 12px;
		border-radius: 2px;
		border: 1px solid rgba(153, 167, 255, 0.4);
		background: rgba(153, 167, 255, 0.1);
	`;
}
