import React, { type FC, useEffect, useState } from 'react';

import Table from 'components/Table';
import ConfirmationModal from 'modules/Common/components/ConfirmationModal';
import { propsFilter } from 'utils/helpers';

import {
	CandidatesApplicationList,
	DELETE_CANDIDATE_MESSAGES,
} from './CandidatesApplicationContainer.constants';
import { columns } from './CandidatesApplicationContainer.entities';
import {
	CandidateApplicationContainerProps,
	DeleteCandidateEnum,
	DeleteCandidateModalType,
} from './CandidatesApplicationContainer.types';

export const CandidatesApplicationContainer: FC<CandidateApplicationContainerProps> = ({
	candidatesData,
	getBackOfficeCandidates,
	deleteBackofficeCandidate,
	pageLink,
	clientId,
	loading,
}) => {
	const [deleteCandidateData, setDeleteCandidateData] = useState<DeleteCandidateModalType | null>(
		null,
	);
	const { data, pageSize, pageIndex, totalCount } = candidatesData || {};

	const filteredData = propsFilter(data, CandidatesApplicationList);

	const handleTableChange = (page: number, size: number) => {
		getBackOfficeCandidates({ page, size });
	};

	const handleCloseModal = () => {
		setDeleteCandidateData(null);
	};

	const handleOpenModal = (candidateData: DeleteCandidateModalType) => {
		setDeleteCandidateData(candidateData);
	};

	const handleDeleteCandidate = () => {
		deleteCandidateData &&
			deleteBackofficeCandidate(
				deleteCandidateData.candidateApplicationId,
				deleteCandidateData.deleteType === DeleteCandidateEnum.DATABASE,
				() => {
					getBackOfficeCandidates({ size: pageSize, page: pageIndex });
					handleCloseModal();
				},
			);
	};

	const columnsData = columns(pageLink, handleOpenModal);

	useEffect(() => {
		getBackOfficeCandidates({ size: pageSize, page: pageIndex });
	}, [clientId]);

	return (
		<>
			<Table
				data={filteredData}
				columns={columnsData}
				pageSize={pageSize}
				current={pageIndex}
				total={totalCount}
				onChange={handleTableChange}
				onPageSizeChange={handleTableChange}
				loading={loading?.getBackofficeCandidatesLoad}
			/>
			<ConfirmationModal
				modalOpen={!!deleteCandidateData}
				title={
					DELETE_CANDIDATE_MESSAGES[deleteCandidateData?.deleteType || DeleteCandidateEnum.DATABASE]
				}
				handleApprove={handleDeleteCandidate}
				handleCloseModal={handleCloseModal}
			/>
		</>
	);
};
