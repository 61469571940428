import React from 'react';

import { Space } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import CreditTag from 'modules/BackOffice/components/CreditTag';
import Tag from 'modules/Common/components/Tag';
import { CreditIcons, SubscriptionTypeColours } from 'modules/Common/constants';
import { TableColumnsType, SubscriptionTypesEnum, ClientTableType } from 'modules/Common/types';
import { COLORS } from 'theme';
import { GenericType, Routes } from 'types';

import { Styled } from './BODashboard.styled';

export const columns = (): ColumnsDataType & TableColumnsType => [
	{
		title: 'Client Name',
		dataIndex: 'clientName',
		key: 'clientName',
		defaultSortOrder: 'ascend',
		render: (name) => name,
	},
	{
		title: 'Active Jobs',
		dataIndex: 'numberOfActiveJobs',
		key: 'numberOfActiveJobs',
		align: 'center',
		sorter: true,
		render: (_, { numberOfActiveJobs }) => numberOfActiveJobs ?? 0,
	},
	{
		title: 'Subscription',
		dataIndex: 'subscription',
		key: 'subscription',
		align: 'center',
		render: (_, { subscription }: GenericType) => {
			const color: SubscriptionTypesEnum = subscription && SubscriptionTypeColours[subscription];

			return (
				<Tag color={color}>
					<>{subscription ?? '-'}</>
				</Tag>
			);
		},
	},
	{
		title: 'Credits',
		key: 'clientCredit',
		dataIndex: 'clientCredit',
		align: 'center',
		render: (
			_,
			{
				numberOfPremiumMultisiteCredits,
				numberOfCvFilteringCredits,
				numberOfGuardianCredits,
				numberOfIndeedCredits,
			}: Partial<ClientTableType>,
		) => (
			<>
				<CreditTag amount={numberOfPremiumMultisiteCredits ?? 0} icon={CreditIcons[1]} />
				<CreditTag amount={numberOfCvFilteringCredits ?? 0} icon={CreditIcons[2]} />
				<CreditTag amount={numberOfGuardianCredits ?? 0} icon={CreditIcons[3]} />
				<CreditTag amount={numberOfIndeedCredits ?? 0} icon={CreditIcons[4]} />
			</>
		),
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		render: (_, { id, clientName }) => (
			<Space size='middle'>
				<Styled.ButtonLink
					to={`${Routes.BackOffice}${Routes.EditClientBranding}/${id}`}
					state={{ clientName, clientId: id }}
				>
					<Styled.Button buttonType={ButtonTypes.default}>Branding</Styled.Button>
				</Styled.ButtonLink>
				<Styled.ButtonLink
					to={`${Routes.BackOffice}${Routes.EditClient}/${id}`}
					state={{ clientName, clientId: id }}
				>
					<Styled.Button buttonType={ButtonTypes.default} icon={<EditIcon fill={COLORS.black} />} />
				</Styled.ButtonLink>
			</Space>
		),
	},
];
