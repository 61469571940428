import React, { type FC } from 'react';

import { ReactParent } from 'types';

import { Styled } from './TimeOffPieChart.styled';

type TimeOffPieChartProps = ReactParent & {
	offsetPercentage?: number;
	color: string;
	percentUsed: number;
	emoji?: string;
	width?: string;
	borderWidth?: string;
	fontSize?: string;
};

const TimeOffStatisticPieChart: FC<TimeOffPieChartProps> = ({
	offsetPercentage = 0,
	percentUsed,
	color,
	emoji,
	width = '60px',
	borderWidth = '7px',
	fontSize = '20px',
}) => {
	return (
		<Styled.Pie
			color={color}
			percentage={percentUsed}
			offsetPercentage={offsetPercentage}
			width={width}
			borderWidth={borderWidth}
			fontSize={fontSize}
		>
			{emoji || `${percentUsed}%`}
		</Styled.Pie>
	);
};

export default TimeOffStatisticPieChart;
