import React, { FC } from 'react';

import CalendarEmpty from 'components/SVG/CalendarEmpty';

import { Styled } from './NoRequests.styled';

interface NoRequestsProps {
	title: string;
}

const NoRequests: FC<NoRequestsProps> = ({ title }) => (
	<Styled.NoRequests>
		<CalendarEmpty width='120px' />
		<p>{title}</p>
	</Styled.NoRequests>
);

export default NoRequests;
