import { type FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Container from 'components/Container';
import Footer from 'modules/Common/components/Footer';
import Header from 'modules/Common/components/Header';
import { IUser } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRolesType, Routes } from 'types';

import { SuperUserMenuList, SupportMenuList } from './BackofficeHeader.constants';
import { S } from './BackofficeLayout.styled';

type BackofficeLayoutProps = {
	user: IUser;
	roles: UserRolesType;
	logout: (p: () => void) => void;
	children: React.ReactNode;
};

export const BackofficeLayout: FC<BackofficeLayoutProps> = ({ user, roles, logout, children }) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const fullWidthRoute = pathname?.includes(`${Routes.BOClientContext}`);

	const menuItems = useMemo(
		() => (roles?.isServiceUser ? SupportMenuList : SuperUserMenuList(roles?.isSuperUser)),
		[],
	);

	const UserMenuList = useMemo(
		() => [
			{
				id: 0,
				name: 'Settings',
				handleClick: () => {
					navigate(`${Routes.BackOffice}${Routes.Settings}`);
				},
			},
			{
				id: 1,
				name: 'Logout',
				handleClick: () => {
					logout(() => navigate(Routes.Login));
				},
			},
		],
		[logout, navigate],
	);

	return (
		<S.Root>
			<>
				<Header subMenuItems={UserMenuList} menuItems={menuItems} user={user} />
				<S.Main>
					<Container noSpaces fullWidth={fullWidthRoute}>
						{children}
					</Container>
					<Footer />
				</S.Main>
			</>
		</S.Root>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
	}),
	{
		logout: unregisteredDucks.unregisteredActions.logOut,
	},
)(BackofficeLayout);
