import React from 'react';
import { useParams } from 'react-router-dom';

import CreateEditEmployee from 'modules/HR/containers/CreateEditEmployee';
import { Routes } from 'modules/HR/routes/types';

import PageNavTitle from '../../../Common/components/PageNavTitle';

import { Styled } from './HROwnProfilePage.styled';

export const HROwnProfilePage = () => {
	const { employeeId } = useParams();

	const navPath = `${Routes.HRModule}${Routes.PeopleManagement}`;

	return (
		<Styled.Root>
			<PageNavTitle title='My Profile' navigationLink={navPath} />
			<CreateEditEmployee isEditMode isOwnProfile employeeId={employeeId} />
		</Styled.Root>
	);
};
