import SelectComponent, { type DefaultOptionType } from 'antd/es/select';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';

interface ButtonProps {
	minWidth?: number;
	fontSize?: number;
}

const { Option: OptionComponent } = SelectComponent;

interface ISelectProps extends DefaultOptionType {
	styles: {
		border: string;
		background: string;
		borderColor: string;
	};
}

export namespace Styled {
	export const ButtonWrap = styled.div`
		width: 60px;
	`;

	export const Button = styled(ButtonComponent)<ButtonProps>`
		line-height: 28px;
		height: 28px;
		min-width: ${({ minWidth }) => `${minWidth ?? 28}px`};
		font-size: ${({ fontSize }) => `${fontSize ?? 12}px`}!important;
		padding: 4px 8px;
		gap: 8px;
	`;

	export const Select = styled(SelectComponent)<ISelectProps>`
		&.ant-select {
			.ant-select-selector {
				min-width: 100px;
				height: 28px;
				line-height: 28px;
				${({ styles }) => styles}
				border-radius: 4px;
				text-align: center;
				font-size: 11px;

				.ant-select-selection-item {
					line-height: 28px;
				}
			}
		}
	`;

	export const CreditsWrap = styled.div`
		section {
			margin: 0 2px;
		}
	`;

	export const Option = styled(OptionComponent)``;

	export const FinanceTableWrap = styled.div`
		.ant-table .ant-table-tbody .ant-table-row td {
			padding: 12px 10px;
			font-size: 12px;
			line-height: 16px;
		}
		.ant-table .ant-table-thead tr th {
			padding: 12px 12px;
			font-size: 10px;
		}
	`;

	export const GenericTableWrap = styled.div`
		.ant-table .ant-table-tbody .ant-table-row td {
			padding: 12px 10px;
			font-size: 14px;
			line-height: 16px;
		}
		.ant-table .ant-table-thead tr th {
			padding: 12px 12px;
			font-size: 12px;
		}
	`;
}
