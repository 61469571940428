import styled from 'styled-components/macro';

export namespace Styled {
	export const FileItem = styled.div`
		display: flex;
		align-items: center;
		border: 1px solid rgb(196, 196, 200);
		padding: 10px;
		height: auto;
		transition: 0.2s;
		margin-bottom: 10px;
		&:hover {
			background-color: transparent;
			box-shadow: rgb(196, 196, 200) 0px 3px 5px 0px;
			cursor: pointer;
		}
	`;

	export const FileName = styled.div`
		width: 90%;
		.ant-upload-list-item {
			border: none !important;
			box-shadow: none !important;
			padding: 0 !important;
			margin-top: 0 !important;
		}
	`;

	export const FileControls = styled.div`
		margin-left: auto;
		display: flex;
		gap: 10px;
		align-items: center;
	`;

	export const DownloadIcon = styled.div`
		cursor: pointer;
	`;

	export const DeleteIcon = styled.div`
		cursor: pointer;
	`;
}
