import React, { memo, useMemo } from 'react';

import TextArea from 'antd/es/input/TextArea';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FormSwitch from 'components/FormSwitch';
import { IActivityNotes, ITicketNote, IUser } from 'modules/Common/types';
import { assignColorsToIds, getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';

import ActivityNote from './ActivityNote';
import TextNote from './TextNote';
import { mergeAndSortByDate } from './TicketNotes.helper';
import { Styled } from './TicketNotes.styled';
import { NoteTypeEnum } from './TicketNotes.types';

interface TicketNotesProps {
	notes: ITicketNote[];
	activities: IActivityNotes[];
	user?: IUser;
	addNoteEnabled?: boolean;
	hideNotVisibleToClient?: boolean;
	handleAddNote?: (note: string, visibleToClient: boolean, cb?: () => void) => void;
}

const TicketNotes: React.FC<TicketNotesProps> = ({
	notes,
	activities,
	user,
	addNoteEnabled = false,
	hideNotVisibleToClient = false,
	handleAddNote,
}) => {
	const [noteText, setNoteText] = React.useState('');
	const [noteVisibleToClient, setNoteVisibleToClient] = React.useState(false);

	const sortedNotes = mergeAndSortByDate(notes, activities, hideNotVisibleToClient);

	const handleNoteVisibilityChange = () => {
		setNoteVisibleToClient(!noteVisibleToClient);
	};

	const addNote = () => {
		handleAddNote?.(noteText, noteVisibleToClient, () => setNoteText(''));
	};

	const senderColors = useMemo(
		() => assignColorsToIds(notes, ['user', 'id'], user?.userId || 0),
		[notes, user?.userId],
	);

	return (
		<Styled.Root>
			<Styled.NotesHeader>
				<h3>Activity</h3>
			</Styled.NotesHeader>
			{sortedNotes.map((note) => {
				const noteType = note.type;

				return noteType === NoteTypeEnum.Activity ? (
					<ActivityNote key={note.id} note={note} senderColors={senderColors} />
				) : (
					<TextNote
						key={note.id}
						note={note}
						senderColors={senderColors}
						isVisibilityLabelHidden={hideNotVisibleToClient}
					/>
				);
			})}
			{addNoteEnabled && (
				<>
					<Styled.NoteForm>
						<Styled.UserIcon bgColor={senderColors[user?.userId || 0]}>
							{getFirstLettersUppercase(user?.fullName || 'user')}
						</Styled.UserIcon>
						<TextArea
							placeholder={'Enter your note'}
							value={noteText}
							autoSize={{ minRows: 4 }}
							onChange={(e) => setNoteText(e.target.value)}
						/>
					</Styled.NoteForm>
					<Styled.ButtonsWrapper>
						<FormSwitch
							name='noteVisibleToClient'
							value={noteVisibleToClient}
							title='Visible to Client'
							onChange={handleNoteVisibilityChange}
						/>
						<Button
							buttonType={ButtonTypes.primary}
							type='button'
							onClick={addNote}
							disabled={noteText.trim().length === 0}
						>
							Add Note
						</Button>
					</Styled.ButtonsWrapper>
				</>
			)}
		</Styled.Root>
	);
};

export default memo(TicketNotes);
