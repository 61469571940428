import React, { Key } from 'react';

import { Tooltip } from 'antd';

import { ButtonLink } from 'components/Button/Button.styled';
import { ColumnsDataType } from 'components/Table/Table.types';
import Tag from 'modules/Common/components/Tag';
import {
	TicketsStatusValuesEnum,
	TicketsStatusColors,
	ITicketsTableValues,
	TicketsStatusEnum,
} from 'modules/Common/types';
import { DataFormatEnum, Routes } from 'types';
import { getDateFormat, removeSpacesUppercaseFirstLetter, truncateString } from 'utils/helpers';

type DataTableType = ITicketsTableValues & {
	key?: Key;
};

export const columns = (): ColumnsDataType => [
	{
		title: 'Ticket type',
		dataIndex: 'ticketType',
		key: 'ticketType',
		render: (_: string, { ticketType }: Partial<DataTableType>) => <span>{ticketType}</span>,
	},
	{
		title: 'Subject',
		dataIndex: 'subject',
		key: 'subject',
		render: (_: string, { subject }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(subject, 60);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '400px' }}>
					<span>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Status',
		dataIndex: 'ticketStatus',
		key: 'ticketStatus',
		align: 'center',
		sorter: true,
		render: (_, { ticketStatus }: Partial<DataTableType>) => {
			const transformedTicketStatus = removeSpacesUppercaseFirstLetter(
				ticketStatus,
			) as TicketsStatusEnum;

			return (
				ticketStatus && (
					<Tag color={String(TicketsStatusColors[transformedTicketStatus])}>
						{TicketsStatusValuesEnum[transformedTicketStatus]}
					</Tag>
				)
			);
		},
	},
	{
		title: 'Creation Date',
		dataIndex: 'creationDate',
		key: 'creationDate',
		align: 'center',
		sorter: true,
		render: (_: string, { creationDate }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(creationDate, DataFormatEnum.FullHoursMinutes) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '54px',
		render: (_, { id }: Partial<DataTableType>) => {
			const route = `${Routes.ATS}${Routes.SupportCenter}/${id}`;

			return <ButtonLink to={route}>View</ButtonLink>;
		},
	},
];
