import React, { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { InboxOutlined } from '@ant-design/icons';
import { DatePicker, Input, UploadFile } from 'antd';
import dayjs from 'dayjs';
import createDecorator from 'final-form-calculate';
import { omit } from 'lodash';
import useMedia from 'use-media';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import DownloadIcon from 'components/SVG/DownloadIcon';
import CollapseList from 'modules/ATS/components/CollapseList';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedEmployeeFilesUploadFormFileFormat } from 'modules/Common/constants';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { RequestTimeOffType } from 'modules/Common/types/hrModuleTypes';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import {
	CalendarEmojisByTypes,
	MOBILE_VIEW_BREAKPOINT,
} from 'modules/HR/constants/HRModuleConstants.constants';
import { COLORS } from 'theme';
import { GenericType } from 'types';
import { handleFileDownload, trimSpacesFormValues } from 'utils/helpers';
import {
	lengthValidator,
	requiredFieldValidator,
	textWithoutNewLinesTabsAndCarriageReturnRegExp,
} from 'utils/validators';

import { dayTypeOptions, filterOptions } from './HRRequestEditTimeOffModal.constants';
import {
	formatDayLabel,
	formatWorkDays,
	prepareFormValues,
} from './HRRequestEditTimeOffModal.helpers';
import { Styled } from './HRRequestEditTimeOffModal.styled';
import {
	CreateTimeOffRequestType,
	GetTimeOffRequestDurationType,
	IPostEditTimeOffEventValues,
	TimeOffRequestDurationType,
	TimeOffStaticsPerEmployeeType,
	UpdateTimeOffRequestType,
} from './HRRequestEditTimeOffModal.types';

const { RangePicker } = DatePicker;

type HRRequestEditTimeOffModalProps = {
	isSuperUser: boolean;
	employeeOptions: { value: number; label: string }[];
	employeeOrRequestId: number | null;
	timeOffStaticsPerEmployee: TimeOffStaticsPerEmployeeType[];
	timeOffEventFormFields: RequestTimeOffType | null;
	dateFormat: string;
	timeOffRequestDuration: TimeOffRequestDurationType;
	loading: GenericType;
	onCancel: () => void;
	handleCalculateTimeOffDuration: (values: GetTimeOffRequestDurationType) => void;
	handleCreateTimeOffRequest?: (formValues: CreateTimeOffRequestType, cb?: () => void) => void;
	handleEditTimeOffEvent?: (formValues: UpdateTimeOffRequestType, cb?: () => void) => void;
	handleEmployeeChange: (id: number) => void;
};

const { TextArea } = Input;

const fileUploadUrl = `${process.env.REACT_APP_API_URL}/hr/time-off-request/file`;

export const HRRequestEditTimeOffModal: FC<HRRequestEditTimeOffModalProps> = ({
	isSuperUser,
	employeeOptions,
	employeeOrRequestId,
	timeOffStaticsPerEmployee,
	timeOffEventFormFields,
	dateFormat,
	timeOffRequestDuration,
	loading,
	onCancel,
	handleCalculateTimeOffDuration,
	handleCreateTimeOffRequest,
	handleEditTimeOffEvent,
	handleEmployeeChange,
}) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [timeOffTypeSelected, setTimeOffTypeSelected] = useState<string | null>(null);
	const [availableDaysOff, setAvailableDaysOff] = useState<number | null>(null);
	const [totalDaysOffRequested, setTotalDaysOffRequested] = useState<number | null>(null);
	const [isSingleDaySelection, setIsSingleDaySelection] = useState<boolean | null>(null);
	const [firstLastDayType, setFirstLastDayType] = useState<{
		startType: string;
		endType: string;
	} | null>(null);
	const [daysListArrayMobile, setDaysListArrayMobile] = useState<{ [key: string]: string }[]>([]);
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);
	const formRef = useRef<unknown>(null);

	const initialValues = useMemo(
		() => ({
			type: null,
			firstDayType: 'FULL',
			lastDayType: 'FULL',
			employee: timeOffEventFormFields?.employee?.id || employeeOrRequestId,
			...(timeOffEventFormFields && prepareFormValues(timeOffEventFormFields)),
		}),
		[timeOffEventFormFields, employeeOrRequestId],
	);

	useEffect(() => {
		if (timeOffEventFormFields?.attachments) {
			const initialFileList = timeOffEventFormFields?.attachments.map((attachment, index) => ({
				uid: `${index}`,
				name: attachment.originalFileName,
				id: attachment.id,
				status: 'done',
				response: {
					data: attachment.ref,
				},
			})) as UploadFile[];
			setFileList(initialFileList);
		}
	}, [timeOffEventFormFields]);

	useEffect(() => {
		if (timeOffTypeSelected && timeOffStaticsPerEmployee.length) {
			const timeOffStatics = timeOffStaticsPerEmployee.find(
				(t) => t.timeOffType === timeOffTypeSelected,
			);
			if (timeOffStatics) {
				if (timeOffStatics.totalDays === null) {
					setAvailableDaysOff(null);
				} else {
					setAvailableDaysOff(timeOffStatics.totalDays - timeOffStatics.usedDays);
				}
			}
		} else {
			setAvailableDaysOff(null);
		}
	}, [timeOffTypeSelected, timeOffStaticsPerEmployee]);

	useEffect(() => {
		if (timeOffRequestDuration?.days && timeOffRequestDuration?.workDays && firstLastDayType) {
			setTotalDaysOffRequested(timeOffRequestDuration.days);
			setDaysListArrayMobile(formatWorkDays(timeOffRequestDuration.workDays, firstLastDayType));
		}
	}, [timeOffRequestDuration]);

	const handleCloseModal = () => {
		formRef.current?.reset();
		setFileList([]);
		onCancel();
	};

	const onSubmit = (values: IPostEditTimeOffEventValues) => {
		const valuesToSend = trimSpacesFormValues({
			...values,
			startDate: dayjs(values.startTimeEndTime[0]).format('YYYY-MM-DD'),
			endDate: dayjs(values.startTimeEndTime[1]).format('YYYY-MM-DD'),
			firstDayType: values.firstDayType,
			lastDayType: dayjs(values.startTimeEndTime[0]).isSame(values.startTimeEndTime[1], 'day')
				? 'NONE'
				: values.lastDayType,
		});

		const valuesToOmit = !timeOffEventFormFields
			? ['startTimeEndTime']
			: ['startTimeEndTime', 'employee'];

		const filteredValuesToSend = omit(valuesToSend, valuesToOmit);

		if (!timeOffEventFormFields) {
			handleCreateTimeOffRequest &&
				handleCreateTimeOffRequest(
					{ ...filteredValuesToSend } as CreateTimeOffRequestType,
					handleCloseModal,
				);
		} else {
			handleEditTimeOffEvent &&
				handleEditTimeOffEvent(
					{ ...filteredValuesToSend, requestId: employeeOrRequestId } as UpdateTimeOffRequestType,
					handleCloseModal,
				);
		}
	};

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData, file) => {
				onChange([...value, responseData]);
			},
			{
				fileList,
				accept: acceptedEmployeeFilesUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					onChange([...value.filter((item) => item !== file?.response?.data)]);
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.Ticket, // should there be a new type created for this? ask the BE team
				maxCount: undefined,
				url: fileUploadUrl,
			},
			setFileList,
		);

	const handleDownload = (file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	};

	const calculateAndSetTotalDaysOff = (allValues: IPostEditTimeOffEventValues) => {
		if (allValues?.startTimeEndTime) {
			const startDate = dayjs(allValues.startTimeEndTime[0]);
			const endDate = dayjs(allValues.startTimeEndTime[1]);
			// Check if start and end dates are the same
			setIsSingleDaySelection(startDate.isSame(endDate, 'day'));
			handleCalculateTimeOffDuration({
				startDate: dayjs(allValues.startTimeEndTime[0]).format('YYYY-MM-DD'),
				endDate: dayjs(allValues.startTimeEndTime[1]).format('YYYY-MM-DD'),
				firstDayType: allValues.firstDayType,
				lastDayType: dayjs(allValues.startTimeEndTime[0]).isSame(
					allValues.startTimeEndTime[1],
					'day',
				)
					? 'NONE'
					: allValues.lastDayType,
			});
			setFirstLastDayType({
				startType: allValues.firstDayType,
				endType: allValues.lastDayType,
			});
		} else {
			setTotalDaysOffRequested(null);
			setDaysListArrayMobile([]);
			setFirstLastDayType(null);
			setIsSingleDaySelection(null);
		}
	};

	const formDecorator = useMemo(
		() =>
			createDecorator(
				{
					field: 'type',
					updates: (value) => {
						setTimeOffTypeSelected(value);

						return {};
					},
				},
				{
					field: 'startTimeEndTime',
					updates: (_, __, allValues) => {
						calculateAndSetTotalDaysOff(allValues);

						return {};
					},
				},
				{
					field: 'firstDayType',
					updates: (_, __, allValues) => {
						calculateAndSetTotalDaysOff(allValues);

						return {};
					},
				},
				{
					field: 'lastDayType',
					updates: (_, __, allValues) => {
						calculateAndSetTotalDaysOff(allValues);

						return {};
					},
				},
				{
					field: 'employee',
					updates: (_, __, allValues) => {
						allValues?.employee && handleEmployeeChange(allValues?.employee);

						return {};
					},
				},
			),
		[],
	) as never;

	const submitButtonIsDisabled = (): boolean => {
		const submitButtonLoading =
			!!loading?.createTimeOffRequestLoad || !!loading?.updateTimeOffRequestLoad;
		const notEnoughDaysOff = availableDaysOff
			? availableDaysOff < (totalDaysOffRequested || 0)
			: false;

		return notEnoughDaysOff || submitButtonLoading;
	};

	return (
		<Styled.Modal
			open={!!employeeOrRequestId}
			footer={null}
			centered
			onCancel={handleCloseModal}
			width='640px'
		>
			<Styled.ModalHead>Add Time-Off</Styled.ModalHead>
			<Styled.ModalContent>
				{availableDaysOff !== null && (
					<Styled.AvailableDaysOffNote>
						{CalendarEmojisByTypes[timeOffTypeSelected || '']} Available days:{' '}
						<span>
							{availableDaysOff === 1 ? `${availableDaysOff} day` : `${availableDaysOff} days`}
						</span>
					</Styled.AvailableDaysOffNote>
				)}
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					decorators={[formDecorator]}
					autoComplete='off'
					render={({ handleSubmit, form }) => {
						formRef.current = form;

						return (
							<form onSubmit={handleSubmit}>
								<Field name='employee' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='employee'
											label='Employee'
											required
											isFixed
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Styled.Select
												{...input}
												value={input.value || null}
												options={employeeOptions}
												placeholder='Select employee'
												disabled={!!timeOffEventFormFields || !isSuperUser}
											/>
										</FieldWrapper>
									)}
								</Field>
								<Field name='type' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='type'
											label='Time-Off type'
											required
											isFixed
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Styled.Select
												{...input}
												value={input.value || null}
												options={filterOptions}
												placeholder='Select type'
											/>
										</FieldWrapper>
									)}
								</Field>
								<Styled.DateRangeLine>
									<Styled.DateRangeLineTitle>
										Date Range <span>*</span>
									</Styled.DateRangeLineTitle>
									<Styled.DateRangeLineMainContent>
										<Styled.DateRangeLineContentColumnsWrap>
											<Styled.DateRangeLineContentColumnTitle>
												From
											</Styled.DateRangeLineContentColumnTitle>
											<Styled.DateRangeLineContentColumnTitle>
												To
											</Styled.DateRangeLineContentColumnTitle>
										</Styled.DateRangeLineContentColumnsWrap>
										<Styled.DateRangeInput isMobile={isMobile}>
											<Field name='startTimeEndTime'>
												{({ input, meta }) => (
													<FieldWrapper
														name='startTimeEndTime'
														errorMessage={meta.submitFailed && meta.touched && meta.error}
														isFixed
													>
														<RangePicker {...input} format={dateFormat} inputReadOnly={false} />
													</FieldWrapper>
												)}
											</Field>
										</Styled.DateRangeInput>
									</Styled.DateRangeLineMainContent>
									{!isMobile && (
										<Styled.DateRangeLineContent>
											<Styled.DateRangeLineContentColumn>
												<Styled.DateRangeLineContentColumnContent>
													<Field name='firstDayType' validate={requiredFieldValidator}>
														{({ input, meta }) => (
															<FieldWrapper
																name='firstDayType'
																errorMessage={meta.submitFailed && meta.touched && meta.error}
															>
																<Styled.RadioGroup
																	{...input}
																	value={input.value}
																	options={dayTypeOptions.map((option) => ({
																		...option,
																		disabled:
																			!isSingleDaySelection &&
																			isSingleDaySelection !== null &&
																			option.value === 'HALF_AM',
																	}))}
																></Styled.RadioGroup>
															</FieldWrapper>
														)}
													</Field>
												</Styled.DateRangeLineContentColumnContent>
											</Styled.DateRangeLineContentColumn>
											<div>-</div>
											<Styled.DateRangeLineContentColumn>
												<Styled.DateRangeLineContentColumnContent>
													<Field name='lastDayType' validate={requiredFieldValidator}>
														{({ input, meta }) => (
															<FieldWrapper
																name='lastDayType'
																errorMessage={meta.submitFailed && meta.touched && meta.error}
															>
																<Styled.RadioGroup
																	{...input}
																	value={isSingleDaySelection ? '' : input.value}
																	options={dayTypeOptions.map((option) => ({
																		...option,
																		disabled:
																			!isSingleDaySelection &&
																			isSingleDaySelection !== null &&
																			option.value === 'HALF_PM',
																	}))}
																	disabled={!!isSingleDaySelection}
																></Styled.RadioGroup>
															</FieldWrapper>
														)}
													</Field>
												</Styled.DateRangeLineContentColumnContent>
											</Styled.DateRangeLineContentColumn>
										</Styled.DateRangeLineContent>
									)}
								</Styled.DateRangeLine>
								<FormBlockLine columns={1}></FormBlockLine>
								<FormBlockLine columns={1}>
									<Field
										name='description'
										validate={lengthValidator(
											0,
											3000,
											textWithoutNewLinesTabsAndCarriageReturnRegExp,
										)}
									>
										{({ input, meta }) => (
											<FieldWrapper
												name='description'
												label='Description'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<TextArea
													{...input}
													placeholder='Include comments for your approver'
													autoSize={{ minRows: 3, maxRows: 3 }}
												/>
											</FieldWrapper>
										)}
									</Field>
								</FormBlockLine>
								<FormBlockLine columns={1}>
									<Field name='attachments'>
										{({ input, meta }) => (
											<FieldWrapper
												isFixed
												name='attachments'
												label='Attachments'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<Styled.Dragger
													{...draggerProps(input)}
													listType='text'
													onPreview={handleDownload}
													showUploadList={{
														showDownloadIcon: true,
														downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
													}}
												>
													<p className='ant-upload-drag-icon'>
														<InboxOutlined />
													</p>
													<p className='ant-upload-text'>Upload a files or drag and drop it</p>
													<p className='ant-upload-hint'>
														{' '}
														PDF, DOC, DOCX, XLS, XLSX, JPEG, PNG up to 15MB{' '}
													</p>
												</Styled.Dragger>
											</FieldWrapper>
										)}
									</Field>
								</FormBlockLine>
								{!isMobile && totalDaysOffRequested && (
									<Styled.TotalRequestedDayOffCalculation>
										Total request: <span>{formatDayLabel(totalDaysOffRequested as number)}</span>
									</Styled.TotalRequestedDayOffCalculation>
								)}
								{isMobile && daysListArrayMobile.length > 0 && (
									<Styled.DayOffCalculationMobile>
										<Styled.DayOffCalculationMobileHeader>
											Time Off Request includes
										</Styled.DayOffCalculationMobileHeader>
										<Styled.DayOffCalculationMobileList>
											{daysListArrayMobile.map((day, index, arr) => {
												const [date, description] = Object.entries(day)[0];
												const isFirst = index === 0;
												const isLast = index === arr.length - 1;
												const durationInput = isFirst || isLast;

												const TitleComponent = (
													<Styled.DayOffCalculationMobileRowTitle>
														<Styled.DayOffCalculationMobileRowDate>
															{date}
														</Styled.DayOffCalculationMobileRowDate>{' '}
														<Styled.DayOffCalculationMobileRowDescription>
															{description}
														</Styled.DayOffCalculationMobileRowDescription>
													</Styled.DayOffCalculationMobileRowTitle>
												);

												const DurationInputComponent = durationInput && (
													<Styled.DayOffCalculationMobileRowDateInput>
														<Field
															name={isFirst ? 'firstDayType' : 'lastDayType'}
															validate={requiredFieldValidator}
														>
															{({ input, meta }) => (
																<FieldWrapper
																	label='Duration'
																	name={isFirst ? 'firstDayType' : 'lastDayType'}
																	errorMessage={meta.submitFailed && meta.touched && meta.error}
																>
																	<Styled.RadioGroup
																		{...input}
																		value={!isFirst && isSingleDaySelection ? '' : input.value}
																		options={dayTypeOptions.map((option) => ({
																			...option,
																			disabled:
																				!isSingleDaySelection &&
																				isSingleDaySelection !== null &&
																				(isFirst
																					? option.value === 'HALF_AM'
																					: option.value === 'HALF_PM'),
																		}))}
																	></Styled.RadioGroup>
																</FieldWrapper>
															)}
														</Field>
													</Styled.DayOffCalculationMobileRowDateInput>
												);

												return (
													<Styled.DayOffCalculationMobileRow key={date}>
														{durationInput ? (
															<CollapseList
																items={[
																	{
																		key: '1',
																		label: TitleComponent,
																		children: DurationInputComponent,
																		showArrow: true,
																	},
																]}
															/>
														) : (
															TitleComponent
														)}
													</Styled.DayOffCalculationMobileRow>
												);
											})}
										</Styled.DayOffCalculationMobileList>
										{totalDaysOffRequested && (
											<Styled.DayOffCalculationMobileFooter>
												<Styled.DayOffCalculationMobileFooterTitle>
													Total request
												</Styled.DayOffCalculationMobileFooterTitle>
												<Styled.DayOffCalculationMobileFooterCalculation>
													{formatDayLabel(totalDaysOffRequested)}
												</Styled.DayOffCalculationMobileFooterCalculation>
											</Styled.DayOffCalculationMobileFooter>
										)}
									</Styled.DayOffCalculationMobile>
								)}
								<Styled.ButtonBox>
									<Button
										type='button'
										buttonType={ButtonTypes.tertiary}
										onClick={() => onCancel()}
									>
										Cancel
									</Button>
									<Button
										type='submit'
										buttonType={ButtonTypes.primary}
										loading={false}
										disabled={submitButtonIsDisabled()}
									>
										{timeOffEventFormFields ? 'Update request' : 'Send Request'}
									</Button>
								</Styled.ButtonBox>
							</form>
						);
					}}
				/>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
