import React from 'react';
import { useNavigate } from 'react-router-dom';

import UserIcon from 'modules/Common/components/UserIcon';
import { IUser } from 'modules/Common/types';
import { COLORS } from 'theme';
import { Routes as CommonRoutes } from 'types';

import { S } from './MobileHeader.styled';
// import MobileMenu from './MobileMenu';

type MobileHeaderProps = {
	user: IUser;
	logout: (p: () => void) => void;
};

const MobileHeader: React.FC<MobileHeaderProps> = ({ user, logout }) => {
	const currentUser = user?.firstName || user?.lastName ? `${user.firstName} ${user.lastName}` : '';
	const navigate = useNavigate();

	const handleLogout = () => {
		logout(() => navigate(CommonRoutes.Login));
	};

	return (
		<>
			<S.Header>
				<UserIcon backgroundColor={COLORS.gray} fullName={currentUser} />
				<S.UserMenu>
					<S.UserProfile>{currentUser}</S.UserProfile>
					<S.UserLogout onClick={handleLogout}>Logout</S.UserLogout>
				</S.UserMenu>
			</S.Header>
			{/* <MobileMenu user={user} logout={logout} /> */}
		</>
	);
};

export default MobileHeader;
