import React, { useCallback, useEffect, useState, type FC } from 'react';
import { connect } from 'react-redux';

import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import Table from 'components/Table';
import { useMount } from 'hooks';
import { atsDucks } from 'modules/ATS/ducks';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants/table';
import { GenericType } from 'types';

import { columns } from './AtsSupportTickets.entities';

type AtsSupportTicketsProps = {
	tickets: {
		data: object[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	loading?: GenericType;
	getTicketsRequested: (params: { active: boolean; page: number; size: number }) => void;
};

const AtsSupportTickets: FC<AtsSupportTicketsProps> = ({
	tickets,
	loading,
	getTicketsRequested,
}) => {
	const {
		data: ticketsData,
		pageIndex = DEFAULT_CURRENT_PAGE,
		pageSize = DEFAULT_PAGE_SIZE,
		totalCount = 0,
	} = tickets || {};

	const [tableParams, setTableParams] = useState({});

	useMount(() => {
		getTicketsRequested({ active: true, page: DEFAULT_CURRENT_PAGE, size: DEFAULT_PAGE_SIZE });
	});

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		getTicketsRequested({
			active: true,
			page: tableParams?.page || 0,
			size: tableParams?.size || DEFAULT_PAGE_SIZE,
			...(tableParams?.sorter?.order &&
				tableParams?.sorter?.field && {
				sort: `${tableParams?.sorter?.field},${
					ordersMap[tableParams?.sorter?.order || 'ascend']
				}`,
			}),
		});
	}, [tableParams]);

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			setTableParams({ ...tableParams, page, size });
		},
		[tableParams],
	);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			sorter,
		});
	};

	const columnsData = columns();

	return (
		<>
			<Table
				pageSize={pageSize}
				current={pageIndex}
				total={totalCount}
				loading={loading?.getTicketsLoad}
				data={ticketsData}
				columns={columnsData}
				onChange={handleTablePaginationChange}
				onPageSizeChange={handleTablePaginationChange}
				onTableChange={handleTableChange}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		tickets: atsDucks.atsSelectors.getAtsTicketsState(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getTicketsRequested: atsDucks.atsActions.geAtsTicketsRequested,
	},
)(AtsSupportTickets);
