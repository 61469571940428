import React, { type FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useMount } from 'hooks';
import ChangePersonalDataContainer from 'modules/BackOffice/containers/ChangePersonalDataContainer';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import { IChangePersonalDataValues, IUserPersonalData } from 'modules/Common/types';
import { GenericType } from 'types';

import { Styled } from './ChangePersonalData.styled';

type ChangePersonalDataProps = {
	userPersonalData: IUserPersonalData;
	getUserPersonalData: () => void;
	updateUserPersonalData: (values: Partial<IChangePersonalDataValues>, cb?: () => void) => void;
	loading: GenericType;
};

const ChangePersonalData: FC<ChangePersonalDataProps> = ({
	userPersonalData,
	getUserPersonalData,
	updateUserPersonalData,
	loading,
}) => {
	useMount(() => {
		getUserPersonalData();
	});

	const navigate = useNavigate();

	const handleSaveUserPersonalData = useCallback((values: IChangePersonalDataValues) => {
		updateUserPersonalData(values, () => navigate(0));
	}, []);

	return (
		<Styled.Root>
			<ChangePersonalDataContainer
				userPersonalData={userPersonalData}
				handleSaveUserPersonalData={handleSaveUserPersonalData}
				loading={!!loading?.changeUserPasswordLoad}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		userPersonalData: backOfficeDucks.backOfficeSelectors.getBOUserPersonalDataState(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getUserPersonalData: backOfficeDucks.backOfficeActions.getBOUserPersonalDataRequested,
		updateUserPersonalData: backOfficeDucks.backOfficeActions.updateBOUserPersonalDataRequested,
	},
)(ChangePersonalData);
