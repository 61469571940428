import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ClntContext } from 'modules/BackOffice/containers/ClientContextContainer/ClientContextContainer';
import CandidatesApplicationContainer from 'modules/BackOffice/containers/СandidatesApplicationContainer';
import { CandidateApplicationContainerType } from 'modules/BackOffice/containers/СandidatesApplicationContainer/CandidatesApplicationContainer.types';
import { Routes } from 'types';

import { Styled } from './ClientContextCandidatesPage.styled';

export const ClientContextCandidatesPage = () => {
	const dispatch = useDispatch();

	const { clientId } = useParams();

	const clientStore = useContext(ClntContext)[clientId];

	const state: Partial<CandidateApplicationContainerType> = {
		loading: useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading),
		candidatesData: useSelector(clientStore?.backOfficeSelectors?.getCandidatesData),
	};

	const actions: Partial<CandidateApplicationContainerType> = {
		getBackOfficeCandidates: (params) =>
			dispatch(
				clientStore?.backOfficeActions?.getBackOfficeCandidatesRequested(
					{ ...params, client: clientId },
					true,
				),
			),
		deleteBackofficeCandidate: (id: number, deleteFromDatabase: boolean, callback: () => void) =>
			dispatch(
				clientStore?.backOfficeActions?.deleteCandidateByIdRequested(
					id,
					deleteFromDatabase,
					callback,
				),
			),
	};

	return (
		<>
			<Styled.Head>
				<Styled.Title>Candidates</Styled.Title>
			</Styled.Head>
			<Styled.Content>
				<CandidatesApplicationContainer
					pageLink={`${Routes.BOClientContext}/${clientId}`}
					clientId={clientId}
					isClientContext
					{...state}
					{...actions}
				/>
			</Styled.Content>
		</>
	);
};

export default ClientContextCandidatesPage;
