import React, { useState, type FC } from 'react';

import { InboxOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';

import { ButtonTypes } from 'components/Button';
import HRThemedButton from 'modules/HR/components/HRThemedButton';

import { Styled } from './ImportHolidaysModal.styled';

type ImportHolidaysModalProps = {
	isOpen: boolean;
	draggerProps: (override: boolean) => object;
	onCancel: () => void;
};

export const ImportHolidaysModal: FC<ImportHolidaysModalProps> = ({
	isOpen,
	draggerProps,
	onCancel,
}) => {
	const [overridePreviousHolidays, setOverridePreviousHolidays] = useState(false);

	return (
		<Styled.Modal open={!!isOpen} footer={null} centered onCancel={onCancel} width='auto'>
			<Styled.ModalHead>
				<h3>Import Custom Holidays</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Styled.Dragger
					{...draggerProps(overridePreviousHolidays)}
					listType='text'
					showUploadList={{
						showDownloadIcon: false,
						showRemoveIcon: false,
					}}
				>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>Upload a files or drag and drop it</p>
					<p className='ant-upload-hint'>iCal, ICalendar</p>
				</Styled.Dragger>
				<Styled.CheckboxWrapper>
					<Checkbox
						checked={overridePreviousHolidays}
						onChange={(e) => setOverridePreviousHolidays(e.target.checked)}
					>
						Override existing custom holidays
					</Checkbox>
				</Styled.CheckboxWrapper>
				<Styled.ButtonBox>
					<HRThemedButton type='button' buttonType={ButtonTypes.tertiary} onClick={onCancel}>
						Cancel
					</HRThemedButton>
				</Styled.ButtonBox>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
