import React, { FC } from 'react';

import ArrowRight from 'components/SVG/ArrowRight';
import { COLORS } from 'theme';

import { RequestTabType } from '../HRDashboard.types';

import { Styled } from './HRDashboardMobileComponents.styled';

interface HRDashboardMobileComponentsProps {
	selectedTab: string;
	handleTabChange: (tab: RequestTabType) => void;
	employeeRequestsNumber: number;
	handleOpenModal: () => void;
}

const HRDashboardMobileComponents: FC<HRDashboardMobileComponentsProps> = ({
	selectedTab,
	handleTabChange,
	employeeRequestsNumber,
	handleOpenModal,
}) => (
	<Styled.Root>
		<Styled.MobileRequestTimeOffButton onClick={handleOpenModal}>
			🌴 <p>Request Time Off</p> <ArrowRight width='16' fill={COLORS.darkGray2} />{' '}
		</Styled.MobileRequestTimeOffButton>
		<Styled.MobileTabs>
			<Styled.Tab onClick={() => handleTabChange('requests')} active={selectedTab === 'requests'}>
				Requests ({employeeRequestsNumber})
			</Styled.Tab>
			<Styled.Tab onClick={() => handleTabChange('calendar')} active={selectedTab === 'calendar'}>
				Calendar
			</Styled.Tab>
		</Styled.MobileTabs>
	</Styled.Root>
);

export default HRDashboardMobileComponents;
