import React, { type FC, useCallback, useRef } from 'react';
import { Field, Form } from 'react-final-form';

import { DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import { EmployeeStatusTypesFormEnum } from 'modules/Common/types/employee';
import { DataFormatEnum, IOption } from 'types';
import { trimSpacesFormValues } from 'utils/helpers';
import { requiredFieldValidator } from 'utils/validators';

import { disabledDateHelper, getDateLabelName } from '../HREmployeesList.helpers';
import { ChangeActionEnum, StatusModalInfoProps } from '../HREmployeesList.types';

import { Styled } from './EmployeesStatusModal.styled';

type FormValues = {
	date?: string;
};

type EmployeesStatusModalProps = {
	statusInfo: StatusModalInfoProps;
	employeeStatusesOptions: IOption[];
	rootClassName?: string;
	className?: string;
	loading?: boolean;
	onCancel: () => void;
	handleModalSubmit: (
		changeAction: ChangeActionEnum,
		employeeId: number,
		values: { employeeStatus: EmployeeStatusTypesFormEnum; startDate?: string; leaveDate?: string },
		cb?: () => void,
	) => void;
};

export const EmployeesStatusModal: FC<EmployeesStatusModalProps> = ({
	statusInfo,
	employeeStatusesOptions,
	onCancel,
	rootClassName = '',
	className = '',
	loading,
	handleModalSubmit,
}) => {
	const { changeAction, startStatus, endStatus } = statusInfo || {};

	const endStatusNormalized = endStatus
		? endStatus
		: changeAction === ChangeActionEnum.Archive
			? EmployeeStatusTypesFormEnum.Left
			: EmployeeStatusTypesFormEnum.Current;

	const dataFormat = DataFormatEnum.UK ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
	const formRef = useRef<FormApi<FormValues, Partial<FormValues>> | null>(null);

	const getStatusName = () => {
		const statusObj = employeeStatusesOptions.find((s) => s.id === endStatusNormalized);

		return statusObj?.name;
	};

	const dateLabelName =
		(startStatus && endStatusNormalized && getDateLabelName(startStatus, endStatusNormalized)) ||
		(changeAction === ChangeActionEnum.Archive && 'Leaving Date');
	const endStatusName = getStatusName();
	const modalTitle =
		changeAction !== ChangeActionEnum.ChangeStatus
			? `Are you sure you want to ${changeAction} the employee?`
			: `Are you sure you want to change status to ${endStatusName}?`;
	const showDateInputField = !!dateLabelName;

	const onSubmit = (values: FormValues) => {
		const updatedValues = trimSpacesFormValues({
			employeeStatus: endStatusNormalized,
			...(values.date
				? {
					[dateLabelName === 'Start Date' ? 'startDate' : 'leaveDate']: dayjs(values.date).format(
						'YYYY-MM-DD',
					),
				  }
				: {}),
		});

		handleModalSubmit(
			changeAction as ChangeActionEnum,
			statusInfo?.employeeId as number,
			updatedValues,
			() => formRef.current && formRef.current.reset(),
		);
	};

	const handleCancel = useCallback((form: FormApi<FormValues, Partial<FormValues>>) => {
		form.reset();
		onCancel();
	}, []);

	return (
		<Modal
			open={!!statusInfo}
			footer={null}
			centered
			onCancel={() => formRef.current && handleCancel(formRef.current)}
			width='auto'
			rootClassName={rootClassName}
			className={className}
		>
			<Styled.ModalHead>
				<h3>{modalTitle}</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Form
					onSubmit={onSubmit}
					initialValues={{}}
					autoComplete='off'
					render={({ handleSubmit, submitting, form }) => {
						formRef.current = form;

						return (
							<form onSubmit={handleSubmit}>
								{showDateInputField && (
									<Styled.FieldsBlock>
										<Field name='date' validate={requiredFieldValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name='date'
													label={dateLabelName}
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													required={showDateInputField}
												>
													<DatePicker
														{...input}
														format={dataFormat}
														disabledDate={(current) =>
															disabledDateHelper(current, endStatusNormalized)
														}
													/>
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldsBlock>
								)}
								<Styled.ButtonBox>
									<Button
										type='button'
										buttonType={ButtonTypes.tertiary}
										onClick={() => handleCancel(form)}
									>
										Cancel
									</Button>
									<Button
										type='submit'
										buttonType={ButtonTypes.primary}
										disabled={submitting || loading}
										loading={submitting || loading}
									>
										Yes
									</Button>
								</Styled.ButtonBox>
							</form>
						);
					}}
				/>
			</Styled.ModalContent>
		</Modal>
	);
};
