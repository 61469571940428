import React, { useCallback, useState, type FC } from 'react';
import { connect } from 'react-redux';

import useMedia from 'use-media';

import { useMount } from 'hooks';
import { atsDucks } from 'modules/ATS/ducks';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import { hrDucks } from 'modules/HR/ducks';

import EmployeeView from './EmployeeView/EmployeeView';
import { Styled } from './HRDashboard.styled';
import { HRDashboardProps, RequestTabType } from './HRDashboard.types';
import { employeeRequestOffDummyData, statisticDummyData } from './HRDashboardDummyData';
import HRDashboardMobileComponents from './HRDashboardMobileComponents/HRDashboardMobileComponents';
import HRView from './HRView/HRView';

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const HRDashboard: FC<HRDashboardProps> = ({
	isSuperUser,
	userId,
	brandingData,
	employeeList,
	isRequestModalOpen,
	loading,
	handleOpenRequestModal,
	handleCloseRequestModal,
	getEmployeeList,
}) => {
	const [selectedTab, setSelectedTab] = useState<RequestTabType>('requests');
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);
	const statisticData = statisticDummyData;
	const employeeRequests = employeeRequestOffDummyData;

	useMount(() => {
		isSuperUser && employeeList.length === 0 && getEmployeeList();
	});

	const handleMobileTabChange = useCallback((tab: RequestTabType) => {
		setSelectedTab(tab);
	}, []);

	const handleApproveAllRequests = () => {
		// approveAllRequests();
	};

	const logoUrl = brandingData?.logo?.split('/').includes('images')
		? brandingData?.logo
		: brandingData?.logo && `${baseUrl}${brandingData?.logo}`;

	const showRequestTab = !isMobile || selectedTab === 'requests';
	const showCalendarTab = !isMobile || selectedTab === 'calendar';

	return (
		<Styled.Root>
			{isMobile && (
				<HRDashboardMobileComponents
					selectedTab={selectedTab}
					handleTabChange={handleMobileTabChange}
					employeeRequestsNumber={employeeRequests.length}
					handleOpenModal={() => handleOpenRequestModal()}
				/>
			)}
			{!isMobile && (
				<Styled.SubHeader>
					<Styled.Logo>
						<img src={logoUrl} alt='Client Logo' />
					</Styled.Logo>
					<Styled.CompanyName textColor={brandingData.textColour}>
						{brandingData?.clientName}
					</Styled.CompanyName>
				</Styled.SubHeader>
			)}
			{isSuperUser ? (
				<HRView
					isRequestModalOpen={isRequestModalOpen}
					showRequestTab={showRequestTab}
					showCalendarTab={showCalendarTab}
					statisticData={statisticData}
					employeeData={employeeList}
					employeeRequests={employeeRequests}
					loading={loading}
					handleApproveAllRequests={handleApproveAllRequests}
					handleOpenRequestModal={handleOpenRequestModal}
					handleCloseRequestModal={handleCloseRequestModal}
				/>
			) : (
				<EmployeeView
					isRequestModalOpen={isRequestModalOpen}
					showRequestTab={showRequestTab}
					showCalendarTab={showCalendarTab}
					statisticData={statisticData}
					employeeRequests={employeeRequests}
					employeeViewUpcomingVacationsRequests={employeeRequests}
					userId={userId}
					handleOpenRequestModal={handleOpenRequestModal}
					handleCloseRequestModal={handleCloseRequestModal}
				/>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		brandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
		employeeList: hrDucks.hrSelectors.getEmployeeShortList(state),
	}),
	{
		getEmployeeList: hrDucks.hrActions.getEmployeeShortListRequested,
	},
)(HRDashboard);
