import { message } from 'antd';
import _, { omit } from 'lodash';

import { GenericType } from 'types';

import { statusOptions } from '../../ATS/containers/CandidatesApplication/CandidatesApplication.constants';
import { socialNetworksIconsMap } from '../constants';
import { AssignCandidateToVacancyDatabaseResponseType, colorPropsMap, regType } from '../types';
import { SocialNetworkDataType, SocialNetworksType } from '../types/socialNetworks';

export const prepareSocialNetworksData = (
	socialNetworks: Partial<SocialNetworksType>,
): SocialNetworkDataType[] =>
	Object.keys(socialNetworks).reduce((acc: SocialNetworkDataType[], cur: string, idx: number) => {
		if (socialNetworks[cur as keyof SocialNetworksType]) {
			const newItem = {
				id: idx,
				icon: socialNetworksIconsMap[cur as keyof typeof socialNetworksIconsMap],
				url: socialNetworks[cur as keyof SocialNetworksType],
			} as SocialNetworkDataType;

			acc = [...acc, newItem];
		}

		return acc;
	}, []);

export const cleanUpCreditAndPrices = (values: GenericType) =>
	Object.keys(values)?.reduce((acc: GenericType, cur) => {
		if (cur?.startsWith('credit-') || cur?.startsWith('price-')) {
			acc[cur] = 0;

			return acc;
		}

		return acc;
	}, {});

type AssessmentQuestionsType = {
	id: string;
	orderInAssessment: number;
	questionType: string;
	questionText: string;
	assessmentAnswers: [
		{
			id: string;
			answer: string;
		},
	];
};

export const prepareAssessmentData = (
	assessmentQuestions: AssessmentQuestionsType[],
	jobDataId: string,
) => {
	return {
		assessmentQuestions: assessmentQuestions?.map((item: AssessmentQuestionsType) => ({
			...omit(item, ['id', 'orderInAssessment']),
			job: jobDataId,
			assessmentAnswers: item?.assessmentAnswers?.map((a) => omit(a, ['id'])),
		})),
	};
};

export const assignColorsToIds = <T>(
	arr: T[],
	propPath: string | string[],
	additionalKey?: number | string,
): Record<string, string> => {
	const colors = [
		'#FFD700',
		'#FFB6C1',
		'#6A5ACD',
		'#7FFFD4',
		'#FFA07A',
		'#20B2AA',
		'#778899',
		'#DB7093',
		'#98FB98',
		'#87CEFA',
	];
	const getValueByPath = (obj: T, path: string | string[]): unknown => {
		const properties = Array.isArray(path) ? path : [path];

		// @ts-ignore
		return properties.reduce((acc, prop) => acc && acc[prop], obj);
	};

	const uniqueNames = new Set(
		arr?.map((item) => getValueByPath(item, propPath))?.filter((id) => id != null),
	);

	if (additionalKey) {
		uniqueNames.add(additionalKey as T[keyof T]);
	}

	const nameColors: Record<string, string> = {};
	Array.from(uniqueNames).forEach((name, index) => {
		nameColors[name as string] = colors[index % colors.length];
	});

	return nameColors;
};

export const getFirstLettersUppercase = (name: string | undefined) => {
	if (!name) return 'US';
	const [first, last] = name.split(/\s+/);
	const firstLetter = first?.[0];
	const lastLetter = last?.[0];
	if (!firstLetter || !lastLetter) return name.slice(0, 2).toUpperCase();

	return `${firstLetter}${lastLetter}`.toUpperCase();
};

export const getColorProps = (statusName: string) => {
	return colorPropsMap[statusName] || colorPropsMap['New'];
};

export const handleJobAssignNotificationMessages = (
	response: AssignCandidateToVacancyDatabaseResponseType,
) => {
	if (response?.failedToAssignJobs?.length) {
		const listOfFailedJobs = response.failedToAssignJobs.map((job) => job.title).join(', ');
		message.error(`Failed to assign candidate to jobs: ${listOfFailedJobs}`);
	}
	if (response?.alreadyAssignedJobs?.length) {
		const listOfAlreadyAssignedJobs = response.alreadyAssignedJobs
			.map((job) => job.title)
			.join(', ');
		message.error(
			`Failed to assign candidate to already assigned jobs: ${listOfAlreadyAssignedJobs}`,
		);
	}
	if (response?.createdCandidateApplications?.length) {
		message.success(
			'The candidate has been assigned to a job. You can find them in the job’s applicants',
		);
	}
};

export const searchCandidates = (key, data) =>
	data?.reduce((acc, job) => {
		if (key === '') {
			acc.push(job);

			return acc;
		}
		if (job?.candidates?.length) {
			const candidates = job?.candidates?.filter((candidate) => {
				const searchTermLower = key.toLowerCase();
				const candidateTermLower = candidate?.name?.toLowerCase();

				return candidateTermLower?.includes(searchTermLower);
			});

			acc.push({ ...job, candidates });
		}

		return acc;
	}, []);

export const sortJobsByCandidateStatus = (data) =>
	data?.reduce((accumulator, item) => {
		const addedKeys = [];

		item?.candidates?.forEach((c) => {
			const candidateState = statusOptions.find(
				(status) => status.value === c?.candidateState?.value,
			);
			const key = candidateState?.name?.replace(/\s+/g, '');

			if (!accumulator[key]) {
				accumulator[key] = [];
			}

			if (!addedKeys.includes(key)) {
				const updatedItem = {
					...item,
					candidates: item.candidates.filter(
						(lc) => _.isObject(lc.candidateState) && lc.candidateState?.id === candidateState?.id,
					),
				};

				accumulator[key].push(updatedItem);
			}
			addedKeys.push(key);
		});

		return accumulator;
	}, {});

export const checkMaxFileSize = (file: File, maxSize: number) => {
	const { size } = file;
	const isLessThanMaxSize = size / 1024 / 1024 < maxSize;
	if (!isLessThanMaxSize) {
		message.error(`File must be smaller than ${maxSize}MB!`);
	}

	return isLessThanMaxSize;
};

function matchUserAgent(ua: regType, rules: (string | RegExp)[][]) {
	// eslint-disable-next-line
	return rules.find(([_, regex]) => regex.exec(ua));
}

function detectOS(ua: regType, rules: (string | RegExp)[][]) {
	// eslint-disable-next-line
	return rules.find(([_, regex]) => regex.exec(ua));
}

export const browserDetect = (
	userAgentRules: (string | RegExp)[][],
	operatingSystemRules: (string | RegExp)[][],
) => {
	const userAgent = navigator.userAgent;
	const matchedRule = matchUserAgent(userAgent, userAgentRules);
	if (!matchedRule) return null;
	const name = matchedRule[0];
	const os = detectOS(userAgent, operatingSystemRules)?.[0];

	return { name, os };
};

export const displayCandidateStatusUpdateErrors = (
	errorObject: {
		upcomingInterviewErrors: string[];
		otherErrors: string[];
	},
	allCandidateIdsLength: number,
) => {
	const { upcomingInterviewErrors, otherErrors } = errorObject;

	const createMessage = (
		count: number,
		total: number,
		singleMessage: string,
		multipleMessage: string,
	) => {
		const messageToBeUsed = count === 1 ? singleMessage : multipleMessage;

		return `${count} of ${total} selected items ${messageToBeUsed}`;
	};

	if (allCandidateIdsLength === 1) {
		if (upcomingInterviewErrors.length) {
			message.info('This candidate has an upcoming interview, please cancel it to change status');
		} else {
			message.info('An error occurred when updating the candidate status');
		}
	} else {
		if (upcomingInterviewErrors.length) {
			message.info(
				createMessage(
					upcomingInterviewErrors.length,
					allCandidateIdsLength,
					'has an upcoming interview, please cancel it to change status',
					'have upcoming interviews, please cancel them to change status',
				),
			);
		}
		if (otherErrors.length) {
			message.info(
				createMessage(
					otherErrors.length,
					allCandidateIdsLength,
					'has an error when updating status',
					'have errors when updating status',
				),
			);
		}
	}
};
