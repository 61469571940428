import { Modal as ModalAntd } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Modal = styled(ModalAntd)`
		.ant-modal-content {
			padding: 24px;
			width: 630px;
			max-width: 100%;
		}
	`;
	export const ModalHead = styled.div`
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
		font-size: 16px;
	`;

	export const ModalContent = styled.div`
		max-width: 100%;
	`;

	export const List = styled.div``;

	export const Item = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		line-height: 24px;
		display: flex;
		margin-bottom: 16px;
		gap: 20px;
	`;

	export const ItemTitle = styled.div`
		font-weight: 600;
		min-width: 250px;
	`;

	export const ItemDate = styled.div`
		width: 120px;
	`;

	export const ItemDay = styled.div``;

	export const ButtonBox = styled.div`
		display: flex;
		flex-direction: row-reverse;
		width: 100%;
	`;
}
