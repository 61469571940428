import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Calendar,
	DateFormat,
	DateLocalizer,
	momentLocalizer,
	ToolbarProps,
	type View,
	Views,
} from 'react-big-calendar';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment-timezone';
import useMedia from 'use-media';

import Box from 'components/Box';
import Container from 'components/Container';
import { useMount } from 'hooks';
import {
	CalendarEventType,
	DateType,
	RequestTimeOffType,
} from 'modules/Common/types/hrModuleTypes';
import { assignColorsToIds } from 'modules/Common/utils/commonHelpers';
import DeclineRequestTimeOffModal from 'modules/HR/components/DeclineRequestTimeOffModal';
import HRCalendarCustomDateHeader from 'modules/HR/components/HRCalendarCustomDateHeader';
import HRCalendarCustomEvent from 'modules/HR/components/HRCalendarCustomEvent';
import HRRequestEditTimeOffModal from 'modules/HR/components/HRRequestEditTimeOffModal';
import {
	CreateTimeOffRequestType,
	GetTimeOffRequestDurationType,
	UpdateTimeOffRequestType,
} from 'modules/HR/components/HRRequestEditTimeOffModal/HRRequestEditTimeOffModal.types';
import { hrDucks } from 'modules/HR/ducks';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { paletteColors } from 'theme/colors';
import { UserRolesEnum } from 'types';
import { getCountryDateFormate } from 'utils/helpers';

import { mapBankHolidaysToEvents, mapTimeOffToEvents } from '../HRCalendar/HRCalendar.helpers';

import { HRCalendarCustomToolbar } from './HRCalendarCustomToolbar/HRCalendarCustomToolbar';
import { Styled } from './HRCalendarWeekly.styled';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { HRCalendarWeeklyProps } from './HRCalendarWeekly.types';
import WeeklyCalendarMobile from './HRCalendarWeeklyMobile';

const defaultTZ = moment.tz.guess();

moment.updateLocale('en', {
	week: {
		dow: 1,
		doy: 1,
	},
});

const getStartEndDate = (date: Date, view: View) => {
	const rangeDate = {
		[Views.WEEK]: [moment(date).startOf('week').toDate(), moment(date).endOf('week').toDate()],
	} as never;

	return rangeDate && rangeDate[view];
};

const HRCalendarWeekly: FC<HRCalendarWeeklyProps> = ({
	showCalendarTab,
	isRequestModalOpen,
	userInfo,
	timeOffList,
	timeOffRequestDuration,
	timeOffStaticsPerEmployee,
	bankHolidays,
	countries,
	employeeOptions,
	loading,
	handleEditRequestModalOpen,
	handleCloseRequestModal,
	getAllTimeOffRequestsRequested,
	getTimeOffRequestDurationRequested,
	getTimeOffStaticsPerEmployeeRequested,
	createTimeOffRequestRequested,
	updateTimeOffRequestRequested,
	getBankHolidaysRequested,
	getEmployeeOptionsRequested,
	approveTimeOffRequestRequested,
	declineTimeOffRequestRequested,
	deleteTimeOffRequestRequested,
}) => {
	const [events, setEvents] = useState<CalendarEventType[] | []>([]);
	const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<number | null>(null);
	const [currentEvent, setCurrentEvent] = useState<RequestTimeOffType | null>(null);
	const isDesktopView = useMedia({ minWidth: '768px' });
	const dateFormat = getCountryDateFormate(countries.isUK);
	const { roleId } = userInfo || {};
	const isSuperUser = [UserRolesEnum.HR_MANAGER, UserRolesEnum.JAT_SUPER_USER].includes(roleId);

	const {
		defaultDate,
		defaultView,
		scrollToTime,
		views,
		formats,
		getNow,
		localizerDate,
		validateTypeView,
	} = useMemo(() => {
		moment.tz.setDefault(defaultTZ);

		return {
			defaultDate: moment().toDate(),
			defaultView: Views.WEEK,
			getNow: () => moment().toDate(),
			localizerDate: momentLocalizer(moment),
			formats: {
				dayFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
					localizer.format(date, 'ddd DD', culture),
				dayRangeHeaderFormat: (
					{ start, end }: DateType,
					culture: string,
					localizer: DateLocalizer,
				): DateFormat =>
					localizer.format(start, 'MMM ', culture) +
					localizer.format(start, 'D', culture) +
					' - ' +
					localizer.format(end, 'D', culture) +
					localizer.format(start, ', YYYY', culture),
			},
			scrollToTime: moment().toDate(),
			views: [Views.WEEK],
			validateTypeView: (date: Date[] | DateType, view: View) => {
				let fromDate;
				let toDate;

				if (Array.isArray(date)) {
					fromDate = moment(date[0]);
					toDate = moment(date[1]);
				} else {
					fromDate = new Date();
					toDate = new Date();
				}

				return {
					fromDate: moment(fromDate.toString())?.format('YYYY-MM-DD'),
					toDate: moment(toDate.toString())?.format('YYYY-MM-DD'),
				};
			},
		};
	}, []);

	const [currentDate, setDate] = useState<Date>(defaultDate);

	const handleOpenDeclineModal = useCallback(
		(id: number) => {
			setIsDeclineModalOpen(id);
		},
		[currentDate, defaultView],
	);

	const handleEventDecline = useCallback(
		({ requestId, comment }: { requestId: number; comment?: string }, cb?: () => void) => {
			declineTimeOffRequestRequested({ requestId, comment }, () => {
				handleNavigate(currentDate, defaultView);
				cb && cb();
			});
		},
		[currentDate, defaultView],
	);

	const handleEventApprove = useCallback(
		(id: number) => {
			approveTimeOffRequestRequested(id, () => handleNavigate(currentDate, defaultView));
		},
		[currentDate, defaultView],
	);

	const handleOpenRequestModal = useCallback(
		(id: number) => {
			const currentTimeOffEvent = timeOffList.find((timeOffEvent) => timeOffEvent.id === id);
			if (currentTimeOffEvent) {
				getTimeOffStaticsPerEmployeeRequested({ employeeId: currentTimeOffEvent.employee.id });
				setCurrentEvent(currentTimeOffEvent);
				handleEditRequestModalOpen(id);
			}
		},
		[timeOffList],
	);

	const handleCloseDeclineModal = () => {
		setIsDeclineModalOpen(null);
	};

	const handleCreateTimeOffRequest = (values: CreateTimeOffRequestType, cb?: () => void) => {
		createTimeOffRequestRequested(values, () => {
			handleNavigate(currentDate, defaultView);
			cb && cb();
		});
	};

	const handleEditTimeOffRequest = (values: UpdateTimeOffRequestType, cb?: () => void) => {
		updateTimeOffRequestRequested(values, () => {
			handleNavigate(currentDate, defaultView);
			cb && cb();
		});
	};

	const handleCalculateTimeOffDuration = (values: GetTimeOffRequestDurationType) => {
		getTimeOffRequestDurationRequested(values);
	};

	const handleDeleteTimeOffRequest = (id: number) => {
		deleteTimeOffRequestRequested(id, () => handleNavigate(currentDate, defaultView));
	};

	const components = useMemo(
		() => ({
			event: (props: React.JSX.IntrinsicAttributes & { event: CalendarEventType }) => (
				<HRCalendarCustomEvent
					{...props}
					handleEventDecline={handleOpenDeclineModal}
					handleEventApprove={handleEventApprove}
					handleEventEdit={handleOpenRequestModal}
					handleEventDelete={handleDeleteTimeOffRequest}
				/>
			),
			toolbar: (toolbarProps: ToolbarProps) => <HRCalendarCustomToolbar {...toolbarProps} />,
			// eslint-disable-next-line camelcase
			week: {
				header: HRCalendarCustomDateHeader,
			},
			dateCellWrapper: (props) => {
				return props.children;
			},
		}),
		[timeOffList, timeOffStaticsPerEmployee, timeOffRequestDuration, currentDate, defaultView],
	);

	// navigate in the calendar (ADD UPDATE)
	const handleNavigate = useCallback(
		(date: Date, view: View) => {
			const currentRange = getStartEndDate(date, view);
			const { fromDate, toDate } = currentRange && validateTypeView(currentRange, view);
			setDate(date);

			if (fromDate && toDate) {
				getAllTimeOffRequestsRequested({ startDate: fromDate, endDate: toDate });
			}
		},
		[currentDate, setDate],
	);

	const handleCalendarChange = useCallback((date: Date[] | DateType, view: View) => {
		const { fromDate, toDate } = validateTypeView(date, view);

		if (fromDate && toDate) {
			getAllTimeOffRequestsRequested({ startDate: fromDate, endDate: toDate });
		}
	}, []);

	const handleEmployeeChange = useCallback((id: number) => {
		getTimeOffStaticsPerEmployeeRequested({ employeeId: id });
	}, []);

	const eventPropGetter = useCallback((event: CalendarEventType) => {
		const showDefaultColors = event.isApproved;

		return {
			style: {
				backgroundColor: showDefaultColors
					? event?.colors?.bgColor || paletteColors.lightBlue
					: 'transparent',
				color: showDefaultColors ? event?.colors?.color || paletteColors.blue : paletteColors.red,
				border: showDefaultColors ? 'none' : `1px solid ${paletteColors.red}`,
				borderRadius: '6px',
			},
		};
	}, []);

	useEffect(() => {
		const colorMapping = assignColorsToIds(timeOffList, ['employeeId']);

		const mappedBankHolidays = mapBankHolidaysToEvents(bankHolidays);
		const mappedEvents = mapTimeOffToEvents(timeOffList, colorMapping);

		setEvents([...mappedEvents, ...mappedBankHolidays] as CalendarEventType[]);
	}, [timeOffList, currentDate, bankHolidays]);

	useMount(() => {
		getEmployeeOptionsRequested();
		getBankHolidaysRequested({ onlyCustom: false, expandRecurring: true });
		const datesObject = {
			startDate: moment(defaultDate).startOf('week').format('YYYY-MM-DD'),
			endDate: moment(defaultDate).endOf('week').format('YYYY-MM-DD'),
		};
		getAllTimeOffRequestsRequested(datesObject);
	});

	return (
		<Styled.Root>
			{isDesktopView && (
				<Container noSpaces fullWidth>
					<Box>
						<Calendar
							date={currentDate}
							defaultDate={defaultDate}
							defaultView={defaultView}
							localizer={localizerDate}
							components={components}
							events={events}
							eventPropGetter={eventPropGetter}
							getNow={getNow}
							onNavigate={(date, view) => handleNavigate(date, view)}
							onRangeChange={(range, view) => view && handleCalendarChange(range, view)}
							scrollToTime={scrollToTime}
							formats={formats}
							views={views}
							startAccessor='start'
							endAccessor='end'
							style={{ height: '420px', minHeight: '200px', maxHeight: 'calc(100vh - 284px)' }}
							step={1440}
							timeslots={1}
							allDayAccessor={() => true}
							popup
						/>
					</Box>
				</Container>
			)}
			{!isDesktopView && showCalendarTab && <WeeklyCalendarMobile eventsList={events} />}
			<HRRequestEditTimeOffModal
				isSuperUser={isSuperUser}
				employeeOptions={employeeOptions}
				employeeOrRequestId={isRequestModalOpen}
				onCancel={handleCloseRequestModal}
				handleEditTimeOffEvent={handleEditTimeOffRequest}
				handleCreateTimeOffRequest={handleCreateTimeOffRequest}
				timeOffEventFormFields={currentEvent}
				loading={loading}
				dateFormat={dateFormat}
				timeOffStaticsPerEmployee={timeOffStaticsPerEmployee}
				timeOffRequestDuration={timeOffRequestDuration}
				handleCalculateTimeOffDuration={handleCalculateTimeOffDuration}
				handleEmployeeChange={handleEmployeeChange}
			/>
			<DeclineRequestTimeOffModal
				isOpen={isDeclineModalOpen}
				loading={!!loading?.declineTimeOffRequestLoad}
				onCancel={handleCloseDeclineModal}
				onApprove={handleEventDecline}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
		timeOffList: hrDucks.hrSelectors.getAllTimeOffRequests(state),
		timeOffRequestDuration: hrDucks.hrSelectors.getTimeOffRequestDuration(state),
		timeOffStaticsPerEmployee: hrDucks.hrSelectors.getTimeOffStaticsPerEmployee(state),
		bankHolidays: hrDucks.hrSelectors.getBankHolidays(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		employeeOptions: hrDucks.hrSelectors.getEmployeeOptions(state),
	}),
	{
		getAllTimeOffRequestsRequested: hrDucks.hrActions.getAllTimeOffRequestsRequested,
		getTimeOffRequestDurationRequested: hrDucks.hrActions.getTimeOffRequestDurationRequested,
		getTimeOffStaticsPerEmployeeRequested: hrDucks.hrActions.getTimeOffStaticsPerEmployeeRequested,
		updateTimeOffRequestRequested: hrDucks.hrActions.updateTimeOffRequestRequested,
		createTimeOffRequestRequested: hrDucks.hrActions.createTimeOffRequestRequested,
		getBankHolidaysRequested: hrDucks.hrActions.getBankHolidaysRequested,
		getEmployeeOptionsRequested: hrDucks.hrActions.getEmployeeOptionsRequested,
		approveTimeOffRequestRequested: hrDucks.hrActions.approveTimeOffRequestRequested,
		declineTimeOffRequestRequested: hrDucks.hrActions.declineTimeOffRequestRequested,
		deleteTimeOffRequestRequested: hrDucks.hrActions.deleteTimeOffRequestRequested,
	},
)(HRCalendarWeekly);
