import React, { type FC } from 'react';
import { Field } from 'react-final-form';

import { FormApi } from 'final-form';

import FieldPassword from 'components/FieldPassword';
import FieldWrapper from 'components/FieldWrapper';
import { IEmployeeValues } from 'modules/Common/types/employee';
import { passwordValidator, repeatPasswordValidator } from 'utils/validators';

import FormBlockLine from '../FormBlockLine';

import { Styled } from './Password.styled';

type PasswordProps = {
	formObj: FormApi<IEmployeeValues, Partial<IEmployeeValues>>;
};

export const Password: FC<PasswordProps> = ({ formObj }) => {
	return (
		<Styled.FormBlockWrap title='Password'>
			<FormBlockLine>
				<Field name='password' validate={passwordValidator}>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='password'
								label='Password'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								isFixed
							>
								<FieldPassword {...input} placeholder='' />
							</FieldWrapper>
						</>
					)}
				</Field>
				<Field
					name='confirmPassword'
					validate={(value: string) =>
						repeatPasswordValidator(formObj.getState().values.password, value)
					}
				>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='confirmPassword'
								label='Confirm password'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								isFixed
							>
								<FieldPassword {...input} placeholder='' />
							</FieldWrapper>
						</>
					)}
				</Field>
			</FormBlockLine>
		</Styled.FormBlockWrap>
	);
};
