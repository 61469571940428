import EmailIcon from 'components/SVG/EmailIcon';
import InfoIcon from 'components/SVG/InfoIcon';
import PasswordIcon from 'components/SVG/PasswordIcon';
import ProfileIcon from 'components/SVG/ProfileIcon';
import { SettingsPanelMenuList } from 'modules/BackOffice/pages/BackOfficeSettingsPage/BackoffieSettingsPage.types';
import { COLORS } from 'theme';
import { BackOfficeSettingsRoutes } from 'types';

export const SettingsShortAccessList = [
	{
		id: 20,
		name: SettingsPanelMenuList.ChangePersonalData,
		path: BackOfficeSettingsRoutes.ChangePersonalData,
		icon: <PasswordIcon fill={COLORS.lightBlue1} />,
	},
];

export const SettingsFullAccessList = [
	{
		id: 10,
		name: SettingsPanelMenuList.CreditPrices,
		path: BackOfficeSettingsRoutes.CreditPrices,
		icon: <InfoIcon fill={COLORS.lightBlue1} />,
	},
	{
		id: 11,
		name: SettingsPanelMenuList.EmailTemplates,
		path: BackOfficeSettingsRoutes.EmailTemplates,
		icon: <EmailIcon fill={COLORS.lightBlue1} />,
		messages: 2,
	},
	...SettingsShortAccessList,
	{
		id: 12,
		name: SettingsPanelMenuList.UserAccess,
		path: BackOfficeSettingsRoutes.UserAccess,
		icon: <ProfileIcon fill={COLORS.lightBlue1} />,
	},
];

export const SettingsRestrictedList = [];
