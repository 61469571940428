import React, { useCallback, useMemo, type FC } from 'react';
import { Field, Form } from 'react-final-form';

import { Input, Select } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import {
	composeValidators,
	numberValidator,
	requiredFieldValidator,
	notNegativeIntegersValidator,
} from 'utils/validators';

import { EmployeeOptions, ListActionEnums, VacationLeaveDayType } from '../HRModuleSettings.types';

import { Styled } from './AddEditCustomTimeOffModal.styled';

type AddEditCustomTimeOffModalProps = {
	isOpen: number | boolean;
	employeeList: EmployeeOptions[];
	currentVacationList: VacationLeaveDayType[];
	loading?: boolean;
	onCancel: () => void;
	handleUpdateList: (vacation: VacationLeaveDayType, action: ListActionEnums) => void;
	handleAddUpdateVacationSettings: (values: { employee: number; days: number }) => void;
};

export const AddEditCustomTimeOffModal: FC<AddEditCustomTimeOffModalProps> = ({
	isOpen,
	employeeList,
	currentVacationList,
	loading,
	onCancel,
	handleUpdateList,
	handleAddUpdateVacationSettings,
}) => {
	const isEditMode = typeof isOpen === 'number';
	const currentVacation = currentVacationList.find((vacation) => vacation.id === isOpen);

	const initialValues = useMemo(
		() => ({
			employee: '',
			days: '',
			...(isEditMode && currentVacation
				? { employee: currentVacation.employee?.id, days: currentVacation.days }
				: {}),
		}),
		[currentVacation],
	);

	const onSubmit = useCallback(
		(values: { employee: number; days: number }) => {
			handleAddUpdateVacationSettings(values);
			onCancel();
		},
		[isOpen],
	);

	return (
		<Styled.Modal open={!!isOpen} footer={null} centered onCancel={onCancel} width='auto'>
			<Styled.ModalHead>
				<h3>{isEditMode ? 'Edit Custom Holiday Allowance' : 'Custom Holiday Allowance'}</h3>
				<Styled.UserHint>
					Please note any already approved leave may need to be manually adjusted
				</Styled.UserHint>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					autoComplete='off'
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Styled.FieldsBlock>
								<Field name='employee' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='employee'
											label='Employee'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
										>
											<Select
												{...input}
												value={input.value || null}
												placeholder='Please Select'
												options={employeeList}
												disabled={isEditMode}
											/>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.FieldsBlock>
								<Field
									name='days'
									validate={composeValidators(
										requiredFieldValidator,
										numberValidator,
										notNegativeIntegersValidator,
									)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='days'
											label='Day Numbers'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
											isFixed
										>
											<Input {...input} placeholder='e.g. 30' autoComplete='off' type='number' />
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.ButtonBox>
								<HRThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									disabled={submitting || loading}
									loading={submitting || loading}
									// disabled={loading.submitButtonLoading}
									// loading={!!loading.settingsLoading}
								>
									Save Holiday
								</HRThemedButton>
								<HRThemedButton type='button' buttonType={ButtonTypes.tertiary} onClick={onCancel}>
									Cancel
								</HRThemedButton>
							</Styled.ButtonBox>
						</form>
					)}
				/>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
