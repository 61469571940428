import React, { createContext, useContext, FC, ReactNode } from 'react';

import { ClientBrandingDataType } from 'modules/Common/types';
import { SubscriptionPlansType } from 'types';

interface HRContextProps {
	subscriptionPlans: SubscriptionPlansType;
	clientBrandingData: ClientBrandingDataType;
}

const HRLayoutContext = createContext<HRContextProps | null>(null);

export const useHRLayoutContext = () => {
	const context = useContext(HRLayoutContext);
	if (!context) {
		return { subscriptionPlans: {}, clientBrandingData: {} };
	}

	return context;
};

type HRLayoutProviderProps = {
	subscriptionPlans: SubscriptionPlansType;
	clientBrandingData: ClientBrandingDataType;
	children: ReactNode;
};

export const HRLayoutProvider: FC<HRLayoutProviderProps> = ({
	subscriptionPlans,
	clientBrandingData,
	children,
}) => {
	return (
		<HRLayoutContext.Provider value={{ subscriptionPlans, clientBrandingData }}>
			{children}
		</HRLayoutContext.Provider>
	);
};
