import { FC } from 'react';
import { Field, useField } from 'react-final-form';

import FormSwitch from 'components/FormSwitch';

import { Styled } from './HRHolidayAbsencePolicyBlock.styled';

export const DayToggleItem: FC<{
	dayName: string;
	dayLabel: string;
	onChange: (field: string, value: boolean) => void;
}> = ({ dayName, dayLabel, onChange }) => {
	const { input: dayToggle } = useField(dayName, { subscription: { value: true } });

	return (
		<Styled.WorkingDaysToggle isActive={dayToggle.value}>
			<Field name={dayName}>
				{({ input }) => (
					<FormSwitch {...input} title={dayLabel} onChange={() => onChange(dayName, input.value)} />
				)}
			</Field>
		</Styled.WorkingDaysToggle>
	);
};
