import styled from 'styled-components';

export namespace Styled {
	export const FormBlockContent = styled.div`
		display: grid;
		grid-template-columns: 240px repeat(auto-fill, minmax(240px, 1fr));
		gap: 32px;
		margin-bottom: 32px;
		@media screen and (max-width: 550px) {
			grid-template-columns: 1fr;
		}

		& > div {
			display: flex;
			flex-direction: column;
			min-width: 220px;
			max-width: 306px;
			@media screen and (max-width: 550px) {
				width: 100%;
				max-width: 100%;
			}
		}
		&:last-of-type {
			margin-bottom: 16px;
		}
	`;
}
