import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Calendar,
	DateFormat,
	DateLocalizer,
	momentLocalizer,
	ToolbarProps,
	type View,
	Views,
} from 'react-big-calendar';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment-timezone';

import Box from 'components/Box';
import Container from 'components/Container';
import { useMount } from 'hooks';
import { CalendarEventType, DateType } from 'modules/Common/types/hrModuleTypes';
import { assignColorsToIds } from 'modules/Common/utils/commonHelpers';
import DeclineRequestTimeOffModal from 'modules/HR/components/DeclineRequestTimeOffModal';
import HRCalendarCustomDateHeader from 'modules/HR/components/HRCalendarCustomDateHeader';
import HRCalendarCustomEvent from 'modules/HR/components/HRCalendarCustomEvent';
import { hrDucks } from 'modules/HR/ducks';
import { paletteColors } from 'theme/colors';

import {
	getStartEndDate,
	mapBankHolidaysToEvents,
	mapTimeOffToEvents,
	validateTypeView,
} from './HRCalendar.helpers';
import { Styled } from './HRCalendar.styled';
import { HRCalendarProps } from './HRCalendar.types';
import { HRCalendarCustomToolbar } from './HRCalendarCustomToolbar/HRCalendarCustomToolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const defaultTZ = moment.tz.guess();

const HRCalendar: FC<HRCalendarProps> = ({
	timeOffList,
	bankHolidays,
	currentDate,
	currentView,
	loading,
	handleEditTimeOffEvent,
	handleUpdateCurrentDate,
	handleChangeView,
	getAllTimeOffRequestsRequested,
	getTimeOffStaticsPerEmployeeRequested,
	getBankHolidaysRequested,
	approveTimeOffRequestRequested,
	declineTimeOffRequestRequested,
	deleteTimeOffRequestRequested,
}) => {
	const [events, setEvents] = useState<CalendarEventType[] | []>([]);
	const [filterValue, setFilterValue] = useState('all');
	const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<number | null>(null);

	const { defaultDate, defaultView, scrollToTime, views, formats, getNow, localizerDate } =
		useMemo(() => {
			moment.tz.setDefault(defaultTZ);

			return {
				defaultDate: moment().toDate(),
				defaultView: Views.MONTH,
				getNow: () => moment().toDate(),
				localizerDate: momentLocalizer(moment),
				formats: {
					dayFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
						localizer.format(date, 'ddd DD', culture),
					dayHeaderFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
						localizer.format(date, 'dddd MMMM Do', culture),
					dayRangeHeaderFormat: (
						{ start, end }: DateType,
						culture: string,
						localizer: DateLocalizer,
					): DateFormat =>
						localizer.format(start, 'MMM ', culture) +
						localizer.format(start, 'D', culture) +
						' - ' +
						localizer.format(end, 'D', culture) +
						localizer.format(start, ', YYYY', culture),
					timeGutterFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
						localizer.format(date, 'HH:mm', culture),
				},
				scrollToTime: moment().toDate(),
				views: [Views.MONTH, Views.WEEK, Views.DAY],
			};
		}, []);

	const handleFilterChange = useCallback((value: string) => {
		return setFilterValue(value);
	}, []);

	const handleOpenDeclineModal = useCallback(
		(id: number) => {
			setIsDeclineModalOpen(id);
		},
		[currentDate, defaultView],
	);

	const handleCloseDeclineModal = () => {
		setIsDeclineModalOpen(null);
	};

	const handleEventDecline = useCallback(
		({ requestId, comment }: { requestId: number; comment?: string }, cb?: () => void) => {
			declineTimeOffRequestRequested({ requestId, comment }, () => {
				handleNavigate(currentDate, defaultView);
				cb && cb();
			});
		},
		[currentDate, defaultView],
	);

	const handleEventApprove = useCallback(
		(id: number) => {
			approveTimeOffRequestRequested(id, () => handleNavigate(currentDate, defaultView));
		},
		[currentDate, defaultView],
	);

	const handleOpenRequestModal = useCallback(
		(id: number) => {
			const currentTimeOffEvent = timeOffList.find((timeOffEvent) => timeOffEvent.id === id);
			if (currentTimeOffEvent) {
				getTimeOffStaticsPerEmployeeRequested({ employeeId: currentTimeOffEvent.employee.id });
				handleEditTimeOffEvent(currentTimeOffEvent);
			}
		},
		[timeOffList],
	);

	const handleDeleteTimeOffRequest = useCallback(
		(id: number) => {
			deleteTimeOffRequestRequested(id, () => handleNavigate(currentDate, defaultView));
		},
		[currentDate, defaultView],
	);

	const components = useMemo(
		() => ({
			event: (props: React.JSX.IntrinsicAttributes & { event: CalendarEventType }) => (
				<HRCalendarCustomEvent
					{...props}
					handleEventDecline={handleOpenDeclineModal}
					handleEventApprove={handleEventApprove}
					handleEventEdit={handleOpenRequestModal}
					handleEventDelete={handleDeleteTimeOffRequest}
				/>
			),
			toolbar: (toolbarProps: ToolbarProps) => (
				<HRCalendarCustomToolbar
					{...toolbarProps}
					filterValue={filterValue}
					handleFilterChange={handleFilterChange}
				/>
			),
			week: {
				header: HRCalendarCustomDateHeader,
			},
		}),
		[timeOffList, filterValue],
	);

	const handleNavigate = useCallback(
		(date: Date, view: View) => {
			const currentRange = getStartEndDate(date, view);
			const { fromDate, toDate } = currentRange && validateTypeView(currentRange, view);
			handleUpdateCurrentDate(date);

			if (fromDate && toDate) {
				getAllTimeOffRequestsRequested({ startDate: fromDate, endDate: toDate });
			}
		},
		[currentDate, handleUpdateCurrentDate],
	);

	const handleCalendarChange = useCallback((date: Date[] | DateType, view: View) => {
		const { fromDate, toDate } = validateTypeView(date, view);

		if (fromDate && toDate) {
			getAllTimeOffRequestsRequested({ startDate: fromDate, endDate: toDate });
		}
	}, []);

	const eventPropGetter = useCallback((event: CalendarEventType) => {
		const showDefaultColors = event.isApproved;

		return {
			style: {
				backgroundColor: showDefaultColors
					? event?.colors?.bgColor || paletteColors.lightBlue
					: 'transparent',
				color: showDefaultColors ? event?.colors?.color || paletteColors.blue : paletteColors.red,
				border: showDefaultColors ? 'none' : `1px solid ${paletteColors.red}`,
				borderRadius: '6px',
			},
		};
	}, []);

	useEffect(() => {
		const colorMapping = assignColorsToIds(timeOffList, ['employeeId']);

		const filteredEvents =
			filterValue === 'all'
				? timeOffList
				: timeOffList?.filter((event) => event.type === filterValue);

		const mappedBankHolidays = filterValue === 'all' ? mapBankHolidaysToEvents(bankHolidays) : [];
		const mappedEvents = mapTimeOffToEvents(filteredEvents, colorMapping);

		setEvents([...mappedEvents, ...mappedBankHolidays] as CalendarEventType[]);
	}, [timeOffList, bankHolidays, currentDate, filterValue]);

	useMount(() => {
		getBankHolidaysRequested({ onlyCustom: false, expandRecurring: true });
		const datesObject = {
			startDate: moment(defaultDate).startOf('month').format('YYYY-MM-DD'),
			endDate: moment(defaultDate).endOf('month').format('YYYY-MM-DD'),
		};
		getAllTimeOffRequestsRequested(datesObject);
	});

	return (
		<Styled.Root isWeekView={currentView === Views.WEEK}>
			<Container noSpaces fullWidth>
				<Box>
					<Calendar
						date={currentDate}
						defaultDate={defaultDate}
						defaultView={defaultView}
						localizer={localizerDate}
						components={components}
						events={events}
						eventPropGetter={eventPropGetter}
						getNow={getNow}
						onNavigate={(date, view) => handleNavigate(date, view)}
						someProp={filterValue}
						onRangeChange={(range, view) => view && handleCalendarChange(range, view)}
						scrollToTime={scrollToTime}
						formats={formats}
						views={views}
						onView={(view) => handleChangeView(view)}
						startAccessor='start'
						endAccessor='end'
						style={{ height: 700, minHeight: '100%', maxHeight: 'calc(100vh - 284px)' }}
						step={120}
						popup={false}
						showMultiDayTimes
						allDayAccessor={() => currentView === Views.WEEK}
					/>
				</Box>
			</Container>
			<DeclineRequestTimeOffModal
				isOpen={isDeclineModalOpen}
				loading={!!loading?.declineTimeOffRequestLoad}
				onCancel={handleCloseDeclineModal}
				onApprove={handleEventDecline}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		timeOffList: hrDucks.hrSelectors.getAllTimeOffRequests(state),
		bankHolidays: hrDucks.hrSelectors.getBankHolidays(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
	}),
	{
		getAllTimeOffRequestsRequested: hrDucks.hrActions.getAllTimeOffRequestsRequested,
		getTimeOffStaticsPerEmployeeRequested: hrDucks.hrActions.getTimeOffStaticsPerEmployeeRequested,
		getBankHolidaysRequested: hrDucks.hrActions.getBankHolidaysRequested,
		approveTimeOffRequestRequested: hrDucks.hrActions.approveTimeOffRequestRequested,
		declineTimeOffRequestRequested: hrDucks.hrActions.declineTimeOffRequestRequested,
		deleteTimeOffRequestRequested: hrDucks.hrActions.deleteTimeOffRequestRequested,
	},
)(HRCalendar);
