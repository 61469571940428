import { message as antMessage } from 'antd';
import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import { CookiesType, ErrorCodes, GenericType, Routes, RouteTypesEnum } from 'types';

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
	baseURL,
});

const showMessageError = (data: string) => antMessage.error(data);

type ErrorDataType = {
	response: {
		data: {
			error?: string;
			errorMessage?: string;
			statusCode: number;
		};
		status: number;
	};
	message: string;
};

function authRequestInterceptor(config: AxiosRequestConfig) {
	const token = Cookies.get(CookiesType.JWT);

	if (token && config?.headers) {
		config.headers['X-Authorization'] = `Bearer ${token}`;
	}

	config.headers = {
		...config.headers,
		'Content-Type': 'application/json; charset=utf-8',
	};

	return config;
}

// @ts-ignore
axiosInstance.interceptors.request.use(authRequestInterceptor, (error) => Promise.reject(error));

const handleErrorMessage = async (error: ErrorDataType) => {
	const status = error?.response?.data?.statusCode || error?.response?.status;
	let message = '';

	const res = error?.response?.data;

	if (typeof res === 'string') {
		message = res;
	} else if (typeof res?.error === 'string') {
		message = res.error;
	} else if (typeof res?.errorMessage === 'string') {
		message = res.errorMessage;
	} else if (!isEmpty(res) && 'errorMessages' in res && res?.errorMessages) {
		message = Object.entries(res?.errorMessages)
			.map(([param, msg]) => `${param}: ${msg}`)
			.join(', ');
	} else if (typeof error?.message === 'string' && isEmpty(res)) {
		message = error?.message;
	}

	return { status, message };
};

export const setAxiosInterceptor = (navigate: (route: string) => void, type: RouteTypesEnum) => {
	const responseInterceptor = axiosInstance.interceptors.response.use(
		(response) => response,
		async (error) => {
			const { status, message } = await handleErrorMessage(error);

			if (status && !message) {
				if (status === ErrorCodes.NotFound) {
					navigate(Routes.NotFound);
				} else if (status === ErrorCodes.Unauthorised) {
					const isLoginError = error?.request?.responseURL?.endsWith('v1/auth/login');

					if (!isLoginError) {
						navigate(Routes.Unauthorised);
					}
				} else if (status === ErrorCodes.Forbidden) {
					navigate(Routes.Forbidden);
				} else if (status === ErrorCodes.ServerError) {
					navigate(Routes.ServerError);
				}
			} else if (status === ErrorCodes.Unauthorised && message) {
				const isLoginError = error?.request?.responseURL?.endsWith('v1/auth/login');

				if (!isLoginError) {
					navigate(Routes.Unauthorised);
				}
			} else if (
				type === RouteTypesEnum.UNREGISTERED &&
				status === ErrorCodes.Forbidden &&
				message === 'Not available'
			) {
				navigate(Routes.Forbidden);
			} else if (status === ErrorCodes.Forbidden) {
				const isHrModuleUnauthError = error?.config?.url?.includes('/hr/') && message === 'Available only for active subscription';
				if(isHrModuleUnauthError) {
					window.location.reload();
				}
			} else {
				const hideError = error?.config['X-Hide-Alert-Error'];

				if (!hideError && message) {
					showMessageError(message);
				}

				console.error(`Code: ${status}, Message: ${message}`);
			}

			return Promise.reject(message);
		},
	);

	return {
		removeInterceptors: () => {
			axiosInstance.interceptors.response.eject(responseInterceptor);
		},
	};
};

export const get = async (url: string, config: AxiosRequestConfig) => {
	if (!url) return null;

	try {
		const response = await axiosInstance.get(url, config);

		return response?.data;
	} catch (error) {
		console.error(error);

		throw error;
	}
};

export const post = async (url: string, body: GenericType, config: AxiosRequestConfig) => {
	if (!url && isEmpty(body)) return null;

	try {
		const response = await axiosInstance.post(url, body, config);

		return response?.data;
	} catch (error) {
		console.error(error);

		throw error;
	}
};

export const put = async (url: string, body: GenericType) => {
	if (!url && isEmpty(body)) return null;

	try {
		const response = await axiosInstance.put(url, body);

		return response?.data;
	} catch (error) {
		console.error(error);

		throw error;
	}
};

export const patch = async (url: string, body: GenericType, config: AxiosRequestConfig) => {
	if (!url && isEmpty(body)) return null;

	try {
		const response = await axiosInstance.patch(url, body, config);

		return response?.data;
	} catch (error) {
		console.error(error);

		throw error;
	}
};

export const del = async (url: string, data: GenericType) => {
	try {
		const response = await axiosInstance.delete(url, { data });

		return response?.data;
	} catch (error) {
		console.error(error);

		throw error;
	}
};

export default axiosInstance;
