import React, { Key } from 'react';

import { Tooltip } from 'antd';

import { ColumnsDataType } from 'components/Table/Table.types';
import { IInvoicesState, InvoicesTypesValuesEnum } from 'modules/Common/types';
import { truncateString } from 'utils/helpers';

import { renderCredits, renderInvoiceStatus } from './FinancialReports.helpers';
import { InvoicesReportValues } from './FinancialReports.types';

type DataTableType = InvoicesReportValues & {
	key?: Key;
};

interface ColumnsParams {
	invoiceStatuses: IInvoicesState[];
	handleChangeInvoiceStatus: (invoiceId: number, statusId: number) => void;
}

export const columns = ({
	invoiceStatuses,
	handleChangeInvoiceStatus,
}: ColumnsParams): ColumnsDataType => [
	{
		title: 'Client',
		dataIndex: 'client',
		key: 'client',
		sorter: true,
		render: (_: string, { client }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(client || '', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span style={{ fontWeight: 'bold' }}>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Reference',
		dataIndex: 'btoReference',
		key: 'btoReference',
		align: 'center',
		render: (_: string, { btoReference }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(btoReference || '-', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Net',
		dataIndex: 'net',
		key: 'net',
		align: 'center',
		render: (_: string, { net }: Partial<DataTableType>) => <span>{net ?? '-'}</span>,
	},
	{
		title: 'Gross',
		dataIndex: 'gross',
		key: 'gross',
		align: 'center',
		render: (_: string, { gross }: Partial<DataTableType>) => <span>{gross ?? '-'}</span>,
	},
	{
		title: 'Vat',
		dataIndex: 'vat',
		key: 'vat',
		align: 'center',
		render: (_: string, { vat }: Partial<DataTableType>) => <span>{vat ?? '-'}</span>,
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		sorter: true,
		align: 'center',
		render: (_: string, { status, invoiceType, invoiceId }: Partial<DataTableType>) =>
			renderInvoiceStatus(
				{ status, invoiceType, id: invoiceId },
				invoiceStatuses,
				handleChangeInvoiceStatus,
			),
	},
	{
		title: 'Employee',
		dataIndex: 'employee',
		key: 'employee',
		render: (_: string, { employeeFullName }: Partial<DataTableType>) => {
			const { truncated, fullText } = truncateString(employeeFullName || '-', 12);

			return (
				<Tooltip title={fullText} overlayStyle={{ maxWidth: '100px' }}>
					<span>{truncated}</span>
				</Tooltip>
			);
		},
	},
	{
		title: 'Date Paid',
		dataIndex: 'datePaid',
		key: 'datePaid',
		align: 'center',
		sorter: true,
		render: (_: string, { paidDate }: Partial<DataTableType>) => <span>{paidDate || '-'}</span>,
	},
	{
		title: 'Payment Type',
		dataIndex: 'paymentType',
		key: 'paymentType',
		align: 'center',
		render: (_: string, { paymentMethod }: Partial<DataTableType>) => (
			<span>{paymentMethod?.name || '-'}</span>
		),
	},
	{
		title: 'Credits',
		dataIndex: 'credits',
		key: 'credits',
		width: '240px',
		align: 'center',
		render: (_: string, { invoiceType, credits }: Partial<DataTableType>) =>
			renderCredits({
				invoiceType: invoiceType?.value as unknown as InvoicesTypesValuesEnum,
				credits,
			}),
	},
];
