import styled from 'styled-components/macro';

export namespace Styled {
	export const ModalHead = styled.div`
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
		font-size: 16px;
		text-align: center;
	`;

	export const ModalContent = styled.div`
		width: 440px;
		max-width: 100%;
	`;

	export const FieldsBlock = styled.div`
		margin-bottom: 25px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: center;
		margin-top: 24px;
		gap: 16px;
	`;
}
