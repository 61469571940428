import React, { type FC } from 'react';

import { Button, Popconfirm } from 'antd';

import DeleteIcon from 'components/SVG/DeleteIcon';
import EditIcon from 'components/SVG/EditIcon';
import { IUser } from 'modules/Common/types';
import { UserRolesEnum } from 'types';
import { capitalizeFirstLetterOfEachWord, formatDateRange } from 'utils/helpers';

import { Styled } from './HRCalendarPopupEvent.styled';

type HRCalendarPopupEventProps = {
	id: number;
	employeeId: number;
	isApproved: boolean | null;
	user: IUser;
	type: string;
	start: Date;
	end: Date;
	comment: string;
	handleApprove: (id: number) => void;
	handleDecline: (id: number) => void;
	handleEdit: (id: number) => void;
	handleDelete: (id: number) => void;
};

const HRCalendarPopupEvent: FC<HRCalendarPopupEventProps> = ({
	id,
	employeeId,
	isApproved,
	user,
	type,
	start,
	end,
	comment,
	handleApprove,
	handleDecline,
	handleEdit,
	handleDelete,
}) => {
	const requestDates = formatDateRange(start.toISOString(), end.toISOString());
	const { employee: currentEmployeeId, roleId } = user || {};
	const isSuperUser = [UserRolesEnum.HR_MANAGER, UserRolesEnum.JAT_SUPER_USER].includes(roleId);
	const isEditingAllowed = !isApproved && (isSuperUser || currentEmployeeId === employeeId);
	const isDeleteAllowed =
		(!isApproved && currentEmployeeId === employeeId) || (isApproved && isSuperUser);
	const isApproveDeclineAllowed = isSuperUser && !isApproved;

	return (
		<Styled.PopoverContent>
			<Styled.PopoverEventType>{capitalizeFirstLetterOfEachWord(type)}</Styled.PopoverEventType>
			<Styled.PopoverEventDate>{requestDates}</Styled.PopoverEventDate>
			<Styled.PopoverCommentsSection>
				<h6>Comments</h6>
				<p>{comment}</p>
			</Styled.PopoverCommentsSection>
			<Styled.ButtonBox>
				{isEditingAllowed && (
					<Styled.ButtonIcon
						type='primary'
						onClick={() => id && handleEdit(id)}
						icon={<EditIcon width={'20px'} height={'20px'} />}
					/>
				)}
				{isDeleteAllowed && (
					<Popconfirm
						placement='top'
						title='Are you sure you want to remove time off request?'
						onConfirm={() => id && handleDelete(id)}
						okText='Yes'
						cancelText='Cancel'
					>
						<Styled.ButtonIcon danger type='primary' icon={<DeleteIcon />} />
					</Popconfirm>
				)}
				{isApproveDeclineAllowed && (
					<>
						<Button danger onClick={() => id && handleDecline(id)}>
							Decline
						</Button>
						<Styled.ButtonApprove type='primary' onClick={() => id && handleApprove(id)}>
							Approve
						</Styled.ButtonApprove>
					</>
				)}
			</Styled.ButtonBox>
		</Styled.PopoverContent>
	);
};

export default HRCalendarPopupEvent;
