import { COLORS } from 'theme';

export type StatisticDataType = {
	vacationsUsed?: number;
	vacationsByDefault?: number;
	sickLeaveUsed?: number;
	sickLeaveByDefault?: number;
	parentalUsed?: number;
	parentalByDefault?: number;
	otherUsed?: number;
	otherByDefault?: number;
};

export enum TimeOffTypes {
	VACATION = 'VACATION',
	SICK_DAY = 'SICK_DAY',
	MEDICAL = 'MEDICAL',
	MATERNITY_PATERNITY = 'MATERNITY_PATERNITY',
	OTHER = 'OTHER',
}

interface TimeOff {
	color: string;
	emoji: string;
}

export const TimeOffs: Record<TimeOffTypes, TimeOff> = {
	[TimeOffTypes.VACATION]: {
		color: COLORS.green3,
		emoji: '🌴',
	},
	[TimeOffTypes.SICK_DAY]: {
		color: COLORS.yellow,
		emoji: '💊',
	},
	[TimeOffTypes.MEDICAL]: {
		color: COLORS.blue6,
		emoji: '🏥',
	},
	[TimeOffTypes.MATERNITY_PATERNITY]: {
		color: COLORS.orange,
		emoji: '🐣',
	},
	[TimeOffTypes.OTHER]: {
		color: COLORS.purple,
		emoji: '📅',
	},
};
