import React from 'react';

import { IUser, INavListItem } from 'modules/Common/types';
import { ClientBrandingDataType } from 'modules/Common/types/branding';

import { S } from './Header.styled';
import MainMenu from './MainMenu';
import UserMenu from './UserMenu';

type HeaderProps = {
	user: IUser;
	isHrModule?: boolean;
	menuItems: INavListItem[];
	subMenuItems: INavListItem[];
	logoUrl?: string;
	brandingData?: ClientBrandingDataType;
	userMenuListStyles?: React.CSSProperties;
};

const Header: React.FC<HeaderProps> = ({
	user,
	logoUrl,
	isHrModule = false,
	menuItems,
	subMenuItems,
	brandingData,
	userMenuListStyles = {},
}) => {
	const currentUser = user?.firstName || user?.lastName ? `${user.firstName} ${user.lastName}` : '';

	return (
		<S.Header isHrModule={isHrModule} backgroundColor={brandingData?.highlightColourAlternate}>
			<S.Container>
				<S.Main>
					{/* {isHrModule && <S.Logo src={logoUrl} alt='logo' />} */}
					<S.MainMenuWrapper isHrModule={false}>
						<MainMenu
							items={menuItems}
							isHrModule={isHrModule}
							highlightColor={brandingData?.accentColour}
						/>
					</S.MainMenuWrapper>
				</S.Main>
				<UserMenu
					isHrModule={isHrModule}
					backgroundColor={brandingData?.highlightColourAlternate}
					items={subMenuItems}
					user={currentUser}
					userMenuListStyles={userMenuListStyles}
				/>
			</S.Container>
		</S.Header>
	);
};

export default Header;
