import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ButtonTypes } from 'components/Button/Button.types';
import { IUser } from 'modules/Common/types';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import HREmployeesList from 'modules/HR/containers/HREmployeesList';
import { Routes } from 'modules/HR/routes/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';

import { Styled } from './HREmployeeListPage.styled';

const HREmployeeListPage = ({ userInfo }: { userInfo: IUser }) => {
	const navigate = useNavigate();
	const isSuperUser = !!userInfo?.hrAdmin;

	const newEmployeePath = `${Routes.HRModule}${Routes.EmployeeCreate}`;
	const pageTitle = isSuperUser ? 'People Management' : 'People';

	return (
		<>
			<Styled.Head>
				<Styled.Title>{pageTitle}</Styled.Title>
				{isSuperUser && (
					<Styled.ButtonsBox>
						<HRThemedButton
							buttonType={ButtonTypes.primary}
							type='button'
							onClick={() => navigate(newEmployeePath)}
						>
							Add New Employee
						</HRThemedButton>
					</Styled.ButtonsBox>
				)}
			</Styled.Head>
			<Styled.Content>
				<HREmployeesList isSuperUser={isSuperUser} />
			</Styled.Content>
		</>
	);
};

export default connect((state) => ({
	userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
}))(HREmployeeListPage);
