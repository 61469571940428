import { EmployeeStatusTypesFormEnum, IEmployeeState } from 'modules/Common/types/employee';
import { GenericType } from 'types';

export enum ChangeActionEnum {
	Archive = 'Archive',
	UnArchive = 'Un-Archive',
	ChangeStatus = 'Change status',
}

export type StatusModalInfoProps = {
	employedId: number;
	changeAction: ChangeActionEnum;
	startStatus?: EmployeeStatusTypesFormEnum;
	endStatus?: EmployeeStatusTypesFormEnum;
} | null;

export type HREmployeesListProps = {
	isSuperUser: boolean;
	employeeStatusesList: IEmployeeState[];
	employeesList: {
		data: object[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	loading: GenericType;
	getEmployeeStatusesRequested: () => void;
	getEmployeeListRequested: (
		params: { active: boolean; page?: number; size?: number; search?: string },
		isSuperUser: boolean,
	) => void;
	archiveEmployeeRequested: (
		data: { employeeId: number; leaveDate: string },
		cb?: () => void,
	) => void;
	unArchiveEmployeeRequested: (employeeId: number, cb?: () => void) => void;
	changeEmployeeStatusRequested: (
		data: { employeeId: number; employeeStatus: number; startData?: string; leaveDate: string },
		cb?: () => void,
	) => void;
};
