import axios from 'axios';
import Cookies from 'js-cookie';

import { get, post, put } from 'api/axiosInstance';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { CookiesType, RequestsEnum } from 'types';
import { getTableParams } from 'utils/helpers';

const baseUrl = process.env.REACT_APP_API_URL;

export const getClients = () => get(RequestsEnum.Client);

export const getClientsShort = (isParentClient) => {
	const url = isParentClient
		? `${RequestsEnum.ClientParent}${RequestsEnum.Short}`
		: `${RequestsEnum.Client}${RequestsEnum.Short}`;

	return get(url);
};

export const getClientById = (clientId) => get(`${RequestsEnum.Client}/${clientId}`);

export const createClient = (data) => post(RequestsEnum.Client, data);

export const getPricesByClientId = (clientId) => get(`${RequestsEnum.PriceClient}/${clientId}`);

export const createPrice = (data) => post(RequestsEnum.Price, data);

export const updatePrice = (data) => put(RequestsEnum.Price, data);

export const getCreditsByClientId = (clientId) => get(`${RequestsEnum.CreditClient}/${clientId}`);

export const getClientsSubs = () => get(RequestsEnum.SubscriptionPlan);

export const getClientRegions = () => get(RequestsEnum.Region);

export const getClientsByRegion = async (region) => {
	return get(`${RequestsEnum.ClientRegion}/${region}${RequestsEnum.Short}`);
};

export const uploadFile = async (options, isGenericFilePath = false) => {
	const {
		onSuccess = () => null,
		onError = () => null,
		file,
		onProgress = () => null,
		fileType = FileUploadTypesEnum.Logo,
		url = null,
		generateFilename = false,
		noAuth = false,
	} = options;
	const fmData = new FormData();
	const token = !noAuth && Cookies.get(CookiesType.JWT);
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		onUploadProgress: (event) => {
			onProgress({ percent: (event.loaded / event.total) * 100 });
		},
	};

	if (token) {
		config.headers['X-Authorization'] = `Bearer ${Cookies.get(CookiesType.JWT)}`;
	}

	if (generateFilename) {
		fmData.append('file', file, 'filename.png');
	} else {
		fmData.append('file', file);
	}

	const uploadUrl = url ?? (isGenericFilePath ? `${baseUrl}/file` : `${baseUrl}/file/${fileType}`);

	try {
		const res = await axios.post(uploadUrl, fmData, config);
		onSuccess(res);
	} catch (err) {
		onError({ err });
	}
};

export const getDashboardsInsights = async () => get(RequestsEnum.StatisticsBODashboardInsights);

export const getDashboardsClients = async () => get(RequestsEnum.BODashboardClient);

export const getDashboardsTickets = async () => get(RequestsEnum.BODashboardTicket);

export const getFinancialReports = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BOFinancialReports}${queryParams}`);
};

export const getInvoicesReports = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BOFinReportInvoice}${queryParams}`);
};

export const getContractsReports = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.ContractBOFinReports}${queryParams}`);
};

export const getUserPersonalData = async () => get(`${RequestsEnum.BOUserPersonalData}`);

export const updateUserPersonalData = (data) => put(RequestsEnum.BOUserPersonalData, data);

export const getBOUserList = async () => get(`${RequestsEnum.BOUserList}`);

const backOfficeService = {
	getClients,
	getClientsShort,
	getClientById,
	getClientsSubs,
	getClientRegions,
	getClientsByRegion,
	uploadFile,
	getPricesByClientId,
	getCreditsByClientId,
	createClient,
	createPrice,
	updatePrice,
	getDashboardsInsights,
	getDashboardsClients,
	getDashboardsTickets,
	getFinancialReports,
	getInvoicesReports,
	getContractsReports,
	getUserPersonalData,
	updateUserPersonalData,
	getBOUserList,
};

export default backOfficeService;
