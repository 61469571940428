import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace S {
	export const Header = styled.header`
		display: flex;
		align-items: center;
		gap: 6px;
		margin-top: 24px;
		padding: 0 16px;
	`;

	export const UserMenu = styled.div``;

	export const UserProfile = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	`;

	export const UserLogout = styled.div`
		color: ${COLORS.red};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	`;

	export const Menu = styled.div`
		position: fixed;
		bottom: 20px;
		left: 24px;
		right: 24px;
		border-radius: 30px;
		background: ${COLORS.white};
		box-shadow: 0px 4px 12px 0px rgba(33, 47, 125, 0.16);
		display: flex;
		justify-content: space-between;
		padding: 15px 16px 10px;
		z-index: 1000;
	`;

	export const MenuItem = styled.div<{ isActive: boolean }>`
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3px;
		flex: 1 0 0;
		svg path {
			fill: ${({ isActive }) => (isActive ? COLORS.blue : COLORS.darkGray2)};
		}
		div {
			color: ${({ isActive }) => (isActive ? COLORS.blue : COLORS.darkGray2)};
		}
	`;

	export const MenuItemIcon = styled.div``;

	export const MenuItemText = styled.div`
		color: ${COLORS.darkGray2};
		text-align: center;
		font-family: 'SF Pro Text';
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -0.24px;
	`;
}
