import styled from 'styled-components/macro';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		height: 100%;
	`;

	export const Title = styled.h2`
		margin-bottom: 24px;
	`;

	export const Fields = styled.div`
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
	`;

	export const ButtonWrapper = styled.div`
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		padding-top: 24px;
	`;
}
