import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Head = styled.div`
		margin-top: 36px;
	`;

	export const Title = styled.h1`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		letter-spacing: -0.264px;
		text-transform: capitalize;
	`;

	export const Content = styled.div`
		margin-top: 24px;
	`;
}
