import { paletteColors } from 'theme/colors';

export const CALENDAR_EVENT_TYPES = {
	vacation: 'VACATION',
	sickLeave: 'ILLNESS',
	maternityLeave: 'PARENTAL',
	other: 'OTHER',
	publicHoliday: 'PUBLIC_HOLIDAY',
	bankHoliday: 'BANK_HOLIDAY',
	companyHoliday: 'COMPANY_HOLIDAY',
};

export const FilterOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'Vacation', value: CALENDAR_EVENT_TYPES.vacation },
	{ label: 'Sick Leave', value: CALENDAR_EVENT_TYPES.sickLeave },
	{ label: 'Parental leave', value: CALENDAR_EVENT_TYPES.maternityLeave },
	{ label: 'Other', value: CALENDAR_EVENT_TYPES.other },
];

export const CalendarColorsByTypes = {
	[CALENDAR_EVENT_TYPES.vacation]: { color: paletteColors.black, bgColor: '#A2E3FF' },
	[CALENDAR_EVENT_TYPES.sickLeave]: { color: paletteColors.black, bgColor: '#FFE79D' },
	[CALENDAR_EVENT_TYPES.maternityLeave]: { color: paletteColors.black, bgColor: '#faad14' },
	[CALENDAR_EVENT_TYPES.other]: { color: paletteColors.black, bgColor: '#9b51e0' },
	[CALENDAR_EVENT_TYPES.bankHoliday]: { color: paletteColors.black, bgColor: 'transparent' },
	[CALENDAR_EVENT_TYPES.companyHoliday]: { color: paletteColors.black, bgColor: 'transparent' },
};

export const CalendarEmojisByTypes = {
	[CALENDAR_EVENT_TYPES.vacation]: '🌴',
	[CALENDAR_EVENT_TYPES.sickLeave]: '💊',
	[CALENDAR_EVENT_TYPES.maternityLeave]: '🐣',
	[CALENDAR_EVENT_TYPES.other]: '📅',
	[CALENDAR_EVENT_TYPES.publicHoliday]: '🎉',
};

export const employeeFormAllowedFields = [
	'photo',
	'firstName',
	'lastName',
	'role',
	'birthdayDate',
	'personalPhone',
	'workPhone',
	'email',
	'postCode',
	'country',
	'city',
	'address',
	'startDate',
	'leaveDate',
	'jobTitle',
	'departmentName',
	'emergencyContactFullName',
	'emergencyContactPhone',
	'password',
	'usesDefaultOnboardingTemplate',
];

export const MOBILE_VIEW_BREAKPOINT = '860px';

export const DATE_FORMAT_UK = 'DD/MM/YYYY';

export const DATE_FORMAT_US = 'MM/DD/YYYY';
