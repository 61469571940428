import dayjs from 'dayjs';

import { IEmployeeAttachment, IEmployeeValues } from 'modules/Common/types/employee';

import { CreateEmployeeStateFromAtsType } from './CreateEditEmployee.types';

export const normalizeEmployeeStateData = (
	data: CreateEmployeeStateFromAtsType,
	isEditMode: boolean,
) => {
	if (isEditMode || !data) return {};

	const fullName = data.fullName.split(' ');
	const firstName = fullName[0] || '';
	const lastName = fullName.slice(1).join(' ') || '';

	return {
		firstName,
		lastName,
		personalPhone: data.phoneNumber || '',
		jobTitle: data.jobTitle || '',
	};
};

export const prepareEmployeeValues = (values: IEmployeeValues) => ({
	...values,
	startDate: values.startDate ? dayjs(values.startDate) : null,
	leaveDate: values.leaveDate ? dayjs(values.leaveDate) : null,
	birthdayDate: values.birthdayDate ? dayjs(values.birthdayDate) : null,
	role: values?.role || values?.role?.id || null,
});

export const prepareEmployeeAttachments = (attachments: IEmployeeAttachment[]) => {
	return attachments.map((attachment, index) => ({
		uid: `${index}`,
		name: attachment.originalFileName || attachment,
		id: attachment.id || attachment,
		status: 'done',
		response: {
			data: attachment.ref || attachment,
		},
	}));
};
