import React from 'react';
import { Link } from 'react-router-dom';

import { Dropdown, Tooltip } from 'antd';

import ButtonComponent from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import CalendarIcon from 'components/SVG/CalendarIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import EmailIcon from 'components/SVG/EmailIcon';
import MessageIcon from 'components/SVG/MessageIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import Ranking from 'modules/Common/components/Ranking';
import { CandidateResponseType, JobDetailsViewTabIds, TagColoursType } from 'modules/Common/types';
import { COLORS } from 'theme';
import { TStyled } from 'theme/styled';
import { Routes, SubscriptionPlansType } from 'types';

import DownloadAttachmentsOptions from '../DownloadAttachments';

import { statusOptions } from './CandidatesApplication.constants';
import { Styled } from './CandidatesApplication.styled';
import {
	CandidatesStatusEnum,
	CandidatesStatusValuesEnum,
	DataTableType,
	QuestionnaireStatusEnum,
	TableColumnsType,
} from './CandidatesApplication.types';

export const columns = (
	handleOpenModal: (id: number[], jobId: number) => void,
	handleResendCandidateQuestionnaire: (id: number) => void,
	handleRecallCandidateQuestionnaire: (id: number) => void,
	handleCandidateRank: (
		id: string | number,
		rank: number,
		jobId?: string | number,
		cb?: () => void,
	) => void,
	handleSendSMSAction: (id: number) => void,
	subscriptionPlans: SubscriptionPlansType,
	handleSelectSpecificCandidate: (id: number) => void,
	handleOpenEmployeeToHRModal: (
		status: CandidatesStatusValuesEnum,
		employeeData: CandidateResponseType[],
	) => void,
): ColumnsDataType & TableColumnsType => [
	{
		title: 'Candidate Name',
		dataIndex: 'name',
		key: 'name',
		render: (_: string, { name, id, job, isNew }: Partial<DataTableType>) => {
			return (
				<Styled.TitleWrapper>
					{handleSelectSpecificCandidate ? (
						<div onClick={() => id && handleSelectSpecificCandidate(id)}>
							<Styled.TitleList>{name}</Styled.TitleList>
						</div>
					) : (
						<TStyled.PageLink
							to={`${Routes.ATS}${Routes.AppliedJobsCandidate}/${id}`}
							state={{ jobId: job }}
						>
							<Styled.TitleList>{name}</Styled.TitleList>
						</TStyled.PageLink>
					)}
					{isNew && <Styled.LabelNew>New</Styled.LabelNew>}
				</Styled.TitleWrapper>
			);
		},
	},
	{
		title: 'Ranking',
		dataIndex: 'rank',
		key: 'rank',
		align: 'center',
		render: (_: string, { id, rank, job }: Partial<DataTableType>) => {
			return (
				<Ranking
					value={rank ?? 0}
					onChange={(val, cb) => id && handleCandidateRank(id, val, job, cb)}
				/>
			);
		},
	},

	{
		title: 'Questionnaire',
		dataIndex: 'questionnaireType',
		key: 'questionnaireType',
		align: 'center',
		render: (_: string, { id, job, videoQuestionnaireInfo }: Partial<DataTableType>) => {
			const disabled =
				videoQuestionnaireInfo?.videoQuestionnaireStatus === QuestionnaireStatusEnum.Send ||
				videoQuestionnaireInfo?.restricted;
			const showFinishedButton =
				videoQuestionnaireInfo?.videoQuestionnaireStatus === QuestionnaireStatusEnum.Finished;

			const hideSendButton =
				videoQuestionnaireInfo?.videoQuestionnaireStatus === QuestionnaireStatusEnum.Sent ||
				videoQuestionnaireInfo?.videoQuestionnaireStatus === QuestionnaireStatusEnum.Started;

			return (
				<Styled.TagBox>
					{hideSendButton && (
						<Styled.SendButtons>
							<>
								<Tooltip
									placement='topRight'
									title={
										videoQuestionnaireInfo?.restricted
											? 'Questionnaire details are restricted.'
											: null
									}
								>
									<Styled.Button
										type='ghost'
										size='middle'
										disabled={videoQuestionnaireInfo?.restricted}
										onClick={() => id && handleResendCandidateQuestionnaire(id)}
									>
										<Styled.Tag>Re-send</Styled.Tag>
									</Styled.Button>
								</Tooltip>
								<Tooltip
									placement='topRight'
									title={
										videoQuestionnaireInfo?.restricted
											? 'Questionnaire details are restricted.'
											: null
									}
								>
									<Styled.Button
										type='ghost'
										size='middle'
										disabled={videoQuestionnaireInfo?.restricted}
										onClick={() => id && handleRecallCandidateQuestionnaire(id)}
									>
										<Styled.Tag>Recall</Styled.Tag>
									</Styled.Button>
								</Tooltip>
							</>
						</Styled.SendButtons>
					)}
					{!hideSendButton && !showFinishedButton && (
						<Styled.Button
							type='ghost'
							size='middle'
							disabled={disabled}
							onClick={() => id && job && handleOpenModal([id], job)}
						>
							<Styled.Tag color={TagColoursType.Blue}>Send</Styled.Tag>
						</Styled.Button>
					)}
					{!hideSendButton && showFinishedButton && (
						<Tooltip
							placement='topRight'
							title={
								videoQuestionnaireInfo?.restricted ? 'Questionnaire details are restricted.' : null
							}
						>
							<>
								{videoQuestionnaireInfo?.restricted ? (
									<Styled.TagBox>
										<Styled.Tag color={TagColoursType.Green}>Review</Styled.Tag>
									</Styled.TagBox>
								) : (
									<Link
										to={`${Routes.ATS}${Routes.AppliedJobsCandidate}/${id}`}
										state={{ tab: JobDetailsViewTabIds.VideoQuestionnaire, jobId: job }}
									>
										<Styled.Tag color={TagColoursType.Green}>Review</Styled.Tag>
									</Link>
								)}
							</>
						</Tooltip>
					)}
				</Styled.TagBox>
			);
		},
	},
	{
		title: 'Status',
		dataIndex: 'candidateState',
		key: 'candidateState',
		align: 'center',
		render: (_: string, { candidateState, id, job }: Partial<DataTableType>) => {
			return (
				<Styled.Select
					onChange={(e) =>
						candidateState?.updateCandidateStatus([id], e, job, (__, candidates) =>
							handleOpenEmployeeToHRModal(e as CandidatesStatusValuesEnum, candidates),
						)
					}
					value={statusOptions.find((so) => so.value === candidateState?.value)}
				>
					{statusOptions?.map((option) => (
						<Styled.SelectOption key={option.id} value={option.value}>
							{option.name}
						</Styled.SelectOption>
					))}
				</Styled.Select>
			);
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		render: (
			_: string,
			{ id, job, candidateState, candidateAttachments, name }: Partial<DataTableType>,
		) => {
			const allowInterviewButton = candidateState?.name === CandidatesStatusEnum.Interviews;

			const DownloadTypeData = candidateAttachments
				? DownloadAttachmentsOptions(candidateAttachments, name)
				: [];

			return (
				<Styled.ActionButtons>
					<Tooltip placement='top' title='Send Message'>
						<Link
							to={`${Routes.ATS}${Routes.CreateMessage}`}
							state={{ candidateList: [{ id, fullName: name }] }}
						>
							<ButtonComponent
								buttonType={ButtonTypes.default}
								icon={<EmailIcon fill={COLORS.black} width='16' height='16' />}
							/>
						</Link>
					</Tooltip>
					<Tooltip placement='top' title='Send SMS'>
						<ButtonComponent
							buttonType={ButtonTypes.default}
							icon={<MessageIcon fill={COLORS.black} width='16' height='16' />}
							onClick={() => id && handleSendSMSAction(id)}
						/>
					</Tooltip>

					<Tooltip placement='top' title='Arrange Interview'>
						<Link
							to={`${Routes.ATS}${Routes.ArrangeInterviewCreate}`}
							state={{ candidate: id, jobId: job }}
						>
							<ButtonComponent
								buttonType={ButtonTypes.default}
								icon={<CalendarIcon fill={COLORS.black} width='16' height='16' />}
								disabled={!allowInterviewButton}
							/>
						</Link>
					</Tooltip>
					<Styled.DownloadPopup>
						<Dropdown
							disabled={!DownloadTypeData?.length}
							menu={{
								items: DownloadTypeData,
							}}
							placement='bottom'
						>
							<ButtonComponent
								buttonType={ButtonTypes.default}
								icon={<DownloadIcon fill={COLORS.black} width='16' height='16' />}
							/>
						</Dropdown>
					</Styled.DownloadPopup>
				</Styled.ActionButtons>
			);
		},
	},
];
