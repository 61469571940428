export const statisticDummyData = {
	vacationsUsed: 5,
	vacationsByDefault: 10,
	sickLeaveUsed: 2,
	sickLeaveByDefault: 10,
	parentalUsed: 0,
	parentalByDefault: 10,
};

export const employeeRequestOffDummyData = [
	{
		id: 2,
		startDate: '2024-08-10',
		endDate: '2024-08-14',
		hours: 40,
		type: 'VACATION',
		firstDayType: 'start',
		lastDayType: 'end',
		description: 'Family Trip',
		client: 1002,
		employee: 102,
		employeeFirstName: 'Jane',
		employeeLastName: 'Smith',
		employeePhoto: '',
		approvedBy: 202,
		declinedBy: false,
		createdBy: 302,
		approved: false,
		created: '2024-07-22',
		declined: null,
		attachments: [
			{
				id: 2,
				fileName: 'hotel_reservation.pdf',
				url: 'https://example.com/attachments/hotel_reservation.pdf',
			},
		],
	},
	{
		id: 3,
		startDate: '2024-09-05',
		endDate: '2024-09-07',
		hours: 24,
		type: 'SICK LEAVE',
		firstDayType: 'start',
		lastDayType: 'end',
		description: 'Medical Leave',
		client: 1003,
		employee: 103,
		employeeFirstName: 'Michael',
		employeeLastName: 'Johnson',
		employeePhoto: '',
		approvedBy: 203,
		declinedBy: false,
		createdBy: 303,
		approved: true,
		created: '2024-08-30',
		declined: null,
		attachments: [
			{
				id: 3,
				fileName: 'doctor_note.pdf',
				url: 'https://example.com/attachments/doctor_note.pdf',
			},
		],
	},
	{
		id: 5,
		startDate: '2024-11-15',
		endDate: '2024-11-18',
		hours: 32,
		type: 'VACATION',
		firstDayType: 'start',
		lastDayType: 'end',
		description: 'Weekend Getaway',
		client: 1005,
		employee: 105,
		employeeFirstName: 'David',
		employeeLastName: 'Williams',
		employeePhoto: '',
		approvedBy: 205,
		declinedBy: false,
		createdBy: 305,
		approved: true,
		created: '2024-11-01',
		declined: null,
		attachments: [
			{
				id: 5,
				fileName: 'getaway_plan.pdf',
				url: 'https://example.com/attachments/getaway_plan.pdf',
			},
		],
	},
	{
		id: 6,
		startDate: '2024-12-20',
		endDate: '2024-12-27',
		hours: 56,
		type: 'VACATION',
		firstDayType: 'start',
		lastDayType: 'end',
		description: 'Christmas Holiday',
		client: 1006,
		employee: 106,
		employeeFirstName: 'Sarah',
		employeeLastName: 'Brown',
		employeePhoto: '',
		approvedBy: 206,
		declinedBy: false,
		createdBy: 306,
		approved: true,
		created: '2024-12-05',
		declined: null,
		attachments: [
			{
				id: 6,
				fileName: 'holiday_schedule.pdf',
				url: 'https://example.com/attachments/holiday_schedule.pdf',
			},
		],
	},
];
