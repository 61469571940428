import { MenuList } from 'modules/HR/routes/menuList';
import { Routes } from 'modules/HR/routes/types';
import { Routes as CommonRoutes, UserRolesType } from 'types';

export const HrMenuList = (roles: UserRolesType): { id: number; name: string; path: string }[] => {
	const isSuperUser = roles.isSuperUser || roles.isHrManager || roles.isAtsSuperUser;

	const proparedList  = [
		{
			id: 1,
			name: MenuList.Dashboard,
			path: `${Routes.HRModule}${Routes.Dashboard}`,
		},
		{
			id: 2,
			name: MenuList.Calendar,
			path: `${Routes.HRModule}${Routes.Calendar}`,
		},
		{
			id: 3,
			name: isSuperUser ? MenuList.PeopleManagement : MenuList.People,
			path: `${Routes.HRModule}${Routes.PeopleManagement}`,
		},
	];

	if(isSuperUser) {
		proparedList.push({
			id: 4,
			name: MenuList.ModuleSettings,
			path: `${Routes.HRModule}${Routes.ModuleSettings}`,
		});
	}

	if(roles.isAtsStandardUser || roles.isAtsFinanceUser || roles.isAtsSuperUser) {
		const atsRoute = roles.isAtsFinanceUser ? `${CommonRoutes.ATS}${CommonRoutes.Finance}` : `${CommonRoutes.ATS}${Routes.Dashboard}`;
	
		proparedList.push({
			id: 5,
			name: MenuList.GoBackToATS,
			path: atsRoute,
		});
	}

	return proparedList;

};
