import styled from 'styled-components/macro';

import { FormBlock, FormBlockProps } from 'modules/Common/components/FormBlock/FormBlock';

export namespace Styled {
	export const FormBlockWrap = styled(FormBlock).attrs<FormBlockProps>(() => ({
		style: {
			border: 'none',
			boxShadow: 'none',
			padding: '0',
		},
	}))<FormBlockProps>``;
}
