import { Modal as ModalAntd } from 'antd';
import DraggerAntd from 'antd/es/upload/Dragger';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Modal = styled(ModalAntd)`
		.ant-modal-content {
			padding: 24px;
			width: 630px;
			max-width: 100%;
		}
	`;
	export const ModalHead = styled.div`
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
		font-size: 16px;
		text-align: center;
	`;

	export const ModalContent = styled.div`
		max-width: 100%;
	`;

	export const Dragger = styled(DraggerAntd)`
		&.ant-upload-wrapper {
			.ant-upload-list {
				.ant-upload-list-item {
					border: 1px solid ${COLORS.gray};
					padding: 10px;
					height: auto;
					transition: all 0.2s ease;
					&:hover {
						background-color: transparent;
						box-shadow: 0px 3px 5px 0px ${COLORS.gray};
						cursor: pointer;
					}
					.ant-upload-list-item-actions {
						display: flex;
					}
					.ant-upload-icon {
						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-actions {
						.ant-btn {
							margin: 0 5px;
						}
					}
					.ant-upload-list-item-action {
						opacity: 1;
						color: ${COLORS.black};

						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-name {
						color: ${COLORS.black};
						font-size: 16px;
					}
				}
			}
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		flex-direction: row-reverse;
		width: 100%;
	`;

	export const CheckboxWrapper = styled.div`
		margin-top: 10px;
	`;
}
