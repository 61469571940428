import DraggerAntd from 'antd/es/upload/Dragger';
import styled from 'styled-components/macro';

import { FormBlock, FormBlockProps } from 'modules/Common/components/FormBlock/FormBlock';
import { COLORS } from 'theme';

export namespace Styled {
	export const FormBlockWrap = styled(FormBlock).attrs<FormBlockProps>(() => ({
		style: {
			border: 'none',
			boxShadow: 'none',
			padding: '0',
			marginBottom: '0',
		},
	}))<FormBlockProps>``;

	export const Dragger = styled(DraggerAntd)`
		&.ant-upload-wrapper {
			margin-bottom: 30px;
			.ant-upload-list {
				margin-top: 20px;
				.ant-upload-list-item {
					border: 1px solid ${COLORS.gray};
					padding: 10px;
					height: auto;
					transition: all 0.2s ease;
					&:hover {
						background-color: transparent;
						box-shadow: 0px 3px 5px 0px ${COLORS.gray};
						cursor: pointer;
					}
					.ant-upload-list-item-actions {
						display: flex;
					}
					.ant-upload-icon {
						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-actions {
						.ant-btn {
							margin: 0 5px;
						}
					}
					.ant-upload-list-item-action {
						opacity: 1;
						color: ${COLORS.black};

						svg {
							font-size: 24px;
						}
					}
					.ant-upload-list-item-name {
						color: ${COLORS.black};
						font-size: 16px;
					}
				}
			}
		}
	`;
}
