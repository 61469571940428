import styled from 'styled-components/macro';

import { device } from 'theme';

export namespace Styled {
	export const Root = styled.section<{ isMobile: boolean }>`
		width: 100%;
		padding: ${({ isMobile }) => (isMobile ? '0 16px' : '0')};
	`;

	export const Head = styled.div`
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
		@media ${device.mobile} {
			display: none;
		}

		h2 {
			margin: 0;
		}
	`;

	export const Title = styled.h2``;

	export const Content = styled.div`
		margin-top: 24px;
	`;
}
