import React, { FC } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';

import Table from 'components/Table';

import { Styled } from '../HREmployeesList.styled';

type EmployeeTableForUserProps = {
	search: string;
	onSearchChange: (value: string) => void;
	pageSize: number;
	pageIndex: number;
	loading: boolean;
	totalCount: number;
	data: object[];
	columnsData: object[];
	onTableChange: (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => void;
	onChange: (page: number, size: number) => void;
	onPageSizeChange: (page: number, size: number) => void;
};

const EmployeeTableForUser: FC<EmployeeTableForUserProps> = ({
	search,
	onSearchChange,
	pageSize,
	pageIndex,
	loading,
	totalCount,
	data,
	columnsData,
	onTableChange,
	onChange,
	onPageSizeChange,
}) => {
	return (
		<Styled.Root>
			<Styled.ExtraControls marginBottom='20'>
				<Styled.Input
					value={search}
					placeholder='Search an employee'
					autoComplete='off'
					prefix={<SearchOutlined style={{ color: 'gray' }} />}
					onChange={(e) => onSearchChange(e.target.value)}
					allowClear
				/>
			</Styled.ExtraControls>
			<Table
				pageSize={pageSize}
				current={pageIndex}
				loading={loading}
				total={totalCount}
				data={data}
				columns={columnsData}
				onTableChange={onTableChange}
				onChange={onChange}
				onPageSizeChange={onPageSizeChange}
			/>
		</Styled.Root>
	);
};

export default EmployeeTableForUser;
