import { Modal as ModalAntd } from 'antd';
import styled from 'styled-components/macro';

export namespace Styled {
	export const Modal = styled(ModalAntd)`
		.ant-modal-content {
			padding: 32px 40px;
		}
	`;
	export const ModalContent = styled.div`
		width: 440px;
		max-width: 100%;
	`;

	export const ModalQuestion = styled.h2`
		font-size: 18px;
		font-weight: 600;
		line-height: 22px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		flex-direction: row-reverse;
		margin-top: 26px;
		gap: 16px;
	`;
}
