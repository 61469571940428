import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: center;
		background-color: ${COLORS.white};
		padding: 24px;
		border-radius: 10px;
		box-shadow: rgba(99, 111, 122, 0.12) 0px 2px 4px 0px;
	`;

	export const Title = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%;
		letter-spacing: -0.22px;
		text-transform: capitalize;
		margin-bottom: 12px;
		align-self: flex-start;
	`;

	export const PieWrap = styled.div`
		margin-bottom: 25px;
	`;

	export const StatisticsDataWrap = styled.div`
		background-color: ${COLORS.lightGray3};
		padding: 12px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		width: 100%;
	`;

	export const StatisticsDataEmoji = styled.div`
		margin-right: 8px;
	`;

	export const StatisticsName = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: -0.22px;
	`;

	export const StatisticsData = styled.div`
		color: ${COLORS.darkGray2};
		text-align: right;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		margin-left: auto;
	`;
}
