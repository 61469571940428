import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Tabs } from 'antd';
import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';

import Table from 'components/Table';
import { ClntContext } from 'modules/BackOffice/containers/ClientContextContainer/ClientContextContainer';
import { columns } from 'modules/BackOffice/containers/SupportTickets/SupportTickets.entities';
import { createOptionsWithAll } from 'modules/BackOffice/containers/SupportTickets/SupportTickets.helper';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { TabsTitleEnum, TicketStatusOptions, TicketTypeOptions } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType, Routes, UserRoleNamesEnum, UserRoleType } from 'types';

import { Styled } from './ClientContextTicketsPage.styled';

type SupportTickets = {
	data: object[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
};

type ClientContextTicketsPageProp = {
	userRole: UserRoleType;
};

const ClientContextTicketsPage = ({ userRole }: ClientContextTicketsPageProp) => {
	const { clientId } = useParams();
	const navigate = useNavigate();

	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);
	const [tableParams, setTableParams] = useState({});
	const dispatch = useDispatch();

	const clientStore = useContext(ClntContext)[clientId];
	const loading: GenericType = useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading);
	const tickets: SupportTickets = useSelector(clientStore?.backOfficeSelectors?.getBOTicketsState);
	const getTicketsRequested: (params: { active: boolean; page?: number; size?: number }) => void =
		clientStore?.backOfficeActions?.geTicketsRequested;
	const deleteTicketByIdRequested: (ticketId: number, callback: () => void) => void =
		clientStore?.backOfficeActions?.deleteTicketByIdRequested;

	const { pageIndex, pageSize, totalCount, data } = tickets || {};

	const isActiveTab = tab === TabsTitleEnum.ActiveTab;
	const isSuperUser = userRole?.name === UserRoleNamesEnum.BACK_SUPER_USER;

	const handleChangeTabs = (id: string) => {
		setTab(id);
	};

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			setTableParams({ ...tableParams, page, size });
		},
		[isActiveTab, tableParams],
	);

	const handleDeleteItem = (ticketId: number) => {
		dispatch(
			deleteTicketByIdRequested(ticketId, () => {
				dispatch(
					getTicketsRequested({ active: isActiveTab, page: pageIndex, size: pageSize, clientId }),
				);
			}),
		);
	};

	useEffect(() => {
		setTableParams({
			...tableParams,
			status: '',
			active: isActiveTab,
			page: 0,
			size: DEFAULT_PAGE_SIZE,
			clientId,
		});
	}, [tab, clientId]);

	const handleSearch = (value: string) => {
		setTableParams({ ...tableParams, page: 1, search: value });
	};

	const handleStatusFilter = (value: number) => {
		setTableParams({ ...tableParams, page: 1, status: value });
	};

	const handleTypeFilter = (value: number) => {
		setTableParams({ ...tableParams, page: 1, type: value });
	};

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			sorter,
		});
	};

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		dispatch(
			getTicketsRequested({
				clientId,
				active: isActiveTab,
				page: tableParams?.page || 0,
				size: tableParams?.size || DEFAULT_PAGE_SIZE,
				...(tableParams?.search && { search: tableParams?.search }),
				...(isActiveTab && tableParams?.status && { status: tableParams?.status }),
				...(tableParams?.type && { type: tableParams?.type }),
				...(tableParams?.sorter?.order &&
					tableParams?.sorter?.field && {
					sort: `${tableParams?.sorter?.field},${
						ordersMap[tableParams?.sorter?.order || 'ascend']
					}`,
				}),
			}),
		);
	}, [tableParams, isActiveTab]);

	const columnsData = columns(isActiveTab, isSuperUser, handleDeleteItem, clientId).slice(1);

	const statusOptions = useMemo(() => createOptionsWithAll(TicketStatusOptions), []);
	const typeOptions = useMemo(() => createOptionsWithAll(TicketTypeOptions), []);

	return (
		<>
			<Styled.Head>
				<Styled.Title>Tickets</Styled.Title>
				<Button
					type='primary'
					onClick={() =>
						navigate(`${Routes.BOClientContext}/${clientId}${Routes.CreateSupportTicket}`)
					}
				>
					Create New
				</Button>
			</Styled.Head>
			<Tabs
				defaultActiveKey={TabsTitleEnum.ActiveTab}
				tabBarStyle={{ textTransform: 'uppercase' }}
				onChange={handleChangeTabs}
				items={[
					{
						label: 'Active',
						key: TabsTitleEnum.ActiveTab,
						active: true,
						children: (
							<Table
								pageSize={pageSize}
								current={pageIndex}
								loading={loading?.getTicketsLoad}
								total={totalCount}
								data={data}
								columns={columnsData}
								onChange={handleTablePaginationChange}
								onPageSizeChange={handleTablePaginationChange}
								onTableChange={handleTableChange}
							/>
						),
					},
					{
						label: 'Resolved',
						key: TabsTitleEnum.ArchiveTab,
						children: (
							<Table
								pageSize={pageSize}
								current={pageIndex}
								loading={loading?.getTicketsLoad}
								total={totalCount}
								data={data}
								columns={columnsData}
								onChange={handleTablePaginationChange}
								onPageSizeChange={handleTablePaginationChange}
								onTableChange={handleTableChange}
							/>
						),
					},
				]}
				tabBarExtraContent={
					<div style={{ textTransform: 'capitalize' }}>
						<SearchFilterSortWrapper
							search={{
								onSearch: handleSearch,
								isDebounce: true,
								placeholder: 'Search',
								styles: { width: '300px', flexBasis: 'auto' },
							}}
							filter={
								isActiveTab
									? {
										mode: 'single',
										options: statusOptions,
										onFilter: handleStatusFilter,
										label: 'Status',
										width: '160px',
										placeholder: 'All',
									  }
									: undefined
							}
							secondFilter={{
								mode: 'single',
								options: typeOptions,
								onFilter: handleTypeFilter,
								width: '170px',
								label: 'Type',
								placeholder: 'All',
							}}
						/>
					</div>
				}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		userRole: unregisteredDucks.unregisteredSelectors.getUserRole(state),
	}),
	{},
)(ClientContextTicketsPage);
