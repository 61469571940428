import React, { type FC } from 'react';

import Box from 'components/Box';
import FinancialReports from 'modules/BackOffice/containers/FinancialReports';

import { Styled } from './BackOfficeFinancePage.styled';

const BackOfficeFinancePage: FC = () => {

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Financial Reports</Styled.Title>
			</Styled.Head>

			<Box>
				<FinancialReports />
			</Box>
		</Styled.Root>
	);
};

export default BackOfficeFinancePage;
