import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Select, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import draftToHtml from 'draftjs-to-html';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-calculate';
import { isEmpty, omit, pick } from 'lodash';

import API from 'api';
import Box from 'components/Box';
import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import { useMount, useUnmount } from 'hooks';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import ApplicationMethod from 'modules/Common/components/ApplicationMethod';
import AssessmentQuestions from 'modules/Common/components/AssessmentQuestions';
import { AppMethodsFreeData, AppMethodsPaidData } from 'modules/Common/constants';
import AutoFilledInfo from 'modules/Common/containers/EmailTemplates/AutoFilledInfo/AutoFilledInfo';
import { commonDucks } from 'modules/Common/ducks';
import {
	AppMethodsEnum,
	JobClassesEnum,
	JobClassesIdsEnum,
	PostJobTypeIdsEnum,
	EmailVariablesEnum,
} from 'modules/Common/types';
import {
	isDraftJsString,
	isHTMLString,
	htmlStringToDraftJsString,
} from 'modules/Common/utils/editorHelper';
import { handleCheckApplicationFormMethod } from 'modules/Common/utils/jobHelpers';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { COLORS } from 'theme';
import { IOption, Routes, UserTypesEnum } from 'types';
import { getDateAndTime, getExistingFields } from 'utils/helpers';
import { composeValidators, requiredFieldValidator, textValidator } from 'utils/validators';

import {
	AllowedJobFields,
	getApplicationFormAttachmentThankYouMessage,
	getApplicationFormUrlThankYouMessage,
	JobClasses,
	JobUpgradesData,
	UpgradesFields,
} from './JobChangesFormContainer.constants';
import { Styled } from './JobChangesFormContainer.styled';
import { JobFormProps, IBOJobValues } from './JobChangesFormContainer.types';
import MainInfo from './MainInfo';
import Upgrades from './Upgrades';

const { Option } = Select;

export const manageJobOnVeritone = async (
	loadingSetter: (newLoadingState: boolean) => void,
	jobId: number,
) => {
	try {
		loadingSetter(true);
		const res = await API.jobsService.getVeritoneUrl(jobId);

		if (res?.veritoneUrl) {
			window.open(res.veritoneUrl, '_blank');
		}
	} catch (e: unknown) {
		if (typeof e === 'string') {
			console.error(e);
			message.error(
				'Sorry, some unexpected issues occurred while posting the job from Broadbean. Please try again later or contact development team',
			);
		}
	} finally {
		loadingSetter(false);
	}
};

const JobChangesFormContainer: FC<JobFormProps> = ({
	jobId,
	currentBOUser,
	clientId,
	jobData,
	jobTypes,
	clientsData,
	employmentTypes,
	educationTypes,
	jobIndustries,
	saveFreeJobRequested,
	savePaidJobRequested,
	updateFreeJobRequested,
	updatePaidJobRequested,
	getJobTypesRequested,
	getEmploymentTypesRequested,
	getEducationTypesByRegionId,
	getClientsListRequested,
	getDefaultMessageByClientId,
	getBackOfficeJobById,
	getJobIndustriesRequested,
	getClientByIdShortRequested,
	resetBackOfficeJobChangesForm,
	resetEducationTypes,
	deleteAssessmentFormRequested,
	defaultThankYouMessage,
	isEditMode,
	isClientContext,
	loadingBackoffice,
	loading,
}) => {
	const navigate = useNavigate();

	const [jobClass, setJobClass] = useState<string>(
		(jobData?.jobClass && JobClassesIdsEnum[jobData?.jobClass]) || JobClassesIdsEnum.PREMIUM,
	);
	const [isAppUrlMessageEditable, setAppUrlEmailEditable] = useState<boolean>(
		!!jobData?.applicationFormUrlThankYouMessage,
	);
	const [currentClientId, setCurrentClientId] = useState<number | null>(null);
	const [isAppAttachmentMessageEditable, setAppAttachmentEmailEditable] = useState<boolean>(
		!!jobData?.applicationFormAttachmentThankYouMessage,
	);
	const [isUKClient, setIsUKClient] = useState(true);
	const filteredJobClasses = useMemo(() => {
		return JobClasses.filter((item) => {
			if (!isEditMode && item.id === PostJobTypeIdsEnum.PendingBoost) return false;
			if (!isUKClient && item.id === PostJobTypeIdsEnum.PaidJob) return false;

			return true;
		});
	}, [isUKClient, JobClasses]);

	const isFreeJob = jobClass === PostJobTypeIdsEnum.FreeJob;
	const isPaidJob = jobClass === PostJobTypeIdsEnum.PaidJob;
	const isPendingBoost = jobClass === PostJobTypeIdsEnum.PendingBoost;
	const isPublished = jobData?.jobClass === JobClassesEnum.PaidJob && !!jobData?.startDate;

	const onSubmit = (values: IBOJobValues) => {
		const appMethodsValues = {
			[AppMethodsEnum.cvAndCoverLetter]:
				values?.applicationMethod === AppMethodsEnum.cvAndCoverLetter,
			[AppMethodsEnum.cvAndCoverLetterEmail]:
				values?.applicationMethod === AppMethodsEnum.cvAndCoverLetterEmail,
			[AppMethodsEnum.applicationForm]:
				values?.applicationMethod === AppMethodsEnum.applicationForm,
		};

		const isApplicationFormUrl = handleCheckApplicationFormMethod(
			values?.applicationMethod,
			!!values?.applicationFormUrl && !values?.applicationFormAttachment,
		);

		const preparedValues = {
			...pick(values, AllowedJobFields),
			...appMethodsValues,
			...(isApplicationFormUrl && {
				applicationFormUrlThankYouMessage: values?.applicationFormUrlThankYouMessage,
			}),
			...(!appMethodsValues?.applicationForm && {
				defaultThankYouMessage: values?.defaultThankYouMessage,
				applicationFormUrl: null,
			}),
			...(!appMethodsValues?.cvAndCoverLetterEmail && { applicationEmail: null }),
		};

		if (isDraftJsString(preparedValues?.jobDescription)) {
			const convertedToJson =
				typeof preparedValues.jobDescription === 'string'
					? JSON.parse(preparedValues.jobDescription)
					: preparedValues.jobDescription;

			preparedValues.jobDescription = draftToHtml(convertedToJson);
		}

		if (isFreeJob || isPendingBoost) {
			if (isEditMode) {
				updateFreeJobRequested(
					{
						...preparedValues,
						jobClass: jobData?.jobClass,
						referenceId: values?.referenceId,
						educationType: values?.educationType || null,
						employmentType: values?.employmentType || null,
						jobIndustry: values?.jobIndustry || null,
						experienceLevel: values?.experienceLevel || '',
					},
					UserTypesEnum.BACKOFFICE,
					() =>
						navigate(
							isClientContext
								? `${Routes.BOClientContext}/${clientId}${Routes.Jobs}`
								: `${Routes.BackOffice}${Routes.Jobs}`,
						),
				);
			} else {
				saveFreeJobRequested(
					{
						...preparedValues,
						jobStartDate: getDateAndTime(values.jobStartDate, { withSeconds: true }),
						clientId: values?.clientId,
					},
					UserTypesEnum.BACKOFFICE,
					() =>
						navigate(
							isClientContext
								? `${Routes.BOClientContext}/${clientId}${Routes.Jobs}`
								: `${Routes.BackOffice}${Routes.Clients}`,
						),
				);
			}
		} else if (isPaidJob) {
			const fieldsToOmit: string[] = [...UpgradesFields, 'jobStartDate'];

			const jobUpgradesValues = pick(values, UpgradesFields);

			if (isApplicationFormUrl) {
				fieldsToOmit.push('assessmentQuestions');
			}

			const isApplicationAttachment = handleCheckApplicationFormMethod(
				values?.applicationMethod,
				!!values?.applicationFormAttachment,
			);

			const preparedPaidValues = omit(
				{
					...preparedValues,
					clientId: values?.clientId,
					...(isEditMode && {
						startDate: getDateAndTime(values?.jobStartDate, { withSeconds: true }),
						endDate: getDateAndTime(values?.jobEndDate, { withSeconds: true }),
						jobClass: jobData?.jobClass || JobClassesEnum[jobClass],
						referenceId: values?.referenceId,
						attachments: [],
					}),
					...(isApplicationAttachment && {
						applicationFormAttachment: values?.applicationFormAttachment,
						applicationFormAttachmentThankYouMessage:
							values?.applicationFormAttachmentThankYouMessage,
					}),
					jobUpgrades: JobUpgradesData.reduce((acc, cur) => {
						if (cur?.value && jobUpgradesValues[cur?.value]) {
							acc.push(cur?.id);
						}

						return acc;
					}, []),
				},
				fieldsToOmit,
			);

			if (isEditMode) {
				updatePaidJobRequested(preparedPaidValues, false, UserTypesEnum.BACKOFFICE, () =>
					navigate(
						isClientContext
							? `${Routes.BOClientContext}/${clientId}${Routes.Jobs}`
							: `${Routes.BackOffice}${Routes.Jobs}`,
					),
				);
			} else {
				savePaidJobRequested(preparedPaidValues, UserTypesEnum.BACKOFFICE, () =>
					navigate(
						isClientContext
							? `${Routes.BOClientContext}/${clientId}${Routes.Jobs}`
							: `${Routes.BackOffice}${Routes.Clients}`,
					),
				);
			}
		}
	};

	const handleChangeJobClass = (id: string) => {
		setJobClass(id);
	};

	const handleChangeClient = useCallback((id: number) => {
		setCurrentClientId(id);
		getDefaultMessageByClientId(id);
	}, []);

	const handleDeleteAssessmentForm = useCallback(() => {
		jobId && deleteAssessmentFormRequested(+jobId, true);
	}, []);

	useMount(() => {
		!jobTypes?.length && getJobTypesRequested();
		!employmentTypes?.length && getEmploymentTypesRequested();
		!jobIndustries?.length && getJobIndustriesRequested();
		getClientsListRequested();
	});

	useEffect(() => {
		if (isEditMode && jobId) {
			getBackOfficeJobById(+jobId);
		}
		if (isClientContext && clientId) {
			getDefaultMessageByClientId(+clientId);
		}
	}, [jobId, isEditMode, clientId]);

	useEffect(() => {
		if (isClientContext && clientId && !educationTypes?.length) {
			getClientByIdShortRequested(clientId, (val) => {
				getEducationTypesByRegionId(val?.region);
			});
		}
	}, [isClientContext, clientId]);

	useEffect(() => {
		if (isEditMode && jobData?.jobClass) {
			setJobClass(JobClassesIdsEnum[jobData?.jobClass]);
		}
		isEditMode && jobData?.applicationFormUrlThankYouMessage && setAppUrlEmailEditable(false);
		isEditMode &&
			jobData?.applicationFormAttachmentThankYouMessage &&
			setAppAttachmentEmailEditable(false);
	}, [jobData?.jobClass, isEditMode]);

	useUnmount(() => resetBackOfficeJobChangesForm());
	useUnmount(() => resetEducationTypes());

	const formDecorator = useMemo(
		() =>
			createDecorator(
				{
					field: 'clientId',
					updates: {
						applicationFormAttachmentThankYouMessage: (id, allValues?: Partial<IBOJobValues>) => {
							const formClientId = Number(id);
							const client = formClientId && clientsData?.find((item) => item.id === formClientId);
							if (client) {
								getClientByIdShortRequested(client?.id, (val) => {
									getEducationTypesByRegionId(val?.region);
									const regionIsUk = val?.region === 1;
									setIsUKClient(regionIsUk);

									if (!isEditMode) {
										if (!regionIsUk) {
											setJobClass(JobClassesIdsEnum.SELF_SERVICE);
										}
									}
								});
							}

							if (
								!jobData?.applicationFormAttachmentThankYouMessage &&
								client?.name &&
								!isAppAttachmentMessageEditable
							) {
								return getApplicationFormAttachmentThankYouMessage();
							} else {
								return allValues?.applicationFormAttachmentThankYouMessage;
							}
						},
						applicationFormUrlThankYouMessage: (id, allValues?: Partial<IBOJobValues>) => {
							if (!jobData?.applicationFormUrl && !isAppUrlMessageEditable) {
								return getApplicationFormUrlThankYouMessage();
							} else {
								return allValues?.applicationFormUrlThankYouMessage;
							}
						},
					},
				},
				{
					field: 'applicationFormUrl',
					updates: {
						applicationFormUrlThankYouMessage: async (value, allValues?: Partial<IBOJobValues>) => {
							if (!jobData?.applicationFormUrlThankYouMessage && !isAppUrlMessageEditable) {
								return getApplicationFormUrlThankYouMessage();
							} else {
								return allValues?.applicationFormUrlThankYouMessage;
							}
						},
					},
				},
			),
		[
			isEditMode,
			clientsData,
			getEducationTypesByRegionId,
			defaultThankYouMessage,
			isAppUrlMessageEditable,
			isAppAttachmentMessageEditable,
		],
	) as never;

	const initialValues = useMemo(
		() => ({
			...(!isEmpty(jobData) && isEditMode && pick(jobData, AllowedJobFields)),
			clientId:
				clientId || currentClientId
					? clientId || currentClientId
					: isEditMode
						? jobData?.client
						: null,
			jobStartDate: isEditMode ? jobData?.startDate && dayjs(jobData?.startDate) : dayjs(),
			jobEndDate: isEditMode ? jobData?.endDate && dayjs(jobData?.endDate) : null,
			applicationMethod: isEditMode ? jobData?.applicationMethod : AppMethodsEnum.cvAndCoverLetter,
			jobType: isEditMode ? jobData?.jobType?.id : null,
			jobIndustry: isEditMode ? jobData?.jobIndustry?.id : null,
			jobClass: isEditMode ? jobData?.jobClass : null,
			educationType: isEditMode ? jobData?.educationType?.id : null,
			employmentType: isEditMode ? jobData?.employmentType?.id : null,
			referenceId: isEditMode ? jobData?.reference : null,
			jobDescription: isHTMLString(jobData?.jobDescription)
				? htmlStringToDraftJsString(jobData?.jobDescription)
				: jobData?.jobDescription,
			applicationEmail:
				isEditMode && jobData?.cvAndCoverLetterEmail ? jobData?.applicationEmail : null,
			applicationFormUrl:
				isEditMode && jobData?.applicationForm && jobData?.applicationFormUrl
					? jobData?.applicationFormUrl
					: null,
			applicationFormAttachment:
				isEditMode && jobData?.applicationForm && jobData?.applicationFormAttachment?.referenceUuid
					? jobData?.applicationFormAttachment?.referenceUuid
					: null,
			defaultThankYouMessage: jobData?.applicationThankYouMessage
				? jobData?.applicationThankYouMessage
				: defaultThankYouMessage,
			...(isEditMode && getExistingFields(JobUpgradesData, jobData?.jobUpgrades)),
			...(jobData?.applicationFormUrlThankYouMessage && {
				applicationFormUrlThankYouMessage: jobData?.applicationFormUrlThankYouMessage,
			}),
			...(jobData?.applicationFormAttachmentThankYouMessage && {
				applicationFormUrlThankYouMessage: jobData?.applicationFormAttachmentThankYouMessage,
			}),
		}),
		[jobData, isEditMode, isClientContext, defaultThankYouMessage],
	);

	const [isLoadingVeritoneUrl, setLoadingVeritoneUrl] = useState(false);
	const handleManageJobOnVeritone = useCallback(
		() => manageJobOnVeritone(setLoadingVeritoneUrl, jobData.id),
		[jobData],
	);

	if (
		loadingBackoffice?.getBackofficeJobByIdLoad ||
		loadingBackoffice?.getClientsShortLoad ||
		loading?.getEducationTypesLoad ||
		loading?.getEmploymentTypesLoad ||
		loading?.getJobIndustriesLoad ||
		loading?.getJobTypesLoad
	) {
		return <Spinner fixed />;
	}

	const submitButtonLoading = loading?.savePaidJobLoad || loading?.saveFreeJobLoad;

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				decorators={[formDecorator]}
				mutators={{
					...arrayMutators,
				}}
				render={({ handleSubmit, dirty, values, form }) => {
					const jobClassColor =
						jobClass === JobClassesIdsEnum.PREMIUM ? COLORS.orange : COLORS.green;
					const jobClassBgColor =
						jobClass === JobClassesIdsEnum.PREMIUM ? COLORS.lightOrange : COLORS.lightGreen;

					const showAttachmentThanksMessage =
						values?.applicationMethod === AppMethodsEnum.applicationForm &&
						!!values?.applicationFormAttachment;

					const isApplicationMessage = handleCheckApplicationFormMethod(
						values?.applicationMethod,
						!!values?.applicationFormUrl && !values?.applicationFormAttachment,
					);

					const showDefaultMessage =
						values?.applicationMethod === AppMethodsEnum.cvAndCoverLetter ||
						values?.applicationMethod === AppMethodsEnum.cvAndCoverLetterEmail;

					const appAttachedMessageInitValue = jobData?.applicationFormAttachmentThankYouMessage
						? jobData?.applicationFormAttachmentThankYouMessage
						: getApplicationFormAttachmentThankYouMessage();
					const appUrlMessageInitValue = jobData?.applicationFormUrlThankYouMessage
						? jobData?.applicationFormUrlThankYouMessage
						: getApplicationFormUrlThankYouMessage();

					const hideAssessmentForm =
						(isPaidJob && isApplicationMessage) ||
						(isFreeJob && handleCheckApplicationFormMethod(values?.applicationMethod, true));

					return (
						<form onSubmit={handleSubmit}>
							<Box>
								<Styled.FormHead>
									<div>
										{isEditMode && !isPublished && !jobData?.endDate && isUKClient && (
											<p>Not Yet Posted to Broadbean</p>
										)}
										{isEditMode && currentBOUser?.veritoneUser?.id && isUKClient && (
											<Button
												buttonType={ButtonTypes.primary}
												disabled={isLoadingVeritoneUrl}
												onClick={handleManageJobOnVeritone}
											>
												Manage Job from Broadbean
											</Button>
										)}
									</div>
									<Styled.SelectWrapper
										color={isEditMode ? 'inherit' : jobClassColor}
										bgColor={isEditMode ? 'none' : jobClassBgColor}
									>
										<Field name='jobTypeField'>
											{() => (
												<Select
													value={
														(jobData?.jobClass && JobClassesIdsEnum[jobData?.jobClass]) ||
														jobClass ||
														null
													}
													onChange={handleChangeJobClass}
													disabled={isEditMode}
												>
													{filteredJobClasses?.map((option: IOption) => (
														<Option key={option.id} value={option.value}>
															{option.name}
														</Option>
													))}
												</Select>
											)}
										</Field>
									</Styled.SelectWrapper>
								</Styled.FormHead>
								<MainInfo
									jobTypes={jobTypes}
									clientsData={clientsData}
									educationTypes={educationTypes}
									employmentTypes={employmentTypes}
									jobIndustries={jobIndustries}
									onChangeClient={handleChangeClient}
									isPaidJob={isPaidJob}
									isPublished={isPublished}
									isEditMode={isEditMode}
									isClientContext={isClientContext}
								/>
							</Box>

							<ApplicationMethod
								appMethodsData={isPaidJob ? AppMethodsPaidData : AppMethodsFreeData}
								defaultValue={values?.applicationMethod}
								defaultUploadFile={
									isEditMode && jobData?.applicationForm && jobData?.applicationFormAttachment
								}
								isPaidJob={isPaidJob}
								setValues={form.change}
							/>

							{!hideAssessmentForm && (
								<AssessmentQuestions form={form} onDeleteForm={handleDeleteAssessmentForm} />
							)}

							{isPaidJob && <Upgrades jobUpgrades={JobUpgradesData} />}

							{showAttachmentThanksMessage && isPaidJob && (
								<Box>
									<h2>{'Thank you message'}</h2>
									<AutoFilledInfo
										emailVariablesList={[
											EmailVariablesEnum.CANDIDATE_NAME,
											EmailVariablesEnum.COMPANY_NAME,
											EmailVariablesEnum.JOB_TITLE,
										]}
									/>
									<Field
										name={'applicationFormAttachmentThankYouMessage'}
										validate={composeValidators(requiredFieldValidator, textValidator)}
										initialValue={
											jobData?.applicationFormAttachmentThankYouMessage ??
											appAttachedMessageInitValue
										}
									>
										{({ input, meta }) => (
											<FieldWrapper
												name={'applicationFormAttachmentThankYouMessage'}
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<TextArea
													{...input}
													value={
														isAppAttachmentMessageEditable
															? input.value
															: values?.applicationFormAttachmentThankYouMessage
													}
													onFocus={(e) => {
														e?.preventDefault();
														!isAppUrlMessageEditable && setAppUrlEmailEditable(true);
													}}
													placeholder=''
													autoSize={{ minRows: 10 }}
												/>
											</FieldWrapper>
										)}
									</Field>
								</Box>
							)}

							{isApplicationMessage && (
								<Box>
									<h2>{'Application URL Message'}</h2>
									<AutoFilledInfo
										emailVariablesList={[
											EmailVariablesEnum.CANDIDATE_NAME,
											EmailVariablesEnum.COMPANY_NAME,
											EmailVariablesEnum.JOB_TITLE,
											EmailVariablesEnum.URL,
										]}
									/>
									<Field
										name={'applicationFormUrlThankYouMessage'}
										validate={composeValidators(requiredFieldValidator, textValidator)}
										initialValue={
											jobData?.applicationFormUrlThankYouMessage ?? appUrlMessageInitValue
										}
									>
										{({ input, meta }) => (
											<FieldWrapper
												name={'applicationFormUrlThankYouMessage'}
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<TextArea
													{...input}
													value={
														isAppUrlMessageEditable
															? input.value
															: values?.applicationFormUrlThankYouMessage
													}
													placeholder=''
													onFocus={(e) => {
														e?.preventDefault();
														setAppUrlEmailEditable(true);
													}}
													autoSize={{ minRows: 10 }}
												/>
											</FieldWrapper>
										)}
									</Field>
								</Box>
							)}

							{showDefaultMessage && (
								<Box>
									<h2>Message</h2>
									<AutoFilledInfo
										emailVariablesList={[
											EmailVariablesEnum.CANDIDATE_NAME,
											EmailVariablesEnum.COMPANY_NAME,
											EmailVariablesEnum.JOB_TITLE,
										]}
									/>
									<Field
										name={'defaultThankYouMessage'}
										validate={composeValidators(requiredFieldValidator, textValidator)}
										initialValue={initialValues.defaultThankYouMessage}
									>
										{({ input, meta }) => (
											<FieldWrapper
												name={'defaultThankYouMessage'}
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<TextArea {...input} autoSize={{ minRows: 10 }} placeholder='' />
											</FieldWrapper>
										)}
									</Field>
								</Box>
							)}

							<Styled.ButtonBox>
								{!isEditMode && (
									<Button
										type='button'
										buttonType={ButtonTypes.default}
										disabled={!dirty || !!submitButtonLoading}
										onClick={() => form.reset()}
									>
										Cancel
									</Button>
								)}
								<Button
									type='submit'
									buttonType={ButtonTypes.primary}
									disabled={!dirty || !!submitButtonLoading}
									loading={!!submitButtonLoading}
								>
									Send
								</Button>
							</Styled.ButtonBox>
						</form>
					);
				}}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		jobTypes: commonDucks.commonSelectors.getJobTypes(state),
		employmentTypes: commonDucks.commonSelectors.getEmploymentTypes(state),
		jobIndustries: commonDucks.commonSelectors.getJobIndustries(state),
		educationTypes: commonDucks.commonSelectors.getEducationTypes(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
		currentBOUser: unregisteredDucks.unregisteredSelectors.getUser(state),
	}),
	{
		getJobTypesRequested: commonDucks.commonActions.getJobTypesRequested,
		getEmploymentTypesRequested: commonDucks.commonActions.getEmploymentTypesRequested,
		getEducationTypesByRegionId: commonDucks.commonActions.getEducationTypesByRegionIdRequested,
		getJobIndustriesRequested: commonDucks.commonActions.getJobIndustriesRequested,
		saveFreeJobRequested: commonDucks.commonActions.saveFreeJobRequested,
		savePaidJobRequested: commonDucks.commonActions.savePaidJobRequested,
		updateFreeJobRequested: commonDucks.commonActions.updateFreeJobRequested,
		updatePaidJobRequested: commonDucks.commonActions.updatePaidJobRequested,
		resetEducationTypes: commonDucks.commonActions.resetEducationTypes,
		getClientByIdShortRequested: backOfficeDucks.backOfficeActions.getClientByIdShortRequested,
		deleteAssessmentFormRequested: commonDucks.commonActions.deleteAssessmentFormRequested,
	},
)(JobChangesFormContainer);
