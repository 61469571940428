import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import CreateEditEmployee from 'modules/HR/containers/CreateEditEmployee';
import { Routes } from 'modules/HR/routes/types';

import PageNavTitle from '../../../Common/components/PageNavTitle';

import { Styled } from './HRCreateEditEmployeePage.styled';

export const HRCreateEditEmployeePage = () => {
	const { employeeId } = useParams();
	const { pathname } = useLocation();
	const isEditMode = pathname.includes(Routes.EmployeeEdit);

	const navPath = `${Routes.HRModule}${Routes.PeopleManagement}`;

	return (
		<Styled.Root>
			<PageNavTitle
				title={isEditMode ? 'Edit an Employee' : 'Create a New Employee'}
				navigationLink={navPath}
			/>
			<CreateEditEmployee isEditMode={isEditMode} employeeId={employeeId} />
		</Styled.Root>
	);
};
