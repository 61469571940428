import React, { type FC } from 'react';

import TimeOffPieChart from '../TimeOffPieChart';

import { Styled } from './TimeOffUsedStatisticsEmployee.styled';
import { StatisticDataType, TimeOffs, TimeOffTypes } from './TimeOffUsedStatisticsEmployee.types';

type TimeOffUsedStatisticsProps = {
	statisticData: StatisticDataType;
};

const TimeOffUsedStatisticsEmployee: FC<TimeOffUsedStatisticsProps> = ({ statisticData }) => {
	const { vacationsUsed = 0, vacationsByDefault = 0 } = statisticData;
	const percentUsed = Math.round((vacationsUsed / vacationsByDefault) * 100);

	return (
		<Styled.Root>
			<Styled.Title>Time Offs Used This Year</Styled.Title>
			<Styled.PieWrap>
				<TimeOffPieChart
					offsetPercentage={0}
					color={TimeOffs[TimeOffTypes.VACATION].color}
					percentUsed={percentUsed}
					width='174px'
					borderWidth='24px'
					fontSize='32px'
				/>
			</Styled.PieWrap>
			<Styled.StatisticsDataWrap>
				<Styled.StatisticsDataEmoji>
					{TimeOffs[TimeOffTypes.VACATION].emoji}
				</Styled.StatisticsDataEmoji>
				<Styled.StatisticsName>Vacation</Styled.StatisticsName>
				<Styled.StatisticsData>{`${vacationsUsed}/${vacationsByDefault} days`}</Styled.StatisticsData>
			</Styled.StatisticsDataWrap>
		</Styled.Root>
	);
};

export default TimeOffUsedStatisticsEmployee;
