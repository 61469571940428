import React, { type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import ATSThemedButton from 'modules/ATS/components/ATSThemedButton';
import AtsSupportTickets from 'modules/ATS/containers/AtsSupportTickets';
import { Routes } from 'types';

import { Styled } from './AtsTickets.styled';

const Clients: FC = () => {
	const navigate = useNavigate();

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Support Tickets</Styled.Title>
				<ATSThemedButton
					buttonType={ButtonTypes.primary}
					onClick={() => navigate(`${Routes.ATS}${Routes.SupportCenterTicketCreate}`)}
				>
					Create New
				</ATSThemedButton>
			</Styled.Head>

			<Box>
				<AtsSupportTickets />
			</Box>
		</Styled.Root>
	);
};

export default Clients;
