import React, { type FC, useCallback, useRef } from 'react';
import { Field, Form } from 'react-final-form';

import TextArea from 'antd/es/input/TextArea';
import { FormApi } from 'final-form';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import { trimSpacesFormValues } from 'utils/helpers';
import { requiredFieldValidator } from 'utils/validators';

import { Styled } from './DeclineRequestModal.styled';

type FormValues = {
	declineComment?: string;
};

type DeclineRequestModalProps = {
	requestId: null | number;
	onCancel: () => void;
	declineTimeOffRequestRequested: (
		requestId: number,
		values: { declineComment: string },
		callback?: () => void,
	) => void;
	rootClassName?: string;
	className?: string;
	loading?: boolean;
};

export const DeclineRequestModal: FC<DeclineRequestModalProps> = ({
	requestId,
	onCancel,
	rootClassName = '',
	className = '',
	loading,
	declineTimeOffRequestRequested,
}) => {
	const formRef = useRef<FormApi<FormValues, Partial<FormValues>> | null>(null);

	const handleCancel = useCallback(() => {
		onCancel();
		formRef.current?.reset();
	}, [onCancel]);

	const onSubmit = useCallback(
		(values: FormValues) => {
			const callback = () => {
				handleCancel();
			};
			const trimmedValues = trimSpacesFormValues(values);

			// declineTimeOffRequestRequested(requestId as number, trimmedValues, callback);
		},
		[requestId, handleCancel],
	);

	return (
		<Styled.Modal
			open={!!requestId}
			footer={null}
			centered
			onCancel={handleCancel}
			width='auto'
			rootClassName={rootClassName}
			className={className}
		>
			<Styled.ModalHead>
				<h3>Please add a comment why are you declining the request</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Form
					onSubmit={onSubmit}
					initialValues={{}}
					autoComplete='off'
					render={({ handleSubmit, submitting, form }) => {
						formRef.current = form;

						return (
							<form onSubmit={handleSubmit}>
								<Styled.FieldsBlock>
									<Field name='declineComment' validate={requiredFieldValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='declineComment'
												label='Comment'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												required
											>
												<TextArea
													{...input}
													placeholder={'Enter your comment'}
													autoSize={{ minRows: 4 }}
												/>
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldsBlock>
								<Styled.ButtonBox>
									<Button type='button' buttonType={ButtonTypes.tertiary} onClick={handleCancel}>
										Cancel
									</Button>
									<Button
										type='submit'
										buttonType={ButtonTypes.primary}
										disabled={submitting || loading}
										loading={submitting || loading}
									>
										Decline request
									</Button>
								</Styled.ButtonBox>
							</form>
						);
					}}
				/>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
