import Cookies from 'js-cookie';

import { get, post, put, patch, del } from 'api/axiosInstance';
import { RequestsEnum, CookiesType } from 'types';
import { getTableParams } from 'utils/helpers';

export const getAtsTickets = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.ATSTickets}${queryParams}`);
};

export const getBackofficeTickets = async ({ page, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BackofficeTickets}${queryParams}`);
};

export const getBackofficeClientTickets = async ({ page, clientId, ...params }) => {
	const queryParams = getTableParams(page, params);

	return get(`${RequestsEnum.BackofficeClientTickets}${queryParams}&client=${clientId}`);
};

export const getBackofficeTicketById = async (id) => get(`${RequestsEnum.BackofficeTickets}/${id}`);

export const getAtsTicketById = async (id) => get(`${RequestsEnum.ATSTickets}/${id}`);

export const getTicketTypes = async () => get(`${RequestsEnum.TicketTypes}`);

export const getTicketStatuses = async () => get(`${RequestsEnum.TicketStatus}`);

export const postATSTicket = async (data) => post(`${RequestsEnum.ATSTickets}`, data);

export const postBackOfficeTicket = async (data) => post(`${RequestsEnum.BackofficeTickets}`, data);

export const updateBackOfficeTicket = async (data) =>
	put(`${RequestsEnum.BackofficeTickets}`, data);

export const updateBackOfficeTicketStatus = async ({ ticketId, ticketStatus }) =>
	patch(`${RequestsEnum.BackofficeTickets}/${ticketId}/status`, { ticketStatus });

export const addBackOfficeTicketNote = async ({ ticketId, ...props }) =>
	post(`${RequestsEnum.BackofficeTickets}/${ticketId}/note`, { ...props });

export const getFileAttachment = async (url) => {
	const token = Cookies.get(CookiesType.JWT);

	const options = {
		headers: {
			'X-Authorization': `Bearer ${token}`,
		},
	};

	try {
		const file = await fetch(url, options).then((res) => res?.blob());

		return file;
	} catch (e) {
		console.error('error with ticket attachments downloading', e);
	}
};

export const deleteTicketById = async (ticketId) =>
	del(`${RequestsEnum.BackofficeTickets}/${ticketId}`);

const ticketsService = {
	getAtsTickets,
	getBackofficeTickets,
	getBackofficeClientTickets,
	getBackofficeTicketById,
	getAtsTicketById,
	getTicketTypes,
	getTicketStatuses,
	postATSTicket,
	postBackOfficeTicket,
	updateBackOfficeTicket,
	updateBackOfficeTicketStatus,
	addBackOfficeTicketNote,
	getFileAttachment,
	deleteTicketById,
};

export default ticketsService;
