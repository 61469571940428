import React, { type FC, useEffect, useCallback, useState } from 'react';

import { Tabs } from 'antd';

import Table from 'components/Table';
import { DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { TabsTitleEnum } from 'modules/Common/types';
import { propsFilter } from 'utils/helpers';

import { UserFields } from './UserAccessContainer.constants';
import { userAccessColumns } from './UserAccessContainer.entities';
import { Styled } from './UserAccessContainer.styled';
import { UserAccessContainerProps } from './UserAccessContainer.types';

const UserAccessContainer: FC<UserAccessContainerProps> = ({
	loading,
	getUsersRequested,
	deActivateUserRequested,
	headerComponent,
	filterComponent,
	searchComponent,
	isClientContext = false,
	editUserLink = '',
	clientId = null,
	role = 0,
	customAction,
	users,
	searchValue,
}) => {
	const [tab, setTab] = useState<string>(TabsTitleEnum.ActiveTab);

	const { pageIndex, pageSize, totalCount, data = [] } = users || {};

	const isActive = tab === TabsTitleEnum.ActiveTab;

	const handleTableChange = useCallback(
		(page: number, size: number) => {
			getUsersRequested(
				{
					page,
					size: size ?? pageSize,
					isActive: tab === TabsTitleEnum.ActiveTab,
					...(role !== 0 && { role }),
					...(searchValue && { search: searchValue }),
				},
				isClientContext,
			);
		},
		[tab, pageSize, role, searchValue],
	);

	const handleDeActivateUser = useCallback(
		(id: number) => {
			deActivateUserRequested(id, !isActive, isClientContext, () => {
				getUsersRequested(
					{
						page: pageIndex,
						size: pageSize,
						isActive: isActive,
						...(role !== 0 && { role }),
						...(searchValue && { search: searchValue }),
					},
					isClientContext,
				);
			});
		},
		[isActive, tab, role, searchValue],
	);

	const handleChangeTabs = (id: string) => {
		getUsersRequested(
			{
				page: pageIndex,
				size: pageSize,
				isActive: id === TabsTitleEnum.ActiveTab,
				...(role !== 0 && { role }),
				...(searchValue && { search: searchValue }),
			},
			isClientContext,
		);
		setTab(id);
	};

	useEffect(() => {
		getUsersRequested(
			{
				page: 0,
				size: DEFAULT_PAGE_SIZE,
				isActive: tab === TabsTitleEnum.ActiveTab,
				...(role !== 0 && { role }),
				...(searchValue && { search: searchValue }),
			},
			isClientContext,
		);
	}, [clientId, role, searchValue]);

	const columns = userAccessColumns(handleDeActivateUser, isActive, editUserLink, customAction);
	const filteredTableData = data?.length && propsFilter(data, UserFields);

	return (
		<Styled.Root>
			<>{headerComponent}</>
			{searchComponent && isActive ? searchComponent : null}
			{filterComponent ? filterComponent({ isActive }) : null}
			<Tabs
				defaultActiveKey={tab}
				onChange={handleChangeTabs}
				activeKey={tab}
				items={[
					{
						label: 'ACTIVE',
						key: TabsTitleEnum.ActiveTab,
						children: (
							<Table
								data={filteredTableData}
								columns={columns}
								loading={loading?.getBoUsersLoad}
								pageSize={pageSize}
								current={pageIndex}
								total={totalCount}
								onChange={handleTableChange}
								onPageSizeChange={handleTableChange}
							/>
						),
					},
					{
						label: 'ARCHIVED',
						key: TabsTitleEnum.ArchiveTab,
						children: (
							<Table
								data={filteredTableData}
								columns={columns}
								loading={loading?.getBoUsersLoad}
								pageSize={pageSize}
								current={pageIndex}
								total={totalCount}
								onChange={handleTableChange}
								onPageSizeChange={handleTableChange}
							/>
						),
					},
				]}
			/>
		</Styled.Root>
	);
};

export default UserAccessContainer;
