import { message } from 'antd';
import { pick } from 'lodash';
import { isEqual } from 'lodash/lang';
import { call, put, select } from 'redux-saga/effects';

import API from 'api';
import { QuestionnaireStatusEnum } from 'modules/ATS/containers/CandidatesApplication/CandidatesApplication.types';
import { atsDucks } from 'modules/ATS/ducks';
import { UserTypesEnum, UserTypesValueEnum } from 'types';

import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, MESSAGES } from '../constants';
import { prepareAssessmentData, displayCandidateStatusUpdateErrors } from '../utils/commonHelpers';

const AllowedAtsParametersJob = [
	'title',
	'jobType',
	'location',
	'salary',
	'experienceLevel',
	'educationType',
	'employmentType',
	'jobIndustry',
	'jobDescription',
	'applicationMethod',
	'cvAndCoverLetter',
	'cvAndCoverLetterEmail',
	'applicationFormUrl',
	'applicationForm',
	'applicationEmail',
	'restricted',
	'attachments',
	'jobUpgrades',
];

export const createSagas = (TYPES) => {
	function* getContractsSaga({ payload, platform }) {
		try {
			const isAtsPlatform = platform === UserTypesValueEnum.ATS;

			const contracts = yield call(
				isAtsPlatform ? API.contractsService.getAtsContracts : API.contractsService.getContracts,
				payload,
			);

			yield put({ type: TYPES.GET_CONTRACTS.SUCCESS, payload: contracts });
		} catch (error) {
			yield put({ type: TYPES.GET_CONTRACTS.FAILED, payload: error });
		}
	}

	function* getContractStatusesSaga() {
		try {
			const contractStatuses = yield call(API.contractsService.getContractStatuses);

			yield put({ type: TYPES.GET_CONTRACT_STATUSES.SUCCESS, payload: contractStatuses });
		} catch (error) {
			yield put({ type: TYPES.GET_CONTRACT_STATUSES.FAILED, payload: error });
		}
	}

	function* updateContractStatusSaga({ payload, params, platform, callback }) {
		try {
			yield call(API.contractsService.updateContractStatus, payload);

			if (callback) {
				yield call(callback);
			} else {
				yield put({
					type: TYPES.GET_CONTRACTS.REQUESTED,
					payload: params,
					platform,
				});
			}

			message.success(MESSAGES.successfullyUpdated);
		} catch (error) {
			yield put({ type: TYPES.GET_CONTRACTS.FAILED, payload: error });
		}
	}

	function* approvePendingContractSaga({ payload, params, cb, platform }) {
		try {
			yield call(API.contractsService.approvePendingContract, payload);

			yield put({
				type: TYPES.GET_CONTRACTS.REQUESTED,
				payload: params,
				platform,
			});

			message.success(MESSAGES.successfullyUpdated);

			yield call(cb);
		} catch (error) {
			yield put({ type: TYPES.GET_CONTRACTS.FAILED, payload: error });
		}
	}

	function* acceptContractByClientIdSaga({ payload, platform, callback }) {
		try {
			yield call(API.contractsService.acceptContractByClientId, payload);

			yield put({ type: TYPES.ACCEPT_CONTRACT_BY_ID.SUCCESS });

			yield put({
				type: TYPES.GET_CONTRACTS.REQUESTED,
				payload: { page: DEFAULT_CURRENT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
				platform,
			});

			yield call(callback);
			message.success(MESSAGES.successfulAcceptedContract);
		} catch (error) {
			yield put({ type: TYPES.ACCEPT_CONTRACT_BY_ID.FAILED, payload: error });
		}
	}

	function* getContractsByClientIdSaga(action) {
		try {
			const contracts = yield call(API.contractsService.getContractsByClientId, action.payload);

			yield put({ type: TYPES.GET_CONTRACTS_BY_CLIENT_ID.SUCCESS, payload: contracts });
		} catch (error) {
			yield put({ type: TYPES.GET_CONTRACTS_BY_CLIENT_ID.FAILED, payload: error });
		}
	}

	function* deleteContractByIdSaga({ payload, cb }) {
		try {
			yield call(API.contractsService.deleteContractById, payload);

			yield put({ type: TYPES.DELETE_CONTRACT_BY_ID.SUCCESS, payload });

			cb && (yield call(cb));

			yield call(message.success, MESSAGES.successfullyDeletedContract);
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.DELETE_CONTRACT_BY_ID.FAILED, payload: e });
		}
	}

	function* getContractReceiptSaga({ payload, isInvoice, callback }) {
		try {
			let receipt;

			if (isInvoice) {
				receipt = yield call(API.invoicesService.getInvoiceReceipt, payload);
			} else {
				receipt = yield call(API.contractsService.getContractReceipt, payload);
			}

			yield put({ type: TYPES.GET_CONTRACT_RECEIPT.SUCCESS, payload: receipt });
			yield call(callback);
		} catch (error) {
			yield put({ type: TYPES.GET_CONTRACT_RECEIPT.FAILED, payload: error });
		}
	}

	function* getContractByIdSaga(action) {
		try {
			const { id, isBO } = action.payload;
			const url = isBO
				? API.contractsService.getBackOfficeContractById
				: API.contractsService.getATSContractById;
			const contract = yield call(url, id);

			yield put({ type: TYPES.GET_CONTRACT_BY_ID.SUCCESS, payload: contract });
		} catch (error) {
			yield put({ type: TYPES.GET_CONTRACT_BY_ID.FAILED, payload: error });
		}
	}

	function* getContractVatSaga() {
		try {
			const credits = yield call(API.contractsService.getContractVat);

			yield put({ type: TYPES.GET_CONTRACT_VAT.SUCCESS, payload: credits });
		} catch (error) {
			yield put({ type: TYPES.GET_CONTRACT_VAT.FAILED, payload: error });
		}
	}

	function* getClientByIdSaga(action) {
		try {
			const client = yield call(API.clientsService.getClientById, action.payload);

			yield put({ type: TYPES.GET_CLIENT_BY_ID.SUCCESS, payload: client });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_BY_ID.FAILED, payload: error });
		}
	}

	function* updateClientSaga(action) {
		try {
			yield call(API.clientsService.patchClient, action.payload);

			message.success(MESSAGES.companyDetailsUpdated);
			yield put({ type: TYPES.UPDATE_CLIENT.SUCCESS });
		} catch (error) {
			message.error(MESSAGES.companyDetailsError);
			yield put({ type: TYPES.UPDATE_CLIENT.FAILED, payload: MESSAGES.companyDetailsError });
		}
	}

	function* getCreditsSaga() {
		try {
			const credits = yield call(API.creditsService.getCredits);

			yield put({ type: TYPES.GET_CREDITS.SUCCESS, payload: credits });
		} catch (error) {
			yield put({ type: TYPES.GET_CREDITS.FAILED, payload: error });
		}
	}

	function* getJobByIdSaga(action) {
		try {
			const job = yield call(API.jobsService.getJobById, action.payload);

			yield put({ type: TYPES.GET_JOB_BY_ID.SUCCESS, payload: job });
		} catch (error) {
			yield put({ type: TYPES.GET_JOB_BY_ID.FAILED, payload: error });
		}
	}

	function* updateApplicationStatusSaga({ payload, callback }) {
		const failedCandidatesIdsObj = {
			upcomingInterviewErrors: [],
			otherErrors: [],
		};
		const updatedSuccessfullyCandidates = [];
		let statusId;

		const { candidateIds, status, jobId } = payload;

		for (const id of candidateIds) {
			try {
				if (!statusId) {
					const statuses = yield call(API.candidateApplicationService.getAvailableStatuses);
					statusId = statuses.find((st) => st.value === status).id;
				}

				const result = yield call(
					API.candidateApplicationService.updateApplicationStatus,
					id,
					statusId,
				);

				updatedSuccessfullyCandidates.push(result);

				yield put({
					type: TYPES.UPDATE_APPLICATION_STATUS.SUCCESS,
					payload: { id, status, jobId },
				});
			} catch (error) {
				yield put({ type: TYPES.UPDATE_APPLICATION_STATUS.FAILED, payload: error });
				if (
					error?.includes(
						'status cannot be changed, as there is at least one upcoming linked interview',
					)
				) {
					failedCandidatesIdsObj.upcomingInterviewErrors.push(id);
				} else {
					failedCandidatesIdsObj.otherErrors.push(id);
				}
			}
		}

		if (
			failedCandidatesIdsObj.upcomingInterviewErrors.length ||
			failedCandidatesIdsObj.otherErrors.length
		) {
			displayCandidateStatusUpdateErrors(failedCandidatesIdsObj, candidateIds?.length);
		} else {
			message.success(
				candidateIds?.length > 1
					? MESSAGES.successfullyUpdatedCandidate
					: MESSAGES.successfullyUpdatedCandidateStatus,
			);
		}

		if (callback) {
			yield call(
				callback,
				[...failedCandidatesIdsObj.upcomingInterviewErrors, ...failedCandidatesIdsObj.otherErrors],
				updatedSuccessfullyCandidates,
			);
		}
	}

	function* getCandidateByIdSaga({ payload }) {
		try {
			const candidateData = yield call(API.candidateApplicationService.getCandidateById, payload);

			yield put({ type: TYPES.GET_CANDIDATE_BY_ID.SUCCESS, payload: candidateData });
		} catch (error) {
			yield put({ type: TYPES.GET_CANDIDATE_BY_ID.FAILED, payload: error });
		}
	}

	function* getOpenJobByIdSaga(action) {
		try {
			const job = yield call(API.jobsService.getOpenJobById, action.payload);

			yield put({ type: TYPES.GET_OPEN_JOB_BY_ID.SUCCESS, payload: job });
		} catch (error) {
			yield put({ type: TYPES.GET_OPEN_JOB_BY_ID.FAILED, payload: error });
		}
	}

	function* saveFreeJobSaga({ cb: callback, platform, payload }) {
		try {
			const { assessmentQuestions, restrictedUserIds, ...data } = payload;
			const isATS = platform === UserTypesEnum.ATS;

			const filteredJobData = isATS ? pick(data, AllowedAtsParametersJob) : data;

			const { isPremiumPlan } = yield select(atsDucks.atsSelectors.getSubscriptionPlans);

			const jobData = yield call(
				isATS ? API.jobsService.saveAtsFreeJob : API.jobsService.saveFreeJob,
				filteredJobData,
			);

			if (assessmentQuestions?.length) {
				const assessmentData = prepareAssessmentData(assessmentQuestions, jobData.id);
				yield call(
					isATS ? API.assessmentService.saveAtsAssessment : API.assessmentService.saveBOAssessment,
					assessmentData,
				);
			}

			if (isATS && restrictedUserIds?.length) {
				yield call(API.jobsService.saveJobRestrictions, {
					jobId: jobData.id,
					restrictedUserIds,
				});
			}

			if (isPremiumPlan) {
				message.success(MESSAGES.successfullySavedSelfJobPremiumPlan);
			} else {
				message.success(MESSAGES.successfullySavedSelfJobFreePlan);
			}

			yield call(callback, jobData.id);
			yield put({ type: TYPES.SAVE_FREE_JOB.SUCCESS });
		} catch (error) {
			console.error(error);
			yield put({ type: TYPES.SAVE_FREE_JOB.FAILED, payload: error });
		}
	}

	function* savePaidJobSaga(action) {
		try {
			const callback = action.cb;
			const { assessmentQuestions, restrictedUserIds, ...data } = action.payload;
			const isATS = action.platform === UserTypesEnum.ATS;

			const jobData = yield call(
				isATS ? API.jobsService.saveAtsPaidJob : API.jobsService.savePaidJob,
				isATS ? pick(data, [...AllowedAtsParametersJob, 'applicationFormAttachment']) : data,
			);

			if (isATS && restrictedUserIds?.length) {
				yield call(API.jobsService.saveJobRestrictions, {
					jobId: jobData.id,
					restrictedUserIds,
				});
			}

			if (assessmentQuestions?.length) {
				const assessmentData = prepareAssessmentData(assessmentQuestions, jobData.id);
				yield call(
					isATS ? API.assessmentService.saveAtsAssessment : API.assessmentService.saveBOAssessment,
					assessmentData,
				);
			}

			if (isATS) {
				yield call(atsDucks.atsActions.resetPostJobChangesFormProcess);
			}

			message.success(MESSAGES.successfullySavedPaidJob);

			yield put({ type: TYPES.SAVE_PAID_JOB.SUCCESS });

			yield call(callback, jobData?.ticketId);
		} catch (error) {
			console.error(error);
			yield put({ type: TYPES.SAVE_PAID_JOB.FAILED, payload: error });
		}
	}

	function* updateFreeJobSaga({ cb: callback, platform, payload }) {
		try {
			let initAssessmentData;
			const { assessmentQuestions, id, restrictedUserIds, ...data } = payload;
			const isBO = platform === UserTypesEnum.BACKOFFICE;
			const isATS = platform === UserTypesEnum.ATS;

			const filteredJobData = isBO
				? { ...data, jobUpgrades: data?.jobUpgrades ? data.jobUpgrades.map((ju) => ju.id) : [] }
				: pick(data, AllowedAtsParametersJob);

			const jobData = yield call(
				isBO ? API.jobsService.updateFreeJob : API.jobsService.updateAtsFreeJob,
				{
					id,
					...filteredJobData,
				},
			);

			if (isATS) {
				const initRestrictedUsers = yield select(atsDucks.atsSelectors.getJobRestrictedUsers);
				const initRestrictedUsersIds = initRestrictedUsers?.map((user) => user.id);

				if (!isEqual(restrictedUserIds, initRestrictedUsersIds)) {
					yield call(API.jobsService.saveJobRestrictions, {
						jobId: jobData.id,
						restrictedUserIds,
					});
				}

				initAssessmentData = yield select(atsDucks.atsSelectors.getPostJobChangesForm);
			}

			if (
				isATS &&
				assessmentQuestions?.length &&
				!isEqual(assessmentQuestions, initAssessmentData?.assessmentQuestions)
			) {
				const assessmentData = prepareAssessmentData(assessmentQuestions, jobData.id);
				yield call(API.assessmentService.saveAtsAssessment, assessmentData);
			}

			if (assessmentQuestions?.length && isBO) {
				const assessmentData = prepareAssessmentData(assessmentQuestions, jobData.id);
				yield call(API.assessmentService.saveBOAssessment, assessmentData);
			}

			if (isBO) {
				message.success(`The ${jobData?.title} has been updated!`);
			} else {
				message.success(MESSAGES.successfullyUpdatedJobFreePlan);
			}

			if (isATS) {
				yield call(atsDucks.atsActions.resetPostJobChangesFormProcess);
			}

			yield call(callback);
			yield put({ type: TYPES.UPDATE_FREE_JOB.SUCCESS });
		} catch (error) {
			console.error(error);
			yield put({ type: TYPES.UPDATE_FREE_JOB.FAILED });
		}
	}

	function* updatePaidJobSaga({ payload, isBoost, platform, callback }) {
		try {
			let boostData;
			let jobPaidData;
			const { assessmentQuestions, client, restrictedUserIds, ...data } = payload;
			const isATS = platform === UserTypesEnum.ATS;
			const isBO = platform === UserTypesEnum.BACKOFFICE;

			if (isBoost) {
				const { jobUpgrades } = payload;
				boostData = yield call(API.jobsService.boostPaidJob, { id: payload?.jobId, jobUpgrades });
			} else {
				jobPaidData = isATS
					? pick(payload, [...AllowedAtsParametersJob, 'applicationFormAttachment'])
					: data;
				yield call(isATS ? API.jobsService.updateAtsPaidJob : API.jobsService.updatePaidJob, {
					id: client,
					...jobPaidData,
				});
			}

			const jobId = isBoost ? payload.jobId : jobPaidData.id;

			if (isATS) {
				const initRestrictedUsers = yield select(atsDucks.atsSelectors.getJobRestrictedUsers);
				const initRestrictedUsersIds = initRestrictedUsers?.map((user) => user.userId);

				if (!isEqual(restrictedUserIds, initRestrictedUsersIds)) {
					yield call(API.jobsService.saveJobRestrictions, {
						jobId: jobId,
						restrictedUserIds,
					});
				}
			}

			const initAssessmentData = yield select(atsDucks.atsSelectors.getPostJobChangesForm);

			if (
				assessmentQuestions?.length &&
				isATS &&
				!isEqual(assessmentQuestions, initAssessmentData?.assessmentQuestions)
			) {
				const assessmentData = prepareAssessmentData(assessmentQuestions, jobId);
				yield call(API.assessmentService.saveAtsAssessment, assessmentData);
			}

			if (assessmentQuestions?.length && isBO) {
				const assessmentData = prepareAssessmentData(assessmentQuestions, jobId);
				yield call(API.assessmentService.saveBOAssessment, assessmentData);
			}

			if (isBO) {
				message.success(`The ${jobPaidData?.title} has been updated!`);
			} else {
				message.success(MESSAGES.successfullySavedPaidJob);
			}

			yield call(callback, boostData?.ticketId);

			yield put({ type: TYPES.UPDATE_PAID_JOB.SUCCESS });
		} catch (error) {
			console.error(error);
			yield put({ type: TYPES.UPDATE_PAID_JOB.FAILED });
		}
	}

	function* getJobTypesSaga() {
		try {
			const types = yield call(API.jobsService.getJobTypes);

			yield put({ type: TYPES.GET_JOB_TYPES.SUCCESS, payload: types });
		} catch (error) {
			yield put({ type: TYPES.GET_JOB_TYPES.FAILED, payload: error });
		}
	}

	function* applyOnJobSaga(action) {
		try {
			const { payload, assessment } = action;
			const application = yield call(API.jobsService.applyOnJob, payload);

			if (assessment) {
				const preparedAssessment = assessment.map((a) => ({
					candidateApplication: application.id,
					...a,
				}));

				yield call(API.assessmentService.assessmentApplicationOpen, preparedAssessment);
			}

			yield put({ type: TYPES.APPLY_ON_JOB.SUCCESS, payload: application });

			action.cb && (yield call(action.cb));
		} catch (error) {
			message.error(error?.response?.data?.errorMessages || 'Application failed');
			yield put({ type: TYPES.APPLY_ON_JOB.FAILED, payload: error });
		}
	}

	function* applyOnCompanySaga({ payload, justSendQuestion, cb }) {
		try {
			const endpoint = justSendQuestion
				? API.jobsService.sendAQuestion
				: API.jobsService.applyOnCompany;

			const job = yield call(endpoint, payload);

			yield put({ type: TYPES.APPLY_ON_COMPANY.SUCCESS, payload: job });

			cb && (yield call(cb));
		} catch (error) {
			message.error(
				JSON.stringify(error?.response?.data?.errorMessages) || error?.message || 'error',
			);

			yield put({ type: TYPES.APPLY_ON_COMPANY.FAILED, payload: error });
		}
	}

	function* getEducationTypesSaga() {
		try {
			const types = yield call(API.educationsService.getEducationTypes);

			yield put({ type: TYPES.GET_EDUCATION_TYPES.SUCCESS, payload: types });
		} catch (error) {
			yield put({ type: TYPES.GET_EDUCATION_TYPES.FAILED, payload: error });
		}
	}

	function* getEducationTypesByRegionIdSaga({ payload }) {
		try {
			const types = yield call(API.educationsService.getEducationTypesByClientId, payload);

			yield put({ type: TYPES.GET_EDUCATION_TYPES_BY_REGION_ID.SUCCESS, payload: types });
		} catch (error) {
			yield put({ type: TYPES.GET_EDUCATION_TYPES_BY_REGION_ID.FAILED, payload: error });
		}
	}

	function* getEmploymentTypesSaga() {
		try {
			const types = yield call(API.employmentsService.getEmploymentTypes);

			yield put({ type: TYPES.GET_EMPLOYMENT_TYPES.SUCCESS, payload: types });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYMENT_TYPES.FAILED, payload: error });
		}
	}

	function* getJobAssessmentSaga(action) {
		try {
			const jobAssessment = yield call(API.jobsService.getOpenJobAssessment, action.payload);

			yield put({ type: TYPES.GET_JOB_ASSESSMENT.SUCCESS, payload: jobAssessment });
		} catch (error) {
			yield put({ type: TYPES.GET_JOB_ASSESSMENT.FAILED, payload: error });
		}
	}

	function* getClientBrandingSaga(action) {
		try {
			const [branding] = yield call(API.clientsService.getOpenClientBranding, action.payload);

			yield put({ type: TYPES.GET_CLIENT_BRANDING.SUCCESS, payload: branding });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_BRANDING.FAILED, payload: error });
		}
	}

	function* getClientJobsSaga() {
		try {
			const clientJobs = yield call(API.clientsService.getClientJobs);

			yield put({ type: TYPES.GET_CLIENT_JOBS.SUCCESS, payload: clientJobs });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_JOBS.FAILED, payload: error });
		}
	}

	function* getClientUsersSaga() {
		try {
			const clientUsers = yield call(API.clientsService.getClientUsers);

			yield put({ type: TYPES.GET_CLIENT_USERS.SUCCESS, payload: clientUsers });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_USERS.FAILED, payload: error });
		}
	}

	function* getClientCopyJobsSaga(action) {
		try {
			const clientJobs = yield call(API.clientsService.getClientCopyJobs, action.payload);

			yield put({ type: TYPES.GET_CLIENT_COPY_JOBS.SUCCESS, payload: clientJobs });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_COPY_JOBS.FAILED, payload: error });
		}
	}

	function* getOpenClientJobsSaga(action) {
		try {
			const clientJobs = yield call(API.clientsService.getOpenClientJobs, action.payload);

			yield put({ type: TYPES.GET_CLIENT_JOBS.SUCCESS, payload: clientJobs });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_JOBS.FAILED, payload: error });
		}
	}

	function* getInterviewOpenSaga({ payload, params }) {
		try {
			const interviewData = yield call(API.interviewService.getOpenInterview, payload, params);

			yield put({ type: TYPES.GET_INTERVIEW_OPEN.SUCCESS, payload: interviewData });
		} catch (error) {
			yield put({ type: TYPES.GET_INTERVIEW_OPEN.FAILED, payload: error });
		}
	}

	function* declineInterviewOpenSaga({ payload, params, callback }) {
		try {
			yield call(API.interviewService.declineInterview, payload, params);

			yield put({ type: TYPES.DECLINE_INTERVIEW_OPEN.SUCCESS });
			message.success(MESSAGES.successfullyDeclinedInterview);

			yield call(callback);
		} catch (error) {
			yield put({ type: TYPES.DECLINE_INTERVIEW_OPEN.FAILED, payload: error });
		}
	}

	function* getAllBOQuestionnairesSaga({ payload }) {
		try {
			const questionnaires = yield call(
				API.questionnairesService.getAllBackofficeQuestionnaires,
				payload,
			);

			yield put({
				type: TYPES.GET_ALL_BO_QUESTIONNAIRES.SUCCESS,
				payload: questionnaires,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_ALL_BO_QUESTIONNAIRES.FAILED, payload: error });
		}
	}

	function* getAllAtsQuestionnairesSaga({ payload }) {
		try {
			const questionnaires = yield call(API.questionnairesService.getAllATSQuestionnaires, payload);

			yield put({
				type: TYPES.GET_ALL_ATS_QUESTIONNAIRES.SUCCESS,
				payload: questionnaires,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_ALL_ATS_QUESTIONNAIRES.FAILED, payload: error });
		}
	}

	function* getQuestionnaireByIdSaga({ payload }) {
		try {
			const questionnaire = yield call(API.questionnairesService.getQuestionnaireById, payload);

			yield put({
				type: TYPES.GET_QUESTIONNAIRE_BY_ID.SUCCESS,
				payload: questionnaire,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_QUESTIONNAIRE_BY_ID.FAILED, payload: error });
		}
	}

	function* getQuestionnaireUsersSaga({ payload }) {
		try {
			const questionnaireUsers = yield call(
				API.questionnairesService.getQuestionnaireUsers,
				payload,
			);

			yield put({
				type: TYPES.GET_QUESTIONNAIRE_USERS.SUCCESS,
				payload: questionnaireUsers,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_QUESTIONNAIRE_USERS.FAILED, payload: error });
		}
	}

	function* getCopyQuestionnairesSaga({ payload }) {
		try {
			const questionnaires = yield call(API.questionnairesService.getQuestionnairesCopy, payload);

			yield put({
				type: TYPES.GET_COPY_QUESTIONNAIRES.SUCCESS,
				payload: questionnaires,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_COPY_QUESTIONNAIRES.FAILED, payload: error });
		}
	}

	function* getSendQuestionnairesSaga({ id, payload }) {
		try {
			const questionnaires = yield call(
				API.questionnairesService.getQuestionnairesSendById,
				id,
				payload,
			);

			yield put({
				type: TYPES.GET_SEND_QUESTIONNAIRES_LIST.SUCCESS,
				payload: questionnaires,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_SEND_QUESTIONNAIRES_LIST.FAILED, payload: error });
		}
	}

	function* sendCandidateQuestionnaireSaga({ payload, jobId, callback }) {
		const failedCandidatesIds = [];
		const candidatesStatuses = [];
		const { questionnaireId, candidateIds } = payload;

		for (const id of candidateIds) {
			try {
				const res = yield call(API.questionnairesService.sendCandidateQuestionnaire, {
					questionnaireId,
					candidateId: id,
				});

				if (res?.videoQuestionnaireStatus) {
					candidatesStatuses.push(res?.videoQuestionnaireStatus);
				}
			} catch (error) {
				failedCandidatesIds.push(id);
			}
		}

		try {
			const clientJobs = yield call(API.clientsService.getClientJobs);
			yield put({ type: TYPES.GET_CLIENT_JOBS.SUCCESS, payload: clientJobs });

			if (jobId) {
				const job = yield call(API.jobsService.getJobById, jobId);
				yield put({ type: TYPES.GET_JOB_BY_ID.SUCCESS, payload: job });
			}

			yield put({
				type: TYPES.SEND_CANDIDATE_QUESTIONNAIRE.SUCCESS,
			});
		} catch (error) {
			yield put({ type: TYPES.SEND_CANDIDATE_QUESTIONNAIRE.FAILED, payload: error });
		} finally {
			if (failedCandidatesIds?.length) {
				message.info(
					`An error occurred when updating ${failedCandidatesIds?.length} of ${
						candidateIds?.length
					} selected item${candidateIds?.length ? 's' : ''}`,
				);
			} else if (
				!failedCandidatesIds?.length &&
				candidatesStatuses?.includes(QuestionnaireStatusEnum.Failed)
			) {
				message.error(MESSAGES.failedSentQuestionnaireWrongEmail);
			} else if (
				!failedCandidatesIds?.length &&
				candidatesStatuses?.includes(QuestionnaireStatusEnum.Sent)
			) {
				message.success(MESSAGES.successfullySentQuestionnaire);
			}
			if (callback) {
				yield call(callback, failedCandidatesIds);
			}
		}
	}

	function* resendCandidateQuestionnaireSaga({ payload, jobId, callback }) {
		try {
			const res = yield call(API.questionnairesService.resendQuestionnaireToCandidate, {
				candidateId: payload?.candidateIds[0],
			});

			yield put({
				type: TYPES.RESEND_CANDIDATE_QUESTIONNAIRE.SUCCESS,
			});

			const clientJobs = yield call(API.clientsService.getClientJobs);
			yield put({ type: TYPES.GET_CLIENT_JOBS.SUCCESS, payload: clientJobs });

			if (jobId) {
				const job = yield call(API.jobsService.getJobById, jobId);
				yield put({ type: TYPES.GET_JOB_BY_ID.SUCCESS, payload: job });
			}

			if (res?.videoQuestionnaireStatus?.includes(QuestionnaireStatusEnum.Failed)) {
				message.error(MESSAGES.failedSentQuestionnaireWrongEmail);
			}
			if (res?.videoQuestionnaireStatus?.includes(QuestionnaireStatusEnum.Sent)) {
				message.success(MESSAGES.successfullyReSentQuestionnaire);
			}

			if (callback) {
				yield call(callback);
			}
		} catch (error) {
			yield put({ type: TYPES.RESEND_CANDIDATE_QUESTIONNAIRE.FAILED, payload: error });
			yield put({ type: TYPES.GET_JOB_BY_ID.FAILED, payload: error });
		}
	}

	function* recallCandidateQuestionnaireSaga({ payload, jobId, callback }) {
		try {
			yield call(API.questionnairesService.recallQuestionnaireToCandidate, {
				candidateId: payload?.candidateIds[0],
			});

			yield put({
				type: TYPES.RECALL_CANDIDATE_QUESTIONNAIRE.SUCCESS,
			});

			const clientJobs = yield call(API.clientsService.getClientJobs);
			yield put({ type: TYPES.GET_CLIENT_JOBS.SUCCESS, payload: clientJobs });

			if (jobId) {
				const job = yield call(API.jobsService.getJobById, jobId);
				yield put({ type: TYPES.GET_JOB_BY_ID.SUCCESS, payload: job });
			}

			message.success(MESSAGES.successfullyRecalledQuestionnaire);

			if (callback) {
				yield call(callback);
			}
		} catch (error) {
			yield put({ type: TYPES.RECALL_CANDIDATE_QUESTIONNAIRE.FAILED, payload: error });
			yield put({ type: TYPES.GET_JOB_BY_ID.FAILED, payload: error });
		}
	}

	function* getVideoQuestionnaireByCandidateIdSaga({ payload }) {
		try {
			const videoQuestionnaireData = yield call(
				API.questionnairesService.getVideoQuestionnaireByCandidateId,
				payload,
			);

			yield put({
				type: TYPES.GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID.SUCCESS,
				payload: videoQuestionnaireData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID.FAILED, payload: error });
		}
	}

	function* getVideoQuestionnaireResultSaga({ payload }) {
		try {
			const questionnaireResult = yield call(
				API.questionnairesService.getVideoQuestionnaireResultByCandidateId,
				payload,
			);

			yield put({
				type: TYPES.GET_QUESTIONNAIRE_RESULT.SUCCESS,
				payload: questionnaireResult,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_QUESTIONNAIRE_RESULT.FAILED, payload: error });
		}
	}

	function* getQuestionnaireDurationsSaga() {
		try {
			const questionnairesDuration = yield call(API.questionnairesService.getQuestionnaireDuration);

			yield put({
				type: TYPES.GET_QUESTIONNAIRE_DURATION.SUCCESS,
				payload: questionnairesDuration,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_QUESTIONNAIRE_DURATION.FAILED, payload: error });
		}
	}

	function* saveQuestionnaireSaga({ payload, callback, id }) {
		try {
			let questionnaire;

			if (id) {
				questionnaire = yield call(
					API.questionnairesService.updateQuestionnaireVersionById,
					id,
					payload,
				);
				message.success('The questionnaire has been updated!');
			} else {
				questionnaire = yield call(API.questionnairesService.saveQuestionnaire, payload);
				message.success('The questionnaire has been created!');
			}

			yield put({ type: TYPES.SAVE_QUESTIONNAIRES.SUCCESS });

			yield call(callback, questionnaire?.id ? questionnaire?.id : '');
		} catch (error) {
			yield put({ type: TYPES.SAVE_QUESTIONNAIRES.FAILED, payload: error });
		}
	}

	function* deleteQuestionnaireByIdSaga({ payload, callback }) {
		try {
			yield call(API.questionnairesService.deleteQuestionnaireById, payload);

			yield put({
				type: TYPES.DELETE_QUESTIONNAIRE_BY_ID.SUCCESS,
			});
			message.success(MESSAGES.successfullyDeletedQuestionnaire);

			yield call(callback);
		} catch (error) {
			yield put({ type: TYPES.DELETE_QUESTIONNAIRE_BY_ID.FAILED, payload: error });
		}
	}

	function* archiveUnarchiveQuestionnaireByIdSaga({ payload, params, callback }) {
		try {
			yield call(API.questionnairesService.archiveUnarchiveQuestionnaireById, payload, params);

			yield put({
				type: TYPES.ARCHIVE_UNARCHIVE_QUESTIONNAIRE_BY_ID.SUCCESS,
			});

			yield call(callback);

			yield call(
				message.success,
				params.active ? MESSAGES.successfullyUnarchived : MESSAGES.successfullyArchived,
			);
		} catch (error) {
			yield put({ type: TYPES.ARCHIVE_UNARCHIVE_QUESTIONNAIRE_BY_ID.FAILED, payload: error });
		}
	}

	function* updateCandidateRankSaga({ payload, candidateAppId, jobId, atsReducerChanges, cb }) {
		try {
			const data = yield call(API.candidateApplicationService.updateCandidatesRank, {
				rank: payload,
				candidateAppId,
			});

			if (atsReducerChanges) {
				yield put({
					type: atsDucks.atsActionTypes.UPDATE_CANDIDATE_RANK,
					payload: { candidateData: data, candidateAppId, jobId },
				});
			} else {
				yield put({
					type: TYPES.UPDATE_CANDIDATE_RANK.SUCCESS,
					payload: { candidateData: data, candidateAppId, jobId },
				});
			}
		} catch (error) {
			yield put({ type: TYPES.UPDATE_CANDIDATE_RANK.FAILED, payload: error });
		} finally {
			if (cb) {
				cb();
			}
		}
	}

	function* getJobIndustriesSaga() {
		try {
			const jobIndustries = yield call(API.jobsService.getJobIndustries);

			yield put({ type: TYPES.GET_JOB_INDUSTRIES.SUCCESS, payload: jobIndustries });
		} catch (e) {
			yield put({ type: TYPES.GET_JOB_INDUSTRIES.FAILED, payload: e });
		}
	}

	function* getTicketTypesSaga() {
		try {
			const ticketTypes = yield call(API.ticketsService.getTicketTypes);

			yield put({ type: TYPES.GET_TICKET_TYPES.SUCCESS, payload: ticketTypes });
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_TICKET_TYPES.FAILED, payload: e });
		}
	}

	function* getTicketStatusesSaga() {
		try {
			const ticketStatuses = yield call(API.ticketsService.getTicketStatuses);

			yield put({ type: TYPES.GET_TICKET_STATUSES.SUCCESS, payload: ticketStatuses });
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_TICKET_STATUSES.FAILED, payload: e });
		}
	}

	function* getSMSCountSaga() {
		try {
			const smsCount = yield call(API.clientsService.getSMSCount);

			yield put({ type: TYPES.GET_SMS_COUNT.SUCCESS, payload: smsCount });
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_SMS_COUNT.FAILED, payload: e });
		}
	}

	function* getSMSOrderPriceSaga() {
		try {
			const smsPrice = yield call(API.pricesService.getSmsPrice);

			yield put({ type: TYPES.GET_SMS_ORDER_PRICE.SUCCESS, payload: smsPrice });
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_SMS_ORDER_PRICE.FAILED, payload: e });
		}
	}

	function* getVideoInterviewOrderPriceSaga() {
		try {
			const videoInterviewPrice = yield call(API.pricesService.getVideoInterviewPrice);

			yield put({
				type: TYPES.GET_VIDEO_INTERVIEW_ORDER_PRICE.SUCCESS,
				payload: videoInterviewPrice,
			});
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_VIDEO_INTERVIEW_ORDER_PRICE.FAILED, payload: e });
		}
	}

	function* getEmailAutoFilledDataSaga() {
		try {
			const placeholderData = yield call(API.usersService.getEmailPlaceholder);

			yield put({
				type: TYPES.GET_EMAIL_AUTO_FILLED_DATA.SUCCESS,
				payload: placeholderData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_EMAIL_AUTO_FILLED_DATA.FAILED });
		}
	}

	function* getEmailTemplatesSaga({ isAtsRoute }) {
		try {
			const emailTemplatesData = yield call(
				isAtsRoute
					? API.usersService.getAtsEmailDefaultTemplates
					: API.usersService.getEmailDefaultTemplates,
			);

			yield put({
				type: TYPES.GET_EMAIL_DEFAULT_TEMPLATES.SUCCESS,
				payload: emailTemplatesData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_EMAIL_DEFAULT_TEMPLATES.FAILED });
		}
	}

	function* updateEmailTemplatesSaga({ payload, isAtsRoute, callback }) {
		try {
			yield call(
				isAtsRoute
					? API.usersService.updateAtsEmailDefaultTemplates
					: API.usersService.updateEmailDefaultTemplates,
				payload,
			);

			yield put({
				type: TYPES.UPDATE_EMAIL_DEFAULT_TEMPLATES.SUCCESS,
			});

			yield call(message.success, MESSAGES.successfullyUpdated);

			yield call(callback);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_EMAIL_DEFAULT_TEMPLATES.FAILED });
		}
	}

	function* getPricesByClientIdSaga({ payload, cb }) {
		try {
			const clientId = payload;

			const clientPrices = yield call(API.pricesService.getPricesByClientId, clientId);

			yield put({
				type: TYPES.GET_PRICES_BY_CLIENT_ID.SUCCESS,
				payload: { clientId: clientId, prices: clientPrices },
			});

			if (cb) {
				yield call(cb, clientPrices);
			}
		} catch (error) {
			yield put({ type: TYPES.GET_PRICES_BY_CLIENT_ID.FAILED, payload: error });
		}
	}

	function* deleteAssessmentFormSaga(action) {
		try {
			const { data, isBO = false } = action.payload;
			const url = isBO
				? API.assessmentService.deleteAssessmentBackofficeFormByJobId
				: API.assessmentService.deleteAssessmentFormByJobId;

			yield call(url, { job: data });

			message.success(MESSAGES.successfullyDeleted);
		} catch (error) {
			console.error(error);
		}
	}

	return {
		getContractsSaga,
		getContractStatusesSaga,
		updateContractStatusSaga,
		approvePendingContractSaga,
		acceptContractByClientIdSaga,
		getContractsByClientIdSaga,
		getContractReceiptSaga,
		getContractByIdSaga,
		deleteContractByIdSaga,
		getContractVatSaga,
		getClientByIdSaga,
		updateClientSaga,
		getCreditsSaga,
		getJobByIdSaga,
		updateApplicationStatusSaga,
		getOpenJobByIdSaga,
		saveFreeJobSaga,
		savePaidJobSaga,
		updateFreeJobSaga,
		updatePaidJobSaga,
		getJobTypesSaga,
		getEducationTypesSaga,
		getEducationTypesByRegionIdSaga,
		getEmploymentTypesSaga,
		getClientBrandingSaga,
		getClientJobsSaga,
		getClientUsersSaga,
		getClientCopyJobsSaga,
		getOpenClientJobsSaga,
		getJobAssessmentSaga,
		applyOnJobSaga,
		applyOnCompanySaga,
		getInterviewOpenSaga,
		declineInterviewOpenSaga,
		getAllBOQuestionnairesSaga,
		getAllAtsQuestionnairesSaga,
		getQuestionnaireByIdSaga,
		getQuestionnaireUsersSaga,
		getCopyQuestionnairesSaga,
		getSendQuestionnairesSaga,
		sendCandidateQuestionnaireSaga,
		resendCandidateQuestionnaireSaga,
		recallCandidateQuestionnaireSaga,
		getVideoQuestionnaireByCandidateIdSaga,
		getQuestionnaireDurationsSaga,
		saveQuestionnaireSaga,
		deleteQuestionnaireByIdSaga,
		archiveUnarchiveQuestionnaireByIdSaga,
		updateCandidateRankSaga,
		getCandidateByIdSaga,
		getJobIndustriesSaga,
		getTicketTypesSaga,
		getTicketStatusesSaga,
		getVideoQuestionnaireResultSaga,
		getSMSCountSaga,
		getSMSOrderPriceSaga,
		getVideoInterviewOrderPriceSaga,
		getEmailAutoFilledDataSaga,
		getEmailTemplatesSaga,
		updateEmailTemplatesSaga,
		getPricesByClientIdSaga,
		deleteAssessmentFormSaga,
	};
};
