import React, { type FC, useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { isEqual } from 'lodash';

import Box from 'components/Box';
import Spinner from 'components/Spinner';
import { useUnmount, usePrevious } from 'hooks';
import CollapseList from 'modules/ATS/components/CollapseList';
import { atsDucks } from 'modules/ATS/ducks';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import SendQuestionnaireModal from 'modules/Common/components/SendQuestionnareModal';
import { commonDucks } from 'modules/Common/ducks';
import {
	IQuestionnairesPaginated,
	JobDataType,
	CandidateStatusType,
	IUpdateCandidateRankProps,
	SendCandidateQuestionnaireType,
	QueryParamsType,
	QueryParamsSearchType,
} from 'modules/Common/types';
import { searchCandidates } from 'modules/Common/utils/commonHelpers';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { TStyled } from 'theme';
import { GenericType, Routes, SubscriptionPlansType, UserRolesType } from 'types';

import { Styled } from '../CandidatesApplication.styled';
import CandidatesApplicationTableController from '../CandidatesApplicationTableController';

type CandidatesApplicationTableListProps = {
	jobsData: JobDataType[];
	updateCandidateStatus: CandidateStatusType;
	sendQuestionnairesData: IQuestionnairesPaginated;
	getSendQuestionnairesList: (jobId: number, params: { page: number; size: number }) => void;
	sendCandidateQuestionnaire: SendCandidateQuestionnaireType;
	resendCandidateQuestionnaire: SendCandidateQuestionnaireType;
	recallCandidateQuestionnaire: SendCandidateQuestionnaireType;
	updateCandidateRankRequested: (props: IUpdateCandidateRankProps) => void;
	handleSelectSpecificCandidate?: (id: number) => void;
	saveSearchQueryParams: (params: QueryParamsSearchType | null) => void;
	subscriptionPlans: SubscriptionPlansType;
	queryParams: QueryParamsType;
	isCollapsable?: boolean;
	loading: GenericType;
	roles: UserRolesType;
};

export const CandidatesApplicationTableListContainer: FC<CandidatesApplicationTableListProps> = ({
	jobsData,
	updateCandidateStatus,
	sendQuestionnairesData,
	getSendQuestionnairesList,
	sendCandidateQuestionnaire,
	resendCandidateQuestionnaire,
	recallCandidateQuestionnaire,
	updateCandidateRankRequested,
	handleSelectSpecificCandidate,
	saveSearchQueryParams,
	subscriptionPlans,
	queryParams,
	roles,
	isCollapsable,
	loading,
}) => {
	const { jobId: jobIdParam = '' } = useParams();
	const { searchKey, searchResult } = queryParams?.search || {};
	const { isAtsSuperUser } = roles;

	const [jobId, setJobId] = useState<string | number>(jobIdParam);
	const [candidateIds, setCandidateIds] = useState<number[]>([]);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [selectedJobId, setSelectedJobId] = useState<number>();
	const [selectedCandidateIds, setSelectedCandidateIds] = useState<number[] | []>([]);
	const [filteredJobsData, setFilteredJobsData] = useState<Partial<JobDataType[]> | null>(jobsData);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [lastSearch, setLastSearch] = useState<null | string>(null);
	const previousJobsData = usePrevious(jobsData);

	useEffect(() => {
		if (lastSearch && !isEqual(jobsData, previousJobsData)) {
			handleSearchCandidate(lastSearch);
		}
	}, [jobsData]);

	const handleOpenModal = useCallback(
		(ids: number[], job: number) => {
			if (subscriptionPlans?.isPremiumPlan) {
				getSendQuestionnairesList(job, {
					page: sendQuestionnairesData?.pageIndex,
					size: sendQuestionnairesData?.pageSize,
				});
			}
			setCandidateIds(() => ids);
			setJobId(() => job);
			setModalOpen(() => true);
		},
		[sendQuestionnairesData, jobsData],
	);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const handleTableChange = useCallback(
		(page: number, size: number) => {
			jobId &&
				getSendQuestionnairesList(+jobId, {
					page,
					size: size ?? sendQuestionnairesData?.pageIndex,
				});
		},
		[jobId],
	);

	const handleSendQuestionnaire = useCallback(
		(questionnaireId: number) => {
			setLoading(true);

			jobId &&
				questionnaireId &&
				sendCandidateQuestionnaire({ questionnaireId, candidateIds }, +jobId, (failedIds) => {
					setModalOpen(false);
					if (failedIds?.length) {
						failedIds?.length && setSelectedCandidateIds(failedIds);
					} else {
						setSelectedCandidateIds([]);
					}

					setLoading(false);
				});
		},
		[candidateIds],
	);

	const handleResendCandidateQuestionnaire = useCallback((id: number) => {
		if (subscriptionPlans?.isPremiumPlan) {
			resendCandidateQuestionnaire({ candidateIds: [id] }, +jobId);
		} else {
			setModalOpen(true);
		}
	}, []);

	const handleRecallCandidateQuestionnaire = useCallback((id: number) => {
		if (subscriptionPlans?.isPremiumPlan) {
			setLoading(true);

			recallCandidateQuestionnaire({ candidateIds: [id] }, +jobId, () => setLoading(false));
		} else {
			setModalOpen(true);
		}
	}, []);

	const handleCandidateRank = useCallback(
		(id: string | number, value: number, jobIdFromTable?: string | number, cb?: () => void) => {
			updateCandidateRankRequested({ candidateAppId: id, rank: value, jobId: jobIdFromTable, cb });
		},
		[],
	);

	const handleSearchCandidate = useCallback(
		(key: string) => {
			const filteredJobs = searchCandidates(key, jobsData);
			const searchResultLength = key?.length
				? filteredJobs?.reduce((acc, cur) => (acc += cur?.candidates?.length), 0)
				: 0;
			saveSearchQueryParams({ searchKey: key, searchResult: searchResultLength });

			setFilteredJobsData(() => filteredJobs || [jobsData]);
			setLastSearch(key);
		},
		[jobsData],
	);

	const sendQuestionnaireLink =
		candidateIds?.length === 1
			? `${Routes.ATS}${Routes.QuestionnairesSend}/${candidateIds[0]}`
			: `${Routes.ATS}${Routes.QuestionnairesSend}`;

	useUnmount(() => saveSearchQueryParams(null));

	const itemsData = useMemo(() => {
		const preparedListJobs = searchKey?.length
			? filteredJobsData?.filter((job) => job?.candidates?.length)
			: jobsData;

		return preparedListJobs?.map((job) => ({
			key: job?.id,
			label: (
				<Link to={`${Routes.ATS}${Routes.Jobs}/${job?.id}`}>
					{job?.title} ({job?.candidates?.length})
				</Link>
			),
			showArrow: !!job?.candidates?.length,
			collapsible: !job?.candidates?.length ? 'disabled' : 'header',
			children: (
				<CandidatesApplicationTableController
					item={job}
					handleOpenModal={handleOpenModal}
					updateCandidateStatus={updateCandidateStatus}
					handleResendCandidateQuestionnaire={handleResendCandidateQuestionnaire}
					handleRecallCandidateQuestionnaire={handleRecallCandidateQuestionnaire}
					handleSendQuestionnaire={handleSendQuestionnaire}
					handleCandidateRank={handleCandidateRank}
					subscriptionPlans={subscriptionPlans}
					setSelectedCandidateIds={setSelectedCandidateIds}
					selectedCandidateIds={selectedCandidateIds}
					setSelectedJobId={setSelectedJobId}
					selectedJobId={selectedJobId}
					setLoading={setLoading}
					isAtsSuperUser={isAtsSuperUser}
				/>
			),
		}));
	}, [filteredJobsData, jobsData, selectedJobId, subscriptionPlans, selectedCandidateIds]);

	return (
		<Box>
			<Styled.Header>
				<SearchFilterSortWrapper
					search={{
						onSearch: handleSearchCandidate,
						placeholder: 'Search a candidate',
					}}
				/>
				{!!searchKey?.length && (
					<TStyled.SearchResultInfo>Search Result {searchResult}</TStyled.SearchResultInfo>
				)}
			</Styled.Header>

			<Styled.Main>
				{isLoading && <Spinner fixed overlay />}
				<>
					{isCollapsable ? (
						<CollapseList items={itemsData} />
					) : (
						<CandidatesApplicationTableController
							item={searchKey ? filteredJobsData?.[0] : jobsData?.[0]}
							handleOpenModal={handleOpenModal}
							updateCandidateStatus={updateCandidateStatus}
							handleResendCandidateQuestionnaire={handleResendCandidateQuestionnaire}
							handleRecallCandidateQuestionnaire={handleRecallCandidateQuestionnaire}
							handleCandidateRank={handleCandidateRank}
							subscriptionPlans={subscriptionPlans}
							handleSelectSpecificCandidate={handleSelectSpecificCandidate}
							setSelectedCandidateIds={setSelectedCandidateIds}
							selectedCandidateIds={selectedCandidateIds}
							setSelectedJobId={setSelectedJobId}
							selectedJobId={selectedJobId}
							setLoading={setLoading}
							isAtsSuperUser={isAtsSuperUser}
						/>
					)}
				</>
			</Styled.Main>

			<SendQuestionnaireModal
				jobId={+jobId}
				open={modalOpen}
				candidateIds={candidateIds}
				subscriptionPlans={subscriptionPlans}
				sendQuestionnaireLink={sendQuestionnaireLink}
				sendQuestionnairesData={sendQuestionnairesData}
				handleSendQuestionnaire={handleSendQuestionnaire}
				onTableChange={handleTableChange}
				onTablePageSizeChange={handleTableChange}
				onClose={handleCloseModal}
				loading={
					!!loading?.sendCandidateQuestionnaireLoad || !!loading?.getSendQuestionnairesListLoad
				}
			/>
		</Box>
	);
};

export default connect(
	(state) => ({
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		sendQuestionnairesData: commonDucks.commonSelectors.getSendQuestionnaires(state),
		queryParams: commonDucks.commonSelectors.getQueryParamsState(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getSendQuestionnairesList: commonDucks.commonActions.getSendQuestionnairesListRequested,
		sendCandidateQuestionnaire: commonDucks.commonActions.sendCandidateQuestionnaireRequested,
		resendCandidateQuestionnaire: commonDucks.commonActions.resendCandidateQuestionnaireRequested,
		recallCandidateQuestionnaire: commonDucks.commonActions.recallCandidateQuestionnaireRequested,
		updateCandidateRankRequested: commonDucks.commonActions.updateCandidateRankRequested,
		saveSearchQueryParams: commonDucks.commonActions.saveSearchQueryParamsRequested,
	},
)(CandidatesApplicationTableListContainer);
