import { combineReducers } from 'redux';

import { takeLatest } from 'redux-saga/effects';

import createLoading from 'modules/Common/ducks/loading';

import { createActions } from './actions';
import { createActionsTypes } from './constants';
import { HRModuleReducer } from './reducer';
import { createSagas } from './sagas';
import { createSelectors } from './selectors';

type HrModuleDucksProps = {
	prefix: string;
	root: string;
};

function getHrDucks(props: HrModuleDucksProps) {
	const { prefix, root } = props;
	const PREFIX = prefix;

	const TYPES = createActionsTypes(PREFIX);
	const ACTIONS = createActions(TYPES);
	const SELECTORS = createSelectors(root);

	const SAGAS = createSagas(TYPES, PREFIX, SELECTORS, ACTIONS, props);
	const hrReducer = HRModuleReducer(TYPES);

	const { reducer: loadingReducer } = createLoading({
		GET_EMPLOYEE_STATUSES: TYPES.GET_EMPLOYEE_STATUSES,
		CREATE_EMPLOYEE: TYPES.CREATE_EMPLOYEE,
		VIEW_EMPLOYEES_LIST: TYPES.VIEW_EMPLOYEES_LIST,
		ARCHIVE_EMPLOYEE: TYPES.ARCHIVE_EMPLOYEE,
		UNARCHIVE_EMPLOYEE: TYPES.UNARCHIVE_EMPLOYEE,
		CHANGE_EMPLOYEE_STATUS: TYPES.CHANGE_EMPLOYEE_STATUS,
		GET_EMPLOYEE_BY_ID: TYPES.GET_EMPLOYEE_BY_ID,
		SEND_FIRST_DAY_INVITATION: TYPES.SEND_FIRST_DAY_INVITATION,
		GET_EMPLOYEE_ATTACHMENTS: TYPES.GET_EMPLOYEE_ATTACHMENTS,
		SAVE_EMPLOYEE_ATTACHMENTS: TYPES.SAVE_EMPLOYEE_ATTACHMENTS,
		EDIT_EMPLOYEE: TYPES.EDIT_EMPLOYEE,
		EDIT_EMPLOYEE_PASSWORD: TYPES.EDIT_EMPLOYEE_PASSWORD,
		GET_OWN_PROFILE: TYPES.GET_OWN_PROFILE,
		EDIT_OWN_PROFILE: TYPES.EDIT_OWN_PROFILE,
		EDIT_OWN_PASSWORD: TYPES.EDIT_OWN_PASSWORD,
		GET_EMPLOYEE_SHORT_LIST: TYPES.GET_EMPLOYEE_SHORT_LIST,
		GET_ONOFF_BOARDING_SETTINGS: TYPES.GET_ONOFF_BOARDING_SETTINGS,
		SET_ONOFF_BOARDING_SETTINGS: TYPES.SET_ONOFF_BOARDING_SETTINGS,
		GET_HR_VACATION_SETTINGS: TYPES.GET_HR_VACATION_SETTINGS,
		CREATE_UPDATE_VACATION_SETTINGS: TYPES.CREATE_UPDATE_VACATION_SETTINGS,
		DELETE_VACATION_SETTINGS: TYPES.DELETE_VACATION_SETTINGS,
		GET_EMPLOYEE_OPTIONS: TYPES.GET_EMPLOYEE_OPTIONS,
		GET_WORK_WEEK_SETTINGS: TYPES.GET_WORK_WEEK_SETTINGS,
		UPDATE_WORK_WEEK_SETTINGS: TYPES.UPDATE_WORK_WEEK_SETTINGS,
		GET_TIME_OFF_REQUEST_DURATION: TYPES.GET_TIME_OFF_REQUEST_DURATION,
		CREATE_TIME_OFF_REQUEST: TYPES.CREATE_TIME_OFF_REQUEST,
		UPDATE_TIME_OFF_REQUEST: TYPES.UPDATE_TIME_OFF_REQUEST,
		GET_TIME_OFF_STATICS_PER_EMPLOYEE: TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE,
		GET_ALL_TIME_OFF_REQUESTS: TYPES.GET_ALL_TIME_OFF_REQUESTS,
		GET_BANK_HOLIDAYS: TYPES.GET_BANK_HOLIDAYS,
		APPROVE_TIME_OFF_REQUEST: TYPES.APPROVE_TIME_OFF_REQUEST,
		APPROVE_ALL_TIME_OFF_REQUESTS: TYPES.APPROVE_ALL_TIME_OFF_REQUESTS,
		DECLINE_TIME_OFF_REQUEST: TYPES.DECLINE_TIME_OFF_REQUEST,
		DELETE_TIME_OFF_REQUEST: TYPES.DELETE_TIME_OFF_REQUEST,
		CREATE_CUSTOM_HOLIDAY: TYPES.CREATE_CUSTOM_HOLIDAY,
		UPDATE_CUSTOM_HOLIDAY: TYPES.UPDATE_CUSTOM_HOLIDAY,
		DELETE_CUSTOM_HOLIDAY: TYPES.DELETE_CUSTOM_HOLIDAY,
	});

	function* hrSaga() {
		yield takeLatest(TYPES.GET_EMPLOYEE_STATUSES.REQUESTED, SAGAS.getEmployeeStatusesSaga);
		yield takeLatest(TYPES.CREATE_EMPLOYEE.REQUESTED, SAGAS.createEmployeeSaga);
		yield takeLatest(TYPES.VIEW_EMPLOYEES_LIST.REQUESTED, SAGAS.getEmployeesListSaga);
		yield takeLatest(TYPES.ARCHIVE_EMPLOYEE.REQUESTED, SAGAS.archiveEmployeeSaga);
		yield takeLatest(TYPES.UNARCHIVE_EMPLOYEE.REQUESTED, SAGAS.unArchiveEmployeeSaga);
		yield takeLatest(TYPES.CHANGE_EMPLOYEE_STATUS.REQUESTED, SAGAS.changeEmployeeStatusSaga);
		yield takeLatest(TYPES.GET_EMPLOYEE_BY_ID.REQUESTED, SAGAS.getEmployeeByIdSaga);
		yield takeLatest(TYPES.SEND_FIRST_DAY_INVITATION.REQUESTED, SAGAS.sendFirstDayInvitationSaga);
		yield takeLatest(TYPES.GET_EMPLOYEE_ATTACHMENTS.REQUESTED, SAGAS.getEmployeeAttachmentsSaga);
		yield takeLatest(TYPES.SAVE_EMPLOYEE_ATTACHMENTS.REQUESTED, SAGAS.saveEmployeeAttachmentsSaga);
		yield takeLatest(TYPES.EDIT_EMPLOYEE.REQUESTED, SAGAS.editEmployeeSaga);
		yield takeLatest(TYPES.EDIT_OWN_PROFILE.REQUESTED, SAGAS.editOwnProfileSaga);
		yield takeLatest(TYPES.GET_OWN_PROFILE.REQUESTED, SAGAS.getOwnProfileSaga);
		yield takeLatest(TYPES.GET_EMPLOYEE_SHORT_LIST.REQUESTED, SAGAS.getEmployeeShortListSaga);
		yield takeLatest(
			TYPES.GET_ONOFF_BOARDING_SETTINGS.REQUESTED,
			SAGAS.getOnOffBoardingSettingsSaga,
		);
		yield takeLatest(
			TYPES.SET_ONOFF_BOARDING_SETTINGS.REQUESTED,
			SAGAS.setOnOffBoardingSettingsSaga,
		);
		yield takeLatest(TYPES.GET_HR_VACATION_SETTINGS.REQUESTED, SAGAS.getHRVacationSettingsSaga);
		yield takeLatest(
			TYPES.CREATE_UPDATE_VACATION_SETTINGS.REQUESTED,
			SAGAS.createUpdateVacationSettingsSaga,
		);
		yield takeLatest(TYPES.DELETE_VACATION_SETTINGS.REQUESTED, SAGAS.deleteVacationSettingsSaga);
		yield takeLatest(TYPES.GET_EMPLOYEE_OPTIONS.REQUESTED, SAGAS.getEmployeeOptionsSaga);
		yield takeLatest(TYPES.GET_WORK_WEEK_SETTINGS.REQUESTED, SAGAS.getWorkWeekSettingsSaga);
		yield takeLatest(TYPES.UPDATE_WORK_WEEK_SETTINGS.REQUESTED, SAGAS.updateWorkWeekSettingsSaga);
		yield takeLatest(
			TYPES.GET_TIME_OFF_REQUEST_DURATION.REQUESTED,
			SAGAS.getTimeOffRequestDurationSaga,
		);
		yield takeLatest(TYPES.CREATE_TIME_OFF_REQUEST.REQUESTED, SAGAS.createTimeOffRequestSaga);
		yield takeLatest(TYPES.UPDATE_TIME_OFF_REQUEST.REQUESTED, SAGAS.updateTimeOffRequestSaga);
		yield takeLatest(
			TYPES.GET_TIME_OFF_STATICS_PER_EMPLOYEE.REQUESTED,
			SAGAS.getTimeOffStaticsPerEmployeeSaga,
		);
		yield takeLatest(TYPES.GET_ALL_TIME_OFF_REQUESTS.REQUESTED, SAGAS.getAllTimeOffRequestsSaga);
		yield takeLatest(TYPES.GET_BANK_HOLIDAYS.REQUESTED, SAGAS.getBankHolidaysSaga);
		yield takeLatest(TYPES.APPROVE_TIME_OFF_REQUEST.REQUESTED, SAGAS.approveTimeOffRequestSaga);
		yield takeLatest(
			TYPES.APPROVE_ALL_TIME_OFF_REQUESTS.REQUESTED,
			SAGAS.approveAllTimeOffRequestsSaga,
		);
		yield takeLatest(TYPES.DECLINE_TIME_OFF_REQUEST.REQUESTED, SAGAS.declineTimeOffRequestSaga);
		yield takeLatest(TYPES.DELETE_TIME_OFF_REQUEST.REQUESTED, SAGAS.deleteTimeOffRequestSaga);
		yield takeLatest(TYPES.CREATE_CUSTOM_HOLIDAY.REQUESTED, SAGAS.createCustomHolidaySaga);
		yield takeLatest(TYPES.UPDATE_CUSTOM_HOLIDAY.REQUESTED, SAGAS.updateCustomHolidaySaga);
		yield takeLatest(TYPES.DELETE_CUSTOM_HOLIDAY.REQUESTED, SAGAS.deleteCustomHolidaySaga);
	}

	const reducer = combineReducers({ data: hrReducer, loadingReducer });

	return {
		reducer,
		hrSaga,
		hrActions: { ...ACTIONS },
		hrSelectors: SELECTORS,
		hrActionTypes: TYPES,
		hrSagas: SAGAS,
	};
}

export const hrDucks = getHrDucks({ prefix: 'hr', root: 'hrReducer' });
