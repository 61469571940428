import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const FormFieldsWrap = styled.div`
		background-color: ${COLORS.white};
		border-radius: 10px;
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 32px 32px 24px;
		margin-bottom: 24px;
	`;

	export const ButtonBox = styled.div`
		display: flex;
		justify-content: flex-end;
		margin-bottom: 32px;
		gap: 16px;
	`;

	export const Button = styled(ButtonComponent)`
		background: none;
		border: none;
	`;
}
