import { DeleteCandidateEnum } from './CandidatesApplicationContainer.types';

export const CandidatesApplicationList = [
	'candidateApplicationId',
	'candidateEmail',
	'jobTitle',
	'candidate',
	'client',
	'hidden',
];

export const DELETE_CANDIDATE_MESSAGES = {
	[DeleteCandidateEnum.DATABASE]:
		'Are you sure you want to remove this candidate from Candidate Database?',
	[DeleteCandidateEnum.CLIENT]: 'Are you sure you want to remove this candidate from Client?',
};
